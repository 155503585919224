import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import axios, { CancelTokenSource } from 'axios'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient, cancelTokenStore } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getUserCurrentRole,
  isDoctor,
} from 'services/userDetailsService'
import { getSpecializationsString } from 'utils/fhirResourcesHelper'
import { getExpandedAppointmentFromBundle } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { logger } from 'utils/logger'
import { FHIRDefaultApiClient } from 'services/fhirDefaultServices'
import { FHIRDefaultApiClientWithoutServiceKey } from 'services/fhirDefaultWithoutService'
import { QuestionSearchStatus } from './questionSearchStatus'

const initialState: QuestionSearchStatus = {
  searchingAppointments: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingAppointments: false,
  isEditAllowed: false,
}

const questionSearchSlice = createSlice({
  name: 'questionSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<QuestionSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.isEditAllowed = action.payload.isEditAllowed
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingAppointments = action.payload.searchingAppointments
      state.resultsAvailable = action.payload.resultsAvailable
      state.question = action.payload.question
      state.prSelectedCoding = action.payload.prSelectedCoding
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingAppointments =
        action.payload.errorWhileSearchingAppointments
    },
    resetState(state, action: PayloadAction<QuestionSearchStatus>) {
      state.errorReason = undefined
      state.isEditAllowed = false
      state.searchingAppointments = false
      state.noResultsAvailable = false

      state.question = undefined
      state.prSelectedCoding = undefined
      state.resultsAvailable = false

      state.errorWhileSearchingAppointments = false
    },
  },
})

export const resetSearchQuestion =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: QuestionSearchStatus = {
      errorReason: undefined,
      searchingAppointments: false,
      noResultsAvailable: false,
      question: undefined,
      prSelectedCoding: undefined,
      resultsAvailable: false,
      errorWhileSearchingAppointments: false,
      isEditAllowed: false,
    }
    dispatch(questionSearchSlice.actions.resetState(state))
  }

export const requestQuestionForDoctor =
  (doctorId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: QuestionSearchStatus = {
      searchingAppointments: true,
      errorWhileSearchingAppointments: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      isEditAllowed: false,
    }
    dispatch(questionSearchSlice.actions.updatedStatus(state))
    try {
      const fhirApi: FHIRDefaultApiClientWithoutServiceKey =
        new FHIRDefaultApiClientWithoutServiceKey()
      const response: any = await fhirApi.doGetResource(
        `Questionnaire?questionnaire-practitioner=${doctorId}`
      )
      logger.info('ChargeItemDefinition Fetching')
      logger.info(response)

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp) {
        if (response.entry && response?.entry.length > 0) {
          const questionData: R4.IQuestionnaire = response.entry[0]
            .resource as R4.IQuestionnaire

          const codingArray: R4.ICoding[] = []
          if (
            questionData &&
            questionData.item &&
            questionData.item.length > 0
          ) {
            for (let i = 0; i < questionData.item.length; i++) {
              if (questionData.item[i].code) {
                const codeData = questionData.item[i].code
                if (codeData && codeData.length > 0) {
                  codingArray.push(codeData[0])
                }
              }
            }

            state.resultsAvailable = true
            state.searchingAppointments = false
            state.question = response.entry[0].resource as R4.IQuestionnaire
            state.noResultsAvailable = false
            state.errorReason = undefined
            state.prSelectedCoding = codingArray
            state.isEditAllowed = !(codingArray.length > 0)

            state.errorWhileSearchingAppointments = false
            dispatch(questionSearchSlice.actions.updatedStatus(state))
          } else {
            const errorSearchDoctor: QuestionSearchStatus = {
              searchingAppointments: false,
              errorWhileSearchingAppointments: false,
              resultsAvailable: false,
              noResultsAvailable: true,
              isEditAllowed: false,
            }
            dispatch(
              questionSearchSlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: QuestionSearchStatus = {
            searchingAppointments: false,
            errorWhileSearchingAppointments: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            isEditAllowed: false,
          }
          dispatch(questionSearchSlice.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      logger.error(error)
      //   const errorSearchDoctor: AppointmentSearchStatus = {
      //     searchingAppointments: false,
      //     errorWhileSearchingAppointments: true,
      //     resultsAvailable: false,
      //     errorReason: 'searching',
      //   }
      //   dispatch(appointmentSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default questionSearchSlice.reducer
