/* eslint-disable guard-for-in */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ICatalogEntry,
  ICodeableConcept,
  INutritionOrder,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { DateWiseDietPlans } from 'models/dateWiseDietPlanData'
import { Diet, DietOfVisit } from 'models/diet'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import moment from 'moment'
import { getEncounterIdFromNutritionOrder } from 'redux/consultation/dietPlan/addDietPlan/addDietPlanSlice'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import { getCarePlanOfPatientWithCurrentUnit } from 'utils/careplan_utils/careplan_utils'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultDisplayOfSystemFromCodableConcept,
  getExtensionValueOfUrl,
  getNameFromHumanName,
} from 'utils/fhirResourcesHelper'
import { getCatalogDetailsFromIds } from './catalogHelper'
import { getUniqueTempId } from './idHelpers'

interface DateWiseNutritionOrders {
  date: string
  nutritionOrders: R4.INutritionOrder[]
}

export function getDietPlansPlanFromBundle(
  responseBundle: R4.IBundle,
  serviceRequest: R4.IServiceRequest,
  encounterId: string,
  plan?: R4.ICarePlan,
  isIpdDiet?: boolean
): Diet[] {
  const planData: Diet[] = []

  const dateWiseOrders: DateWiseNutritionOrders[] = []
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          const allNutritionOrders: R4.INutritionOrder[] = []
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (e.resource?.resourceType === 'NutritionOrder') {
                allNutritionOrders.push(e.resource)
              }
            })
          }
          allNutritionOrders.forEach((item) => {
            const dateDet = moment(
              item.oralDiet?.schedule![0].event![0]
            ).format('YYYY-MM-DD')
            const index = dateWiseOrders.findIndex((s) => s.date === dateDet)
            if (index < 0) {
              dateWiseOrders.push({
                date: dateDet,
                nutritionOrders: [item],
              })
            } else {
              dateWiseOrders[index].nutritionOrders.push(item)
            }
          })
        }
      }
    }

    const startDate: Date = moment()
      .subtract(2, 'days')
      .isAfter(moment(serviceRequest.occurrencePeriod?.start))
      ? moment().subtract(2, 'days').local().toDate()
      : moment(serviceRequest.occurrencePeriod?.start).local().toDate()
    let endDate: Date = moment()
      .add(4, 'days')
      .isBefore(moment(serviceRequest.occurrencePeriod?.end))
      ? moment().add(4, 'days').local().endOf('day').toDate()
      : moment(serviceRequest.occurrencePeriod?.end)
          .local()
          .endOf('day')
          .toDate()

    let currentDate: Date = startDate

    do {
      const index = dateWiseOrders.findIndex(
        // eslint-disable-next-line no-loop-func
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        (e) => e.date === moment(currentDate).format('YYYY-MM-DD')
      )
      let carePlanRefId: string = ''

      if (serviceRequest.basedOn) {
        const referenceArray = serviceRequest.basedOn ?? []
        if (referenceArray.length > 0)
          for (let j = 0; j < referenceArray.length; j++) {
            const ref = referenceArray[j].reference
            if (ref) {
              const planDataRef = ref.split('/')[0]
              if (planDataRef === 'CarePlan') {
                carePlanRefId = ref.split('/')[1]
              }
            }
          }
      }
      if (plan) {
        carePlanRefId = plan.id ?? ''
      }

      if (index < 0) {
        if (carePlanRefId.length > 0) {
          planData.push({
            date: moment(currentDate).format('YYYY-MM-DD'),
            eight: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                dateTime: currentDate.toISOString(),
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '830AM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            five: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '5PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            id: `${moment(currentDate).format('YYYY-MM-DD')}id`,
            seven: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,

                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '7PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            six: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '630AM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            twelve: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '12PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            two: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '2PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          })
        } else {
          planData.push({
            date: moment(currentDate).format('YYYY-MM-DD'),
            eight: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                dateTime: currentDate.toISOString(),
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '830AM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            five: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '5PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            id: `${moment(currentDate).format('YYYY-MM-DD')}id`,
            seven: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,

                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '7PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            six: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '630AM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            twelve: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '12PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            two: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '2PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          })
        }
      } else if (carePlanRefId.length > 0) {
        planData.push({
          date: moment(currentDate).format('YYYY-MM-DD'),
          eight: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '830AM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
          five: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '5PM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
          id: `${moment(currentDate).format('YYYY-MM-DD')}id`,
          seven: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '7PM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
          six: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '630AM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
          twelve: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '12PM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
          two: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '2PM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
        })
      } else {
        planData.push({
          date: moment(currentDate).format('YYYY-MM-DD'),
          eight: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '830AM',
            encounterId,
            serviceRequest,
            currentDate
          ),
          five: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '5PM',
            encounterId,
            serviceRequest,
            currentDate
          ),
          id: `${moment(currentDate).format('YYYY-MM-DD')}id`,
          seven: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '7PM',
            encounterId,
            serviceRequest,
            currentDate
          ),
          six: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '630AM',
            encounterId,
            serviceRequest,
            currentDate
          ),
          twelve: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '12PM',
            encounterId,
            serviceRequest,
            currentDate
          ),
          two: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '2PM',
            encounterId,
            serviceRequest,
            currentDate
          ),
        })
      }
      const newDate = moment(currentDate).add(1, 'days').toDate()
      currentDate = newDate
      if (moment().isAfter(moment(serviceRequest.occurrencePeriod?.end))) {
        const newEndDate = moment().local().endOf('day').toDate()
        endDate = newEndDate
      }
    } while (currentDate <= endDate)
  } catch (error) {
    console.error(error)
  }

  return planData
}

export function getDietPlansPlanFromBundleForAyurVeda(
  responseBundle: R4.IBundle,
  serviceRequest: R4.IServiceRequest,
  encounterId: string
): Diet[] {
  const planData: Diet[] = []

  const dateWiseOrders: DateWiseNutritionOrders[] = []
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          const allNutritionOrders: R4.INutritionOrder[] = []
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (e.resource?.resourceType === 'NutritionOrder') {
                allNutritionOrders.push(e.resource)
              }
            })
          }
          allNutritionOrders.forEach((item) => {
            const dateDet = moment(
              item.oralDiet?.schedule![0].event![0]
            ).format('YYYY-MM-DD')
            const index = dateWiseOrders.findIndex((s) => s.date === dateDet)
            if (index < 0) {
              dateWiseOrders.push({
                date: dateDet,
                nutritionOrders: [item],
              })
            } else {
              dateWiseOrders[index].nutritionOrders.push(item)
            }
          })
        }
      }
    }

    const startDate: Date = moment(serviceRequest.occurrencePeriod?.start)
      .local()
      .toDate()
    let endDate: Date = moment(serviceRequest.occurrencePeriod?.end)
      .local()
      .endOf('day')
      .toDate()

    let currentDate: Date = startDate

    do {
      if (
        moment(currentDate).format('YYYY-MM-DD') >=
        moment().format('YYYY-MM-DD')
      ) {
        const index = dateWiseOrders.findIndex(
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          (e) => e.date === moment(currentDate).format('YYYY-MM-DD')
        )

        if (index < 0) {
          planData.push({
            date: moment(currentDate).format('YYYY-MM-DD'),
            eight: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                dateTime: currentDate.toISOString(),
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '830AM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            five: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '5PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            id: `${moment(currentDate).format('YYYY-MM-DD')}id`,
            seven: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,

                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '7PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            six: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '630AM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            twelve: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '12PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            two: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '2PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          })
        } else {
          planData.push({
            date: moment(currentDate).format('YYYY-MM-DD'),
            eight: getNutritionOrdersForSepcificTimeWithoutPlan(
              dateWiseOrders[index].nutritionOrders,
              '830AM',
              encounterId,
              serviceRequest,
              currentDate
            ),
            five: getNutritionOrdersForSepcificTimeWithoutPlan(
              dateWiseOrders[index].nutritionOrders,
              '5PM',
              encounterId,
              serviceRequest,
              currentDate
            ),
            id: `${moment(currentDate).format('YYYY-MM-DD')}id`,
            seven: getNutritionOrdersForSepcificTimeWithoutPlan(
              dateWiseOrders[index].nutritionOrders,
              '7PM',
              encounterId,
              serviceRequest,
              currentDate
            ),
            six: getNutritionOrdersForSepcificTimeWithoutPlan(
              dateWiseOrders[index].nutritionOrders,
              '630AM',
              encounterId,
              serviceRequest,
              currentDate
            ),
            twelve: getNutritionOrdersForSepcificTimeWithoutPlan(
              dateWiseOrders[index].nutritionOrders,
              '12PM',
              encounterId,
              serviceRequest,
              currentDate
            ),
            two: getNutritionOrdersForSepcificTimeWithoutPlan(
              dateWiseOrders[index].nutritionOrders,
              '2PM',
              encounterId,
              serviceRequest,
              currentDate
            ),
          })
        }
      }

      const newDate = moment(currentDate).add(1, 'days').toDate()
      currentDate = newDate
      if (moment().isAfter(moment(serviceRequest.occurrencePeriod?.end))) {
        const newEndDate = moment().local().endOf('day').toDate()
        endDate = newEndDate
      }
    } while (currentDate <= endDate)
  } catch (error) {
    console.error(error)
  }

  return planData
}

export function getDateWiseDietPlansFromBundle(
  responseBundle: R4.IBundle
): DateWiseDietPlans[] {
  const planData: Diet[] = []
  const encounters: any = {}
  const serviceRequests: any = {}
  const patients: any = {}

  const dateWiseOrders: DateWiseNutritionOrders[] = []
  const dateWisePlans: DateWiseDietPlans[] = []
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          const allNutritionOrders: R4.INutritionOrder[] = []
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (e.resource?.resourceType === 'NutritionOrder') {
                allNutritionOrders.push(e.resource)
              }
              if (e.resource) {
                if (e.resource.id) {
                  if (e.resource?.resourceType === 'Encounter') {
                    encounters[e.resource.id] = e.resource as R4.IEncounter
                  }
                  if (e.resource?.resourceType === 'ServiceRequest') {
                    serviceRequests[e.resource.id] =
                      e.resource as R4.IServiceRequest
                  }
                  if (e.resource?.resourceType === 'Patient') {
                    patients[e.resource.id] = e.resource as R4.IPatient
                  }
                }
              }
            })
          }
          allNutritionOrders.forEach((item) => {
            const dateDet = moment(
              item.oralDiet?.schedule![0].event![0]
            ).format('YYYY-MM-DD')
            const index = dateWiseOrders.findIndex((s) => s.date === dateDet)
            if (index < 0) {
              dateWiseOrders.push({
                date: dateDet,
                nutritionOrders: [item],
              })
            } else {
              dateWiseOrders[index].nutritionOrders.push(item)
            }
          })
        }
      }
    }

    dateWiseOrders.forEach((e) => {
      const index = dateWisePlans.findIndex((eDate) => eDate.date === e.date)
      if (index < 0) {
        dateWisePlans.push({
          date: e.date,
          dateWiseDiets: [],
        })
      }

      const finalIndex = dateWisePlans.findIndex(
        (eDate) => eDate.date === e.date
      )

      e.nutritionOrders.forEach((eachOrder) => {
        const encounterId = eachOrder.encounter?.reference?.split('/')[1]
        const encounterIndex = dateWisePlans[
          finalIndex
        ].dateWiseDiets.findIndex(
          (eachDiet) => eachDiet.encounterId === encounterId
        )

        let visitDiet: DietOfVisit
        if (encounterIndex > -1) {
          visitDiet = {
            ...dateWisePlans[finalIndex].dateWiseDiets[encounterIndex],
          }
          visitDiet = addDataToDietDateDiet(visitDiet, eachOrder)
          dateWisePlans[finalIndex].dateWiseDiets[encounterIndex] = visitDiet
        } else {
          const encounter: R4.IEncounter = encounters[
            encounterId!
          ] as R4.IEncounter
          const serviceRequestRefNum = encounter.basedOn?.findIndex((enc) =>
            enc.reference?.toLowerCase().includes('servicerequest')
          )

          const serviceRequestId =
            encounter.basedOn![serviceRequestRefNum!].reference?.split('/')[1]
          const patientId = encounter.subject!.reference?.split('/')[1]

          visitDiet = {
            date: e.date,
            encounterId: encounterId!,
            encounter,
            serviceRequest: serviceRequests[serviceRequestId!],
            patient: patients[patientId!],
          }

          visitDiet = addDataToDietDateDiet(visitDiet, eachOrder)

          dateWisePlans[finalIndex].dateWiseDiets.push(visitDiet)
        }
      })
    })
  } catch (error) {
    console.error(error)
  }

  return dateWisePlans
}

export function getProcedureData(
  procedures: any,
  id: string
): R4.IProcedure | undefined {
  for (const key3 in procedures) {
    const currentProcedure: R4.IProcedure = procedures[key3] as R4.IProcedure
    const childServiceReq = currentProcedure.basedOn
      ? currentProcedure.basedOn.length > 0
        ? currentProcedure.basedOn[1].reference
          ? currentProcedure.basedOn[1].reference.split('/')[1]
          : ''
        : ''
      : ''
    if (childServiceReq === id) {
      return currentProcedure
    }
  }
  return undefined
}

export function isPastDate(date: string): boolean {
  const todayDate = moment(
    `${moment(new Date()).format('DD-MM-YYYY')} 12:00`,
    'DD-MM-YYYY HH:mm'
  )

  const pastDate = moment(`${date} 23:59`, 'DD-MM-YYYY HH:mm')

  if (todayDate.isAfter(pastDate)) {
    return true
  }
  return false
}

export function isBetweenPast2Date(date: string): boolean {
  const todayDate = moment(
    `${moment(new Date()).subtract(2, 'days').format('DD-MM-YYYY')} 12:00`,
    'DD-MM-YYYY HH:mm'
  )

  const pastDate = moment(`${date} 23:59`, 'DD-MM-YYYY HH:mm')

  if (todayDate.isAfter(pastDate)) {
    return true
  }
  return false
}

export function addDataToDietDateDiet(
  dietVisit: DietOfVisit,
  no: R4.INutritionOrder
) {
  const res = getDayCodeFromNO(no)

  switch (res) {
    case '830AM':
      dietVisit.eight = [...(dietVisit.eight ?? []), no]
      break
    case '5PM':
      dietVisit.five = [...(dietVisit.five ?? []), no]
      break
    case '2PM':
      dietVisit.two = [...(dietVisit.two ?? []), no]
      break
    case '7PM':
      dietVisit.seven = [...(dietVisit.seven ?? []), no]
      break
    case '630AM':
      dietVisit.six = [...(dietVisit.six ?? []), no]
      break
    case '12PM':
      dietVisit.twelve = [...(dietVisit.twelve ?? []), no]
      break

    default:
      break
  }
  return dietVisit
}

export function isPastDateTimeForDiet(date: string, time: string): boolean {
  const todayDate = moment(new Date()).subtract(2, 'day').local()
  let timePart = '6:00'
  switch (time) {
    case '830AM':
      timePart = '8:30'
      break

    case '5PM':
      timePart = '17:00'
      break

    case '7PM':
      timePart = '19:00'
      break

    case '630AM':
      timePart = '6:30'
      break

    case '12PM':
      timePart = '12:00'
      break
    case '2PM':
      timePart = '14:00'
      break

    case '6AM':
      timePart = '6:00'
      break
    default:
      timePart = '23:99'
      break
  }

  timePart = '23:59'

  const pastDate = moment(`${date} ${timePart}`, 'DD-MM-YYYY HH:mm')

  if (todayDate.isAfter(pastDate)) {
    return true
  }
  return false
}

export function getNotes(procedure: R4.IProcedure): string {
  const notes: string = procedure.note ? procedure.note[0].text ?? '' : ''
  return notes
}

export function isFutureDate(date: string): boolean {
  const todayDate = moment(
    moment(new Date()).format('DD-MM-YYYY'),
    "'DD-MM-YYYY'"
  )
  const future = moment(date, 'DD-MM-YYYY')

  const dDiff = todayDate.diff(future)
  if (dDiff < 0) {
    return true
  }
  return false
}

export function isExistingDiet(dietRow: Diet) {
  if (getIdsOfSelectedDetails(dietRow.eight ?? []).length > 0) {
    return true
  }
  if (getIdsOfSelectedDetails(dietRow.five ?? []).length > 0) {
    return true
  }
  if (getIdsOfSelectedDetails(dietRow.seven ?? []).length > 0) {
    return true
  }
  if (getIdsOfSelectedDetails(dietRow.six ?? []).length > 0) {
    return true
  }
  if (getIdsOfSelectedDetails(dietRow.twelve ?? []).length > 0) {
    return true
  }
  if (getIdsOfSelectedDetails(dietRow.two ?? []).length > 0) {
    return true
  }

  if (dietRow.eight?.some((e) => e.id && !e.id.includes('urn'))) {
    return true
  }
  if (dietRow.five?.some((e) => e.id && !e.id.includes('urn'))) {
    return true
  }
  if (dietRow.seven?.some((e) => e.id && !e.id.includes('urn'))) {
    return true
  }
  if (dietRow.six?.some((e) => e.id && !e.id.includes('urn'))) {
    return true
  }
  if (dietRow.twelve?.some((e) => e.id && !e.id.includes('urn'))) {
    return true
  }
  if (dietRow.two?.some((e) => e.id && !e.id.includes('urn'))) {
    return true
  }

  return false
}

function getNutritionOrdersForSepcificTime(
  nutritionOrders: R4.INutritionOrder[],
  type: string,
  encounterId: string,
  serviceRequest: R4.IServiceRequest,
  currentDate: Date,
  carePlanId: string
): R4.INutritionOrder[] {
  const filteredNOS = [...nutritionOrders].filter(
    (e) => getDayCodeFromNO(e) === type
  )

  if (filteredNOS && filteredNOS.length > 0) {
    return filteredNOS
  }
  return [
    {
      resourceType: 'NutritionOrder',
      status: 'active',
      id: getUniqueTempId(),
      encounter: {
        reference: `Encounter/${encounterId}`,
      },
      extension: [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
          valueReference: {
            reference: `CarePlan/${carePlanId}`,
          },
        },
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
          valueReference: {
            reference: `Organization/${getCurrentUserUnitDetails().id}`,
          },
        },

        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
          valueReference: {
            reference: `ServiceRequest/${serviceRequest.id!}`,
          },
        },
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
          valueCoding: {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
            code: 'ipd-diet',
            display: 'IPD Diet',
          },
        },
      ],
      patient: serviceRequest.subject,
      oralDiet: {
        extension: [],
        schedule: [
          {
            event: [moment(currentDate).local().startOf('d').toISOString()],
            code: {
              coding: [
                {
                  code: type,
                },
              ],
            },
          },
        ],
      },
    },
  ]
}

function getNutritionOrdersForSepcificTimeWithoutPlan(
  nutritionOrders: R4.INutritionOrder[],
  type: string,
  encounterId: string,
  serviceRequest: R4.IServiceRequest,
  currentDate: Date
): R4.INutritionOrder[] {
  const filteredNOS = [...nutritionOrders].filter(
    (e) => getDayCodeFromNO(e) === type
  )

  if (filteredNOS && filteredNOS.length > 0) {
    return filteredNOS
  }
  return [
    {
      resourceType: 'NutritionOrder',
      status: 'active',
      id: getUniqueTempId(),
      encounter: {
        reference: `Encounter/${encounterId}`,
      },
      extension: [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
          valueReference: {
            reference: `Organization/${getCurrentUserUnitDetails().id}`,
          },
        },

        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
          valueReference: {
            reference: `ServiceRequest/${serviceRequest.id!}`,
          },
        },
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
          valueCoding: {
            system:
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
            code: 'ipd-diet',
            display: 'IPD Diet',
          },
        },
      ],
      patient: serviceRequest.subject,
      oralDiet: {
        extension: [],
        schedule: [
          {
            event: [moment(currentDate).local().startOf('d').toISOString()],
            code: {
              coding: [
                {
                  code: type,
                },
              ],
            },
          },
        ],
      },
    },
  ]
}

export function getDayCodeFromNO(nutritionOrders: R4.INutritionOrder): string {
  if (nutritionOrders.oralDiet) {
    if (
      nutritionOrders.oralDiet.schedule &&
      nutritionOrders.oralDiet.schedule.length > 0 &&
      nutritionOrders.oralDiet.extension &&
      nutritionOrders.oralDiet.extension.length > 0
    ) {
      return (
        getDefaultCodeOfSystemFromCodableConcept(
          nutritionOrders.oralDiet.schedule[0].code
        ) ?? ''
      )
    }
  }
  return ''
}

function getDayCodeFromNOFOrNoDiet(
  nutritionOrders: R4.INutritionOrder
): string {
  if (nutritionOrders.oralDiet) {
    if (
      nutritionOrders.oralDiet.schedule &&
      nutritionOrders.oralDiet.schedule.length > 0
    ) {
      return (
        getDefaultCodeOfSystemFromCodableConcept(
          nutritionOrders.oralDiet.schedule[0].code
        ) ?? ''
      )
    }
  }
  return ''
}

export function getIdOfSelectedDetails(nos: R4.INutritionOrder[]) {
  if (nos.length > 0) {
    const ext: R4.IExtension | undefined = getExtensionValueOfUrl(
      nos[0].oralDiet?.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext'
    )

    if (ext) {
      const ext1 = getExtensionValueOfUrl(
        ext.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-ext'
      )

      if (ext1) {
        return ext1.valueReference?.reference?.split('/')[1] ?? undefined
      }
    }
  }

  return undefined
}

export function getIdsOfSelectedDetails(nos: R4.INutritionOrder[]) {
  if (nos.length > 0) {
    const ext: R4.IExtension | undefined = getExtensionValueOfUrl(
      nos[0].oralDiet?.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext'
    )

    if (ext) {
      const tempExt = [...(ext.extension ?? [])]

      const resExts = tempExt.filter(
        (e) =>
          e.url ===
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-ext'
      )

      const resExtIds: string[] = []

      resExts.forEach((ext1) => {
        const id = ext1.valueReference?.reference?.split('/')[1] ?? undefined

        if (id) resExtIds.push(id)
      })

      return resExtIds
    }
  }

  return []
}

export function getDisplayItemsForNutritionOrders(
  catalogEntries: ICatalogEntry[] | undefined,
  nos: R4.INutritionOrder[]
) {
  const refIds: string[] = getIdsOfSelectedDetails(nos)

  if (refIds.length > 0) {
    return getCatalogDetailsFromIds(catalogEntries ?? [], refIds)
  }

  const resCodes: ICodeableConcept[] = getCodesOfSelectedNutritionOrders(nos)

  if (resCodes.length > 0) {
    const resStrings: string[] = resCodes.map(
      (e) => getDefaultDisplayOfSystemFromCodableConcept(e) ?? ''
    )
    return resStrings.join(', ')
  }

  return ''
}

export function getCodesOfSelectedNutritionOrders(nos: R4.INutritionOrder[]) {
  if (nos.length > 0) {
    const ext: R4.IExtension | undefined = getExtensionValueOfUrl(
      nos[0].oralDiet?.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext'
    )

    if (ext) {
      const tempExt = [...(ext.extension ?? [])]

      const resExts = tempExt.filter(
        (e) =>
          e.url ===
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-code-ext'
      )

      const resCodes: ICodeableConcept[] = []

      for (const resExt of resExts) {
        if (resExt.valueCodeableConcept) {
          resCodes.push(resExt.valueCodeableConcept)
        }
      }

      return resCodes
    }
  }

  return []
}

export function getNamesOfOrders(nos: R4.INutritionOrder[]) {
  if (nos.length > 0) {
    const ext: R4.IExtension | undefined = getExtensionValueOfUrl(
      nos[0].oralDiet?.extension,
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext'
    )

    if (ext) {
      const tempExt = [...(ext.extension ?? [])]

      const resExts = tempExt.filter(
        (e) =>
          e.url ===
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-ext'
      )

      const resExtIds: string[] = []

      resExts.forEach((ext1) => {
        const id = ext1.valueReference?.display ?? ''

        if (id) resExtIds.push(id)
      })

      return resExtIds.join(', ')
    }
  }

  return ''
}

export function isExistingResource(resources: R4.INutritionOrder[]) {
  console.log('resources', resources)
  if (resources.length > 0) {
    if (resources[0].id) {
      if (!resources[0].id.includes('urn')) {
        console.log('inside include urn')
        return true
      }
    }
  }
  return false
}
export async function updateNutritionOrderDetails(
  nos: R4.INutritionOrder,

  patient: R4.IPatient
): Promise<boolean> {
  try {
    const plan: R4.ICarePlan | undefined =
      await getCarePlanOfPatientWithCurrentUnit(patient.id!)
    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response: R4.IBundle | FHIRErrorResponses =
      await fhirClient.doCreateFHIRTransaction(
        ``,
        getUpdatedNutritionOrder(nos, plan)
      )

    const resp: R4.IBundle = response as R4.IBundle

    return true
  } catch (error) {
    console.error(error)
  }

  return false
}

export function getUpdatedNutritionOrder(
  no: R4.INutritionOrder,

  plan?: R4.ICarePlan
) {
  const nos: R4.INutritionOrder = { ...no }
  nos.status = 'completed'

  const oldAppointmentMatchString: string = `W/${JSON.stringify(
    no.meta?.versionId ?? ' '
  )}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `${nos.resourceType}/${nos.id}`,
        request: {
          ifMatch: oldAppointmentMatchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `${nos.resourceType}/${nos.id}`,
        },
        resource: nos,
      },

      {
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: 'Observation',
        },
        resource: getUpdateObservationIntakeResource(
          nos,
          getIdOfSelectedDetails([nos])!,
          plan
        ),
      },
    ],
  }

  return requestBundle
}

export function getUpdateObservationIntakeResource(
  no: R4.INutritionOrder,
  ceId: String,
  plan?: R4.ICarePlan
) {
  const userRole: R4.IPractitionerRole = getCurrentUserPractitionerRoleDetails()
  const userDetails: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    meta: {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationNutritionIntake',
      ],
    },

    subject: no.patient,
    extension: [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-order-ref-ext',
        valueReference: {
          reference: `NutritionOrder/${no.id}`,
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-intake-items-ext',
        extension: [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-consumed-intake-ext',
            extension: [
              {
                url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-ext',
                valueReference: {
                  reference: `CatalogEntry/${ceId}`,
                },
              },
              {
                url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-quantity-percentage-amount-ext',
                valueQuantity: {
                  value: 100,
                  unit: '%',
                  system: 'http://unitsofmeasure.org',
                  code: '%',
                },
              },
            ],
          },
        ],
      },
    ],
    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'social-history',
            display: 'Social History',
          },
        ],
      },
    ],
    effectiveDateTime: moment(no.oralDiet?.schedule?.[0].event?.[0])
      .startOf('d')
      .toISOString(),
    code: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '182922004',
          display: 'Dietary regime',
        },
      ],
    },
    performer: [
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userRole.id,
        reference: `${userRole.resourceType}/${userRole.id}`,
        type: userRole.resourceType,
      },
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userDetails.id,
        reference: `${userDetails.resourceType}/${userDetails.id}`,
        type: userDetails.resourceType,
      },
    ],
    basedOn: [
      {
        reference: `NutritionOrder/${no.id}`,
        type: 'NutritionOrder',
      },
    ],
  }

  if (plan) {
    if (observation.basedOn && observation.basedOn.length > 0) {
      observation.basedOn.push({
        id: plan.id!,
        reference: `CarePlan/${plan.id!}`,
        type: 'CarePlan',
      })
    } else {
      observation.basedOn = [
        {
          id: plan.id!,
          reference: `CarePlan/${plan.id!}`,
          type: 'CarePlan',
        },
      ]
    }
  }

  return observation
}

export function isFutureDateTimeForDiet(
  date: string,
  time: string,
  offSetToday?: number
): boolean {
  const todayDate = moment(new Date())

  let timePart = '6:30'
  switch (time) {
    case '830AM':
      timePart = '8:30'
      break

    case '5PM':
      timePart = '17:00'
      break

    case '7PM':
      timePart = '19:00'
      break

    case '630AM':
      timePart = '6:30'
      break

    case '12PM':
      timePart = '12:00'
      break
    case '2PM':
      timePart = '14:00'
      break
    case '6AM':
      timePart = '6:00'
      break
    default:
      timePart = '23:59'
      break
  }

  const pastDate = moment(`${date} ${timePart}`, 'DD-MM-YYYY HH:mm')

  if (todayDate.isBefore(pastDate)) {
    return true
  }

  return false
}

export function getDietHistoryDataFromBundle(
  responseBundle: R4.IBundle
): R4.INutritionOrder[] {
  const dietData: R4.INutritionOrder[] = []
  const nutritionOrder: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'NutritionOrder':
                  nutritionOrder[value.resource.id] =
                    value.resource as R4.INutritionOrder
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in nutritionOrder) {
              const currentDiet: R4.INutritionOrder = nutritionOrder[
                key1
              ] as R4.INutritionOrder

              if (currentDiet.encounter) {
                const ref = currentDiet.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    if (currentDiet.oralDiet) {
                      const oralDietData = currentDiet.oralDiet
                      if (
                        oralDietData.extension &&
                        oralDietData.extension.length > 0
                      ) {
                        dietData.push(currentDiet)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return dietData
}

export function getIdsOfSelectedDetailsForHistory(nos: R4.INutritionOrder) {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(
    nos.oralDiet?.extension,
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext'
  )

  if (ext) {
    const tempExt = [...(ext.extension ?? [])]

    const resExts = tempExt.filter(
      (e) =>
        e.url ===
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-ext'
    )

    const resExtIds: string[] = []

    resExts.forEach((ext1) => {
      const id = ext1.valueReference?.reference?.split('/')[1] ?? undefined
      if (id) resExtIds.push(id)
    })
    return resExtIds
  }

  return []
}

export function getTimeString(time: string): string {
  let timePart = '6:30'
  switch (time) {
    case '830AM':
      timePart = 'Mid Morning'
      break

    case '5PM':
      timePart = 'Evening'
      break

    case '7PM':
      timePart = 'Night'
      break

    case '630AM':
      timePart = 'Early Morning'
      break

    case '12PM':
      timePart = 'Afternoon'
      break
    case '2PM':
      timePart = 'Mid Afternoon'
      break
    case '6AM':
      timePart = 'Early Morning'
      break
    default:
      timePart = '14:00'
      break
  }

  return timePart
}

export function isDietCompleted(diet?: R4.INutritionOrder[]) {
  if (diet && diet.length > 0) {
    if (diet[0].status) return diet[0].status === 'completed'
  }
  return false
}

export function checkDisabledForSix(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Early Morning') {
        return false
      }
    }
  }
  return true
}

export function getEarlyMorningTime(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Early Morning') {
        const coding = dietTime[i].time
        if (coding && dietTime[i].active === true) {
          return `${coding.display ?? ''} AM`
        }
      }
    }
  }
  return ''
}

export function getEarlyMorningTimeForOrder(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Early Morning') {
        return dietTime[i].timeOfTheDay
      }
    }
  }
  return ''
}
export function getBreakfastTimeForOrder(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Breakfast') {
        return dietTime[i].timeOfTheDay
      }
    }
  }
  return ''
}

export function getMidMorningTimeForOrder(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Mid Morning') {
        return dietTime[i].timeOfTheDay
      }
    }
  }
  return ''
}
export function getLunchTImeForOrder(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Lunch') {
        return dietTime[i].timeOfTheDay
      }
    }
  }
  return ''
}

export function getSnackTImeForOrder(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Evening Snack') {
        return dietTime[i].timeOfTheDay
      }
    }
  }
  return ''
}

export function getDinnerTimeForOrder(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Dinner') {
        return dietTime[i].timeOfTheDay
      }
    }
  }
  return ''
}

export function getBedTimeTimeForOrder(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Bed Time') {
        return dietTime[i].timeOfTheDay
      }
    }
  }
  return ''
}

export function getEarlyMorningTimeCode(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Early Morning') {
        const coding = dietTime[i].time
        if (coding) {
          const codingData = {
            system: coding.system,
            code: coding.code,
            display: 'Early Morning',
          }
          return codingData
        }
      }
    }
  }
  return undefined
}

export function getEarlyMorningTimeForPullDown(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Early Morning') {
        const coding = dietTime[i].time
        if (coding) {
          if (coding.display) {
            return `${coding.display ?? ''}AM`
          }
          return ''
        }
      }
    }
  }
  return ''
}

export function getEarlyBreakFastTimeForPullDown(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Breakfast') {
        const coding = dietTime[i].time
        if (coding) {
          if (coding.display) {
            return `${coding.display ?? ''}AM`
          }
          return ''
        }
      }
    }
  }
  return ''
}

export function getEarlyBreakFastTime(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Breakfast') {
        const coding = dietTime[i].time
        if (coding && dietTime[i].active === true) {
          return `${coding.display ?? ''} AM`
        }
      }
    }
  }
  return ''
}

export function getEarlyBreakFastTimeCode(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Breakfast') {
        const coding = dietTime[i].time
        if (coding) {
          const codingData = {
            system: coding.system,
            code: coding.code,
            display: 'Breakfast',
          }
          return codingData
        }
      }
    }
  }
  return undefined
}

export function getBreakfastDisplayText(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Breakfast') {
        return dietTime[i].displayLabel
      }
    }
  }
  return ''
}

export function getMidMorningDisplayText(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Mid Morning') {
        return dietTime[i].displayLabel
      }
    }
  }
  return ''
}

export function getMorningDisplayText(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Early Morning') {
        return dietTime[i].displayLabel
      }
    }
  }
  return ''
}

export function getLunchDisplayText(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Lunch') {
        return dietTime[i].displayLabel
      }
    }
  }
  return ''
}

export function getSnackDisplayText(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Evening Snack') {
        return dietTime[i].displayLabel
      }
    }
  }
  return ''
}

export function getDinnerText(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Dinner') {
        return dietTime[i].displayLabel
      }
    }
  }
  return ''
}

export function getBedTimeText(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Bed Time') {
        return dietTime[i].displayLabel
      }
    }
  }
  return ''
}

export function getMidMorningTime(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (
        dietTime[i].dietTime === 'Mid Morning' &&
        dietTime[i].active === true
      ) {
        const coding = dietTime[i].time
        if (coding) {
          return `${coding.display ?? ''} AM`
        }
      }
    }
  }
  return ''
}

export function getMidMorningTimeCode(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Mid Morning') {
        const coding = dietTime[i].time
        if (coding) {
          const codingData = {
            system: coding.system,
            code: coding.code,
            display: 'Mid Morning',
          }
          return codingData
        }
      }
    }
  }
  return undefined
}

export function getMidMorningTimeForPullDown(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Mid Morning') {
        const coding = dietTime[i].time
        if (coding) {
          if (coding.display) {
            return `${coding.display ?? ''}AM`
          }
          return ''
        }
      }
    }
  }
  return ''
}

export function getMidMorningTimeForDisable(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Mid Morning') {
        return dietTime[i].timeOfTheDay
      }
    }
  }
  return ''
}

export function getBreakFastForDisable(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Breakfast') {
        return dietTime[i].timeOfTheDay
      }
    }
  }
  return ''
}

export function getEarlyMorningForDisable(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Early Morning') {
        return dietTime[i].timeOfTheDay
      }
    }
  }
  return ''
}

export function getLunchTime(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Lunch') {
        const coding = dietTime[i].time
        if (coding && dietTime[i].active === true) {
          return `${coding.display ?? ''} PM`
        }
      }
    }
  }
  return ''
}

export function getLunchTimeCode(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Lunch') {
        const coding = dietTime[i].time
        if (coding) {
          const codingData = {
            system: coding.system,
            code: coding.code,
            display: 'Lunch',
          }
          return codingData
        }
      }
    }
  }
  return undefined
}

export function getLunchTimeForPullDown(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Lunch') {
        const coding = dietTime[i].time
        if (coding) {
          return `${coding.display ?? ''}PM`
        }
      }
    }
  }
  return ''
}

export function getSnackTime(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Evening Snack') {
        const coding = dietTime[i].time
        if (coding && dietTime[i].active === true) {
          return `${coding.display ?? ''} PM`
        }
      }
    }
  }
  return ''
}

export function getSnackTimeCode(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Evening Snack') {
        const coding = dietTime[i].time
        if (coding) {
          const codingData = {
            system: coding.system,
            code: coding.code,
            display: 'Evening Snack',
          }
          return codingData
        }
      }
    }
  }
  return undefined
}

export function getSnackTimeForPullDown(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Evening Snack') {
        const coding = dietTime[i].time
        if (coding) {
          return `${coding.display ?? ''}PM`
        }
      }
    }
  }
  return ''
}

export function getDinnerTime(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Dinner') {
        const coding = dietTime[i].time
        if (coding) {
          return `${coding.display ?? ''} PM`
        }
      }
    }
  }
  return ''
}

export function getDinnerTimeCode(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Dinner') {
        const coding = dietTime[i].time
        if (coding) {
          const codingData = {
            system: coding.system,
            code: coding.code,
            display: 'Dinner',
          }
          return codingData
        }
      }
    }
  }
  return undefined
}
export function getDinnerTimeForPullDown(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Dinner') {
        const coding = dietTime[i].time
        if (coding) {
          return `${coding.display ?? ''}PM`
        }
      }
    }
  }
  return ''
}

export function getDinnerTimeForDisable(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Dinner') {
        const coding = dietTime[i].timeOfTheDay
        return coding
        //   if (coding) {
        //     return `${coding.display ?? ''}PM`
        //   }
      }
    }
  }
  return ''
}

export function getSnackTimeForDisable(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Evening Snack') {
        const coding = dietTime[i].timeOfTheDay
        return coding
        //   if (coding) {
        //     return `${coding.display ?? ''}PM`
        //   }
      }
    }
  }
  return ''
}

export function getLunchTimeForDisable(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Lunch') {
        const coding = dietTime[i].timeOfTheDay
        return coding
        //   if (coding) {
        //     return `${coding.display ?? ''}PM`
        //   }
      }
    }
  }
  return ''
}

export function getBedTimeForDisable(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Bed Time') {
        const coding = dietTime[i].timeOfTheDay
        return coding
        //   if (coding) {
        //     return `${coding.display ?? ''}PM`
        //   }
      }
    }
  }
  return ''
}

export function getBedTime(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Bed Time') {
        const coding = dietTime[i].time
        if (coding && dietTime[i].active === true) {
          return `${coding.display ?? ''} PM`
        }
      }
    }
  }
  return ''
}

export function getBedTimeCode(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Bed Time') {
        const coding = dietTime[i].time
        if (coding) {
          const codingData = {
            system: coding.system,
            code: coding.code,
            display: 'Bed Time',
          }
          return codingData
        }
      }
    }
  }
  return undefined
}

export function getBedTimeForPullDown(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Bed Time') {
        const coding = dietTime[i].time
        if (coding) {
          return `${coding.display ?? ''}PM`
        }
      }
    }
  }
  return ''
}

export function checkDisabledForBreakFast(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Breakfast') {
        return false
      }
    }
  }
  return true
}

export function checkDisabledForMidMorning(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Mid Morning') {
        return false
      }
    }
  }
  return true
}

export function checkDisabledForLunch(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Lunch') {
        return false
      }
    }
  }
  return true
}

export function checkDisabledForSnacks(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Evening Snack') {
        return false
      }
    }
  }
  return true
}

export function checkDisabledForDinner(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Dinner') {
        return false
      }
    }
  }
  return true
}

export function checkDisabledForBedTime(dietTime: DietTiming[]) {
  if (dietTime.length > 0) {
    for (let i = 0; i < dietTime.length; i++) {
      if (dietTime[i].dietTime === 'Bed Time') {
        return false
      }
    }
  }
  return true
}

export function getNutritionOrdersForSepcificTimeForOPD(
  nutritionOrders: R4.INutritionOrder[],
  type: string,
  encounterId: string,
  patientReference: R4.IReference,
  currentDate: Date,
  timeCode?: R4.ICoding,
  displayText?: string,
  timeOfDay?: string
): R4.INutritionOrder[] {
  let filteredNOS = [...nutritionOrders].filter(
    (e) => getDayCodeFromNO(e) === type
  )
  if (timeCode && filteredNOS.length === 0) {
    filteredNOS = [...nutritionOrders].filter(
      (e) => getDayCodeFromNO(e) === timeCode.code
    )
  }
  //   if (filteredNOS.length === 0 && timeCode) {
  //     filteredNOS = [...nutritionOrders].filter(
  //       (e) => getDayCodeFromNOFOrNoDiet(e) === timeCode.code
  //     )
  //   }

  if (filteredNOS && filteredNOS.length > 0) {
    return filteredNOS
  }

  return [
    {
      resourceType: 'NutritionOrder',
      status: 'active',
      intent: 'plan',
      id: getUniqueTempId(),
      encounter: {
        reference: `Encounter/${encounterId}`,
      },
      extension: [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
          valueReference: {
            reference: `Organization/${getCurrentUserUnitDetails().id}`,
          },
        },
      ],

      patient: patientReference,
      oralDiet: {
        extension: [],
        schedule: [
          {
            event: [moment(currentDate).local().startOf('d').toISOString()],
            repeat: {
              timeOfDay: [timeOfDay ?? ''],
            },
            code: {
              text: displayText ?? '',
              coding: [
                timeCode || {
                  code: type,
                },
              ],
            },
          },
        ],
      },
    },
  ]
}

export async function addDietDataForCustom(
  diet: Diet,
  patientId: string,
  encounterId?: string
): Promise<boolean> {
  const bundleObject: R4.INutritionOrder[] = createBundleObjectForDietPlan(
    diet,
    encounterId ?? ''
  )
  const unitId = getCurrentUserUnitDetails()?.id
  const fhirClient: EnrolCient = new EnrolCient()

  const response: R4.IBundle | FHIRErrorResponses =
    await fhirClient.doCreateEnrolmentFlowRequest('dietplan/create-new', {
      patientId,
      nutritionOrderOralDiet: bundleObject,
      unitId,
    })
  if ((response as R4.IBundle)!.entry!.length > 0) {
    return true

    // dispatch(showSuccessAlert('Diet plan updated successfully'))
    // dispatch(addDietPlanSlice.actions.updateAddVitalsStatus(addingState))
    // dispatch(requestDietPlanDetails({ patientId, encounterId }))
  }
  return false
}

export async function addDietDataForCustomForMultiple(
  diet: Diet[],
  patientId: string,
  encounterId?: string
): Promise<boolean> {
  const bundleObject: R4.INutritionOrder[] =
    createBundleObjectForDietMultiplePlan(diet, encounterId ?? '')
  const unitId = getCurrentUserUnitDetails()?.id
  const fhirClient: EnrolCient = new EnrolCient()

  const response: R4.IBundle | FHIRErrorResponses =
    await fhirClient.doCreateEnrolmentFlowRequest('dietplan/create-new', {
      patientId,
      nutritionOrderOralDiet: bundleObject,
      unitId,
    })
  if ((response as R4.IBundle)!.entry!.length > 0) {
    return true

    // dispatch(showSuccessAlert('Diet plan updated successfully'))
    // dispatch(addDietPlanSlice.actions.updateAddVitalsStatus(addingState))
    // dispatch(requestDietPlanDetails({ patientId, encounterId }))
  }
  return false
}

function createBundleObjectForDietPlan(
  treatment: Diet,

  encounterId: string
): R4.INutritionOrder[] {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const practRoleRef: R4.IReference = getCurrentUserPractitionerRoleRef()!
  const requestId: string = getUniqueTempId()
  if (
    getIdsOfSelectedDetails(treatment.six ?? []).length > 0 ||
    treatment.six?.some((e) => e.id && !e.id.includes('urn'))
  )
    requestBundle.entry?.push({
      fullUrl: `${treatment.six![0].resourceType}/${treatment.six![0].id}`,
      resource: {
        ...treatment.six![0],
        orderer: practRoleRef,
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.six![0].id}`,
      },
    })
  if (
    getIdsOfSelectedDetails(treatment.eight ?? []).length > 0 ||
    treatment.eight?.some((e) => e.id && !e.id.includes('urn'))
  )
    requestBundle.entry?.push({
      fullUrl: `${treatment.eight![0].resourceType}/${treatment.eight![0].id}`,
      resource: {
        ...treatment.eight![0],
        orderer: practRoleRef,
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.eight![0].id}`,
      },
    })
  if (
    getIdsOfSelectedDetails(treatment.twelve ?? []).length > 0 ||
    treatment.twelve?.some((e) => e.id && !e.id.includes('urn'))
  )
    requestBundle.entry?.push({
      fullUrl: `${treatment.twelve![0].resourceType}/${
        treatment.twelve![0].id
      }`,
      resource: {
        ...treatment.twelve![0],
        orderer: practRoleRef,
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.twelve![0].id}`,
      },
    })

  if (
    getIdsOfSelectedDetails(treatment.two ?? []).length > 0 ||
    treatment.two?.some((e) => e.id && !e.id.includes('urn'))
  )
    requestBundle.entry?.push({
      fullUrl: `${treatment.two![0].resourceType}/${treatment.two![0].id}`,
      resource: {
        ...treatment.two![0],
        orderer: practRoleRef,
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.two![0].id}`,
      },
    })

  if (
    getIdsOfSelectedDetails(treatment.five ?? []).length > 0 ||
    treatment.five?.some((e) => e.id && !e.id.includes('urn'))
  )
    requestBundle.entry?.push({
      fullUrl: `${treatment.five![0].resourceType}/${treatment.five![0].id}`,
      resource: {
        ...treatment.five![0],

        orderer: practRoleRef,
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.five![0].id}`,
      },
    })

  if (
    getIdsOfSelectedDetails(treatment.seven ?? []).length > 0 ||
    treatment.seven?.some((e) => e.id && !e.id.includes('urn'))
  )
    requestBundle.entry?.push({
      fullUrl: `${treatment.seven![0].resourceType}/${treatment.seven![0].id}`,
      resource: {
        ...treatment.seven![0],

        orderer: practRoleRef,
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.seven![0].id}`,
      },
    })

  if (
    getIdsOfSelectedDetails(treatment.nine ?? []).length > 0 ||
    treatment.seven?.some((e) => e.id && !e.id.includes('urn'))
  )
    requestBundle.entry?.push({
      fullUrl: `${treatment.nine![0].resourceType}/${treatment.nine![0].id}`,
      resource: {
        ...treatment.nine![0],

        orderer: practRoleRef,
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.nine![0].id}`,
      },
    })

  const finalEntry: R4.IBundle_Entry[] =
    requestBundle.entry?.map((e) => {
      if (
        getEncounterIdFromNutritionOrder(e.resource as INutritionOrder) !==
        encounterId
      ) {
        return {
          fullUrl: `${e.resource?.resourceType}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: `${e.resource?.resourceType}`,
          } as R4.IBundle_Request,
          resource: {
            ...e.resource,

            id: getUniqueTempId(),
            // dateTime: new Date().toISOString(),
            encounter: {
              reference: `Encounter/${encounterId}`,
            },
          } as INutritionOrder,
        }
      }
      return e
    }) ?? []

  return finalEntry.map((e) => e.resource as INutritionOrder) ?? []
}

function createBundleObjectForObservations(
  treatment: Diet,
  EncounterId: string,
  PatientId: string
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const updatedTreatment: Diet = { ...treatment }

  const requestId: string = getUniqueTempId()
  if (isExistingResource(treatment.six ?? [])) {
    const resourceData: R4.INutritionOrder = { ...treatment.six![0] }
    resourceData.encounter = {
      reference: `Encounter/${EncounterId}`,
    }
    requestBundle.entry?.push({
      fullUrl: `${treatment.six![0].resourceType}/${treatment.six![0].id}`,
      resource: treatment.six![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.six![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.six ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: treatment.six![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.six![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }

  if (isExistingResource(treatment.eight ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.eight![0].resourceType}/${treatment.eight![0].id}`,
      resource: treatment.eight![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.eight![0].id}`,
      },
    })
  } else {
    requestBundle.entry?.push({
      fullUrl: treatment.eight![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.eight![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }

  if (isExistingResource(treatment.twelve ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.twelve![0].resourceType}/${
        treatment.twelve![0].id
      }`,
      resource: treatment.twelve![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.twelve![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.twelve ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: treatment.twelve![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.twelve![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }

  if (isExistingResource(treatment.two ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.two![0].resourceType}/${treatment.two![0].id}`,
      resource: treatment.two![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.two![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.two ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: treatment.two![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.two![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }
  if (isExistingResource(treatment.five ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.five![0].resourceType}/${treatment.five![0].id}`,
      resource: treatment.five![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.five![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.five ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: treatment.five![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.five![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }

  if (isExistingResource(treatment.seven ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.seven![0].resourceType}/${treatment.seven![0].id}`,
      resource: treatment.seven![0],
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.seven![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.seven ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: treatment.seven![0].id ?? `urn:uuid:${requestId}`,
      resource: treatment.seven![0],
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `NutritionOrder`,
      },
    })
  }
  console.log('------ dev requestBundle-------------', requestBundle)

  return requestBundle
}

export function getDietPlans(diet: Diet[], dietTiming: DietTiming[]): Diet[] {
  const dietDataFinal: Diet[] = []

  for (let i = 0; i < dietTiming.length; i++) {
    if (
      dietTiming[i].active === false &&
      dietTiming[i].dietTime === 'Early Morning'
    ) {
      for (let j = 0; j < diet.length; j++) {
        dietDataFinal.push({
          date: diet[i].date,
          id: diet[i].id,
          six: undefined,
          eight: diet[i].eight,
          twelve: diet[i].twelve,
          two: diet[i].two,
          five: diet[i].five,
          seven: diet[i].seven,
          nine: diet[i].nine,
        })
      }
    }

    if (
      dietTiming[i].active === false &&
      dietTiming[i].dietTime === 'Mid Morning'
    ) {
      for (let j = 0; j < diet.length; j++) {
        dietDataFinal.push({
          date: diet[i].date,
          id: diet[i].id,
          six: diet[i].six,
          eight: undefined,
          twelve: diet[i].twelve,
          two: diet[i].two,
          five: diet[i].five,
          seven: diet[i].seven,
          nine: diet[i].nine,
        })
      }
    }

    if (
      dietTiming[i].active === false &&
      dietTiming[i].dietTime === 'Breakfast'
    ) {
      for (let j = 0; j < diet.length; j++) {
        dietDataFinal.push({
          date: diet[i].date,
          id: diet[i].id,
          six: diet[i].six,
          eight: undefined,
          twelve: diet[i].twelve,
          two: diet[i].two,
          five: diet[i].five,
          seven: diet[i].seven,
          nine: diet[i].nine,
        })
      }
    }
    if (
      dietTiming[i].active === false &&
      dietTiming[i].dietTime === 'Mid Morning'
    ) {
      for (let j = 0; j < diet.length; j++) {
        dietDataFinal.push({
          date: diet[i].date,
          id: diet[i].id,
          six: diet[i].six,
          eight: diet[i].eight,
          twelve: undefined,
          two: diet[i].two,
          five: diet[i].five,
          seven: diet[i].seven,
          nine: diet[i].nine,
        })
      }
    }
    if (dietTiming[i].active === false && dietTiming[i].dietTime === 'Lunch') {
      for (let j = 0; j < diet.length; j++) {
        dietDataFinal.push({
          date: diet[i].date,
          id: diet[i].id,
          six: diet[i].six,
          eight: diet[i].eight,
          twelve: diet[i].twelve,
          two: undefined,
          five: diet[i].five,
          seven: diet[i].seven,
          nine: diet[i].nine,
        })
      }
    }

    if (
      dietTiming[i].active === false &&
      dietTiming[i].dietTime === 'Evening Snack'
    ) {
      for (let j = 0; j < diet.length; j++) {
        dietDataFinal.push({
          date: diet[i].date,
          id: diet[i].id,
          six: diet[i].six,
          eight: diet[i].eight,
          twelve: diet[i].twelve,
          two: diet[i].two,
          five: undefined,
          seven: diet[i].seven,
          nine: diet[i].nine,
        })
      }
    }

    if (dietTiming[i].active === false && dietTiming[i].dietTime === 'Dinner') {
      for (let j = 0; j < diet.length; j++) {
        dietDataFinal.push({
          date: diet[i].date,
          id: diet[i].id,
          six: diet[i].six,
          eight: diet[i].eight,
          twelve: diet[i].twelve,
          two: diet[i].two,
          five: diet[i].five,
          seven: undefined,
          nine: diet[i].nine,
        })
      }
    }

    if (
      dietTiming[i].active === false &&
      dietTiming[i].dietTime === 'Bed Time'
    ) {
      for (let j = 0; j < diet.length; j++) {
        dietDataFinal.push({
          date: diet[i].date,
          id: diet[i].id,
          six: diet[i].six,
          eight: diet[i].eight,
          twelve: diet[i].twelve,
          two: diet[i].two,
          five: diet[i].five,
          seven: diet[i].seven,
          nine: undefined,
        })
      }
    }
  }

  return dietDataFinal
}

export function getPrSelectedTime(dietTimeData: DietTiming[], time: string) {
  if (dietTimeData.length > 0) {
    for (let i = 0; i < dietTimeData.length; i++) {
      if (dietTimeData[i].time) {
        if (dietTimeData[i].time!.code === time) {
          return dietTimeData[i].time
        }
      }
    }
  }

  return undefined
}

export function isPastDateTimeForCustomDiet(
  date: string,
  time: string
): boolean {
  if (time.length === 0) return true

  const todayDate = moment(new Date()).local()

  const pastDate = moment(`${date} ${time}`, 'DD-MM-YYYY HH:mm')

  if (todayDate.isAfter(pastDate)) {
    return true
  }

  return false
}

export function isPastDateForCustom(date: string): boolean {
  if (date >= moment().format('YYYY-MM-DD')) {
    return false
  }
  return true
}

function createBundleObjectForDietMultiplePlan(
  treatment: Diet[],

  encounterId: string
): R4.INutritionOrder[] {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const practRoleRef: R4.IReference = getCurrentUserPractitionerRoleRef()!
  const requestId: string = getUniqueTempId()
  for (let i = 0; i < treatment.length; i++) {
    if (
      getIdsOfSelectedDetails(treatment[i].six ?? []).length > 0 ||
      treatment[i].six?.some((e) => e.id && !e.id.includes('urn'))
    )
      requestBundle.entry?.push({
        fullUrl: `${treatment[i].six![0].resourceType}/${
          treatment[i].six![0].id
        }`,
        resource: {
          ...treatment[i].six![0],
          orderer: practRoleRef,
        },
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `/NutritionOrder?_id=${treatment[i].six![0].id}`,
        },
      })
    if (
      getIdsOfSelectedDetails(treatment[i].eight ?? []).length > 0 ||
      treatment[i].eight?.some((e) => e.id && !e.id.includes('urn'))
    )
      requestBundle.entry?.push({
        fullUrl: `${treatment[i].eight![0].resourceType}/${
          treatment[i].eight![0].id
        }`,
        resource: {
          ...treatment[i].eight![0],
          orderer: practRoleRef,
        },
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `/NutritionOrder?_id=${treatment[i].eight![0].id}`,
        },
      })
    if (
      getIdsOfSelectedDetails(treatment[i].twelve ?? []).length > 0 ||
      treatment[i].twelve?.some((e) => e.id && !e.id.includes('urn'))
    )
      requestBundle.entry?.push({
        fullUrl: `${treatment[i].twelve![0].resourceType}/${
          treatment[i].twelve![0].id
        }`,
        resource: {
          ...treatment[i].twelve![0],
          orderer: practRoleRef,
        },
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `/NutritionOrder?_id=${treatment[i].twelve![0].id}`,
        },
      })

    if (
      getIdsOfSelectedDetails(treatment[i].two ?? []).length > 0 ||
      treatment[i].two?.some((e) => e.id && !e.id.includes('urn'))
    )
      requestBundle.entry?.push({
        fullUrl: `${treatment[i].two![0].resourceType}/${
          treatment[i].two![0].id
        }`,
        resource: {
          ...treatment[i].two![0],
          orderer: practRoleRef,
        },
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `/NutritionOrder?_id=${treatment[i].two![0].id}`,
        },
      })

    if (
      getIdsOfSelectedDetails(treatment[i].five ?? []).length > 0 ||
      treatment[i].five?.some((e) => e.id && !e.id.includes('urn'))
    )
      requestBundle.entry?.push({
        fullUrl: `${treatment[i].five![0].resourceType}/${
          treatment[i].five![0].id
        }`,
        resource: {
          ...treatment[i].five![0],

          orderer: practRoleRef,
        },
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `/NutritionOrder?_id=${treatment[i].five![0].id}`,
        },
      })

    if (
      getIdsOfSelectedDetails(treatment[i].seven ?? []).length > 0 ||
      treatment[i].seven?.some((e) => e.id && !e.id.includes('urn'))
    )
      requestBundle.entry?.push({
        fullUrl: `${treatment[i].seven![0].resourceType}/${
          treatment[i].seven![0].id
        }`,
        resource: {
          ...treatment[i].seven![0],

          orderer: practRoleRef,
        },
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `/NutritionOrder?_id=${treatment[i].seven![0].id}`,
        },
      })

    if (
      getIdsOfSelectedDetails(treatment[i].nine ?? []).length > 0 ||
      treatment[i].nine?.some((e) => e.id && !e.id.includes('urn'))
    ) {
      requestBundle.entry?.push({
        fullUrl: `${treatment[i].nine![0].resourceType}/${
          treatment[i].nine![0].id
        }`,
        resource: {
          ...treatment[i].nine![0],

          orderer: practRoleRef,
        },
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `/NutritionOrder?_id=${treatment[i].nine![0].id}`,
        },
      })
    }
    //   requestBundle.entry?.push({
    //     fullUrl: `${treatment[i].nine![0].resourceType}/${
    //       treatment[i].nine![0].id
    //     }`,
    //     resource: {
    //       ...treatment[i].nine![0],

    //       orderer: practRoleRef,
    //     },
    //     request: {
    //       method: R4.Bundle_RequestMethodKind._post,
    //       url: `/NutritionOrder?_id=${treatment[i].nine![0].id}`,
    //     },
    //   })
    // }
  }
  const finalEntry: R4.IBundle_Entry[] =
    requestBundle.entry?.map((e) => {
      if (
        getEncounterIdFromNutritionOrder(e.resource as INutritionOrder) !==
        encounterId
      ) {
        return {
          fullUrl: `${e.resource?.resourceType}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: `${e.resource?.resourceType}`,
          } as R4.IBundle_Request,
          resource: {
            ...e.resource,

            id: getUniqueTempId(),
            // dateTime: new Date().toISOString(),
            encounter: {
              reference: `Encounter/${encounterId}`,
            },
          } as INutritionOrder,
        }
      }
      return e
    }) ?? []

  const finalBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: finalEntry,
  }
  const nutritionOrderArray =
    finalEntry.map((e) => e.resource as INutritionOrder) ?? []

  return finalEntry.map((e) => e.resource as INutritionOrder) ?? []
}

export function getDietPlansPlanFromBundleForCase(
  responseBundle: R4.IBundle,
  serviceRequest: R4.IServiceRequest,
  encounterId: string,
  plan?: R4.ICarePlan
): Diet[] {
  const planData: Diet[] = []

  const dateWiseOrders: DateWiseNutritionOrders[] = []
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          const allNutritionOrders: R4.INutritionOrder[] = []
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (e.resource?.resourceType === 'NutritionOrder') {
                allNutritionOrders.push(e.resource)
              }
            })
          }
          allNutritionOrders.forEach((item) => {
            const dateDet = moment(
              item.oralDiet?.schedule![0].event![0]
            ).format('YYYY-MM-DD')
            const index = dateWiseOrders.findIndex((s) => s.date === dateDet)
            if (index < 0) {
              dateWiseOrders.push({
                date: dateDet,
                nutritionOrders: [item],
              })
            } else {
              dateWiseOrders[index].nutritionOrders.push(item)
            }
          })
        }
      }
    }

    const startDate: Date = moment(serviceRequest.occurrencePeriod?.start)
      .local()
      .toDate()
    let endDate: Date = moment()
      .add(4, 'days')
      .isBefore(moment(serviceRequest.occurrencePeriod?.end))
      ? moment().add(4, 'days').local().endOf('day').toDate()
      : moment(serviceRequest.occurrencePeriod?.end)
          .local()
          .endOf('day')
          .toDate()

    let currentDate: Date = startDate

    do {
      const index = dateWiseOrders.findIndex(
        // eslint-disable-next-line no-loop-func
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        (e) => e.date === moment(currentDate).format('YYYY-MM-DD')
      )
      let carePlanRefId: string = ''

      if (serviceRequest.basedOn) {
        const referenceArray = serviceRequest.basedOn ?? []
        if (referenceArray.length > 0)
          for (let j = 0; j < referenceArray.length; j++) {
            const ref = referenceArray[j].reference
            if (ref) {
              const planDataRef = ref.split('/')[0]
              if (planDataRef === 'CarePlan') {
                carePlanRefId = ref.split('/')[1]
              }
            }
          }
      }
      if (plan) {
        carePlanRefId = plan.id ?? ''
      }

      if (index < 0) {
        if (carePlanRefId.length > 0) {
          planData.push({
            date: moment(currentDate).format('YYYY-MM-DD'),
            eight: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                dateTime: currentDate.toISOString(),
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '830AM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            five: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '5PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            id: `${moment(currentDate).format('YYYY-MM-DD')}id`,
            seven: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,

                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '7PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            six: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '630AM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            twelve: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '12PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            two: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `CarePlan/${carePlanRefId}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '2PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          })
        } else {
          planData.push({
            date: moment(currentDate).format('YYYY-MM-DD'),
            eight: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                dateTime: currentDate.toISOString(),
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '830AM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            five: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '5PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            id: `${moment(currentDate).format('YYYY-MM-DD')}id`,
            seven: [
              {
                resourceType: 'NutritionOrder',
                id: getUniqueTempId(),
                status: 'active',
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                patient: serviceRequest.subject,

                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '7PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            six: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '630AM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            twelve: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '12PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            two: [
              {
                resourceType: 'NutritionOrder',
                status: 'active',
                id: getUniqueTempId(),
                extension: [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                    valueReference: {
                      reference: `Organization/${
                        getCurrentUserUnitDetails().id
                      }`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/WP-NutrionOrderBasedOn',
                    valueReference: {
                      reference: `ServiceRequest/${serviceRequest.id!}`,
                    },
                  },
                  {
                    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/diet-plan-type',
                    valueCoding: {
                      system:
                        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-diet-category-cs',
                      code: 'ipd-diet',
                      display: 'IPD Diet',
                    },
                  },
                ],
                encounter: {
                  reference: `Encounter/${encounterId}`,
                },
                patient: serviceRequest.subject,
                oralDiet: {
                  extension: [],
                  schedule: [
                    {
                      event: [
                        moment(currentDate).local().startOf('d').toISOString(),
                      ],
                      code: {
                        coding: [
                          {
                            code: '2PM',
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          })
        }
      } else if (carePlanRefId.length > 0) {
        planData.push({
          date: moment(currentDate).format('YYYY-MM-DD'),
          eight: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '830AM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
          five: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '5PM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
          id: `${moment(currentDate).format('YYYY-MM-DD')}id`,
          seven: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '7PM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
          six: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '630AM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
          twelve: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '12PM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
          two: getNutritionOrdersForSepcificTime(
            dateWiseOrders[index].nutritionOrders,
            '2PM',
            encounterId,
            serviceRequest,
            currentDate,
            carePlanRefId
          ),
        })
      } else {
        planData.push({
          date: moment(currentDate).format('YYYY-MM-DD'),
          eight: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '830AM',
            encounterId,
            serviceRequest,
            currentDate
          ),
          five: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '5PM',
            encounterId,
            serviceRequest,
            currentDate
          ),
          id: `${moment(currentDate).format('YYYY-MM-DD')}id`,
          seven: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '7PM',
            encounterId,
            serviceRequest,
            currentDate
          ),
          six: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '630AM',
            encounterId,
            serviceRequest,
            currentDate
          ),
          twelve: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '12PM',
            encounterId,
            serviceRequest,
            currentDate
          ),
          two: getNutritionOrdersForSepcificTimeWithoutPlan(
            dateWiseOrders[index].nutritionOrders,
            '2PM',
            encounterId,
            serviceRequest,
            currentDate
          ),
        })
      }
      const newDate = moment(currentDate).add(1, 'days').toDate()
      currentDate = newDate
      if (moment().isAfter(moment(serviceRequest.occurrencePeriod?.end))) {
        const newEndDate = moment().local().endOf('day').toDate()
        endDate = newEndDate
      }
    } while (currentDate <= endDate)
  } catch (error) {
    console.error(error)
  }
  return planData
}

export function getTimeOfDayTimeOfOralDietOfNO(
  nutritionOrder: INutritionOrder
): string {
  if (
    nutritionOrder.oralDiet?.schedule &&
    nutritionOrder.oralDiet?.schedule[0]?.repeat?.timeOfDay
  ) {
    return nutritionOrder.oralDiet?.schedule[0].repeat?.timeOfDay[0]
  }
  return ''
}
export function getTimeOfDayDisplayOfOralDietOfNO(
  nutritionOrder: INutritionOrder
): string {
  if (
    nutritionOrder.oralDiet?.schedule &&
    nutritionOrder.oralDiet?.schedule[0].code
  ) {
    return (
      getDefaultDisplayOfSystemFromCodableConcept(
        nutritionOrder.oralDiet?.schedule[0].code
      ) ?? ''
    )
  }
  return ''
}
