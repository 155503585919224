import { R4 } from '@ahryman40k/ts-fhir-types'
import { INutritionOrder } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { Diet } from 'models/diet'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getBedTimeTimeForOrder,
  getBreakfastTimeForOrder,
  getDinnerTimeForOrder,
  getEarlyMorningTimeForOrder,
  getIdsOfSelectedDetails,
  getLunchTImeForOrder,
  getMidMorningTimeForOrder,
  getSnackTImeForOrder,
  isExistingResource,
} from 'utils/fhirResoureHelpers/ipdDietHelper'
import { en } from 'wello-fhir-l10n/dist/in'
import { requestDietPlanDetails } from '../existingDietPlanSearch/existingDietPlanSearchSlice'
import { DietPlanAddSlice } from './addDietPlanStatusType'

const initialState: DietPlanAddSlice = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addDietPlanSlice = createSlice({
  name: 'addDietPlanSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<DietPlanAddSlice>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addDietBundle = action.payload.addDietBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<DietPlanAddSlice>) {
      state.adding = initialState.adding
      state.additionSuccessful = false
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addDietBundle = initialState.addDietBundle
    },
  },
})

export const addDietDetails =
  (
    encounterId: string,
    patientId: string,
    startDate: Date,
    endDate: Date,
    dietTime?: DietTiming[],
    treatment?: Diet,
    prefilledDietList?: R4.INutritionOrder[],
    foodsToAvoid?: string,
    preferredFoods?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: DietPlanAddSlice = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    console.log('-------------diet plan update-------------------', treatment)
    dispatch(addDietPlanSlice.actions.updateAddVitalsStatus(addingState))
    try {
      let bundleObject: R4.INutritionOrder[] = []
      if (prefilledDietList) {
        bundleObject = prefilledDietList
      } else if (dietTime && treatment) {
        bundleObject = createBundleObjectForDietPlan(
          dietTime,
          treatment,
          startDate,
          endDate,
          encounterId,
          foodsToAvoid,
          preferredFoods
        )
      }

      console.log(bundleObject)
      const unitId = getCurrentUserUnitDetails()?.id
      const fhirClient: EnrolCient = new EnrolCient()
      const response: R4.IBundle | FHIRErrorResponses =
        await fhirClient.doCreateEnrolmentFlowRequest('dietplan/create-new', {
          patientId,
          nutritionOrderOralDiet: bundleObject,
          unitId,
        })

      if (response) {
        addingState = {
          adding: false,
          additionSuccessful: true,
          error: false,
          errorMessage: '',
        }
        dispatch(showSuccessAlert('Diet plan updated successfully'))
        dispatch(addDietPlanSlice.actions.updateAddVitalsStatus(addingState))
        if (dietTime && patientId && encounterId) {
          dispatch(requestDietPlanDetails({ dietTime, patientId, encounterId }))
        }
      } else {
        const errorCreatePersonState: DietPlanAddSlice = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addDietPlanSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: DietPlanAddSlice = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addDietPlanSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

export const resetAddDietPlanDetails = () => (dispatch: AppDispatch) => {
  dispatch(addDietPlanSlice.actions.resetAddVitalsDetails(initialState))
}

function createBundleObjectForDietPlan(
  dietTImeSettings: DietTiming[],
  treatment: Diet,
  inputStartDate: Date,
  inputEndDate: Date,
  encounterId: string,
  foodsToAvoid?: string,
  preferredFoods?: string
): R4.INutritionOrder[] {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  let startDate: Date = inputStartDate
  let endDate: Date = inputEndDate

  if (moment(inputStartDate).isBefore(moment(new Date()))) {
    startDate = moment(new Date()).startOf('day').toDate()
  }
  if (moment(inputEndDate).isBefore(moment(startDate))) {
    endDate = moment(startDate).add(7, 'days').startOf('day').toDate()
  }
  const practRoleRef: R4.IReference = getCurrentUserPractitionerRoleRef()!
  const requestId: string = getUniqueTempId()
  if (isExistingResource(treatment.six ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.six![0].resourceType}/${treatment.six![0].id}`,
      resource: {
        ...treatment.six![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        orderer: practRoleRef,
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],
        oralDiet: {
          ...treatment.six![0].oralDiet,
          schedule: [
            {
              ...treatment.six![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getEarlyMorningTimeForOrder(dietTImeSettings)],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.six![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.six ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.six![0].resourceType}/${treatment.six![0].id}`,
      resource: {
        ...treatment.six![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        orderer: practRoleRef,
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],
        oralDiet: {
          ...treatment.six![0].oralDiet,
          schedule: [
            {
              ...treatment.six![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getEarlyMorningTimeForOrder(dietTImeSettings)],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.six![0].id}`,
      },
    })
  }
  if (isExistingResource(treatment.eight ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.eight![0].resourceType}/${treatment.eight![0].id}`,
      resource: {
        ...treatment.eight![0],
        foodPreferenceModifier:
          preferredFoods !== undefined && preferredFoods.length > 0
            ? [
                {
                  text: preferredFoods,
                },
              ]
            : undefined,
        excludeFoodModifier:
          foodsToAvoid !== undefined && foodsToAvoid.length > 0
            ? [
                {
                  text: foodsToAvoid,
                },
              ]
            : undefined,
        orderer: practRoleRef,
        oralDiet: {
          ...treatment.eight![0].oralDiet,
          schedule: [
            {
              ...treatment.eight![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getBreakfastTimeForOrder(dietTImeSettings)],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.eight![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.eight ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.eight![0].resourceType}/${treatment.eight![0].id}`,
      resource: {
        ...treatment.eight![0],
        foodPreferenceModifier:
          preferredFoods !== undefined && preferredFoods.length > 0
            ? [
                {
                  text: preferredFoods,
                },
              ]
            : undefined,
        excludeFoodModifier:
          foodsToAvoid !== undefined && foodsToAvoid.length > 0
            ? [
                {
                  text: foodsToAvoid,
                },
              ]
            : undefined,
        orderer: practRoleRef,
        oralDiet: {
          ...treatment.eight![0].oralDiet,
          schedule: [
            {
              ...treatment.eight![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getBreakfastTimeForOrder(dietTImeSettings)],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.eight![0].id}`,
      },
    })
  }
  if (isExistingResource(treatment.twelve ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.twelve![0].resourceType}/${
        treatment.twelve![0].id
      }`,
      resource: {
        ...treatment.twelve![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],
        orderer: practRoleRef,
        oralDiet: {
          ...treatment.twelve![0].oralDiet,
          schedule: [
            {
              ...treatment.twelve![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getMidMorningTimeForOrder(dietTImeSettings)],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.twelve![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.twelve ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.twelve![0].resourceType}/${
        treatment.twelve![0].id
      }`,
      resource: {
        ...treatment.twelve![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],
        orderer: practRoleRef,
        oralDiet: {
          ...treatment.twelve![0].oralDiet,
          schedule: [
            {
              ...treatment.twelve![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getMidMorningTimeForOrder(dietTImeSettings)],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.twelve![0].id}`,
      },
    })
  }

  if (isExistingResource(treatment.two ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.two![0].resourceType}/${treatment.two![0].id}`,
      resource: {
        ...treatment.two![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],
        orderer: practRoleRef,
        oralDiet: {
          ...treatment.two![0].oralDiet,
          schedule: [
            {
              ...treatment.two![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getLunchTImeForOrder(dietTImeSettings)],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.two![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.two ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.two![0].resourceType}/${treatment.two![0].id}`,
      resource: {
        ...treatment.two![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],
        orderer: practRoleRef,
        oralDiet: {
          ...treatment.two![0].oralDiet,
          schedule: [
            {
              ...treatment.two![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getLunchTImeForOrder(dietTImeSettings)],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.two![0].id}`,
      },
    })
  }
  if (isExistingResource(treatment.five ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.five![0].resourceType}/${treatment.five![0].id}`,
      resource: {
        ...treatment.five![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],

        orderer: practRoleRef,
        oralDiet: {
          ...treatment.five![0].oralDiet,
          schedule: [
            {
              ...treatment.five![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getSnackTImeForOrder(dietTImeSettings).trim()],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.five![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.five ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.five![0].resourceType}/${treatment.five![0].id}`,
      resource: {
        ...treatment.five![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],

        orderer: practRoleRef,
        oralDiet: {
          ...treatment.five![0].oralDiet,
          schedule: [
            {
              ...treatment.five![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getSnackTImeForOrder(dietTImeSettings).trim()],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.five![0].id}`,
      },
    })
  }
  if (isExistingResource(treatment.seven ?? [])) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.seven![0].resourceType}/${treatment.seven![0].id}`,
      resource: {
        ...treatment.seven![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],
        orderer: practRoleRef,
        oralDiet: {
          ...treatment.seven![0].oralDiet,
          schedule: [
            {
              ...treatment.seven![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getDinnerTimeForOrder(dietTImeSettings).trim()],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.seven![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.seven ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.seven![0].resourceType}/${treatment.seven![0].id}`,
      resource: {
        ...treatment.seven![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],
        orderer: practRoleRef,
        oralDiet: {
          ...treatment.seven![0].oralDiet,
          schedule: [
            {
              ...treatment.seven![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getDinnerTimeForOrder(dietTImeSettings).trim()],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.seven![0].id}`,
      },
    })
  }

  if (
    getIdsOfSelectedDetails(treatment.nine ?? []).length > 0 ||
    treatment.nine?.some((e) => e.id && !e.id.includes('urn'))
  ) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.nine![0].resourceType}/${treatment.nine![0].id}`,
      resource: {
        ...treatment.nine![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],
        orderer: practRoleRef,
        oralDiet: {
          ...treatment.nine![0].oralDiet,
          schedule: [
            {
              ...treatment.nine![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getBedTimeTimeForOrder(dietTImeSettings).trim()],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.nine![0].id}`,
      },
    })
  } else if (getIdsOfSelectedDetails(treatment.nine ?? []).length > 0) {
    requestBundle.entry?.push({
      fullUrl: `${treatment.nine![0].resourceType}/${treatment.nine![0].id}`,
      resource: {
        ...treatment.nine![0],
        foodPreferenceModifier: [
          {
            text: preferredFoods,
          },
        ],
        excludeFoodModifier: [
          {
            text: foodsToAvoid,
          },
        ],
        orderer: practRoleRef,
        oralDiet: {
          ...treatment.nine![0].oralDiet,
          schedule: [
            {
              ...treatment.nine![0].oralDiet?.schedule?.[0],
              repeat: {
                timeOfDay: [getBedTimeTimeForOrder(dietTImeSettings).trim()],
                boundsPeriod: {
                  start: startDate.toISOString(),
                  end: endDate.toISOString(),
                },
              },
            },
          ],
        },
      },
      request: {
        method: R4.Bundle_RequestMethodKind._put,
        url: `/NutritionOrder?_id=${treatment.nine![0].id}`,
      },
    })
  }

  const finalEntry: R4.IBundle_Entry[] =
    requestBundle.entry?.map((e) => {
      if (
        getEncounterIdFromNutritionOrder(e.resource as INutritionOrder) !==
        encounterId
      ) {
        return {
          fullUrl: `${e.resource?.resourceType}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: `${e.resource?.resourceType}`,
          } as R4.IBundle_Request,
          resource: {
            ...e.resource,

            id: getUniqueTempId(),
            dateTime: new Date().toISOString(),
            encounter: {
              reference: `Encounter/${encounterId}`,
            },
          } as INutritionOrder,
        }
      }
      return e
    }) ?? []

  return finalEntry.map((e) => e.resource as INutritionOrder) ?? []
}

export function getEncounterIdFromNutritionOrder(
  nutritionOrder: INutritionOrder
): string {
  return nutritionOrder.encounter!.reference!.split('/')[1]
}

export default addDietPlanSlice.reducer
