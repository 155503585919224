import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import React, { useEffect, useState } from 'react'
import ReactPanZoom from 'react-image-pan-zoom-rotate'
import { getContentOfDiagnosticReport } from 'services/fileServices'
import { isCurrentUnitIsWellonessCenter } from 'services/userDetailsService'
import { titleCase } from 'utils/fhirResourcesHelper'
import { getReportNames } from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { ReportAllPages } from './reportAllFile'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    medium: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    big: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
)

interface IProps {
  documentRef: R4.IDiagnosticReport
  showPreview: boolean
  onCloseClicked: () => void
}

export const DiagnosticReportPopup: React.FC<IProps> = ({
  documentRef,
  showPreview,
  onCloseClicked,
}: IProps) => {
  const [file, setFile] = useState<string>()
  const [fetching, setFetching] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [scale, setScale] = useState<number>(1.5)

  const type = documentRef.presentedForm
    ? documentRef.presentedForm[0].contentType!
    : ''

  const fileName = documentRef.code
    ? documentRef.code.coding && documentRef.code.coding.length > 0
      ? getReportNames(documentRef.code.coding ?? [])
      : ''
    : ''

  useEffect(() => {
    getContentOfDiagnosticReport(documentRef)
      .catch(() => {
        setError(true)
        setFetching(false)
        setFile(undefined)
      })
      .then((e) => {
        setError(false)
        setFetching(false)
        setFile(e!)
      })
  }, [])

  return (
    <Dialog
      maxWidth='xl'
      open={showPreview}
      onClose={() => {
        onCloseClicked()
      }}
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        {' '}
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>{fileName}</Box>
          <Box>
            <Tooltip title='Zoom In'>
              <ZoomInIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  if (scale < 1.75) setScale(scale + 0.25)
                }}
              />
            </Tooltip>

            <Tooltip title='Zoom Out'>
              <ZoomOutIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  if (scale > 1.0) setScale(scale - 0.25)
                }}
              />
            </Tooltip>
            <Tooltip title='Close'>
              <CloseOutlined
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  onCloseClicked()
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          justifyContent='center'
          alignContent='center'
          minWidth='400px'
          minHeight='500px'
        >
          {fetching && <CircularProgress />}
          {error && (
            <Typography variant='subtitle1' color='initial'>
              Error
            </Typography>
          )}
          {file && file.length > 0 && (
            <Box>
              {type.includes('pdf') && (
                <ReportAllPages pdf={file} scaleData={scale} />
              )}
              {type.includes('image') && (
                <ReactPanZoom image={file} alt='document image' />
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
