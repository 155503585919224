import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Theme,
  makeStyles,
} from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import { UmlResponse } from 'models/umlResponse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addAllergiesDetails,
  resetAddAllergiesState,
} from 'redux/fhirMedicalResources/addAllergy/addAllergySlice'
import { RootState } from 'redux/rootReducer'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'

import { WelloLoadingIndicator, WelloTextField } from 'wello-web-components'
import { validateCheckoutDateForChiefComplaints } from 'utils/formValidators'
import {
  getAgeInDays,
  getAgeInMonths,
  getAgeInWeeks,
  getAgeInYears,
  getAgeOfPatientForChecking,
} from 'utils/fhirResourcesHelper'
import { WelloSelectUnit } from 'views/components/LeftMenu/welloSelectUnit'
import {
  durationData,
  durationDataForChiefComplaints,
  durationDataForChiefComplaintsWithOne,
  durationDataWithOne,
} from 'utils/constants'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { RadioRaisedButton } from 'views/components/LeftMenu/RadioButtons'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloAllergy } from 'models/WelloAllergy'
import _ from 'lodash'
import { getCurrentUserPractitionerDetails } from 'services/userDetailsService'
import { updateAllergyDetails } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showSuccessAlert, showErrorAlert } from 'redux/alertHandler/alertSlice'
import { WelloSelectFHIRCodingWithAutocomplete } from 'views/components/LeftMenu/WelloSelectCodingWithAutoComplete'
import { AllergyMasterChosser } from '../subjective/allergyMasterChosser'

interface Props {
  open: boolean
  existingCondition: WelloAllergy

  onClose: () => void
  patient: R4.IPatient
  onAllergiesDetailsAdded: () => void
  appointmentId?: string
}
export const EditAllergy: React.FC<Props> = ({
  open,
  onClose,
  onAllergiesDetailsAdded,
  existingCondition,
  patient,
  appointmentId,
}) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '40%',
    },
  }))
  const classes = useStyles()

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  )
  const [otherData, setOtherData] = useState<string>()
  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [selectedSeverity, setSeverity] =
    useState<R4.AllergyIntoleranceCategoryKind>()
  const [isActive, setIsActive] = useState<boolean>(true)
  const handleDateChange = (date: MaterialUiPickersDate) => {
    setCurrentCondition({
      ...currentCondition,
      selectedDate: date !== null ? moment(date).toDate() : new Date(),
    })
  }
  const [currentCondition, setCurrentCondition] = useState<WelloAllergy>({
    id: existingCondition.id,
    selectedType: existingCondition.selectedType ?? 'days'.toLowerCase(),
    days: existingCondition.days,
    month: existingCondition.month,
    year: existingCondition.year,
    week: existingCondition.week,
    selectedDate: existingCondition.selectedDate,
    condition: existingCondition.condition,
    existingAllergy: existingCondition.existingAllergy,
    selectedSeverity: existingCondition.selectedSeverity,
    isActive: existingCondition.isActive,
  })

  function handleMonths(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      month: rate,
    })
  }
  function handleYear(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      year: rate,
    })
  }

  function handleDays(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      days: rate,
    })
  }

  function handleWeeks(rate: number) {
    setCurrentCondition({
      ...currentCondition,
      week: rate,
    })
  }
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  function updateConditions() {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IAllergyIntolerance = {
      ...currentCondition.existingAllergy!,
    }

    oldProcedure.code = {
      coding: [currentCondition.condition!],
      text: currentCondition.condition!.display,
    }
    oldProcedure.category = [currentCondition.selectedSeverity!]
    oldProcedure.recordedDate = new Date().toISOString()
    oldProcedure.clinicalStatus = {
      coding: [
        {
          code: currentCondition.isActive === true ? 'active' : 'inactive',
          system: 'http://terminology.hl7.org/CodeSystem/condition-clinical',
          display: currentCondition.isActive === true ? 'Active' : 'Inactive',
        },
      ],
    }
    if (
      currentCondition.selectedType &&
      currentCondition.selectedType!.length > 0
    ) {
      if (
        currentCondition.selectedType === 'days' ||
        currentCondition.selectedType === 'day'
      ) {
        if (currentCondition.days && currentCondition.days! > 0) {
          oldProcedure.onsetDateTime = undefined
          oldProcedure.onsetAge = {
            system: 'http://unitsofmeasure.org',
            unit: 'd',
            code: 'd',
            value: currentCondition.days,
          }
        }
      } else if (
        currentCondition.selectedType === 'months' ||
        currentCondition.selectedType === 'month'
      ) {
        oldProcedure.onsetDateTime = undefined
        oldProcedure.onsetAge = {
          system: 'http://unitsofmeasure.org',
          unit: 'mo',
          code: 'mo',
          value: currentCondition.month!,
        }
      } else if (
        currentCondition.selectedType === 'weeks' ||
        currentCondition.selectedType === 'week'
      ) {
        oldProcedure.onsetDateTime = undefined
        oldProcedure.onsetAge = {
          system: 'http://unitsofmeasure.org',
          unit: 'wk',
          code: 'wk',
          value: currentCondition.week!,
        }
      } else if (
        currentCondition.selectedType === 'years' ||
        currentCondition.selectedType === 'year'
      ) {
        oldProcedure.onsetDateTime = undefined
        oldProcedure.onsetAge = {
          system: 'http://unitsofmeasure.org',
          unit: 'a',
          code: 'a',
          value: currentCondition.year!,
        }
      } else if (currentCondition.selectedType === 'custom') {
        oldProcedure.onsetAge = undefined
        if (currentCondition.selectedDate) {
          oldProcedure.onsetDateTime =
            currentCondition.selectedDate!.toISOString()
        }
      }
    }
    if (currentCondition.notes && currentCondition.notes!.length > 0) {
      oldProcedure.note = [
        {
          authorReference: {
            reference: `${getCurrentUserPractitionerDetails().resourceType}/${
              getCurrentUserPractitionerDetails().id
            }`,
          },
          text: currentCondition.notes,
          time: new Date().toISOString(),
        },
      ]
    } else {
      oldProcedure.note = undefined
    }
    setLoading(true)

    updateAllergyDetails(oldProcedure, appointmentId!).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Allergy details updated successfully'))
        onAllergiesDetailsAdded()
        // dispatch(
        //   requestConditionHistoryOfPatient(
        //     patient,
        //     purposeOfUse,
        //     splitview,
        //     requestingAppointmentId,
        //     encounterId
        //   )
        // )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Allergy details. Please try again later'
          )
        )
      }
    })
  }

  function resetDetails() {
    setCurrentCondition({
      id: _.random(1, 10000000).toString(),
      selectedType: 'days',
      days: 1,
      month: 1,
      year: 1,
      week: 1,
      selectedDate: new Date(),
      isActive: true,
    })
  }
  function isEnableCreateButton(): boolean | undefined {
    let result: boolean = false
    if (currentCondition.condition === undefined) {
      result = false
    }

    if (currentCondition.selectedType) {
      if (
        currentCondition.selectedType === 'days' &&
        currentCondition.days &&
        (currentCondition.days < 1 ||
          currentCondition.days > getAgeInDays(patient))
      ) {
        result = true
      }
      if (
        currentCondition.selectedType === 'months' &&
        currentCondition.month &&
        (currentCondition.month < 1 ||
          currentCondition.month > getAgeInMonths(patient))
      ) {
        result = true
      }
      if (
        currentCondition.selectedType === 'years' &&
        currentCondition.year &&
        (currentCondition.year < 1 ||
          currentCondition.year > getAgeInYears(patient))
      ) {
        result = true
      }
      if (
        currentCondition.selectedType === 'weeks' &&
        currentCondition.week &&
        (currentCondition.week < 1 ||
          currentCondition.week > getAgeInWeeks(patient))
      ) {
        result = true
      }
      if (
        currentCondition.selectedType === 'custom' &&
        currentCondition.selectedDate &&
        validateCheckoutDateForChiefComplaints(
          moment(currentCondition.selectedDate).format('YYYY-MM-DD'),
          getAgeOfPatientForChecking(patient),
          false
        ).length > 0
      ) {
        result = true
      } else {
        result = false
      }
    }

    return result
  }
  const dispatch = useDispatch()

  const addAllergySlice = useSelector(
    (state: RootState) => state.addAllergySlice
  )

  useEffect(() => {
    setCurrentCondition({
      id: existingCondition.id,
      selectedType: existingCondition.selectedType ?? 'days'.toLowerCase(),
      days: existingCondition.days,
      month: existingCondition.month,
      year: existingCondition.year,
      week: existingCondition.week,
      selectedDate: existingCondition.selectedDate,
      condition: existingCondition.condition,
      existingAllergy: existingCondition.existingAllergy,
      isActive: existingCondition.isActive,
      notes: existingCondition.notes,
      selectedSeverity: existingCondition.selectedSeverity,
    })
  }, [existingCondition])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
        setSeverity(undefined)
        setSelectedCondition(undefined)
        setIsActive(false)
        setSelectedDate(new Date())
        setOtherData(undefined)
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Edit Allergy Details</DialogTitle>
      <DialogContent dividers={true}>
        {loading && <WelloLoadingIndicator message='Adding details' />}

        {!loading && (
          <Box
            marginX={1}
            paddingBottom={1}
            key={currentCondition.id}
            display='flex'
            flexDirection='column'
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container direction='column' spacing={1} wrap='nowrap'>
                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <Box display='flex' flexDirection='row'>
                        <WelloFormItemLabel title='Select Allergies' />{' '}
                      </Box>
                      <AllergyMasterChosser
                        disabled={false}
                        onSelectionChanges={(allergy: UmlResponse) => {
                          const conditonVal1: R4.ICoding = {
                            system:
                              'http://terminology.hl7.org/CodeSystem/umls',
                            code: allergy.cui,
                            display: allergy.display,
                          }
                          setCurrentCondition({
                            ...currentCondition,
                            condition: conditonVal1,
                          })
                        }}
                        preSelectedComplaints={{
                          cui: currentCondition.condition
                            ? currentCondition.condition.code ?? ''
                            : '',
                          display: currentCondition.condition
                            ? currentCondition.condition.display ?? ''
                            : '',
                          label: currentCondition.condition
                            ? currentCondition.condition.display ?? ''
                            : '',
                          snomed_ct:
                            'http://terminology.hl7.org/CodeSystem/umls',
                        }}
                      />

                      {/* <SingleSelectWithTextSearch
                        id='add_allergy'
                        availableOptions={allergyOptions}
                        detachLabel={true}
                        displayField='display'
                        isInEditMode={true}
                        labelString='Select Allergy'
                        onAddClicked={(selectedValue) => {
                          setSelectedCondition(selectedValue as R4.ICoding)
                        }}
                        placeHolderString='Select Allergy'
                        valuesField='code'
                        selectedValue={selectedCondition ?? {}}
                      >
                        {' '}
                        Chief Complaint{' '}
                      </SingleSelectWithTextSearch> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction='row' wrap='nowrap'>
                    <Grid
                      item
                      xs={
                        currentCondition.selectedType &&
                        currentCondition.selectedType === 'days'
                          ? 8
                          : currentCondition.selectedType &&
                            currentCondition.selectedType === 'custom'
                          ? 6
                          : 7
                      }
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        paddingTop={1}
                      >
                        <WelloFormItemLabel title='Onset Duration' />{' '}
                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'day' ||
                            currentCondition.selectedType === 'days') && (
                            <Box paddingLeft={1} width='70%'>
                              <WelloSelectFHIRCodingWithAutocomplete
                                title=''
                                placeHolder='Select Duration Unit'
                                availableCodings={
                                  currentCondition.days! === 1
                                    ? durationDataForChiefComplaintsWithOne
                                    : durationDataForChiefComplaints
                                }
                                onChanged={(type) => {
                                  if (type.display && type.display.length > 0) {
                                    if (
                                      type.display === 'Days' ||
                                      type.display === 'Day'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'days',
                                      })
                                    else if (
                                      type.display === 'Months' ||
                                      type.display === 'Month'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'months',
                                      })
                                    else if (
                                      type.display === 'Weeks' ||
                                      type.display === 'Week'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'weeks',
                                      })
                                    else if (
                                      type.display === 'Years' ||
                                      type.display === 'Year'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'years',
                                      })
                                    else
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'custom',
                                      })
                                  }
                                }}
                                id='al20'
                                preSelectedCoding={
                                  currentCondition.days! === 1 ||
                                  currentCondition.week! === 1
                                    ? durationDataWithOne[0]
                                    : durationData[0]
                                }
                              />
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'week' ||
                            currentCondition.selectedType === 'weeks') && (
                            <Box paddingLeft={1} width='65%'>
                              <WelloSelectFHIRCodingWithAutocomplete
                                title=''
                                placeHolder='Select Duration Unit'
                                id='al21'
                                availableCodings={
                                  currentCondition.week! === 1
                                    ? durationDataForChiefComplaintsWithOne
                                    : durationDataForChiefComplaints
                                }
                                onChanged={(type) => {
                                  if (type.display && type.display.length > 0) {
                                    if (
                                      type.display === 'Days' ||
                                      type.display === 'Day'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'days',
                                      })
                                    else if (
                                      type.display === 'Months' ||
                                      type.display === 'Month'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'months',
                                      })
                                    else if (
                                      type.display === 'Weeks' ||
                                      type.display === 'Week'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'weeks',
                                      })
                                    else if (
                                      type.display === 'Years' ||
                                      type.display === 'Year'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'years',
                                      })
                                    else
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'custom',
                                      })
                                  }
                                }}
                                preSelectedCoding={
                                  currentCondition.week! === 1
                                    ? durationDataWithOne[1]
                                    : durationData[1]
                                }
                              />
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'month' ||
                            currentCondition.selectedType === 'months') && (
                            <Box paddingLeft={1} width='65%'>
                              <WelloSelectFHIRCodingWithAutocomplete
                                title=''
                                placeHolder='Select Duration Unit'
                                id='al22'
                                availableCodings={
                                  currentCondition.month! === 1
                                    ? durationDataForChiefComplaintsWithOne
                                    : durationDataForChiefComplaints
                                }
                                onChanged={(type) => {
                                  if (type.display && type.display.length > 0) {
                                    if (
                                      type.display === 'Days' ||
                                      type.display === 'Day'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'days',
                                      })
                                    else if (
                                      type.display === 'Months' ||
                                      type.display === 'Month'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'months',
                                      })
                                    else if (
                                      type.display === 'Weeks' ||
                                      type.display === 'Week'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'weeks',
                                      })
                                    else if (
                                      type.display === 'Years' ||
                                      type.display === 'Year'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'years',
                                      })
                                    else
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'custom',
                                      })
                                  }
                                }}
                                preSelectedCoding={
                                  currentCondition.month! === 1
                                    ? durationDataWithOne[2]
                                    : durationData[2]
                                }
                              />
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'year' ||
                            currentCondition.selectedType === 'years') && (
                            <Box paddingLeft={1} width='65%'>
                              <WelloSelectFHIRCodingWithAutocomplete
                                title=''
                                placeHolder='Select Duration Unit'
                                id='al23'
                                availableCodings={
                                  currentCondition.year! === 1
                                    ? durationDataForChiefComplaintsWithOne
                                    : durationDataForChiefComplaints
                                }
                                onChanged={(type) => {
                                  if (type.display && type.display.length > 0) {
                                    if (
                                      type.display === 'Days' ||
                                      type.display === 'Day'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'days',
                                      })
                                    else if (
                                      type.display === 'Months' ||
                                      type.display === 'Month'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'months',
                                      })
                                    else if (
                                      type.display === 'Weeks' ||
                                      type.display === 'Week'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'weeks',
                                      })
                                    else if (
                                      type.display === 'Years' ||
                                      type.display === 'Year'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'years',
                                      })
                                    else
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'custom',
                                      })
                                  }
                                }}
                                preSelectedCoding={
                                  currentCondition.year! === 1
                                    ? durationDataWithOne[3]
                                    : durationData[3]
                                }
                              />
                            </Box>
                          )}
                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'custom' && (
                            <Box paddingLeft={1} width='59%'>
                              <WelloSelectFHIRCodingWithAutocomplete
                                title=''
                                placeHolder='Select Duration Unit'
                                id='al24'
                                availableCodings={
                                  currentCondition.days! === 1 &&
                                  currentCondition.month! === 1 &&
                                  currentCondition.week! === 1 &&
                                  currentCondition.year! === 1
                                    ? durationDataForChiefComplaintsWithOne
                                    : durationDataForChiefComplaints
                                }
                                onChanged={(type) => {
                                  if (type.display && type.display.length > 0) {
                                    if (
                                      type.display === 'Days' ||
                                      type.display === 'Day'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'days',
                                      })
                                    else if (
                                      type.display === 'Months' ||
                                      type.display === 'Month'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'months',
                                      })
                                    else if (
                                      type.display === 'Weeks' ||
                                      type.display === 'Week'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'weeks',
                                      })
                                    else if (
                                      type.display === 'Years' ||
                                      type.display === 'Year'
                                    )
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'years',
                                      })
                                    else
                                      setCurrentCondition({
                                        ...currentCondition,
                                        selectedType: 'custom',
                                      })
                                  }
                                }}
                                preSelectedCoding={
                                  durationDataForChiefComplaints[4]
                                }
                              />
                            </Box>
                          )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={
                        currentCondition.selectedType &&
                        currentCondition.selectedType === 'days'
                          ? 4
                          : currentCondition.selectedType &&
                            currentCondition.selectedType === 'custom'
                          ? 6
                          : 5
                      }
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='100%'
                        paddingTop={1}
                      >
                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'day' ||
                            currentCondition.selectedType === 'days') && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={4.5}
                            >
                              <WelloFormItemLabel title='Enter Days' />{' '}
                              <Box width='55%' paddingLeft={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: `al25`,
                                    value: currentCondition.days!,
                                    type: 'number',
                                    inputProps: {
                                      max: 365,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.days! < 1 ||
                                      currentCondition.days! >
                                        getAgeInDays(patient) ||
                                      Number.isNaN(currentCondition.days!),
                                    helperText:
                                      currentCondition.days! < 1 ||
                                      currentCondition.days! >
                                        getAgeInDays(patient) ||
                                      Number.isNaN(currentCondition.days)
                                        ? `Days should be in between 1 to ${getAgeInDays(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleDays(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'week' ||
                            currentCondition.selectedType === 'weeks') && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={4.5}
                            >
                              <WelloFormItemLabel title='Enter Weeks' />{' '}
                              <Box width='59.5%' paddingLeft={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: `al26`,
                                    value: currentCondition.week!,
                                    type: 'number',
                                    inputProps: {
                                      max: 52,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.week! < 1 ||
                                      currentCondition.week! >
                                        getAgeInWeeks(patient) ||
                                      Number.isNaN(currentCondition.week!),
                                    helperText:
                                      currentCondition.week! < 1 ||
                                      currentCondition.week! > 52 ||
                                      Number.isNaN(currentCondition.week!)
                                        ? `Weeks should be in between 1 to ${getAgeInWeeks(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleWeeks(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'month' ||
                            currentCondition.selectedType === 'months') && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={4.5}
                            >
                              <WelloFormItemLabel title='Enter Months' />{' '}
                              <Box width='57%' paddingLeft={1}>
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: `al27`,
                                    value: currentCondition.month!,
                                    type: 'number',
                                    inputProps: {
                                      max: 12,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.month! < 1 ||
                                      currentCondition.month! >
                                        getAgeInMonths(patient) ||
                                      Number.isNaN(currentCondition.month!),
                                    helperText:
                                      currentCondition.month! < 1 ||
                                      currentCondition.month! >
                                        getAgeInMonths(patient) ||
                                      Number.isNaN(currentCondition.month!)
                                        ? `Months should be in between 1 to ${getAgeInMonths(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleMonths(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          (currentCondition.selectedType === 'years' ||
                            currentCondition.selectedType === 'year') && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              flexGrow={1}
                              paddingLeft={4.5}
                            >
                              <WelloFormItemLabel title='Enter Years' />{' '}
                              <Box
                                width={
                                  currentCondition.selectedType &&
                                  currentCondition.selectedType === 'years'
                                    ? '63%'
                                    : '69%'
                                }
                                paddingLeft={1}
                              >
                                <WelloTextFieldWithoutTitle
                                  textProps={{
                                    id: `al28`,
                                    value: currentCondition.year!,
                                    type: 'number',
                                    inputProps: {
                                      max: 12,
                                      min: 1,
                                    },
                                    error:
                                      currentCondition.year! < 1 ||
                                      currentCondition.year! >
                                        getAgeInYears(patient) ||
                                      Number.isNaN(currentCondition.year!),
                                    helperText:
                                      currentCondition.year! < 1 ||
                                      currentCondition.year! >
                                        getAgeInYears(patient) ||
                                      Number.isNaN(currentCondition.year!)
                                        ? `Years should be in between 1 to ${getAgeInYears(
                                            patient
                                          )}`
                                        : '',
                                    onChange: (rate) => {
                                      handleYear(
                                        parseInt(rate.target.value, 10)
                                      )
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                          )}

                        {currentCondition.selectedType &&
                          currentCondition.selectedType === 'custom' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                              paddingLeft={4.5}
                            >
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Box paddingTop={0.4}>
                                  <WelloFormItemLabel title='Choose Date' />
                                </Box>
                                <Box width='67%' paddingLeft={0.5}>
                                  <KeyboardDatePicker
                                    clearable
                                    id='date'
                                    tabIndex={0}
                                    maxDate={moment(
                                      currentCondition.selectedDate &&
                                        currentCondition.selectedDate
                                    )}
                                    minDate={moment(
                                      getAgeOfPatientForChecking(patient)
                                    )}
                                    value={
                                      currentCondition.selectedDate
                                        ? currentCondition.selectedDate
                                        : new Date()
                                    }
                                    format='DD-MM-YYYY'
                                    onChange={handleDateChange}
                                    error={
                                      validateCheckoutDateForChiefComplaints(
                                        moment(selectedDate).format(
                                          'YYYY-MM-DD'
                                        ),
                                        getAgeOfPatientForChecking(patient),
                                        false
                                      ).length > 0
                                    }
                                    helperText={validateCheckoutDateForChiefComplaints(
                                      moment(selectedDate).format('YYYY-MM-DD'),
                                      getAgeOfPatientForChecking(patient),
                                      false
                                    )}
                                    size='small'
                                    inputVariant='outlined'
                                  />
                                </Box>
                              </MuiPickersUtilsProvider>
                            </Box>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box width='100%' flexDirection='row' display='flex'>
                    <Box width='20.5%' py={1.3} paddingLeft={0.3}>
                      <Typography variant='subtitle2'>
                        {t('labelCommon:Status')}{' '}
                      </Typography>
                    </Box>
                    <Box width='50%' paddingLeft={1.3}>
                      <Typography component='div'>
                        <Box px={0.5}>
                          <Grid component='label' container alignItems='center'>
                            <Box>
                              <Typography variant='subtitle2'>
                                Resolved
                              </Typography>
                            </Box>
                            <Box>
                              <Switch
                                checked={currentCondition.isActive!}
                                color='primary'
                                id='med_15'
                                onChange={(val: any, checked) => {
                                  setCurrentCondition({
                                    ...currentCondition,
                                    isActive: checked,
                                  })
                                }}
                                name='checkedA'
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography variant='subtitle2'>
                                Active{' '}
                              </Typography>
                            </Box>
                          </Grid>
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box width='100%' flexDirection='row' display='flex'>
                    <Box width='20%' paddingTop={0.3}>
                      <WelloFormItemLabel
                        title={t(
                          'labelAllergyTollerance:attr_labels.AllergyIntolerance.category'
                        )}
                      />
                    </Box>

                    <Box width='75%' paddingRight={2} paddingLeft={2}>
                      <RadioRaisedButton
                        allowChanging={true}
                        availableOptions={[
                          {
                            title: t(
                              'labelCommon:' +
                                `${R4.AllergyIntoleranceCategoryKind._food.toLocaleUpperCase()}`
                            ),
                            code: R4.AllergyIntoleranceCategoryKind._food,
                          },
                          {
                            title: t(
                              'labelCommon:' +
                                `${R4.AllergyIntoleranceCategoryKind._environment.toLocaleUpperCase()}`
                            ),
                            code: R4.AllergyIntoleranceCategoryKind
                              ._environment,
                          },

                          {
                            title: t(
                              'labelCommon:' +
                                `${R4.AllergyIntoleranceCategoryKind._biologic.toLocaleUpperCase()}`
                            ),
                            code: R4.AllergyIntoleranceCategoryKind._biologic,
                          },

                          {
                            title: t(
                              'labelCommon:' +
                                `${R4.AllergyIntoleranceCategoryKind._medication.toLocaleUpperCase()}`
                            ),
                            code: R4.AllergyIntoleranceCategoryKind._medication,
                          },
                        ]}
                        displayField='title'
                        onValueChanged={(val) => {
                          setCurrentCondition({
                            ...currentCondition,
                            selectedSeverity: val.code,
                          })
                          //   setSeverity(val.code)
                        }}
                        valuesField='code'
                        selectedValue={{
                          code: currentCondition.selectedSeverity!,
                          title: '',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item>
                  <Grid container direction='column' wrap='nowrap'>
                    <Grid item>
                      <WelloFormItemLabel title='Additional Notes' />
                    </Grid>
                    <Grid item>
                      <TextField
                        size='small'
                        fullWidth
                        multiline
                        id='al_33'
                        type='number'
                        value={currentCondition.notes}
                        variant='outlined'
                        onChange={(event) => {
                          setCurrentCondition({
                            ...currentCondition,
                            notes: event.target.value,
                          })
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          paddingRight={2.7}
        >
          <Button
            onClick={() => {
              onClose()
              setSeverity(undefined)
              setSelectedCondition(undefined)
              setIsActive(false)
              setSelectedDate(new Date())
              resetDetails()
            }}
            variant='outlined'
            id='al_33'
            disableElevation
            disabled={loading}
          >
            {t('labelCommon:cancel')}
          </Button>

          <Button
            onClick={() => {
              updateConditions()
            }}
            variant='contained'
            color='primary'
            disableElevation
            id='al_34'
            disabled={
              currentCondition.condition === undefined ||
              isEnableCreateButton() ||
              loading === true
            }
          >
            Update
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
