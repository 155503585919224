import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  Theme,
  TableRow,
  Typography,
  TableHead,
  TableBody,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { up } from 'dinero.js'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import React, { useState } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import {
  DisplayTableData,
  EachItem,
} from 'utils/careplan_utils/llmDietPlanHelper'

interface Item {
  id: string
  content: string
}

interface Column {
  id: string
  title: string
  items: Item[]
}

interface DragAndDropProps {
  tableData: DisplayTableData
  tableDataAfterDragDrop: (data: DisplayTableData) => void
}

const DragAndDrop: React.FC<DragAndDropProps> = ({
  tableData,
  tableDataAfterDragDrop
}: DragAndDropProps) => {
  const [columns, setColumns] = useState<DisplayTableData>(tableData)

  const onDragEnd = (result: DropResult) => {
    console.log('-----result----')
    console.log(result)

    const itemId = result.draggableId.split('_')[3]

    const source = result.source
    const sourceDate = source.droppableId.split('_')[0]
    const sourceDayOfTime = source.droppableId.split('_')[1]
    if (result.source && result.destination) {
      const destinationDate = result.destination?.droppableId.split('_')[0]
      const destinationDayOfTime = result.destination?.droppableId.split('_')[1]

      const sourceColumnIndex = columns.header.findIndex(
        (header) => header.time === sourceDayOfTime
      )
      const destinationColumnIndex = columns.header.findIndex(
        (header) => header.time === destinationDayOfTime
      )
      const destRowIndex = columns.data.findIndex(
        (col) => col[0] === destinationDate
      )
      const sourceRowIndex = columns.data.findIndex(
        (col) => col[0] === sourceDate
      )

      console.log('-----sourceItem row----')
      console.log(columns.data[sourceRowIndex][sourceColumnIndex])

      console.log('-----itemId----')
      console.log(itemId)

      const sourceItemIndex = columns.data[sourceRowIndex][
        sourceColumnIndex
      ].findIndex((item: EachItem) => item.itemDisplay === itemId)

      console.log('-----sourceItemIndex----')
      console.log(sourceItemIndex)
      console.log('-----sourceItem----')
      console.log(
        columns.data[sourceRowIndex][sourceColumnIndex][sourceItemIndex]
      )

      console.log('-----destinationItem----')
      console.log(columns.data[destRowIndex][destinationColumnIndex])

      const newItem: EachItem = {
        date: destinationDate,
        time: destinationDayOfTime,
        itemValue: (
          columns.data[sourceRowIndex][sourceColumnIndex][
            sourceItemIndex
          ] as EachItem
        ).itemValue,
        itemDisplay: (
          columns.data[sourceRowIndex][sourceColumnIndex][
            sourceItemIndex
          ] as EachItem
        ).itemDisplay,
      }
      const updatedData: DisplayTableData = { ...columns }
      updatedData.data[destRowIndex][destinationColumnIndex].push(newItem)
      updatedData.data[sourceRowIndex][sourceColumnIndex].splice(
        sourceItemIndex,
        1
      )
      setColumns(updatedData)
      tableDataAfterDragDrop(updatedData)
    }
  }

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : '',
  })

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ) =>
    ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // change background colour if dragging
      background: isDragging ? 'lightgreen' : 'lightgrey',

      // styles we need to apply on draggables
      ...draggableStyle,
    } as React.CSSProperties)

  const useStyles = makeStyles((theme: Theme) => ({
    table: {
      padding: '3px',
      height: '40vh',
      margin: '0px',
      '& .MuiTableCell-root': {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        padding: '4px',
        margin: '0px',
        height: 35,
        maxWidth: '12%',
        overflow: 'ellipse',
      },
    },
    tableDietCompliance: {
      padding: '3px',
      margin: '0px',
      height: 150,
      '& .MuiTableCell-root': {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        padding: '4px',
        margin: '0px',

        maxWidth: '12%',
        overflow: 'ellipse',
      },
    },
    dialogCustomizedWidth: {
      'max-width': '100%',
      minHeight: '100vh',
      maxHeight: '100vh',
    },
    dialogCustomizedWidthSub: {
      'max-width': '40%',
      minHeight: '45vh',
      maxHeight: '45vh',
    },
    dialogCustomizedWidthEdit: {
      'max-width': '30%',
      minHeight: '34vh',
      maxHeight: '34vh',
    },
    dialogCustomizedWidthAccompaniment: {
      'max-width': '40%',
      minHeight: '40vh',
      maxHeight: '40vh',
    },
    dialogCustomizedWidthMealCourse: {
      'max-width': '40%',
      minHeight: '100vh',
      maxHeight: '100vh',
    },
    dialogCustomizedWidthCategories: {
      'max-width': '40%',
      minHeight: '65vh',
      maxHeight: '65vh',
    },
  }))
  const classes = useStyles()

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width='100%'
      px={1}
      paddingTop={5}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        width='90%'
        paddingRight={1}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <TableContainer
            component={Paper}
            style={{
              width: '100%',
              margin: '0px',
              padding: '0px',
            }}
          >
            <Table className={classes.table} aria-label='simple table'>
              <TableHead
                style={{
                  backgroundColor: kDialogueBackground,
                }}
              >
                <TableRow
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {columns.header.map((eachHeader, cellIndex) => {
                    if (cellIndex === 0) {
                      return (
                        <TableCell key='day_of_week'>
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 14,
                              fontWeight: 600,
                              lineHeight: 'normal',
                            }}
                          >
                            Day of Week
                          </Typography>
                        </TableCell>
                      )
                    }
                    return (
                      <TableCell key={eachHeader.time}>
                        <Typography
                          variant='subtitle2'
                          // color='initial'
                          style={{
                            fontFamily: 'Open Sans',
                            fontSize: 14,
                            fontWeight: 600,
                            lineHeight: 'normal',
                          }}
                        >
                          {eachHeader.display} ({eachHeader.displayMealType})
                        </Typography>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={onDragEnd}>
                <TableBody>
                  {console.log("---The columns data is---")}
                  {console.log(columns)}
                  {columns.data.map((day, index) => (
                    <TableRow key={day.length > 0 ? day[0] : 'abc'}>
                    {console.log("---The day value is---")}
                    {console.log(day)}
                      {day.map((eachDay, cellIndex) => {
                        if (cellIndex === 0) {
                          return (
                            <TableCell
                              key={eachDay as string}
                              style={{
                                backgroundColor: '#f5f5f5',
                              }}
                            >
                                {console.log("---The eachDay value is---")}
                                {console.log(eachDay)}
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 14,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {eachDay}
                              </Typography>
                            </TableCell>
                          )
                        }
                        return (
                          <Droppable
                            key={`${day[0]}_${columns.header[cellIndex].time}`}
                            droppableId={`${day[0]}_${columns.header[cellIndex].time}`}
                          >
                            {(provided, snapshot) => (
                              <TableCell
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                                key={`${day[0]}_${columns.header[cellIndex].time}_cell`}
                              >
                                {(eachDay as EachItem[]).map(
                                  (eachItem: EachItem) => (
                                    <Draggable
                                      key={`draggable_${eachItem.date}_${eachItem.time}_${eachItem.itemDisplay}`}
                                      draggableId={`drag-id_${eachItem.date}_${eachItem.time}_${eachItem.itemDisplay}`}
                                      index={index}
                                    >
                                      {(dragProvided, dragSnapshot) => (
                                        <div
                                          ref={dragProvided.innerRef}
                                          {...dragProvided.draggableProps}
                                          {...dragProvided.dragHandleProps}
                                          style={{
                                            ...getItemStyle(
                                              dragSnapshot.isDragging,
                                              dragProvided.draggableProps.style
                                            ),
                                          }}
                                        >
                                          <Typography
                                            key={`${eachItem.date}_${eachItem.time}_${eachItem.itemDisplay}`}
                                            color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 14,
                                              fontWeight: 600,
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            {eachItem.itemDisplay}
                                          </Typography>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                )}
                                {provided.placeholder}
                              </TableCell>
                            )}
                          </Droppable>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </DragDropContext>
            </Table>
          </TableContainer>
        </DragDropContext>
      </Box>
    </Box>
  )
}

export default DragAndDrop

