import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { GroupedOccupation } from 'models/groupedOccupations'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { isOrgAdmin } from 'services/userDetailsService'
import { sleep } from 'utils/dateUtil'
import {
  getEmailOfPatient,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import {
  getBottom4HabitList,
  getHabitDataFromObs,
  getSortedObservationForHabit,
  getTop4HabitList,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { HabitsHistoryStatus } from './habitsHistoryTypes'

const initialState: HabitsHistoryStatus = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
}

const habitsHistorySlice = createSlice({
  name: 'habitsHistorySlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<HabitsHistoryStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.habitsList = action.payload.habitsList
      state.groupedHabitData = action.payload.groupedHabitData
      state.groupedBottomHabit = action.payload.groupedBottomHabit
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.habitFinalData = action.payload.habitFinalData
    },
  },
})

export const requestHabitsHistoryOfPatient =
  (
    appointmentId: string,
    selectedPatient: R4.IPatient,
    split: boolean,
    type?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: HabitsHistoryStatus = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
    }
    dispatch(habitsHistorySlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        category: 'activity',
        _count: 500,
        status: 'final,preliminary',
      }
      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParameters['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }

      const endDate = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .toISOString()}`
      let response: any
      if (split && type) {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            `/Observation?date=gt${endDate}`,
            appointmentId!,
            searchParameters
          )
      } else if (type) {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            '/Observation',
            appointmentId!,
            searchParameters
          )
      } else if (isOrgAdmin()) {
        response = await fhirClient.doGetResourceForAppointmentCrossSearch(
          '/Observation',
          '',
          searchParameters
        )
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformIPD(
            '/Observation',
            appointmentId!,
            searchParameters
          )
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false

        dispatch(habitsHistorySlice.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse?.total && proceduresResponse?.total > 0) {
          const habList: R4.IObservation[] =
            proceduresResponse.entry?.map(
              (item) => item.resource as R4.IObservation
            ) ?? []

          if (habList.length > 0) {
            habList.sort((a, b) =>
              (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
                ? -1
                : (a.issued ?? '') < (b.issued ?? '')
                ? 1
                : 0
            )

            habList.sort((a, b) =>
              a.code!.text! > b.code!.text!
                ? 1
                : b.code!.text! > a.code!.text!
                ? -1
                : 0
            )

            const top4Habits: R4.IObservation[] = getTop4HabitList(habList)
            // top4Habits = getSortedObservationForHabit(top4Habits)

            let groupedOccupationData: GroupedOccupation[] = []
            const finalGroupedOccupationData: GroupedOccupation[] = []
            for (let i = 0; i < top4Habits.length; i++) {
              if (top4Habits[i].issued) {
                if (groupedOccupationData.length > 0) {
                  for (let j = 0; j < groupedOccupationData.length; j++) {
                    if (
                      moment(top4Habits[i].issued).format('YYYY-MM-DD') ===
                      moment(groupedOccupationData[j].date).format('YYYY-MM-DD')
                    ) {
                      groupedOccupationData[j].occupation.push(top4Habits[i])
                    } else {
                      groupedOccupationData.push({
                        date: top4Habits[i].issued ?? '',
                        occupation: [top4Habits[i]],
                      })
                      break
                    }
                  }
                } else {
                  groupedOccupationData.push({
                    date: top4Habits[i].issued ?? '',
                    occupation: [top4Habits[i]],
                  })
                }
              }
            }
            groupedOccupationData = groupedOccupationData.filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    moment(t.date).format('YYYY-MM-DD') ===
                    moment(value.date).format('YYYY-MM-DD')
                )
            )

            for (let i = 0; i < groupedOccupationData.length; i++) {
              finalGroupedOccupationData.push({
                date: groupedOccupationData[i].date,
                occupation: getSortedObservationForHabit(
                  groupedOccupationData[i].occupation
                ),
              })
            }

            const habitData: VitalsData[] = getHabitDataFromObs(habList)
            if (habitData.length > 0) {
              state.resultsAvailable = true
              state.searchingConditions = false
              state.habitsList = habList
              state.noResultsAvailable = false
              state.habitFinalData = habitData
              state.groupedHabitData = finalGroupedOccupationData
              state.errorReason = undefined
              state.errorWhileSearchingProcedures = false
              dispatch(habitsHistorySlice.actions.updatedStatus(state))
            } else {
              const errorSearchDoctor: HabitsHistoryStatus = {
                searchingConditions: false,
                errorWhileSearchingProcedures: false,
                resultsAvailable: false,
                noResultsAvailable: true,
              }
              dispatch(
                habitsHistorySlice.actions.updatedStatus(errorSearchDoctor)
              )
            }
          } else {
            const errorSearchDoctor: HabitsHistoryStatus = {
              searchingConditions: false,
              errorWhileSearchingProcedures: false,
              resultsAvailable: false,
              noResultsAvailable: true,
            }
            dispatch(
              habitsHistorySlice.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: HabitsHistoryStatus = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
          }
          dispatch(habitsHistorySlice.actions.updatedStatus(errorSearchDoctor))
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: HabitsHistoryStatus = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
      }
      dispatch(habitsHistorySlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export default habitsHistorySlice.reducer
