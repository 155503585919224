import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  LocationStatusKind,
  LocationModeKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { sleep } from 'utils/dateUtil'
import { CurrentLoggedInUser } from 'models/currentUserDetails'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { UnitServices } from 'models/units/unitServices'
import { User } from 'models/user'
import {
  resetUnitServicesSlice,
  searchUnits,
} from 'redux/administration/units/unitManagement/unitsListsOfOrganization/unitsOfOrganizationSlice'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { uploadFileDetails } from 'services/fileApiService'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitDetails,
  getCurrentUserUnitLocationDetails,
  getUserCurrentRole,
  getUserDetails,
  isCurrentUnitIsClinic,
  isCurrentUnitIsWellonessCenter,
  isUnitAdmin,
  updateUserDetailsFromServer,
} from 'services/userDetailsService'
import { ORG_LOGO_SPECIALIZATION_URL } from 'utils/constants/fhir_vs_ext_constants'
import { Qualifications } from 'utils/constants/qualifications'
import {
  getGenderFhirKind,
  getStringValueExtensionsOfUrl,
  getValueAttachmentFromExtension,
  getValueAttachmentFromExtensionData,
  getValueAttachmentFromExtensionVal,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import { getPractitionersWithRoleObject } from 'utils/fhirResoureHelpers/practitioner_and_role_helpers'
import { isClinic } from 'utils/fhirResoureHelpers/unit_setup_helpers'
import { logger } from 'utils/logger'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePicker'
import { WelloOrgDetails } from 'models/welloUnit'
import { PractitionerSearchStatus } from './practioinerSearchStatusTypes'
import { searchPractitioners } from './practitionerSearchSlice'

const initialState: PractitionerSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
  userDetailsUpdating: false,
  userDetailsUpdated: false,
}

const practitionerManagement = createSlice({
  name: 'practitionerManagement',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
      state.userDetails = undefined
    },

    updatedStatus(state, action: PayloadAction<PractitionerSearchStatus>) {
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searching = action.payload.searching
      state.resultsAvailable = action.payload.resultsAvailable
      state.doctorList = action.payload.doctorList
      state.doctorRoleList = action.payload.doctorRoleList
      state.userDetails = action.payload.userDetails
      state.userDetailsUpdating = action.payload.userDetailsUpdating
      state.userDetailsUpdated = action.payload.userDetailsUpdated
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },

    searchResults(state, action: PayloadAction<PractitionerSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.doctorList = action.payload.doctorList
      state.doctorRoleList = action.payload.doctorRoleList
      state.userDetails = action.payload.userDetails
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = false
      state.searching = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
      state.userDetails = undefined
    },

    errorWhileSearching(
      state,
      action: PayloadAction<PractitionerSearchStatus>
    ) {
      state.error = true
      state.searching = false
      state.userDetailsUpdated = false
      state.userDetailsUpdating = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.doctorList = undefined
      state.doctorRoleList = undefined
      state.userDetails = undefined
    },
  },
})

export const fetchUser =
  (id: string, unitDetails?: R4.IOrganization): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PractitionerSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
      userDetailsUpdated: false,
      userDetailsUpdating: false,
    }
    dispatch(practitionerManagement.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        _id: id,
      }

      const currentRole = getUserCurrentRole() ?? []
      const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
      //   if (currentRole.includes('doctor'))
      //     searchParameters.practitioner = `Practitioner/${pract.id}`
      if (unitDetails) {
        searchParameters.organization = `Organization/${unitDetails.id}`
      } else {
        searchParameters.organization = `Organization/${
          getCurrentUserUnitDetails().id
        }`
      }

      const response: any = await fhirClient.doGetResource(
        '/PractitionerRole?_include=PractitionerRole:practitioner&_include=PractitionerRole:service&_include:iterate=HealthcareService:healthcare-service-billing&_tag:not=mirror-resource',
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const practitionerRolesResponses: R4.IBundle =
          relatedFhirDecodeRes.right
        if (practitionerRolesResponses.total) {
          if (practitionerRolesResponses.total > 0) {
            if (practitionerRolesResponses.entry) {
              const practitionerWithRoleList: PractitionerWithRole[] =
                getPractitionersWithRoleObject(practitionerRolesResponses) ?? []
              state.userDetails = practitionerWithRoleList[0]
              state.resultsAvailable = true
              state.searching = false
              state.error = false
              dispatch(practitionerManagement.actions.searchResults(state))
              return
            }
            state.resultsAvailable = false
            state.searching = false
            state.error = false
            state.noResultsAvailable = true
            dispatch(practitionerManagement.actions.noDataFoundForSearch(state))
            return
          }
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(practitionerManagement.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(practitionerManagement.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(practitionerManagement.actions.errorWhileSearching(state))
    }
  }

export const requestForUupdateUser =
  (
    mapCenter: any,
    newUser: User,
    unitServices: UnitServices[],
    userDetails?: PractitionerWithRole,
    qualificationData?: R4.ICoding,
    regNo?: string,
    orgData?: WelloOrgDetails,
    spl?: R4.ICoding,
    languages?: R4.ICoding[],
    splArr?: R4.ICoding[],
    profilePic?: string,
    idProofType?: R4.ICoding,
    proofNumber?: string,
    file?: SelectedFile,
    frontFile?: SelectedFile,
    backFile?: SelectedFile,
    isContactInfo?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PractitionerSearchStatus = { ...initialState }
    state.userDetailsUpdating = true
    dispatch(practitionerManagement.actions.updatedStatus(state))
    try {
      if (userDetails) {
        const locationObj: R4.IBundle | undefined =
          getUserRequestTransactionObject(
            mapCenter,
            newUser,
            unitServices,
            userDetails,
            qualificationData,
            regNo,
            orgData,
            spl,
            languages,
            splArr,
            profilePic,
            idProofType,
            proofNumber,
            file,
            frontFile,
            backFile,
            isContactInfo
          )
        if (locationObj) {
          const fhirClient: FHIRApiClient = new FHIRApiClient()
          const response: any = await fhirClient.doCreateFHIRTransaction(
            '',
            locationObj
          )
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)
          if (relatedFhirDecodeRes._tag === 'Right') {
            try {
              dispatch(searchUnits(getCurrentUserUnitDetails().id ?? ''))
              let logoAttachment: R4.IAttachment | undefined = {}
              if (file) {
                logoAttachment = {
                  data: file.path as string,
                  contentType: file.files ? file.files.type : file.type ?? '',
                  title: file.name,
                }

                const organization: R4.IOrganization =
                  getCurrentUserUnitDetails()
                if (logoAttachment) {
                  await uploadFileDetails(
                    logoAttachment,
                    "Organization.extension('http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo').value.as(Attachment)",
                    'Organization',
                    organization.id ?? ''
                  )
                }
              }
              if (isContactInfo === undefined) {
                if (frontFile && userDetails.practitionerObject) {
                  let frontFileAttachment: R4.IAttachment | undefined = {}
                  frontFileAttachment = {
                    data: frontFile.path as string,
                    contentType: frontFile.files
                      ? frontFile.files.type
                      : frontFile.type ?? '',
                    title: frontFile.name,
                  }
                  if (frontFileAttachment) {
                    await uploadFileDetails(
                      frontFileAttachment,
                      "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)",
                      'Practitioner',
                      userDetails.practitionerObject.id ?? ''
                    )
                  }
                }

                if (backFile && userDetails.practitionerObject) {
                  let backFileAttachment: R4.IAttachment | undefined = {}
                  backFileAttachment = {
                    data: backFile.path as string,
                    contentType: backFile.files
                      ? backFile.files.type
                      : backFile.type ?? '',
                    title: backFile.name,
                  }
                  if (backFileAttachment) {
                    await uploadFileDetails(
                      backFileAttachment,
                      "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)",
                      'Practitioner',
                      userDetails.practitionerObject.id ?? ''
                    )
                  }
                }
              }

              const res: boolean = await updateUserDetailsFromServer()
            } catch (error) {
              logger.info(error)
              //   dispatch(showErrorAlert('Patient created. Error in uploading proofs'))
            }
            const loggedInUser: CurrentLoggedInUser | undefined =
              getUserDetails()
            if (
              loggedInUser?.practitioner.id ===
              userDetails.practitionerObject?.id
            ) {
              const res: boolean = await updateUserDetailsFromServer()
              if (res) {
                state.userDetailsUpdating = false
                state.userDetailsUpdated = true

                dispatch(searchPractitioners())
                dispatch(fetchUser(userDetails.roleObject.id ?? ''))
                dispatch(showSuccessAlert('User Details Updated Successfully'))
              }
            } else {
              state.userDetailsUpdating = false
              state.userDetailsUpdated = true
              dispatch(searchPractitioners())

              //   dispatch(searchUnits(getCurrentUserUnitDetails().id ?? ''))

              dispatch(fetchUser(userDetails.roleObject.id ?? ''))
              dispatch(showSuccessAlert('User Details Updated Successfully'))

              dispatch(practitionerManagement.actions.updatedStatus(state))
            }

            return
          }
          state.userDetailsUpdating = false
          state.error = true
          dispatch(
            showErrorAlert('Error while Updating User. Please try later')
          )
          dispatch(practitionerManagement.actions.updatedStatus(state))
          return
        }
      }
    } catch (error) {
      logger.error(error)
      const currentState = { ...initialState }
      currentState.error = true

      dispatch(practitionerManagement.actions.updatedStatus(currentState))
    }
  }

export const requestForProfileUpdate =
  (
    mapCenter: any,
    newUser: User,
    userDetails: R4.IPractitioner,
    roleObject: R4.IPractitionerRole,
    qualificationData?: R4.ICoding,
    regNo?: string,
    orgData?: WelloOrgDetails,
    spl?: R4.ICoding,
    languages?: R4.ICoding[],
    splArr?: R4.ICoding[],
    profilePic?: string,
    idProofType?: R4.ICoding,
    proofNumber?: string,
    file?: SelectedFile,
    frontFile?: SelectedFile,
    backFile?: SelectedFile
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: PractitionerSearchStatus = { ...initialState }
    state.userDetailsUpdating = true
    dispatch(practitionerManagement.actions.updatedStatus(state))
    try {
      if (userDetails) {
        const locationObj: R4.IBundle | undefined =
          getUserRequestTransactionObjectForProfileUpdate(
            mapCenter,
            newUser,
            userDetails,
            roleObject,
            qualificationData,
            regNo,
            orgData,
            spl,
            languages,
            splArr,
            profilePic,
            idProofType,
            proofNumber,
            file,
            frontFile,
            backFile
          )
        if (locationObj) {
          const fhirClient: FHIRApiClient = new FHIRApiClient()
          const response: any = await fhirClient.doCreateFHIRTransaction(
            '',
            locationObj
          )
          const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
            R4.RTTI_Bundle.decode(response)
          if (relatedFhirDecodeRes._tag === 'Right') {
            try {
              let logoAttachment: R4.IAttachment | undefined = {}
              if (file) {
                logoAttachment = {
                  data: file.path as string,
                  contentType: file.files ? file.files.type : file.type ?? '',
                  title: file.name,
                }

                const organization: R4.IOrganization =
                  getCurrentUserUnitDetails()
                if (logoAttachment) {
                  await uploadFileDetails(
                    logoAttachment,
                    "Organization.extension('http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo').value.as(Attachment)",
                    'Organization',
                    organization.id ?? ''
                  )
                }
              }

              if (frontFile && userDetails) {
                let frontFileAttachment: R4.IAttachment | undefined = {}
                frontFileAttachment = {
                  data: frontFile.path as string,
                  contentType: frontFile.files
                    ? frontFile.files.type
                    : frontFile.type ?? '',
                  title: frontFile.name,
                }
                if (frontFileAttachment) {
                  await uploadFileDetails(
                    frontFileAttachment,
                    "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)",
                    'Practitioner',
                    userDetails.id ?? ''
                  )
                }
              }

              if (backFile && userDetails) {
                let backFileAttachment: R4.IAttachment | undefined = {}
                backFileAttachment = {
                  data: backFile.path as string,
                  contentType: backFile.files
                    ? backFile.files.type
                    : backFile.type ?? '',
                  title: backFile.name,
                }
                if (backFileAttachment) {
                  await uploadFileDetails(
                    backFileAttachment,
                    "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)",
                    'Practitioner',
                    userDetails.id ?? ''
                  )
                }
              }
            } catch (error) {
              logger.info(error)
              //   dispatch(showErrorAlert('Patient created. Error in uploading proofs'))
            }
            const loggedInUser: CurrentLoggedInUser | undefined =
              getUserDetails()
            if (loggedInUser?.practitioner.id === userDetails.id) {
              if (isUnitAdmin()) {
                dispatch(resetUnitServicesSlice())
              }
              const res: boolean = await updateUserDetailsFromServer()

              if (res) {
                await sleep(2000)
                state.userDetailsUpdating = false
                state.userDetailsUpdated = true
                dispatch(searchPractitioners())
                dispatch(fetchUser(roleObject.id ?? ''))
                dispatch(
                  showSuccessAlert('Profile Details Updated Successfully')
                )
              }
            } else {
              if (isUnitAdmin()) {
                dispatch(resetUnitServicesSlice())
              }
              await sleep(2000)
              state.userDetailsUpdating = false
              state.userDetailsUpdated = true
              dispatch(searchPractitioners())
              dispatch(fetchUser(roleObject.id ?? ''))
              dispatch(showSuccessAlert('Profile Details Updated Successfully'))

              dispatch(practitionerManagement.actions.updatedStatus(state))
            }

            return
          }
          state.userDetailsUpdating = false
          state.error = true
          dispatch(
            showErrorAlert('Error while Updating profile. Please try later')
          )
          dispatch(practitionerManagement.actions.updatedStatus(state))
          return
        }
      }
    } catch (error) {
      logger.error(error)
      const currentState = { ...initialState }
      currentState.error = true

      dispatch(practitionerManagement.actions.updatedStatus(currentState))
    }
  }

export function getUserRequestTransactionObjectForProfileUpdate(
  mapCenter: any,
  newUser: User,
  userDetails: R4.IPractitioner,
  roleObject: R4.IPractitionerRole,
  qualificationData?: R4.ICoding,
  regNo?: string,
  orgData?: WelloOrgDetails,
  spl?: R4.ICoding,
  languages?: R4.ICoding[],
  splArr?: R4.ICoding[],
  profilePic?: string,
  idProofType?: R4.ICoding,
  proofNumber?: string,
  file?: SelectedFile,
  frontFile?: SelectedFile,
  backFile?: SelectedFile
): R4.IBundle | undefined {
  if (userDetails && roleObject) {
    const practitioner: R4.IPractitioner = {
      ...userDetails,
    }
    console.log(practitioner)
    const lngDataArr: R4.ICodeableConcept[] = []
    if (languages && languages.length > 0) {
      for (let i = 0; i < languages.length; i++) {
        const codingArr: R4.ICoding[] = [languages[i]]
        const lngData: R4.ICodeableConcept = {}
        lngData.coding = codingArr
        lngDataArr.push(lngData)
      }
    }

    if (newUser.gender) {
      practitioner.gender = getGenderFhirKind(newUser.gender)
    }

    const splDataArr: R4.ICodeableConcept[] = []
    if (splArr && splArr.length > 0) {
      for (let i = 0; i < splArr.length; i++) {
        const codingArr: R4.ICoding[] = [splArr[i]]
        const splData: R4.ICodeableConcept = {}
        splData.coding = codingArr
        splDataArr.push(splData)
      }
    }

    const organization: R4.IOrganization = getCurrentUserUnitDetails()

    const location: R4.ILocation = getCurrentUserUnitLocationDetails()

    const matchStringForOrg: string = `W/${JSON.stringify(
      organization.meta?.versionId ?? ' '
    )}`

    const matchStringLocationId: string = `W/${JSON.stringify(
      location.meta?.versionId ?? ' '
    )}`

    const matchString: string = `W/${JSON.stringify(
      practitioner.meta?.versionId ?? ' '
    )}`
    practitioner.communication = lngDataArr
    let frontExt: R4.IExtension | undefined = {}
    if (
      getValueAttachmentFromExtensionVal(
        practitioner.extension,
        'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
      ) &&
      frontFile === undefined
    ) {
      frontExt = getValueAttachmentFromExtensionVal(
        practitioner.extension,
        'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
      )
    }

    if (frontFile) {
      frontExt = {}
      frontExt.url =
        'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
      frontExt.valueAttachment = {
        contentType: frontFile.files
          ? frontFile.files.type
          : frontFile.type ?? '',
        title: frontFile.name ?? '',
      }
    }
    let backExt: R4.IExtension | undefined = {}
    if (
      getValueAttachmentFromExtensionVal(
        practitioner.extension,
        'http://wellopathy.com/fhir/india/core/Code/identityProofback'
      ) &&
      backFile === undefined
    ) {
      backExt = getValueAttachmentFromExtensionVal(
        practitioner.extension,
        'http://wellopathy.com/fhir/india/core/Code/identityProofback'
      )
    }

    if (backFile) {
      backExt = {}
      backExt.url =
        'http://wellopathy.com/fhir/india/core/Code/identityProofback'
      backExt.valueAttachment = {
        contentType: backFile.files ? backFile.files.type : backFile.type ?? '',
        title: backFile.name ?? '',
      }
    }

    const mainOrganization: R4.IOrganization =
      getCurrentUserMainOrganizationDetails()

    practitioner.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PractitionerManagingOrganization',
        valueReference: {
          id: mainOrganization.id ?? '',
          reference: `Organization/${mainOrganization.id}`,
          type: 'Organization',
          display: mainOrganization.name,
        },
      },

      {
        url: 'http://wellopathy.com/fhir/india/core/Code/identityProofType',
        valueCoding: idProofType,
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/Code/identityProofValue',
        valueAttachment: {
          url: proofNumber,
        },
      },
    ]
    if (newUser.profileDescription) {
      practitioner.extension.push({
        url: 'http://wellopathy.com/fhir/india/core/ExtUrl/profileDescription',
        valueString: newUser.profileDescription,
      })
    }
    if (
      getStringValueExtensionsOfUrl(
        practitioner.extension,
        'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor'
      )
    ) {
      practitioner.extension.push({
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor',
        valueString: getStringValueExtensionsOfUrl(
          practitioner.extension,
          'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor'
        ),
      })
    }
    if (frontExt) {
      practitioner.extension.push(frontExt)
    }
    if (backExt) {
      practitioner.extension.push(backExt)
    }

    if (
      getValueAttachmentFromExtensionData(
        userDetails.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext'
      )
    ) {
      practitioner.extension.push({
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext',
        valueAttachment: getValueAttachmentFromExtensionData(
          userDetails.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext'
        ),
      })
    }

    if (profilePic) {
      practitioner.photo = [
        {
          url: profilePic,
        },
      ]
    }

    if (
      practitioner.qualification &&
      practitioner.qualification.length > 0 &&
      practitioner.qualification[0].code
    ) {
      practitioner.qualification = [
        {
          id: practitioner.qualification[0].id,
          extension: spl
            ? [
                {
                  url: 'http://wellopathy.com/fhir/india/core/qualificationSpecialization',
                  valueCoding: spl,
                },
              ]
            : practitioner.qualification[0].extension,
          identifier: [
            {
              value: regNo ?? '',
            },
          ],
          code: {
            coding: qualificationData
              ? [qualificationData]
              : practitioner.qualification[0].code.coding,
          },
          period: practitioner.qualification[0].period,
          issuer: orgData
            ? {
                id: orgData.name,
                // reference: `Organization/${orgData.id}`,
                display: orgData.name ?? '',
              }
            : practitioner.qualification[0].issuer,
        },
      ]
    }

    practitioner.name = [
      {
        family: '',

        given: [newUser.name.trim()],
      },
    ]
    practitioner.birthDate = newUser.dob

    if (newUser.address.length > 0) {
      practitioner.address = [
        {
          line: [newUser.address],
        },
      ]
    }
    const practitionerRole: R4.IPractitionerRole = {
      ...roleObject,
    }

    const practMatchString: string = `W/${JSON.stringify(
      practitionerRole.meta?.versionId ?? ' '
    )}`
    if (splDataArr.length > 0) {
      practitionerRole.specialty = splDataArr
    }

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `Practitioner/${practitioner.id}`,
          request: {
            // ifMatch: matchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `Practitioner/${practitioner.id}`,
          },
          resource: practitioner,
        },
      ],
    }
    if (newUser.labName && newUser.labAddress) {
      organization.name = newUser.labName
      if (newUser.address.length > 0) {
        organization.address = [
          {
            line: [newUser.labAddress],
          },
        ]
      }
      if (file) {
        organization.extension = removeExtensionFromExtensions(
          organization.extension,
          ORG_LOGO_SPECIALIZATION_URL
        )
        const logoExt: R4.IExtension = {}
        logoExt.url = ORG_LOGO_SPECIALIZATION_URL
        logoExt.valueAttachment = {
          contentType: file.files ? file.files.type : file.type ?? '',
          title: file.name ?? '',
        }
        if (organization.extension && organization.extension.length > 0) {
          organization.extension.push(logoExt)
        } else {
          organization.extension = [logoExt]
        }
      }

      requestBundle.entry?.push({
        fullUrl: `Organization/${organization.id}`,
        request: {
          ifMatch: matchStringForOrg,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Organization/${organization.id}`,
        },
        resource: organization,
      })
    }
    if (mapCenter.lat > 0 && mapCenter.lng > 0 && location.id !== undefined) {
      const locPoc: R4.ILocation_Position = {
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
      }
      location.name = organization.name ?? ''
      location.position = locPoc

      requestBundle.entry?.push({
        fullUrl: `Location/${location.id}`,
        request: {
          ifMatch: matchStringLocationId,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Location/${location.id}`,
        },
        resource: location,
      })
    } else if (
      mapCenter.lat > 0 &&
      mapCenter.lng > 0 &&
      location.id === undefined
    ) {
      const locationData: R4.ILocation = {
        resourceType: 'Location',
        status: LocationStatusKind._active,
        name: organization.name ?? '',
        position: {
          latitude: mapCenter.lat,
          longitude: mapCenter.lng,
        },
        mode: LocationModeKind._instance,
        managingOrganization: {
          reference: `Organization/${organization.id}`,
        },
      }

      const randomNum: number = getRandomInt(999999)
      const conditionFullUrl = `urn:uuid:Location${randomNum.toString()}`
      practitionerRole.location = [
        {
          reference: `Location/${conditionFullUrl}`,
        },
      ]

      requestBundle.entry?.push({
        fullUrl: conditionFullUrl,
        request: {
          ifMatch: matchStringLocationId,
          method: R4.Bundle_RequestMethodKind._post,
          url: locationData.resourceType,
        },
        resource: locationData,
      })
    }

    requestBundle.entry?.push({
      fullUrl: `PractitionerRole/${practitionerRole.id}`,
      request: {
        // ifMatch: practMatchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `PractitionerRole/${practitionerRole.id}`,
      },
      resource: practitionerRole,
    })

    return requestBundle
  }

  return undefined
}

function getUserRequestTransactionObject(
  mapCenter: any,
  newUser: User,
  unitServices: UnitServices[],
  userDetails: PractitionerWithRole,
  qualificationData?: R4.ICoding,
  regNo?: string,
  orgData?: WelloOrgDetails,
  spl?: R4.ICoding,
  languages?: R4.ICoding[],
  splArr?: R4.ICoding[],
  profilePic?: string,
  idProofType?: R4.ICoding,
  proofNumber?: string,
  file?: SelectedFile,
  frontFile?: SelectedFile,
  backFile?: SelectedFile,
  isContactInfo?: boolean
): R4.IBundle | undefined {
  if (userDetails.practitionerObject && userDetails.roleObject) {
    const practitioner: R4.IPractitioner = {
      ...userDetails.practitionerObject,
    }

    const lngDataArr: R4.ICodeableConcept[] = []
    if (languages && languages.length > 0) {
      for (let i = 0; i < languages.length; i++) {
        const codingArr: R4.ICoding[] = [languages[i]]
        const lngData: R4.ICodeableConcept = {}
        lngData.coding = codingArr
        lngDataArr.push(lngData)
      }
    }

    if (newUser.gender) {
      practitioner.gender = getGenderFhirKind(newUser.gender)
    }

    const splDataArr: R4.ICodeableConcept[] = []
    if (splArr && splArr.length > 0) {
      for (let i = 0; i < splArr.length; i++) {
        const codingArr: R4.ICoding[] = [splArr[i]]
        const splData: R4.ICodeableConcept = {}
        splData.coding = codingArr
        splDataArr.push(splData)
      }
    }

    const organization: R4.IOrganization = getCurrentUserUnitDetails()
    const location: R4.ILocation = getCurrentUserUnitLocationDetails()

    const matchStringForOrg: string = `W/${JSON.stringify(
      organization.meta?.versionId ?? ' '
    )}`

    const matchStringLocationId: string = `W/${JSON.stringify(
      location.meta?.versionId ?? ' '
    )}`

    const matchString: string = `W/${JSON.stringify(
      practitioner.meta?.versionId ?? ' '
    )}`
    practitioner.communication =
      lngDataArr.length > 0 ? lngDataArr : practitioner.communication

    let frontExt: R4.IExtension | undefined = {}
    if (
      getValueAttachmentFromExtensionVal(
        practitioner.extension,
        'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
      ) &&
      frontFile === undefined
    ) {
      frontExt = getValueAttachmentFromExtensionVal(
        practitioner.extension,
        'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
      )
    }
    if (isContactInfo === undefined) {
      if (frontFile) {
        frontExt = {}
        frontExt.url =
          'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
        frontExt.valueAttachment = {
          contentType: frontFile.files
            ? frontFile.files.type
            : frontFile.type ?? '',
          title: frontFile.name ?? '',
        }
      }
      let backExt: R4.IExtension | undefined = {}
      if (
        getValueAttachmentFromExtensionVal(
          practitioner.extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofback'
        ) &&
        backFile === undefined
      ) {
        backExt = getValueAttachmentFromExtensionVal(
          practitioner.extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofback'
        )
      }

      if (backFile) {
        backExt = {}
        backExt.url =
          'http://wellopathy.com/fhir/india/core/Code/identityProofback'
        backExt.valueAttachment = {
          contentType: backFile.files
            ? backFile.files.type
            : backFile.type ?? '',
          title: backFile.name ?? '',
        }
      }

      const mainOrganization: R4.IOrganization =
        getCurrentUserMainOrganizationDetails()

      practitioner.extension = [
        {
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PractitionerManagingOrganization',
          valueReference: {
            id: mainOrganization.id ?? '',
            reference: `Organization/${mainOrganization.id}`,
            type: 'Organization',
            display: mainOrganization.name,
          },
        },

        {
          url: 'http://wellopathy.com/fhir/india/core/Code/identityProofType',
          valueCoding: idProofType,
        },
        {
          url: 'http://wellopathy.com/fhir/india/core/Code/identityProofValue',
          valueAttachment: {
            url: proofNumber,
          },
        },
      ]
      if (newUser.profileDescription) {
        practitioner.extension.push({
          url: 'http://wellopathy.com/fhir/india/core/ExtUrl/profileDescription',
          valueString: newUser.profileDescription,
        })
      }
      if (
        getStringValueExtensionsOfUrl(
          practitioner.extension,
          'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor'
        )
      ) {
        practitioner.extension.push({
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor',
          valueString: getStringValueExtensionsOfUrl(
            practitioner.extension,
            'http://wellopathy.com/fhir/india/core/StructureDefinition/AssignedColor'
          ),
        })
      }

      if (
        getValueAttachmentFromExtensionData(
          userDetails.practitionerObject.extension ?? [],
          'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext'
        )
      ) {
        practitioner.extension.push({
          url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext',
          valueAttachment: getValueAttachmentFromExtensionData(
            userDetails.practitionerObject.extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-doctor-signature-ext'
          ),
        })
      }
      if (backExt) {
        practitioner.extension.push(backExt)
      }
      if (frontExt) {
        practitioner.extension.push(frontExt)
      }
    }
    if (isContactInfo) {
      if (profilePic) {
        practitioner.photo = [
          {
            url: profilePic,
          },
        ]
      }
    }
    if (isContactInfo === undefined) {
      if (
        practitioner.qualification &&
        practitioner.qualification.length > 0 &&
        practitioner.qualification[0].code
      ) {
        practitioner.qualification = [
          {
            id: practitioner.qualification[0].id,
            extension: spl
              ? [
                  {
                    url: 'http://wellopathy.com/fhir/india/core/qualificationSpecialization',
                    valueCoding: spl,
                  },
                ]
              : practitioner.qualification[0].extension,
            identifier: [
              {
                value: regNo ?? '',
              },
            ],
            code: {
              coding: qualificationData
                ? [qualificationData]
                : practitioner.qualification[0].code.coding,
            },
            period: practitioner.qualification[0].period,
            issuer: orgData
              ? {
                  id: orgData.name,
                  // reference: `Organization/${orgData.id}`,
                  display: orgData.name ?? '',
                }
              : practitioner.qualification[0].issuer,
          },
        ]
      }
    }

    const nameData = newUser.name.trim().split(' ')
    let newName: string = ''
    let newLastName: string = newUser.lastName ?? ''
    if (nameData.length === 3) {
      newName = `${nameData[0].trim()} ${nameData[1].trim()}`
      if (nameData[0].trim() !== 'Dr.' || nameData[0].trim() !== 'Dr') {
        newLastName = `${nameData[2].trim()}`
      }
    }
    if (nameData.length === 4) {
      newName = `${nameData[0].trim()} ${nameData[1].trim()} ${nameData[2].trim()}`
      newLastName = `${nameData[3].trim()}`
    }
    if (nameData.length === 2) {
      newName = `${nameData[0].trim()}`
      newLastName = `${nameData[1].trim()}`
    }

    practitioner.name = [
      {
        family: newLastName.trim(),

        given: [newName.trim()],
      },
    ]
    if (isContactInfo) {
      if (newUser.dob) {
        practitioner.birthDate = newUser.dob
      }
    }

    if (newUser.address.length > 0) {
      practitioner.address = [
        {
          line: [newUser.address],
        },
      ]
    }
    const practitionerRole: R4.IPractitionerRole = {
      ...userDetails.roleObject,
    }

    const practMatchString: string = `W/${JSON.stringify(
      practitionerRole.meta?.versionId ?? ' '
    )}`
    if (isContactInfo === undefined) {
      if (splDataArr.length > 0) {
        practitionerRole.specialty = splDataArr
      }
    }

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `Practitioner/${practitioner.id}`,
          request: {
            ifMatch: matchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `Practitioner/${practitioner.id}`,
          },
          resource: practitioner,
        },
      ],
    }
    if (newUser.labName && newUser.labAddress) {
      organization.name = newUser.labName
      if (newUser.address.length > 0) {
        organization.address = [
          {
            line: [newUser.labAddress],
          },
        ]
      }
      if (file) {
        organization.extension = removeExtensionFromExtensions(
          organization.extension,
          ORG_LOGO_SPECIALIZATION_URL
        )
        const logoExt: R4.IExtension = {}
        logoExt.url = ORG_LOGO_SPECIALIZATION_URL
        logoExt.valueAttachment = {
          contentType: file.files ? file.files.type : file.type ?? '',
          title: file.name ?? '',
        }
        if (organization.extension && organization.extension.length > 0) {
          organization.extension.push(logoExt)
        } else {
          organization.extension = [logoExt]
        }
      }

      requestBundle.entry?.push({
        fullUrl: `Organization/${organization.id}`,
        request: {
          ifMatch: matchStringForOrg,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Organization/${organization.id}`,
        },
        resource: organization,
      })
    }
    if (mapCenter.lat > 0 && mapCenter.lng > 0 && location.id !== undefined) {
      const locPoc: R4.ILocation_Position = {
        latitude: mapCenter.lat,
        longitude: mapCenter.lng,
      }
      location.name = newUser.labName ?? ''
      location.position = locPoc

      requestBundle.entry?.push({
        fullUrl: `Location/${location.id}`,
        request: {
          ifMatch: matchStringLocationId,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Location/${location.id}`,
        },
        resource: location,
      })
    } else if (
      mapCenter.lat > 0 &&
      mapCenter.lng > 0 &&
      location.id === undefined
    ) {
      const locationData: R4.ILocation = {
        resourceType: 'Location',
        status: LocationStatusKind._active,
        name: newUser.labName ?? '',
        position: {
          latitude: mapCenter.lat,
          longitude: mapCenter.lng,
        },
        mode: LocationModeKind._instance,
        managingOrganization: {
          reference: `Organization/${organization.id}`,
        },
      }

      const randomNum: number = getRandomInt(999999)
      const conditionFullUrl = `urn:uuid:Location${randomNum.toString()}`
      practitionerRole.location = [
        {
          reference: `Location/${conditionFullUrl}`,
        },
      ]

      requestBundle.entry?.push({
        fullUrl: conditionFullUrl,
        request: {
          ifMatch: matchStringLocationId,
          method: R4.Bundle_RequestMethodKind._post,
          url: locationData.resourceType,
        },
        resource: locationData,
      })
    }

    requestBundle.entry?.push({
      fullUrl: `PractitionerRole/${practitionerRole.id}`,
      request: {
        ifMatch: practMatchString,
        method: R4.Bundle_RequestMethodKind._put,
        url: `PractitionerRole/${practitionerRole.id}`,
      },
      resource: practitionerRole,
    })

    if (
      (isCurrentUnitIsClinic() || isCurrentUnitIsWellonessCenter()) &&
      isUnitAdmin()
    ) {
      if (
        unitServices.length > 0 &&
        unitServices[0].serviceType &&
        unitServices[0].systemOfMedicine &&
        unitServices[0].systemOfMedicine.length > 0
      ) {
        if (unitServices[0].systemOfMedicineService) {
          const medicineServiceDetails: R4.IHealthcareService = {
            ...unitServices[0].systemOfMedicineService,
          }
          const oldAppointmentMatchString: string = `W/${JSON.stringify(
            medicineServiceDetails.meta?.versionId ?? ' '
          )}`
          medicineServiceDetails.type = [
            {
              coding: newUser.medicineType ?? [],
            },
          ]

          requestBundle.entry?.push({
            fullUrl: `HealthcareService/${medicineServiceDetails.id}`,
            request: {
              ifMatch: oldAppointmentMatchString,
              method: R4.Bundle_RequestMethodKind._put,
              url: `HealthcareService/${medicineServiceDetails.id}`,
            },
            resource: medicineServiceDetails,
          })
        }
        if (
          unitServices[0].serviceType &&
          unitServices[0].serviceType.length > 0 &&
          newUser.serviceType
        ) {
          for (let i = 0; i < newUser.serviceType.length; i++) {
            if (newUser.serviceType[i].code === '394656005') {
              if (unitServices[0].ipdService) {
                const ipdServices: R4.IHealthcareService = {
                  ...unitServices[0].ipdService,
                }
                const oldAppointmentMatchString: string = `W/${JSON.stringify(
                  ipdServices.meta?.versionId ?? ' '
                )}`
                if (newUser.serviceType[i].code !== '394656005') {
                  ipdServices.active = false
                }
                ipdServices.type = [
                  {
                    coding: [
                      {
                        system: 'http://snomed.info/sct',
                        code: '394656005',
                        display: 'IPD',
                      },
                    ],
                  },
                ]

                requestBundle.entry?.push({
                  fullUrl: `HealthcareService/${ipdServices.id}`,
                  request: {
                    ifMatch: oldAppointmentMatchString,
                    method: R4.Bundle_RequestMethodKind._put,
                    url: `HealthcareService/${ipdServices.id}`,
                  },
                  resource: ipdServices,
                })
              } else {
                const randomNum: number = getRandomInt(444444)
                requestBundle.entry?.push({
                  fullUrl: `urn:uuid:HealthcareService${randomNum.toString()}`,
                  request: {
                    method: R4.Bundle_RequestMethodKind._post,
                    url: 'HealthcareService',
                  },
                  resource: {
                    resourceType: 'HealthcareService',
                    active: true,
                    name: `${unitServices[0].orgs.name ?? ''} Services`,
                    providedBy: {
                      reference: `Organization/${unitServices[0].orgs.id}`,
                      type: 'Organization',
                      display: unitServices[0].orgs.name ?? '',
                    },
                    category: [
                      {
                        coding: [
                          {
                            system: 'http://snomed.info/sct',
                            code: '224891009',
                            display: 'Healthcare services',
                          },
                        ],
                      },
                    ],
                    type: [
                      {
                        coding: [
                          {
                            system: 'http://snomed.info/sct',
                            code: '394656005',
                            display: 'IPD',
                          },
                        ],
                      },
                    ],
                  },
                })
              }
            } else if (newUser.serviceType[i].code === '33022008') {
              if (unitServices[0].opdService) {
                const opdService: R4.IHealthcareService = {
                  ...unitServices[0].opdService,
                }
                const opdMatchingVersion: string = `W/${JSON.stringify(
                  opdService.meta?.versionId ?? ' '
                )}`
                if (newUser.serviceType[i].code !== '33022008') {
                  opdService.active = false
                }
                opdService.type = [
                  {
                    coding: [
                      {
                        system: 'http://snomed.info/sct',
                        code: '33022008',
                        display: 'OPD',
                      },
                    ],
                  },
                ]

                requestBundle.entry?.push({
                  fullUrl: `HealthcareService/${opdService.id}`,
                  request: {
                    ifMatch: opdMatchingVersion,
                    method: R4.Bundle_RequestMethodKind._put,
                    url: `HealthcareService/${opdService.id}`,
                  },
                  resource: opdService,
                })
              } else {
                const randomNum: number = getRandomInt(777777)
                requestBundle.entry?.push({
                  fullUrl: `urn:uuid:HealthcareService${randomNum.toString()}`,
                  request: {
                    method: R4.Bundle_RequestMethodKind._post,
                    url: 'HealthcareService',
                  },
                  resource: {
                    resourceType: 'HealthcareService',
                    active: true,
                    name: `${unitServices[0].orgs.name ?? ''} Services`,
                    providedBy: {
                      reference: `Organization/${unitServices[0].orgs.id}`,
                      type: 'Organization',
                      display: unitServices[0].orgs.name ?? '',
                    },
                    category: [
                      {
                        coding: [
                          {
                            system: 'http://snomed.info/sct',
                            code: '224891009',
                            display: 'Healthcare services',
                          },
                        ],
                      },
                    ],
                    type: [
                      {
                        coding: [
                          {
                            system: 'http://snomed.info/sct',
                            code: '33022008',
                            display: 'OPD',
                          },
                        ],
                      },
                    ],
                  },
                })
              }
            } else if (newUser.serviceType[i].code === '304903009') {
              if (unitServices[0].dayCareService) {
                const dayCareService: R4.IHealthcareService = {
                  ...unitServices[0].dayCareService,
                }
                const opdMatchingVersion: string = `W/${JSON.stringify(
                  dayCareService.meta?.versionId ?? ' '
                )}`
                if (newUser.serviceType[i].code !== '304903009') {
                  dayCareService.active = false
                }
                dayCareService.type = [
                  {
                    coding: [
                      {
                        system: 'http://snomed.info/sct',
                        code: '304903009',
                        display: 'Day Care',
                      },
                    ],
                  },
                ]

                requestBundle.entry?.push({
                  fullUrl: `HealthcareService/${dayCareService.id}`,
                  request: {
                    ifMatch: opdMatchingVersion,
                    method: R4.Bundle_RequestMethodKind._put,
                    url: `HealthcareService/${dayCareService.id}`,
                  },
                  resource: dayCareService,
                })
              } else {
                const randomNum: number = getRandomInt(666666)
                requestBundle.entry?.push({
                  fullUrl: `urn:uuid:HealthcareService${randomNum.toString()}`,
                  request: {
                    method: R4.Bundle_RequestMethodKind._post,
                    url: 'HealthcareService',
                  },
                  resource: {
                    resourceType: 'HealthcareService',
                    active: true,
                    name: `${unitServices[0].orgs.name ?? ''} Services`,
                    providedBy: {
                      reference: `Organization/${unitServices[0].orgs.id}`,
                      type: 'Organization',
                      display: unitServices[0].orgs.name ?? '',
                    },
                    category: [
                      {
                        coding: [
                          {
                            system: 'http://snomed.info/sct',
                            code: '224891009',
                            display: 'Healthcare services',
                          },
                        ],
                      },
                    ],
                    type: [
                      {
                        coding: [
                          {
                            system: 'http://snomed.info/sct',
                            code: '304903009',
                            display: 'Day Care',
                          },
                        ],
                      },
                    ],
                  },
                })
              }
            }
          }
          if (unitServices[0].ipdService) {
            const ipdData =
              newUser.serviceType &&
              newUser.serviceType.filter(
                (coding: R4.ICoding) =>
                  coding.code && coding.code === '394656005'
              ).length
            if (ipdData === 0) {
              const ipdServices: R4.IHealthcareService = {
                ...unitServices[0].ipdService,
              }
              const oldAppointmentMatchString: string = `W/${JSON.stringify(
                ipdServices.meta?.versionId ?? ' '
              )}`

              ipdServices.active = false

              requestBundle.entry?.push({
                fullUrl: `HealthcareService/${ipdServices.id}`,
                request: {
                  ifMatch: oldAppointmentMatchString,
                  method: R4.Bundle_RequestMethodKind._put,
                  url: `HealthcareService/${ipdServices.id}`,
                },
                resource: ipdServices,
              })
            }
          }

          if (unitServices[0].opdService) {
            const ipdData =
              newUser.serviceType &&
              newUser.serviceType.filter(
                (coding: R4.ICoding) =>
                  coding.code && coding.code === '33022008'
              ).length
            if (ipdData === 0) {
              const opdService: R4.IHealthcareService = {
                ...unitServices[0].opdService,
              }
              const oldAppointmentMatchString: string = `W/${JSON.stringify(
                opdService.meta?.versionId ?? ' '
              )}`

              opdService.active = false

              requestBundle.entry?.push({
                fullUrl: `HealthcareService/${opdService.id}`,
                request: {
                  ifMatch: oldAppointmentMatchString,
                  method: R4.Bundle_RequestMethodKind._put,
                  url: `HealthcareService/${opdService.id}`,
                },
                resource: opdService,
              })
            }
          }

          if (unitServices[0].dayCareService) {
            const ipdData =
              newUser.serviceType &&
              newUser.serviceType.filter(
                (coding: R4.ICoding) =>
                  coding.code && coding.code === '304903009'
              ).length
            if (ipdData === 0) {
              const dayCareService: R4.IHealthcareService = {
                ...unitServices[0].dayCareService,
              }
              const oldAppointmentMatchString: string = `W/${JSON.stringify(
                dayCareService.meta?.versionId ?? ' '
              )}`

              dayCareService.active = false

              requestBundle.entry?.push({
                fullUrl: `HealthcareService/${dayCareService.id}`,
                request: {
                  ifMatch: oldAppointmentMatchString,
                  method: R4.Bundle_RequestMethodKind._put,
                  url: `HealthcareService/${dayCareService.id}`,
                },
                resource: dayCareService,
              })
            }
          }
        }
      }

      if (
        newUser.serviceType &&
        newUser.serviceType.length > 0 &&
        (unitServices === undefined || unitServices.length === 0)
      ) {
        for (let i = 0; i < newUser.serviceType.length; i++) {
          const randomNum: number = getRandomInt(999999)
          requestBundle.entry?.push({
            fullUrl: `urn:uuid:HealthcareService${randomNum.toString()}`,
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: 'HealthcareService',
            },
            resource: {
              resourceType: 'HealthcareService',
              active: true,
              name: `${getCurrentUserUnitDetails().name ?? ''} Services`,
              providedBy: {
                reference: `Organization/${getCurrentUserUnitDetails().id}`,
                type: 'Organization',
                display: getCurrentUserUnitDetails().name ?? '',
              },
              category: [
                {
                  coding: [
                    {
                      system: 'http://snomed.info/sct',
                      code: '224891009',
                      display: 'Healthcare services',
                    },
                  ],
                },
              ],
              type: [
                {
                  coding: [newUser.serviceType[i]],
                },
              ],
            },
          })
        }
      }

      if (
        newUser.medicineType &&
        newUser.medicineType.length > 0 &&
        (unitServices === undefined || unitServices.length === 0)
      ) {
        const randomNum: number = getRandomInt(888888)
        requestBundle.entry?.push({
          fullUrl: `urn:uuid:HealthcareService${randomNum.toString()}`,
          request: {
            method: R4.Bundle_RequestMethodKind._post,
            url: 'HealthcareService',
          },
          resource: {
            resourceType: 'HealthcareService',
            active: true,
            name: `System of Medicine ${
              getCurrentUserUnitDetails().name ?? ''
            }`,
            providedBy: {
              reference: `Organization/${getCurrentUserUnitDetails().id}`,
              type: 'Organization',
              display: getCurrentUserUnitDetails().name ?? '',
            },
            category: [
              {
                coding: [
                  {
                    system:
                      'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-service-category-cs',
                    code: '306',
                    display: 'System of Medicine',
                  },
                ],
              },
            ],
            type: [
              {
                coding: newUser.medicineType ?? [],
              },
            ],
          },
        })
      }
    }

    return requestBundle
  }

  return undefined
}

export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    gender: '',
    name: item.practitioner?.display ?? '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}
function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max))
}

export default practitionerManagement.reducer
