import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'
import {
  getCatalogDetailsFromIds,
  getCatalogList,
} from 'utils/fhirResoureHelpers/catalogHelper'
import { getIdsOfSelectedDetails } from 'utils/fhirResoureHelpers/ipdDietHelper'
import { CatalogSelector } from './catalogEntrySelector'

export interface DietSelectorProps {
  nutritionOrders: R4.INutritionOrder[]
  timeOfDay: string
  onSelectionChanged: (a: any, b: any) => void
  searchType: string
  showProcessing: boolean
  availableOptions: R4.ICatalogEntry[]
  onNutritionIntakeUpdated?: (orders: R4.INutritionOrder[]) => void
  disableSelection?: boolean
  timeNotApplicable?: boolean
}

export const DietSelector: React.FC<DietSelectorProps> = ({
  nutritionOrders,
  timeOfDay,
  onSelectionChanged,
  searchType,
  showProcessing,
  availableOptions,
  onNutritionIntakeUpdated,
  disableSelection,
  timeNotApplicable,
}: DietSelectorProps) => {
  const addDietSlice = useSelector(
    (state: RootState) => state.nutritionCatalogueValueSetSlice
  )
  const details = getCatalogDetailsFromIds(
    addDietSlice.catalogueEntries ?? [],
    getIdsOfSelectedDetails(nutritionOrders) ?? ''
  )

  const [editing, setEditing] = React.useState(false)

  return (
    <Box width='100%'>
      {timeNotApplicable === undefined && (
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          alignContent='center'
          justifyItems='start'
          justifyContent='start'
          padding={disableSelection ? 1 : 0}
          style={{
            backgroundColor: disableSelection ? 'white' : undefined,
            border: disableSelection ? '16px' : undefined,
            //   borderRadius: '4px',
            //   borderColor: 'lightgray',
            //   borderWidth: '1px',
            //   borderStyle: 'solid',
          }}
        >
          {showProcessing && (
            <Box p={1}>
              {' '}
              <CircularProgress size={20} />
            </Box>
          )}

          {timeNotApplicable === undefined &&
            disableSelection &&
            !showProcessing &&
            details.length === 0 && (
              <Typography variant='body2'>Not prescribed</Typography>
            )}

          {disableSelection && !showProcessing && details.length > 0 && (
            <Typography variant='subtitle2'>{details}</Typography>
          )}
        </Box>
      )}
      {!disableSelection && !showProcessing && (
        <CatalogSelector
          id={timeOfDay}
          isInEditMode={true}
          onRadioOptionsChanged={(e) => {
            onSelectionChanged(e, timeOfDay)
          }}
          showProcessing={showProcessing}
          availableOptions={availableOptions}
          selectedValue={getCatalogList(
            addDietSlice.catalogueEntries ?? [],
            getIdsOfSelectedDetails(nutritionOrders) ?? ''
          )}
          searchType={searchType}
        />
      )}
    </Box>
  )
}
