import axios, { AxiosInstance } from 'axios'
import { getAccessToken } from 'utils/authHelpers'

export class ApiClinetWithMasterToken {
  axiosInstance: AxiosInstance

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      timeout: 90000,
    })

    this.axiosInstance.interceptors.request.use((config) => {
      if (getAccessToken() != null) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6InRkSHZEeDRKX2xSVUQ2b08tRUZzSGo4OUdSQjdSNnJ1UEtqUnczcXJOcHMiLCJ0eXAiOiJKV1QifQ.eyJzY29wZSI6Im9wZW5pZCBmaGlyVXNlciBlbWFpbCByb2xlcyBvZmZsaW5lX2FjY2VzcyIsImlzcyI6Imh0dHBzOi8vcWEuYWNjb3VudC53ZWxsb3BhdGh5LmNvbSIsImF1ZCI6InBsYXRmb3JtIiwiX19jbGllbnRfaWQiOiJzZXJ2aWNlL3dzZSIsImV4cCI6MTc1NzA1Nzk5MSwiaWF0IjoxNzI1NTIxOTkxLCJuYmYiOjE3MjU1MjE5OTF9.r5A4jC9RRgJJR-zd-zqP1g851B74RH2jcDwbf3i0up2uR63hcAMvh3Y-fK_sWCvl6TL8Dl8RUH69KcGRTxWtWlzlgoUnxnhnhOYFHvZ51G-oVJVED105PhYFNYCVLHJVNG-fY2q1pa8qcZ4RlSrR7tommEBwLAw4Ove00DT6s0Lzmg-LJPb23vCfVwmL3QmfjhNx-geaJhiXWU8cvjeXMvF_IDRcMbBQSpWopi9vlDt2NAtG3mTq41DYW56Z0hFgEE4k4f4H5wq-l7nJeJ4P-kcPN8VUtr3c68eM49uuTpZwJ8aptO1-hdHcug_dwIPew1ug9tQphgdzwjP530y5dw`,
          'Cache-Control': 'no-cache',
        }
      }

      return config
    })

    this.axiosInstance.interceptors.response.use((param) => ({
      ...param,
    }))
  }
}
