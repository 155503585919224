import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  useTheme,
  CircularProgress,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { FhirAppointmentFullDetail } from 'models/fhirAppointmentFullDetail'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchUnits } from 'redux/administration/units/unitManagement/unitsListsOfOrganization/unitsOfOrganizationSlice'
import { fetchAppointmentDetailsForSplit } from 'redux/appointments/appointmentDetails/appointmentDetailsService'
import { requestDietTiming } from 'redux/appointments/dietTimeSearch/dietTimeSearchSlice'
import { searchObservationsForAddLabTest } from 'redux/lab/diaognsticLabTestSearchSlice/dianogsticObsFinderSlice'
import { RootState } from 'redux/rootReducer'
import { getAllNutritionValueSets } from 'redux/valueSet/nutrition_catalogue/nutrtionCatalogueSlice'
import {
  isCurrentUserDoctor,
  getCurrentUserPractitionerDetails,
  isMedicalServiceProvider,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { CURRENT_VISIT_REFERENCE } from 'utils/appConstants'

import { PatientNameWithAppointmentWidget } from 'views/components/clinic_home_page/patientNameWithAppointmentWidget'
import { ProfilePicWithAgeAndGender } from 'views/components/clinic_home_page/profilePicWithAgeAndGender'
import { AssessmentPartOfConsultation } from 'views/components/consultation/assementDetailsOfConsultation'

import { ObjectivePartOfConsultation } from 'views/components/consultation/objectiveDetailsOfConsultation'

import { PlanSplit } from 'views/components/consultation/plan/nextStepsSplit'

import { SubjectivePartOfConsultation } from 'views/components/consultation/subjectiveDetailsOfConsultation'
import { ToggleOption } from 'views/components/single_toggleSelector'
import CustomizedDividers from 'views/components/toggle_selector'

interface Props {
  appointmentDetails: FhirAppointmentFullDetail
  isOdd?: boolean
  onCloseClicked: () => void
  date?: Date
}

export const AppointmentDetails: React.FC<Props> = ({
  appointmentDetails,
  isOdd = false,
  onCloseClicked,
  date,
}: Props) => {
  const appointmentDetailsSlice = useSelector(
    (state: RootState) => state.appointmentDetailsSlice
  )
  const { t } = useTranslation()
  const menu: ToggleOption[] = [
    { value: 'subjective', displayText: 'Subjective' },
    { value: 'objective', displayText: 'Objective' },
    { value: 'assessment', displayText: 'Assessment' },
    { value: 'plan', displayText: 'Plan' },
  ]
  const dispatch = useDispatch()
  const [appointmentData, setAppointmentData] =
    useState<FhirAppointmentFullDetail>(appointmentDetails)

  const [tab, setTab] = useState<string>('subjective')
  const paperColor = useTheme().palette.background.paper

  useEffect(() => {
    dispatch(getAllNutritionValueSets())
    if (isMedicalServiceProvider()) {
      dispatch(searchObservationsForAddLabTest())
      dispatch(searchUnits(getCurrentUserUnitDetails().id ?? ''))
    }

    if (isCurrentUserDoctor()) {
      dispatch(requestDietTiming(getCurrentUserPractitionerDetails().id ?? ''))
    }
  }, [])

  useEffect(() => {
    sessionStorage.setItem(
      CURRENT_VISIT_REFERENCE,
      `${appointmentDetails.appointment.resourceType}/${appointmentDetails.appointment.id}`
    )
    if (appointmentDetailsSlice.appointmentFetched === false) {
      dispatch(
        fetchAppointmentDetailsForSplit(appointmentDetails.appointment.id ?? '')
      )
    }

    return () => {
      sessionStorage.removeItem(CURRENT_VISIT_REFERENCE)
    }
  }, [])

  useEffect(() => {
    if (appointmentDetailsSlice.appointmentFetched) {
      if (appointmentDetailsSlice.appointmentFullDetails) {
        setAppointmentData(appointmentDetailsSlice.appointmentFullDetails)
      }
    }
    return () => {}
  }, [appointmentDetailsSlice.appointmentFetched])

  return (
    <Box width='100%' px={1} style={{ overflow: 'auto', height: '100vh' }}>
      <SimpleBar
        style={{
          height: '100%',
          overflowX: 'hidden',
          padding: '8px',
        }}
      >
        <Card
          elevation={0}
          style={{
            padding: 0,
            margin: 0,
            borderRadius: 4,
            backgroundColor: isOdd ? '#FFFFFFAF' : undefined,
          }}
        >
          <CardContent
            style={{
              padding: 8,
              paddingLeft: 16,
              paddingRight: 16,
              border: 1,
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              width='100%'
              // style={{ position: 'absolute' }}
            >
              {appointmentDetailsSlice.fetchingAppointmentDetails && (
                <Box
                  flexDirection='row'
                  display='flex'
                  alignSelf='center'
                  justifyContent='center'
                >
                  <CircularProgress size={25} />
                </Box>
              )}
              <Box
                display='flex'
                flexDirection='row'
                width='100%'
                justifyContent='space-between'
                alignContent='center'
                alignItems='center'
                //   style={{ position: 'absolute' }}
              >
                <Box flexGrow={1}>
                  <Box display='flex' alignItems='center' alignContent='center'>
                    <Box px={1}>
                      <ProfilePicWithAgeAndGender
                        patient={appointmentData.patient}
                      />
                    </Box>
                    {appointmentDetailsSlice.appointmentDetails &&
                      appointmentDetailsSlice.appointmentFetched && (
                        <Box>
                          <PatientNameWithAppointmentWidget
                            appointmentDetails={
                              appointmentDetailsSlice.appointmentDetails
                            }
                            split={true}
                          />
                        </Box>
                      )}
                  </Box>
                </Box>
                <Box display='flex' flexDirection='row'>
                  <Tooltip title='Close'>
                    <IconButton
                      aria-label='btn_ord_detail_menu'
                      onClick={onCloseClicked}
                    >
                      <CloseOutlined color='primary' />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Divider variant='fullWidth' />
              <Box
                display='flex'
                //   overflow='auto'
                flexDirection='row'
                bgcolor={paperColor}
              >
                <Box
                  flexDirection='column'
                  // overflow='auto'
                  width='100%'
                  id='rootEL'
                >
                  <Box width='100%' py={1}>
                    <CustomizedDividers
                      onSelectionChanged={(id) => {
                        setTab(id)
                      }}
                      options={menu}
                    />
                  </Box>
                  {appointmentDetailsSlice.appointmentDetails &&
                    appointmentDetailsSlice.appointmentFetched && (
                      <Grid container direction='column'>
                        <Grid item id='subjective' xl={8}>
                          {tab === 'subjective' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <SubjectivePartOfConsultation
                                fhirAppointmentDetails={
                                  appointmentDetailsSlice.appointmentDetails
                                }
                                split={true}
                                date={date}
                              />
                            </Box>
                          )}
                          {tab === 'objective' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <ObjectivePartOfConsultation
                                fhirAppointmentDetails={
                                  appointmentDetailsSlice.appointmentDetails
                                }
                                split={true}
                                displayReport={true}
                              />
                            </Box>
                          )}
                          {tab === 'assessment' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <AssessmentPartOfConsultation
                                fhirAppointmentDetails={
                                  appointmentDetailsSlice.appointmentDetails
                                }
                                split={true}
                              />
                            </Box>
                          )}
                          {tab === 'plan' && (
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <PlanSplit
                                fhirAppointmentDetails={
                                  appointmentDetailsSlice.appointmentDetails
                                }
                                split={true}
                              />
                            </Box>
                          )}
                        </Grid>

                        {/* <Grid item>
                          <AssessmentReadOnly
                            fhirAppointmentDetails={appointmentData}
                          />
                        </Grid>
                        <Grid item>
                          <PlanDetials
                            fhirAppointmentDetails={appointmentData}
                          />
                        </Grid> */}
                      </Grid>
                    )}
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </SimpleBar>
    </Box>
  )
}
