import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors, NullType } from 'io-ts'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestGeneralExaminationOfPatient } from 'redux/consultation/generalExamination/generalExaminationSlice'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { fToC } from 'utils/appointment_handle/vitals_util'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getVitalsObservationObject } from 'utils/fhirResoureHelpers/observationHelpers'
import { GeneralExamAddStatus } from './addGeneralExamStatusTypes'

const initialState: GeneralExamAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addGeneralExaminationSlice = createSlice({
  name: 'addGeneralExaminationSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<GeneralExamAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<GeneralExamAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addGeneralExam =
  (
    appointment: FhirAppointmentDetail,
    builtChanged: boolean,
    built?: string,
    nmentChanged?: boolean,
    nourishment?: string,
    pallorChanged?: boolean,
    pallor?: string,
    pallorRemarks?: string,
    ictChanged?: boolean,
    ict?: string,
    ictRemarks?: string,
    cynChanged?: boolean,
    cyn?: string,
    cynRemarks?: string,
    clubbingChanged?: boolean,
    clubbing?: string,
    clubbingRemarks?: string,
    lympChanged?: boolean,
    lymp?: string,
    lympRemarks?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: GeneralExamAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(
      addGeneralExaminationSlice.actions.updateAddVitalsStatus(addingState)
    )

    try {
      const bundleObject: R4.IBundle =
        createBundleObjectForObservationsForGeneralExam(
          appointment,

          built,

          nourishment,

          pallor,
          pallorRemarks,

          ict,
          ictRemarks,

          cyn,
          cynRemarks,

          clubbing,
          clubbingRemarks,

          lymp,
          lympRemarks
        )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        if (relatedFhirDecodeRes.right) {
          addingState = {
            adding: false,
            additionSuccessful: true,
            error: false,
            errorMessage: '',
          }
          dispatch(
            requestGeneralExaminationOfPatient(
              appointment.patient,
              appointment.appointment.id!,
              false
            )
          )

          dispatch(showSuccessAlert('General Examination Added Successfully'))
          dispatch(
            addGeneralExaminationSlice.actions.updateAddVitalsStatus(
              addingState
            )
          )
        }
      } else {
        const errorCreatePersonState: GeneralExamAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addGeneralExaminationSlice.actions.updateAddVitalsStatus(
            errorCreatePersonState
          )
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: GeneralExamAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addGeneralExaminationSlice.actions.updateAddVitalsStatus(
          errorCreatePersonState
        )
      )
    }
  }

export const resetAddGeneralExaminationState =
  () => (dispatch: AppDispatch) => {
    dispatch(
      addGeneralExaminationSlice.actions.resetAddVitalsDetails(initialState)
    )
  }

export function createBundleObjectForObservationsForGeneralExam(
  appointment: FhirAppointmentDetail,

  built?: string,

  nourishment?: string,

  pallor?: string,
  pallorRemarks?: string,

  ict?: string,
  ictRemarks?: string,

  cyn?: string,
  cynRemarks?: string,

  clubbing?: string,
  clubbingRemarks?: string,

  lymp?: string,
  lympRemarks?: string
): R4.IBundle {
  const encounter: R4.IEncounter = getEncounterObjectForAppointment(appointment)
  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }
  const practitionerRoleDetail: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()

  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
    type: encounter.resourceType,
  }

  if (built != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'A',
      coding: [
        {
          code: '165184000',
          display: 'Built',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    if (built === 'normal') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '301309000',
            display: 'Normal Built',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else if (built === 'obese') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '271590003',
            display: 'Obese',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '371597004',
            display: 'Emaciated',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (nourishment != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'B',
      coding: [
        {
          code: '1759002',
          display: 'Nourishment',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    if (nourishment === 'wellNourished') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '248324001',
            display: 'Well nourished ',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '248325000',
            display: 'Malnourished',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (pallor != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.meta = {
      profile: ['http://hl7.org/fhir/StructureDefinition/bodyheight'],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.code = {
      text: 'C',
      coding: [
        {
          code: '1237486008',
          display: 'Pallor',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    if (pallor === 'present') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '1237486008',
            display: 'Present',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '2667000',
            display: 'Absent',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }
    if (pallorRemarks && pallorRemarks.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: pallorRemarks,
          time: new Date().toISOString(),
        },
      ]
    }
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (ict != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'D',
      coding: [
        {
          code: '18165001',
          display: 'Icterus',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    if (ict === 'present') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '18165001',
            display: 'Present',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '2667000',
            display: 'Absent',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }
    if (ictRemarks && ictRemarks.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: ictRemarks,
          time: new Date().toISOString(),
        },
      ]
    }
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (cyn != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'E',
      coding: [
        {
          code: '3415004',
          display: 'Cyanosis',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    if (cyn === 'present') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '3415004',
            display: 'Present',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '2667000',
            display: 'Absent',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }
    if (cynRemarks && cynRemarks.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: cynRemarks,
          time: new Date().toISOString(),
        },
      ]
    }
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (clubbing != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'F',
      coding: [
        {
          code: '4373005',
          display: 'Clubbing',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    if (clubbing === 'present') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '4373005',
            display: 'Present',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '2667000',
            display: 'Absent',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }
    if (clubbingRemarks && clubbingRemarks.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: clubbingRemarks,
          time: new Date().toISOString(),
        },
      ]
    }
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (lymp != null) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'exam',
            display: 'exam',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'G',
      coding: [
        {
          code: '30746006',
          display: 'Lymphadenopathy',
          system: 'http://snomed.info/sct',
        },
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    if (lymp === 'present') {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '30746006',
            display: 'Present',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    } else {
      observationObject.valueCodeableConcept = {
        text: '',
        coding: [
          {
            code: '2667000',
            display: 'Absent',
            system: 'http://snomed.info/sct',
          },
        ],
      }
    }
    if (lympRemarks && lympRemarks.length > 0) {
      observationObject.note = [
        {
          authorReference: {
            reference: `${practitioner.resourceType}/${practitioner.id}`,
          },
          text: lympRemarks,
          time: new Date().toISOString(),
        },
      ]
    }
    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default addGeneralExaminationSlice.reducer
