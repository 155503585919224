import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { MemberRelation } from 'models/Memberrelation'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { searchInvitations } from 'redux/collectionCenter/Search/partnerLabSearchSlice'
import { searchPractitioners } from 'redux/practitioner/practitionerSearchHandler/practitionerSearchSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserPractitionerDetails,
  getCurrentUserUnitDetails,
  getUserDetails,
  isOrgAdmin,
} from 'services/userDetailsService'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import {
  getCombinedRoles,
  getCombinedRolesForUnit,
  getPractitionerObject,
  getPractitionerRoleObject,
  getPractitionerRoleOfPractitionerForSpecificUnit,
  getTaskObjectForProviderInvitation,
  UnitMemberDetailsCombined,
} from 'utils/fhirResoureHelpers/invitationHelpers'
import { getOrganizationType } from 'utils/fhirResoureHelpers/organizationHelpers'
import { logger } from 'utils/logger'
import { ActorInvitationSetupType } from './actorInvitationSetupType'

const initialState: ActorInvitationSetupType = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const actorInvitationSetupSlice = createSlice({
  name: 'adminInvitations',
  initialState,
  reducers: {
    updateState(state, action: PayloadAction<ActorInvitationSetupType>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
    },
  },
})

export const sendActorInvitations =
  (unitDetails: MemberRelation[], userAdd?: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    const addingCreatePersonState: ActorInvitationSetupType = {
      adding: true,
      additionSuccessful: false,
      error: false,
    }
    dispatch(
      actorInvitationSetupSlice.actions.updateState(addingCreatePersonState)
    )
    try {
      const requestBody: R4.IBundle | undefined = await getTransactionObject(
        unitDetails
      )

      if (requestBody) {
        const fhirApi: FHIRApiClient = new FHIRApiClient()
        const response = await fhirApi.doCreateFHIRTransaction('', requestBody)
        logger.info('Response ', response)
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          const successCreatePersonState: ActorInvitationSetupType = {
            adding: false,
            additionSuccessful: true,
            error: false,
            errorMessage: '',
          }
          dispatch(
            actorInvitationSetupSlice.actions.updateState(
              successCreatePersonState
            )
          )
          if (userAdd) {
            dispatch(showSuccessAlert('Invitation sent successfully'))
          } else {
            dispatch(
              showSuccessAlert(
                'Please update Lab data in order to access lab orders and Test(S)'
              )
            )
          }

          dispatch(searchInvitations([], []))
          dispatch(searchPractitioners())
          return
        }
      }
      const errorCreatePersonState: ActorInvitationSetupType = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'Error while sending invitation',
      }
      dispatch(
        actorInvitationSetupSlice.actions.updateState(errorCreatePersonState)
      )
      return
    } catch (error: any) {
      let message: string = 'Error while sending invitations. Please try later'

      const errorData: string = error.message
      if (errorData.includes('412'))
        message = 'Either email or phone already exist'
      logger.info('error', error)
      const errorCreatePersonState: ActorInvitationSetupType = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: message,
      }
      dispatch(
        actorInvitationSetupSlice.actions.updateState(errorCreatePersonState)
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(actorInvitationSetupSlice.actions.updateState(initialState))
}

export default actorInvitationSetupSlice.reducer

async function getTransactionObject(
  input: MemberRelation[]
): Promise<R4.IBundle | undefined> {
  console.log(input)
  const mainOrganization: R4.IOrganization | undefined =
    getUserDetails()?.mainOrganization
  const currentOrganization: R4.IOrganization = isOrgAdmin()
    ? getCurrentUserMainOrganizationDetails()
    : getCurrentUserUnitDetails()
  const currentUserPractRole: R4.IPractitionerRole | undefined =
    getUserDetails()?.practitionerRole
  const roleMergedData: UnitMemberDetailsCombined[] =
    getCombinedRolesForUnit(input)

  console.log(roleMergedData)
  if (
    mainOrganization &&
    mainOrganization.id &&
    currentUserPractRole &&
    currentUserPractRole.id
  ) {
    const entries: R4.IBundle_Entry[] = []

    await Promise.all(
      roleMergedData.map(async (e) => {
        const practId: string = e.existingUserPractitionerId ?? e.id
        let existingPractRole: R4.IPractitionerRole | undefined

        if (e.existingUserPractitionerId) {
          existingPractRole =
            await getPractitionerRoleOfPractitionerForSpecificUnit(
              getCurrentUserUnitDetails().id!,
              e.existingUserPractitionerId
            )
        }
        const practRoleId: string =
          existingPractRole !== undefined
            ? existingPractRole.id!
            : getUniqueTempId()

        const taskObject: R4.ITask[] = getTaskObjectForProviderInvitation(
          getOrganizationType(currentOrganization) === 'vendor-unit'
            ? 'unit'
            : 'org',
          e.practitionerEmail,
          e.practitionerPhoneNumber,
          {
            type: 'PractitionerRole',
            reference: `${'PractitionerRole/'}${practRoleId}`,
          },
          {
            type: 'PractitionerRole',
            id: currentUserPractRole.id,
            reference: `${'PractitionerRole/'}${currentUserPractRole.id}`,
          },
          false,
          false
        )

        const practObject: R4.IPractitioner = e.isSelf
          ? getCurrentUserPractitionerDetails()
          : getPractitionerObject(
              practId,
              e.practitionerName,
              e.practitionerEmail,
              e.practitionerPhoneNumber,
              mainOrganization
            )

        // const practObject: R4.IPractitioner = getPractitionerObject(
        //   practId,
        //   e.practitionerName,
        //   e.practitionerEmail,
        //   e.practitionerPhoneNumber,
        //   mainOrganization
        // )

        const practRoleObject: R4.IPractitionerRole =
          existingPractRole === undefined
            ? getPractitionerRoleObject(
                practId,
                practRoleId,
                e.roles,
                e.practitionerName,
                e.practitionerEmail,
                e.practitionerPhoneNumber,
                currentOrganization
              )
            : {
                ...existingPractRole,
                code: [
                  ...(existingPractRole.code ?? []),
                  ...e.roles.map(
                    (role) =>
                      ({
                        coding: role,
                      } as R4.ICodeableConcept)
                  ),
                ],
              }

        // const practRoleObject: R4.IPractitionerRole = getPractitionerRoleObject(
        //   practId,
        //   practRoleId,
        //   e.roles,
        //   e.practitionerName,
        //   e.practitionerEmail,
        //   e.practitionerPhoneNumber
        // )
        const practEntry: R4.IBundle_Entry = {
          request: {
            url: practObject.resourceType,
            method: R4.Bundle_RequestMethodKind._post,
          },
          resource: practObject,
        }
        const practRoleEntry: R4.IBundle_Entry = {
          request: {
            url:
              existingPractRole === undefined
                ? practRoleObject.resourceType
                : `${practRoleObject.resourceType}/${practRoleObject.id}`,
            method:
              existingPractRole === undefined
                ? R4.Bundle_RequestMethodKind._post
                : R4.Bundle_RequestMethodKind._put,
          },
          resource: practRoleObject,
        }
        taskObject.forEach((task) => {
          const taskEntry: R4.IBundle_Entry = {
            request: {
              url: task.resourceType,
              method: R4.Bundle_RequestMethodKind._post,
            },
            resource: task,
          }
          entries.push(taskEntry)
        })

        if (!e.existingUserPractitionerId) {
          entries.push(practEntry)
        }

        entries.push(practRoleEntry)
      })
    )
    const bundleObject: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: entries,
    }
    return bundleObject
  }
  return undefined
}
