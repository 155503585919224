import { Box } from '@material-ui/core'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";

import React, { Suspense, useEffect, useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import 'simplebar/dist/simplebar.min.css'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import { logger } from 'utils/logger'
import { AlertHandler } from 'views/components/alertDispay'
import { AuthCallbackHandler } from 'views/components/authCallback'
import { AuthRoute } from 'views/components/authRouteComponent'
import { UnAuthorized } from 'views/components/unauthorized'
import { AuthRoutes, NonAuthRoutes } from 'views/navigation/navigationRoutes'
import { AuthChecker } from 'views/pages/authChecker'
import { ActiveIPDDetailsForMedicalRole } from 'views/pages/clinic/activeIPDDetailsForMedicalRole'
import { ViewAppointmentPage } from 'views/pages/clinic/viewAppointment'
import { Dashboard } from 'views/pages/dashboard'
import { LoginHandler } from 'views/pages/loginHandler'
import { Settings } from 'views/pages/settings'
import { WelloLoadingPage } from 'wello-web-components'
import { WinstonProvider } from 'winston-react'
import './App.css'

export interface RouteParams {
  vendor: string
}

function App() {
  TimeAgo.addDefaultLocale(en)

  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: true,
    requestSuccess: true,
  })

  function fetchInvoice() {
    setFetchingStatus({
      requesting: true,
    })

    /* new NTPClient()
      .getNetworkTime()

      .then((result) => {
        )
        
        

         if (moment(new Date()).diff(moment(result.now), 'd') != 0) {
          setFetchingStatus({
            requesting: false,
            requestSuccess: false,
          })
        } else {
          setFetchingStatus({
            requesting: false,
            requestSuccess: true,
          })
        } 
      })
      .catch((err) => {
        console.error(err)
      }) */
  }
  const { height } = useWindowDimensions()
  /* useEffect(() => {
    fetchInvoice()
  }, []) */

  return (
    <WinstonProvider logger={logger}>
      <Suspense fallback='loading'>
        {fetchingStatus.requestSuccess && (
          <Box
            id='root_wello'
            display='flex'
            //   height={height}
            width='100%'
            overflow='none'
          >
            <RecoilRoot>
              <AlertHandler />
              <BrowserRouter>
                <Switch>
                  <Route
                    path={NonAuthRoutes.AuthCallBack}
                    component={AuthCallbackHandler}
                  />

                  <Route
                    exact
                    strict
                    path={NonAuthRoutes.Default}
                    component={AuthChecker}
                  />
                  <Route path={NonAuthRoutes.Login} component={LoginHandler} />
                  <AuthRoute
                    path={AuthRoutes.Dashboard}
                    Component={Dashboard}
                  />

                  <AuthRoute path={AuthRoutes.Settings} Component={Settings} />
                  <AuthRoute
                    path={AuthRoutes.ViewAppointment}
                    Component={ViewAppointmentPage}
                  />
                  <AuthRoute
                    path={AuthRoutes.ViewIpdAppointment}
                    Component={ActiveIPDDetailsForMedicalRole}
                  />
                  <AuthRoute
                    path={AuthRoutes.Medicine}
                    Component={ActiveIPDDetailsForMedicalRole}
                  />
                  <AuthRoute
                    path={AuthRoutes.ViewOPDAppointment}
                    Component={ActiveIPDDetailsForMedicalRole}
                  />
                  <Route
                    path={NonAuthRoutes.Unauthorized}
                    component={UnAuthorized}
                  />
                </Switch>
              </BrowserRouter>
            </RecoilRoot>
          </Box>
        )}
        {/* {fetchingStatus.requesting && (
          <Box
            id='root_wello'
            display='flex'
            //   height={height}
            width='100%'
            overflow='none'
          >
            <WelloLoadingPage title='Loading..' />
          </Box>
        )} */}
      </Suspense>
    </WinstonProvider>
  )
}

export default App
