import { R4 } from "@ahryman40k/ts-fhir-types"
import { doPostQuestionForDietEngineLlm, regexExpressionFirst, regexExpressionForAnswerWithFlowerBracket, regexExpressionForStringMeetsCriteriaOf, regexExpressionForStringThatContainsARWithCommaSeparated, regexExpressionForStringThatContainsARWithNewLine, regexExpressionForStringWithHereAre, regexExpressionToCheckDietStringPresentAfterDietCode, regexExpressionToCheckStringContainsDietCodeAR } from "./llmDietPlanHelper"

export async function getMainDish (mealTime: string, cuisineString: string, easeOfPreparationString: string, batchCookingString: string, 
    easyRestaurantRecipeString: string): Promise<R4.ICoding[]>
{

    const question = `Give me fifteen days unique diet code and diet name with meal time as ${mealTime}${cuisineString} and course as Main Dish${easeOfPreparationString}${batchCookingString}${easyRestaurantRecipeString}. Please give me diet code also`

    const dataToSend = {
        query: question,
      }
  
      try {
  
        const questionForDietEngine = JSON.stringify(dataToSend)

        console.log(`-----The question sent for llm for ${mealTime} main dish is----`)
        console.log(questionForDietEngine)
  
        const result: any = await doPostQuestionForDietEngineLlm(
          questionForDietEngine
        )
  
  
        const finalResult = result.answer
  
        console.log(`-----The response for ${mealTime} main dish question is----`)
        console.log(finalResult)
  
        if (
          finalResult.includes('No results') ||
          finalResult.includes('no results') ||
          finalResult.includes('No result') ||
          finalResult.includes('no result') ||
          finalResult.includes('SQLResult:') ||
          finalResult.includes('SQLQuery') ||
          finalResult.includes('no diet') ||
          finalResult.includes('no diets')
        ) {
        //   const questionSecond = `Give me fifteen days diet along with diet code with meal time as lunch or afternoon and course as Main Dish`
          const questionSecond = `Give me fifteen days unique diet code and diet name with meal time as ${mealTime} and course as Main Dish`

  
          console.log(`---The second question for ${mealTime} Main Dish is----`)
          console.log(questionSecond)
  
          const secondDataToSend = {
            query: questionSecond,
          }
  
          try {
  
            const secondQuestionForDietEngine = JSON.stringify(secondDataToSend)
            const secondResponse: any = await doPostQuestionForDietEngineLlm(secondQuestionForDietEngine)
            const finalResultSecond = secondResponse.answer
  
            console.log(`---The second answer for ${mealTime} Main Dish is----`)
            console.log(finalResultSecond)

            if (finalResultSecond.includes('Diet code'))
                {
                   const outputArr: R4.ICoding[] = regexExpressionFirst(finalResultSecond)
                   if (outputArr.length !== 0)
                      return outputArr
                }
  
            if (finalResultSecond.includes('Here are') || finalResultSecond.includes('are:') || finalResultSecond.includes('are as follows')) 
              {
                const outputArr: R4.ICoding[] = regexExpressionForStringWithHereAre(finalResultSecond)
                if (outputArr.length !== 0) return outputArr
              }
      

              if (regexExpressionToCheckStringContainsDietCodeAR(finalResultSecond))
              {
                  let outputArr: R4.ICoding[] = []
                  if(finalResultSecond.includes('\n'))
                      outputArr = regexExpressionForStringThatContainsARWithNewLine(finalResultSecond)
                  else
                      outputArr = regexExpressionForStringThatContainsARWithCommaSeparated(finalResultSecond)
                  
                      return outputArr
              }

              if (finalResultSecond.includes('(') && finalResultSecond.includes(')'))
              {
                const outputArr: R4.ICoding[] = regexExpressionFirst(finalResultSecond)
                if (outputArr.length !== 0)
                   return outputArr
              }
      
          
          } catch (err) {
            console.log('---Printing the error---')
            console.log(err)
          }
        }
  
  
          if (finalResult.includes('Here are') || finalResult.includes('are:') || finalResult.includes('are as follows')) 
          {
            const outputArr: R4.ICoding[] = regexExpressionForStringWithHereAre(finalResult)
            if (outputArr.length !== 0) return outputArr
          }
  
          if (finalResult.includes('that meets the criteria of')) 
          {
              const outputArr: R4.ICoding[] = regexExpressionForStringMeetsCriteriaOf(finalResult)
              if (outputArr.length !== 0) return outputArr
          }
  
          if (finalResult.includes('Diet code'))
          {
             const outputArr: R4.ICoding[] = regexExpressionFirst(finalResult)
             if (outputArr.length !== 0)
             {
                return outputArr
             }
          }
  
          if (regexExpressionToCheckStringContainsDietCodeAR(finalResult) && !regexExpressionToCheckDietStringPresentAfterDietCode(finalResult))
          {
              let outputArr: R4.ICoding[] = []
              
              if(finalResult.includes('\n'))
                  outputArr = regexExpressionForStringThatContainsARWithNewLine(finalResult)
              else
                  outputArr = regexExpressionForStringThatContainsARWithCommaSeparated(finalResult)
              
                  return outputArr
          }
  
  
        //   const questionSecond = `Give me fifteen days unique diet code and diet name with meal time as lunch or afternoon and course as Main Dish`
          const questionSecond = `Give me fifteen days unique diet code and diet name with meal time as ${mealTime} and course as Main Dish`

  
  
          console.log(`---The second ${mealTime} Main Dish question is----`)
          console.log(questionSecond)
  
          const secondDataToSend = {
            query: questionSecond,
          }
  
          try {
  
            const secondQuestionForDietEngine = JSON.stringify(secondDataToSend)
            const secondResponse: any = await doPostQuestionForDietEngineLlm(secondQuestionForDietEngine)
            const finalResultSecond = secondResponse.answer
  
            console.log(`---The second ${mealTime} Main Dish answer is----`)
            console.log(finalResultSecond)
  
            if (finalResultSecond.includes('Here are') || finalResultSecond.includes('are:') || finalResultSecond.includes('are as follows')) 
              {
                const outputArr: R4.ICoding[] = regexExpressionForStringWithHereAre(finalResultSecond)
                if (outputArr.length !== 0) return outputArr
              }
      
              if (finalResultSecond.includes('Diet code'))
              {
                 const outputArr: R4.ICoding[] = regexExpressionFirst(finalResultSecond)
                 if (outputArr.length !== 0)
                    return outputArr
              }
      
              if (regexExpressionToCheckStringContainsDietCodeAR(finalResultSecond))
              {
                  let outputArr: R4.ICoding[] = []
                  if(finalResultSecond.includes('\n'))
                      outputArr = regexExpressionForStringThatContainsARWithNewLine(finalResultSecond)
                  else
                      outputArr = regexExpressionForStringThatContainsARWithCommaSeparated(finalResultSecond)
                  
                      return outputArr
              }

              if (finalResultSecond.includes('(') && finalResultSecond.includes(')'))
                {
                  const outputArr: R4.ICoding[] = regexExpressionFirst(finalResultSecond)
                  if (outputArr.length !== 0)
                     return outputArr
                }
      
          
          } catch (err) {
            console.log('---Printing the error---')
            console.log(err)
          }
        
  
      } catch (err) {
        console.log('---Printing the error---')
        console.log(err)
      }
  
      return []
    
}



export async function getSideDish (mealTime: string, cuisineString: string, easeOfPreparationString: string, batchCookingString: string, 
    easyRestaurantRecipeString: string): Promise<R4.ICoding[]>
{

    const question = `Give me fifteen days unique diet code and diet name with meal time as ${mealTime} and${cuisineString} and course as Side Dish${easeOfPreparationString}${batchCookingString}${easyRestaurantRecipeString}. Please give me diet code also`

    const dataToSend = {
        query: question,
      }
  
      try {
  
        const questionForDietEngine = JSON.stringify(dataToSend)

        console.log(`-----The ${mealTime} side dish question sent for llm is----`)
        console.log(questionForDietEngine)
  
        const result: any = await doPostQuestionForDietEngineLlm(
          questionForDietEngine
        )
  
  
        const finalResult = result.answer
  
        console.log(`-----The response for ${mealTime} side dish question is----`)
        console.log(finalResult)
  
        if (
          finalResult.includes('No results') ||
          finalResult.includes('no results') ||
          finalResult.includes('No result') ||
          finalResult.includes('no result') ||
          finalResult.includes('SQLResult:') ||
          finalResult.includes('SQLQuery') ||
          finalResult.includes('no diet') ||
          finalResult.includes('no diets')
        ) {
        //   const questionSecond = `Give me fifteen days diet along with diet code with meal time as lunch or afternoon and course as Side Dish`

         const questionSecond = `Give me fifteen days unique diet code and diet name with meal time as ${mealTime} and course as Side Dish`

  
          console.log(`---The second ${mealTime} side dish question is----`)
          console.log(questionSecond)
  
          const secondDataToSend = {
            query: questionSecond,
          }
  
          try {
  
            const secondQuestionForDietEngine = JSON.stringify(secondDataToSend)
            const secondResponse: any = await doPostQuestionForDietEngineLlm(secondQuestionForDietEngine)
            const finalResultSecond = secondResponse.answer
  
            console.log(`---The second ${mealTime} side dish answer is----`)
            console.log(finalResultSecond)
  
            if (finalResultSecond.includes('Here are') || finalResultSecond.includes('are:') || finalResultSecond.includes('are as follows')) 
              {
                const outputArr: R4.ICoding[] = regexExpressionForStringWithHereAre(finalResultSecond)
                if (outputArr.length !== 0) return outputArr
              }
      
              if (finalResultSecond.includes('Diet code'))
              {
                 const outputArr: R4.ICoding[] = regexExpressionFirst(finalResultSecond)
                 if (outputArr.length !== 0)
                    return outputArr
              }
      
              if (regexExpressionToCheckStringContainsDietCodeAR(finalResultSecond))
              {
                  let outputArr: R4.ICoding[] = []
                  if(finalResultSecond.includes('\n'))
                      outputArr = regexExpressionForStringThatContainsARWithNewLine(finalResultSecond)
                  else
                      outputArr = regexExpressionForStringThatContainsARWithCommaSeparated(finalResultSecond)
                  
                      return outputArr
              }

              if (finalResultSecond.includes('(') && finalResultSecond.includes(')'))
                {
                  const outputArr: R4.ICoding[] = regexExpressionFirst(finalResultSecond)
                  if (outputArr.length !== 0)
                     return outputArr
                }
      
          
          } catch (err) {
            console.log('---Printing the error---')
            console.log(err)
          }
        }
  
  
          if (finalResult.includes('Here are') || finalResult.includes('are:') || finalResult.includes('are as follows')) 
          {
            const outputArr: R4.ICoding[] = regexExpressionForStringWithHereAre(finalResult)
            if (outputArr.length !== 0) return outputArr
          }
  
          if (finalResult.includes('that meets the criteria of')) 
          {
              const outputArr: R4.ICoding[] = regexExpressionForStringMeetsCriteriaOf(finalResult)
              if (outputArr.length !== 0) return outputArr
          }
  
          if (finalResult.includes('Diet code'))
          {
             const outputArr: R4.ICoding[] = regexExpressionFirst(finalResult)
             if (outputArr.length !== 0)
             {
                console.log("--------The output after it includes Diet code is------")
                return outputArr
             }
          }
  
          if (regexExpressionToCheckStringContainsDietCodeAR(finalResult) && !regexExpressionToCheckDietStringPresentAfterDietCode(finalResult))
          {
              let outputArr: R4.ICoding[] = []
              if(finalResult.includes('\n'))
                  outputArr = regexExpressionForStringThatContainsARWithNewLine(finalResult)
              else
                  outputArr = regexExpressionForStringThatContainsARWithCommaSeparated(finalResult)
              
                  return outputArr
          }
          
  
  
        //   const questionSecond = `Give me fifteen days diet along with diet code with meal time as lunch or afternoon and course as Side Dish. Please give me diet code also`
          const questionSecond = `Give me fifteen days unique diet code and diet name with meal time as ${mealTime} and course as Side Dish`
          
  
          console.log(`---The second ${mealTime} side dish question is----`)
          console.log(questionSecond)
  
          const secondDataToSend = {
            query: questionSecond,
          }
  
          try {
  
            const secondQuestionForDietEngine = JSON.stringify(secondDataToSend)
            const secondResponse: any = await doPostQuestionForDietEngineLlm(secondQuestionForDietEngine)
            const finalResultSecond = secondResponse.answer
  
            console.log(`---The second ${mealTime} side dish answer is----`)
            console.log(finalResultSecond)
  
            if (finalResultSecond.includes('Here are') || finalResultSecond.includes('are:') || finalResultSecond.includes('are as follows')) 
              {
                const outputArr: R4.ICoding[] = regexExpressionForStringWithHereAre(finalResultSecond)
                if (outputArr.length !== 0) return outputArr
              }
      
              if (finalResultSecond.includes('Diet code'))
              {
                 const outputArr: R4.ICoding[] = regexExpressionFirst(finalResultSecond)
                 if (outputArr.length !== 0)
                    return outputArr
              }
      
              if (regexExpressionToCheckStringContainsDietCodeAR(finalResultSecond))
              {
                  let outputArr: R4.ICoding[] = []
                  if(finalResultSecond.includes('\n'))
                      outputArr = regexExpressionForStringThatContainsARWithNewLine(finalResultSecond)
                  else
                      outputArr = regexExpressionForStringThatContainsARWithCommaSeparated(finalResultSecond)
                  
                      return outputArr
              }

              if (finalResultSecond.includes('(') && finalResultSecond.includes(')'))
                {
                  const outputArr: R4.ICoding[] = regexExpressionFirst(finalResultSecond)
                  if (outputArr.length !== 0)
                     return outputArr
                }
      
          
          } catch (err) {
            console.log('---Printing the error---')
            console.log(err)
          }
        
  
      } catch (err) {
        console.log('---Printing the error---')
        console.log(err)
      }
  
      return []
    
}

