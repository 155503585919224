/* eslint-disable no-irregular-whitespace */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography } from '@material-ui/core'
import { CarePlanCPG } from 'models/administration/cpgDetailsForTable'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import React, { useEffect, useState } from 'react'
import ContentShimmer from 'react-content-shimmer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import 'react-vis/dist/style.css'
import { EndoQOL } from './qol/endoQOL'
import { HeavyBleeding } from './qol/heavyBleedingQOL'

import { MenoPauseQol } from './qol/menopauseQol'
import { PainfulQOL } from './qol/painfulQol'
import { PCOSQol } from './qol/pcosQol'
import { VaginalQOL } from './qol/vaginalQOL'

interface CardioVascularExaminationsListProps {
  fhirAppointmentDetails: FhirAppointmentDetail

  isReadOnly?: boolean
  planData?: CarePlanCPG
}

export const QQLForm: React.FC<CardioVascularExaminationsListProps> = ({
  fhirAppointmentDetails,

  isReadOnly = false,
  planData,
}: CardioVascularExaminationsListProps) => {
  const { t } = useTranslation(['common'])

  return (
    <Box sx={{ width: '100%' }} display='flex' flexDirection='column'>
      <Box display='flex' flexGrow={1} py={1}>
        <Box
          borderRadius={2}
          style={{
            backgroundColor: 'lightGrey',
          }}
          display='flex'
          flexDirection='row'
          width='100%'
          height={40}
          px={1}
        >
          <Box
            justifyContent='flex-start'
            display='flex'
            flexGrow={1}
            alignItems='center'
          >
            <Typography
              variant='subtitle1'
              style={{
                color: 'black',

                fontWeight: 'bold',
              }}
            >
              Quality of Life Assessment
            </Typography>
          </Box>
        </Box>
      </Box>

      {planData && planData.menoPause && (
        <MenoPauseQol
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
          carePlan={planData.menoPause}
        />
      )}
      {planData && planData.vaginalInfectionPlan && (
        <VaginalQOL
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
          carePlan={planData.vaginalInfectionPlan}
        />
      )}
      {planData && planData.PcosPlan && (
        <PCOSQol
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
          carePlan={planData.PcosPlan}
        />
      )}
      {planData && planData.heavyMensuralPlan && (
        <HeavyBleeding
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
          carePlan={planData.heavyMensuralPlan}
        />
      )}
      {planData && planData.painFullPeriods && (
        <PainfulQOL
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
          carePlan={planData.painFullPeriods}
        />
      )}

      {planData && planData.endoPlan && (
        <EndoQOL
          fhirAppointmentDetails={fhirAppointmentDetails}
          isReadOnly={isReadOnly}
          carePlan={planData.endoPlan}
        />
      )}

      {/* {fetchingStatus.requestSuccess &&
        !fetchingStatus.initial &&
        !fetchingStatus.requesting &&
        !fetchingStatus.requestError && (
          <Box width='100%' display='flex' flexDirection='column' flexGrow={1}>
            <OCARenderer
              OCAFormProviderBaseURL={`${
                process.env.REACT_APP_CC_WORKFLOW_URL
              }${getVendorPartId()}/ocaForms/forms`}
              OCAFormProviderHeader={{
                Authorization: `Bearer ${getAccessToken()}`,

                'Cache-Control': 'no-cache',
              }}
              onSubmit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExamForGe(
                    fhirAppointmentDetails.patient.id!,
                    fhirAppointmentDetails.encounter!.id!,
                    [
                      {
                        coding: [
                          {
                            code: 'C0518214',
                            system:
                              'http://terminology.hl7.org/CodeSystem/umls',
                            display: 'Subjective Questionnaire',
                          },
                        ],
                      },
                    ],
                    observation,
                    undefined,
                    plan
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }
                    getObservationDetails()
                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              onEdit={(
                observation: R4.IObservation,
                onSubmitSuccess?: (data: any) => void
              ) => {
                const updatedObservation =
                  getObservationResourceForPostForSystemicExamForGe(
                    fhirAppointmentDetails.patient.id!,
                    fhirAppointmentDetails.encounter!.id!,
                    [],
                    observation,
                    undefined,
                    plan
                  )

                addObservationDetailsForSys(updatedObservation).then((e) => {
                  if (e === false) {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  } else if (
                    (e as R4.IObservation).resourceType === 'Observation'
                  ) {
                    if (onSubmitSuccess) {
                      onSubmitSuccess(e)
                    }

                    dispatch(
                      showSuccessAlert(
                        'Observation details updated successfully'
                      )
                    )
                    getObservationDetails()
                  } else {
                    dispatch(
                      showErrorAlert('Error while updating observation details')
                    )
                  }
                })
              }}
              onDelete={(
                observation: R4.IObservation,
                onSuccess?: ((data: any) => void) | undefined,
                onError?: ((data: any) => void) | undefined
              ) => {
                deleteObservationDetails(observation).then((e) => {
                  if (e) {
                    dispatch(
                      showSuccessAlert(
                        'Observation details deleted successfully'
                      )
                    )
                    getObservationDetails()
                    if (onSuccess) onSuccess({})
                  } else if (onError) onError({})
                })
              }}
              formSection='soap'
              formCategory='qql'
              displayAsPopup={false}
              isViewOnly={isReadOnly}
              showAdded={true}
              existingObservations={observations}
            />
          </Box>
        )} */}
    </Box>
  )
}
