import { R4 } from '@ahryman40k/ts-fhir-types'
import {
    Box,
    makeStyles,
    Paper,
    Table,
    TableCell,
    TableContainer,
    Theme,
    TableRow,
    Typography,
    TableHead,
    TableBody,
  } from '@material-ui/core'
  import { kDialogueBackground } from 'configs/styles/muiThemes'
  import { up } from 'dinero.js'
  import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
  import React, { useState } from 'react'
  import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    DraggingStyle,
    NotDraggingStyle,
  } from 'react-beautiful-dnd'
  import {
    DisplayTableData,
    EachItem,
  } from 'utils/careplan_utils/llmDietPlanHelper'
  
  interface Item {
    id: string
    content: string
  }
  
  interface Column {
    id: string
    title: string
    items: Item[]
  }
  
  interface DragAndDropProps {
    tableDataForChart: DisplayTableData
  }
  
  export const DietChartComponent: React.FC<DragAndDropProps> = ({
    tableDataForChart,
  }: DragAndDropProps) => {
    const [columns, setColumns] = useState<DisplayTableData>(tableDataForChart)

    console.log("----The table data for chart is----")
    console.log(tableDataForChart)


    const useStyles = makeStyles((theme: Theme) => ({
      table: {
        padding: '3px',
        height: '40vh',
        margin: '0px',
        '& .MuiTableCell-root': {
          border: '2px solid rgba(0, 0, 0, 0.12)',
          padding: '4px',
          margin: '0px',
          height: 35,
          maxWidth: '12%',
          overflow: 'ellipse',
        },
      },
      tableDietCompliance: {
        padding: '3px',
        margin: '0px',
        height: 150,
        '& .MuiTableCell-root': {
          border: '2px solid rgba(0, 0, 0, 0.12)',
          padding: '4px',
          margin: '0px',
  
          maxWidth: '12%',
          overflow: 'ellipse',
        },
      },
      dialogCustomizedWidth: {
        'max-width': '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
      },
      dialogCustomizedWidthSub: {
        'max-width': '40%',
        minHeight: '45vh',
        maxHeight: '45vh',
      },
      dialogCustomizedWidthEdit: {
        'max-width': '30%',
        minHeight: '34vh',
        maxHeight: '34vh',
      },
      dialogCustomizedWidthAccompaniment: {
        'max-width': '40%',
        minHeight: '40vh',
        maxHeight: '40vh',
      },
      dialogCustomizedWidthMealCourse: {
        'max-width': '40%',
        minHeight: '100vh',
        maxHeight: '100vh',
      },
      dialogCustomizedWidthCategories: {
        'max-width': '40%',
        minHeight: '65vh',
        maxHeight: '65vh',
      },
    }))
    const classes = useStyles()
  
    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        width='100%'
        px={1}
        paddingTop={5}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          width='90%'
          paddingRight={1}
        >
            <TableContainer
              component={Paper}
              style={{
                width: '100%',
                margin: '0px',
                padding: '0px',
              }}
            >
              <Table className={classes.table} aria-label='simple table'>
                <TableHead
                  style={{
                    backgroundColor: kDialogueBackground,
                  }}
                >
                  <TableRow
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {columns.header.map((eachHeader, cellIndex) => {
                      if (cellIndex === 0) {
                        return (
                          <TableCell key='day_of_week'>
                            <Typography
                                variant='subtitle2'
                                align='center'
                                style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                                }}
                            >
                               Day of Week
                            </Typography>
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={eachHeader.time}>
                          <Typography
                            variant='subtitle2'
                            align='center'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 14,
                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            {eachHeader.display} ({eachHeader.displayMealType})
                          </Typography>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                  <TableBody>
                    {columns.data.map((day, index) => (
                      <TableRow key={day.length > 0 ? day[0] : 'abc'}>
                        {day.map((eachDay: R4.ICoding, cellIndex) => {
                          if (cellIndex === 0) {
                            return (
                              <TableCell
                                key={`${day[0]}_${columns.header[cellIndex].time}_cell`}
                                // style={{
                                //     backgroundColor: kDialogueBackground,
                                //   }}
                               >
                                <Typography
                                    variant='subtitle2'
                                    align='center'
                                    style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                    }}
                                >
                                  {eachDay}
                                </Typography>
                              </TableCell>
                            )
                          }
                          return (
                                <TableCell
                                  key={`${day[0]}_${columns.header[cellIndex].time}_cell`}
                                >
                                  {(eachDay as EachItem[]).map(
                                    (eachItem: EachItem) => (
                                        <Typography
                                            key={`${eachItem.date}_${eachItem.time}_${eachItem.itemDisplay}`}
                                            variant='subtitle2'
                                            align='center'
                                            style={{
                                            fontFamily: 'Open Sans',
                                            fontSize: 14,
                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                            }}
                                        >
                                        {eachItem.itemDisplay}
                                      </Typography>
                                    )
                                  )}
                                </TableCell>
                          )
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
              </Table>
            </TableContainer>
        </Box>
      </Box>
    )
  }
  
  
 