import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import { WelloReports } from 'models/WelloConditions'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getAppointmentIdForFollowUp } from 'utils/fhirResourcesHelper'
import { getEncounterFromBundle } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getLabDiagnosticRequestFromBundle,
  getTestsFromDiagnostics,
  getTestsFromTasks,
} from 'utils/fhirResoureHelpers/labOrderHelpers'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { ReportsSearchStatus } from './reportsSearchStatus'

const initialState: ReportsSearchStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const reportsSearchSlice = createSlice({
  name: 'reportsSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<ReportsSearchStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.reports = action.payload.reports
      state.diagnosticReport = action.payload.diagnosticReport
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions

      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions

      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
    },
  },
})

export const getLabReportsForTheAppointment =
  (
    fhirAppointmentDetails: FhirAppointmentDetail,
    followUp?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: ReportsSearchStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(reportsSearchSlice.actions.updatedStatus(state))
    try {
      const docRefFiles: R4.IDocumentReference[] = []
      let referredEncounter: R4.IEncounter[] = []

      const reportsFiles: R4.IDiagnosticReport[] = []
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      let searchParameters: any = {
        encounter: fhirAppointmentDetails.encounter
          ? fhirAppointmentDetails.encounter.id ?? ''
          : '',
        _sort: '-_lastUpdated',
      }

      if (
        followUp === undefined &&
        fhirAppointmentDetails.appointment.basedOn === undefined
      ) {
        searchParameters = {
          encounter: fhirAppointmentDetails.encounter
            ? fhirAppointmentDetails.encounter.id ?? ''
            : '',
        }
      }

      if (
        followUp === undefined &&
        fhirAppointmentDetails.appointment.basedOn
      ) {
        if (fhirAppointmentDetails.appointment.basedOn[0].reference) {
          const serviceRequestData =
            fhirAppointmentDetails.appointment.basedOn[0].reference.split('/')

          const newResponse =
            await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
              `/ServiceRequest?_id=${serviceRequestData[1]}&_include=ServiceRequest:encounter`,
              ''
            )
          if (
            newResponse &&
            newResponse.entry &&
            newResponse.entry.length > 0
          ) {
            referredEncounter = getEncounterFromBundle(
              newResponse as R4.IBundle
            )
            if (referredEncounter.length > 0) {
              const searchParametersOld: any = {
                encounter: referredEncounter[0].id ?? '',
                _sort: '-_lastUpdated',
              }
              let reportResponse: any

              reportResponse =
                await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
                  '/DiagnosticReport?category:missing=true&based-on:missing=false&status=final',
                  referredEncounter[0].appointment
                    ? referredEncounter[0].appointment[0].id ?? ''
                    : '',
                  searchParametersOld
                )

              logger.info('Org  Response')
              logger.info(reportResponse)
              if (
                reportResponse &&
                reportResponse.entry &&
                reportResponse.entry.length > 0
              ) {
                for (let i = 0; i < reportResponse.entry.length; i++) {
                  reportsFiles.push(
                    reportResponse.entry[i].resource as R4.IDiagnosticReport
                  )
                }
              } else {
                reportResponse =
                  await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
                    '/DocumentReference?type=11502-2&status=current',
                    referredEncounter[0].appointment
                      ? referredEncounter[0].appointment[0].id ?? ''
                      : '',
                    searchParametersOld
                  )

                if (
                  reportResponse &&
                  reportResponse.entry &&
                  reportResponse.entry.length > 0
                ) {
                  for (let i = 0; i < reportResponse.entry.length; i++) {
                    docRefFiles.push(
                      reportResponse.entry[i].resource as R4.IDocumentReference
                    )
                  }
                }
              }
            }
          }
        }
      }

      let response: any =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
          '/DiagnosticReport?category:missing=true&based-on:missing=false&status=final',
          fhirAppointmentDetails.appointment.id!,
          searchParameters
        )

      const conditionResponseData: R4.IBundle = response

      if (
        conditionResponseData &&
        conditionResponseData.entry &&
        conditionResponseData.entry.length > 0
      ) {
        for (let i = 0; i < conditionResponseData.entry.length; i++) {
          reportsFiles.push(
            conditionResponseData.entry[i].resource as R4.IDiagnosticReport
          )
        }
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            '/DocumentReference?type=11502-2&status=current',
            fhirAppointmentDetails.appointment.id!
          )

        if (response && response.entry && response.entry.length > 0) {
          for (let i = 0; i < response.entry.length; i++) {
            docRefFiles.push(
              response.entry[i].resource as R4.IDocumentReference
            )
          }
        }
      }

      if (
        followUp &&
        getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0
      ) {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
            '/DiagnosticReport?category:missing=true&based-on:missing=false&status=final',
            getAppointmentIdForFollowUp(
              fhirAppointmentDetails.appointment.supportingInformation ?? []
            ),
            searchParameters
          )
      }

      const conditionResponse: R4.IBundle = response

      if (
        conditionResponse &&
        conditionResponse.entry &&
        conditionResponse.entry.length > 0
      ) {
        for (let i = 0; i < conditionResponse.entry.length; i++) {
          reportsFiles.push(
            conditionResponse.entry[i].resource as R4.IDiagnosticReport
          )
        }
      } else {
        response =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            '/DocumentReference?type=11502-2&status=current',
            fhirAppointmentDetails.appointment.id!,
            searchParameters
          )

        if (response && response.entry && response.entry.length > 0) {
          for (let i = 0; i < response.entry.length; i++) {
            docRefFiles.push(
              response.entry[i].resource as R4.IDocumentReference
            )
          }
        }
      }
      if (
        fhirAppointmentDetails.appointment.id &&
        fhirAppointmentDetails.encounter === undefined
      ) {
        const searchParametersNew: any = {
          _sort: '-_lastUpdated',
        }

        const docResponseList =
          await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
            `/DocumentReference?related=Appointment/${fhirAppointmentDetails.appointment.id}&type=11502-2&status=current`,
            fhirAppointmentDetails.appointment.id!,
            searchParametersNew
          )

        if (
          docResponseList &&
          docResponseList.entry &&
          docResponseList.entry.length > 0
        ) {
          for (let i = 0; i < docResponseList.entry.length; i++) {
            docRefFiles.push(
              docResponseList.entry[i].resource as R4.IDocumentReference
            )
          }
        }
      }

      if (reportsFiles.length === 0) {
        state.reports = docRefFiles
      } else {
        state.diagnosticReport = reportsFiles.sort((a, b) =>
          a.code &&
          a.code.coding &&
          a.code.coding[0]!.display! > b.code &&
          b.code.coding &&
          b.code.coding[0]!.display!
            ? 1
            : b.code &&
              b.code.coding &&
              b.code.coding[0]!.display! > a.code &&
              a.code.coding &&
              a.code.coding[0]!.display!
            ? -1
            : 0
        )
      }

      state.resultsAvailable = true
      state.fetchingImpressions = false

      state.noResultsAvailable = false
      state.errorReason = undefined
      state.errorWhileSearchingImpressions = false

      dispatch(reportsSearchSlice.actions.updatedStatus(state))
    } catch (error) {
      const errorSearchDoctor: ReportsSearchStatus = {
        ...initialState,
      }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(reportsSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

export async function getReportsForSocket(
  fhirAppointmentDetails: FhirAppointmentDetail,
  followUp?: boolean
): Promise<WelloReports> {
  const docRefFiles: R4.IDocumentReference[] = []
  let referredEncounter: R4.IEncounter[] = []

  const reportsFiles: R4.IDiagnosticReport[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  let searchParameters: any = {
    encounter: fhirAppointmentDetails.encounter
      ? fhirAppointmentDetails.encounter.id ?? ''
      : '',
    _sort: '-_lastUpdated',
  }

  if (
    followUp === undefined &&
    fhirAppointmentDetails.appointment.basedOn === undefined
  ) {
    searchParameters = {
      encounter: fhirAppointmentDetails.encounter
        ? fhirAppointmentDetails.encounter.id ?? ''
        : '',
    }
  }

  if (followUp === undefined && fhirAppointmentDetails.appointment.basedOn) {
    if (fhirAppointmentDetails.appointment.basedOn[0].reference) {
      const serviceRequestData =
        fhirAppointmentDetails.appointment.basedOn[0].reference.split('/')

      const newResponse =
        await fhirClient.doGetResourceForAppointmentWithIncludeIterate(
          `/ServiceRequest?_id=${serviceRequestData[1]}&_include=ServiceRequest:encounter`,
          ''
        )
      if (newResponse && newResponse.entry && newResponse.entry.length > 0) {
        referredEncounter = getEncounterFromBundle(newResponse as R4.IBundle)
        if (referredEncounter.length > 0) {
          const searchParametersOld: any = {
            encounter: referredEncounter[0].id ?? '',
            _sort: '-_lastUpdated',
          }
          let reportResponse: any

          reportResponse =
            await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
              '/DiagnosticReport?category:missing=true&based-on:missing=false&status=final',
              referredEncounter[0].appointment
                ? referredEncounter[0].appointment[0].id ?? ''
                : '',
              searchParametersOld
            )

          logger.info('Org  Response')
          logger.info(reportResponse)
          if (
            reportResponse &&
            reportResponse.entry &&
            reportResponse.entry.length > 0
          ) {
            for (let i = 0; i < reportResponse.entry.length; i++) {
              reportsFiles.push(
                reportResponse.entry[i].resource as R4.IDiagnosticReport
              )
            }
          } else {
            reportResponse =
              await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
                '/DocumentReference?type=11502-2&status=current',
                referredEncounter[0].appointment
                  ? referredEncounter[0].appointment[0].id ?? ''
                  : '',
                searchParametersOld
              )

            if (
              reportResponse &&
              reportResponse.entry &&
              reportResponse.entry.length > 0
            ) {
              for (let i = 0; i < reportResponse.entry.length; i++) {
                docRefFiles.push(
                  reportResponse.entry[i].resource as R4.IDocumentReference
                )
              }
            }
          }
        }
      }
    }
  }

  let response: any =
    await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
      '/DiagnosticReport?category:missing=true&based-on:missing=false&status=final',
      fhirAppointmentDetails.appointment.id!,
      searchParameters
    )

  if (
    followUp &&
    getAppointmentIdForFollowUp(
      fhirAppointmentDetails.appointment.supportingInformation ?? []
    ).length > 0
  ) {
    response =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformForReport(
        '/DiagnosticReport?category:missing=true&based-on:missing=false&status=final',
        getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ),
        searchParameters
      )
  }

  const conditionResponse: R4.IBundle = response

  if (
    conditionResponse &&
    conditionResponse.entry &&
    conditionResponse.entry.length > 0
  ) {
    for (let i = 0; i < conditionResponse.entry.length; i++) {
      reportsFiles.push(
        conditionResponse.entry[i].resource as R4.IDiagnosticReport
      )
    }
  } else {
    response =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
        '/DocumentReference?type=11502-2&status=current',
        fhirAppointmentDetails.appointment.id!,
        searchParameters
      )

    if (response && response.entry && response.entry.length > 0) {
      for (let i = 0; i < response.entry.length; i++) {
        docRefFiles.push(response.entry[i].resource as R4.IDocumentReference)
      }
    }
  }
  if (
    fhirAppointmentDetails.appointment.id &&
    fhirAppointmentDetails.encounter === undefined
  ) {
    const searchParametersNew: any = {
      _sort: '-_lastUpdated',
    }

    const docResponseList =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
        `/DocumentReference?related=Appointment/${fhirAppointmentDetails.appointment.id}&type=11502-2&status=current`,
        fhirAppointmentDetails.appointment.id!,
        searchParametersNew
      )

    if (
      docResponseList &&
      docResponseList.entry &&
      docResponseList.entry.length > 0
    ) {
      for (let i = 0; i < docResponseList.entry.length; i++) {
        docRefFiles.push(
          docResponseList.entry[i].resource as R4.IDocumentReference
        )
      }
    }
  }

  return {
    diagnosticReports: reportsFiles.sort((a, b) =>
      a.code &&
      a.code.coding &&
      a.code.coding[0]!.display! > b.code &&
      b.code.coding &&
      b.code.coding[0]!.display!
        ? 1
        : b.code &&
          b.code.coding &&
          b.code.coding[0]!.display! > a.code &&
          a.code.coding &&
          a.code.coding[0]!.display!
        ? -1
        : 0
    ),
    reportFiles: docRefFiles,
  }
}

export async function getReportsForSocketIPD(
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
): Promise<WelloReports> {
  const docRefFiles: R4.IDocumentReference[] = []

  const reportsFiles: R4.IDiagnosticReport[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _sort: '-_lastUpdated',
  }
  if (
    getPatientIdentifiersForSearch(fhirAppointmentDetails.patient).length > 0
  ) {
    searchParameters['patient:Patient.identifier'] =
      getPatientIdentifiersForSearch(fhirAppointmentDetails.patient)
  }
  const reportResponse =
    await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformIPD(
      '/DiagnosticReport?status=final',
      fhirAppointmentDetails.mainServiceRequest.id!,
      searchParameters
    )

  if (
    reportResponse &&
    reportResponse.entry &&
    reportResponse.entry.length > 0
  ) {
    for (let i = 0; i < reportResponse.entry.length; i++) {
      reportsFiles.push(
        reportResponse.entry[i].resource as R4.IDiagnosticReport
      )
    }
  }

  return {
    diagnosticReports: reportsFiles.sort((a, b) =>
      a.code &&
      a.code.coding &&
      a.code.coding[0]!.display! > b.code &&
      b.code.coding &&
      b.code.coding[0]!.display!
        ? 1
        : b.code &&
          b.code.coding &&
          b.code.coding[0]!.display! > a.code &&
          a.code.coding &&
          a.code.coding[0]!.display!
        ? -1
        : 0
    ),
    reportFiles: docRefFiles,
  }
}

export async function getReportsForSockeForDashboardIPD(
  fhirAppointmentDetails: FhirClinicIpdDetails
): Promise<FhirClinicIpdDetails> {
  const reportsFiles: R4.IDiagnosticReport[] = []
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    _sort: '-_lastUpdated',
  }
  if (
    getPatientIdentifiersForSearch(fhirAppointmentDetails.patient).length > 0
  ) {
    searchParameters['patient:Patient.identifier'] =
      getPatientIdentifiersForSearch(fhirAppointmentDetails.patient)
  }
  const reportResponse =
    await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatformIPD(
      '/DiagnosticReport?status=final',
      fhirAppointmentDetails.serviceRequest.id!,
      searchParameters
    )

  if (
    reportResponse &&
    reportResponse.entry &&
    reportResponse.entry.length > 0
  ) {
    for (let i = 0; i < reportResponse.entry.length; i++) {
      reportsFiles.push(
        reportResponse.entry[i].resource as R4.IDiagnosticReport
      )
    }
  }
  const finalResults: FhirClinicIpdDetails = {
    ...fhirAppointmentDetails,
  }
  if (reportsFiles.length > 0) {
    finalResults.diagnosticReport = reportsFiles.sort((a, b) =>
      a.code &&
      a.code.coding &&
      a.code.coding[0]!.display! > b.code &&
      b.code.coding &&
      b.code.coding[0]!.display!
        ? 1
        : b.code &&
          b.code.coding &&
          b.code.coding[0]!.display! > a.code &&
          a.code.coding &&
          a.code.coding[0]!.display!
        ? -1
        : 0
    )
  }
  return finalResults
}
export default reportsSearchSlice.reducer
