import { R4 } from '@ahryman40k/ts-fhir-types'
import { AxiosResponse, CancelToken } from 'axios'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import QueryString from 'qs'
import { getErrorResponse } from 'utils/apiClientHelper'
import { logger } from 'utils/logger'
import { ApiClient } from './apiService'
import { ApiClientWithoutAuth } from './apiServiceWithoututh'

export class DietEngineAPIClient extends ApiClient {
  constructor() {
    const finalUrl = `${process.env.REACT_APP_LLM_DIET_ENGINE_URL ?? ''}/`
    super(finalUrl)
  }

  public async doPost(
    path: string,
    body: any
  ): Promise<R4.IBundle | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        path,
        body,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.status === 201 || response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }

  public async doGetResource(
    path: string,
    searchParameters?: {}
  ): Promise<any | FHIRErrorResponses> {
    try {
      const response: AxiosResponse = await this.axiosInstance.get(path, {
        params: searchParameters,
      })

      if (response.status === 200) {
        return response.data
      }
      return getErrorResponse(response)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }
}
