import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors, NullType } from 'io-ts'
import { isNumber } from 'lodash'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import moment from 'moment'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { requestVitalDetailsOfPatient } from 'redux/consultation/vitalsDetailsSlice/vitalsDetailSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { fToC } from 'utils/appointment_handle/vitals_util'
import { getEncounterObjectForAppointment } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getVitalsObservationObject } from 'utils/fhirResoureHelpers/observationHelpers'
import { VitalAddStatus } from './addVitalStatusTypes'

const initialState: VitalAddStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addVitalsSlice = createSlice({
  name: 'addVitalsSlice',
  initialState,
  reducers: {
    updateAddVitalsStatus(state, action: PayloadAction<VitalAddStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.addedVitalsBundle = action.payload.addedVitalsBundle
    },

    resetAddVitalsDetails(state, action: PayloadAction<VitalAddStatus>) {
      state.adding = initialState.adding
      state.additionSuccessful = initialState.additionSuccessful
      state.error = initialState.error
      state.errorMessage = initialState.errorMessage
      state.addedVitalsBundle = initialState.addedVitalsBundle
    },
  },
})

export const addVitalDetails =
  (
    appointment: FhirAppointmentDetail,
    bodyTempInF?: number,
    pulseRate?: number,
    sysBloodPressure?: number,
    diaBloodPressure?: number,
    weight?: number,
    height?: number,
    rrRate?: number
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: VitalAddStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(addVitalsSlice.actions.updateAddVitalsStatus(addingState))

    try {
      const bundleObject: R4.IBundle = createBundleObjectForObservationsVitals(
        appointment,
        bodyTempInF,
        pulseRate,
        sysBloodPressure,
        diaBloodPressure,
        weight,
        height,
        rrRate
      )

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        bundleObject
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        if (relatedFhirDecodeRes.right) {
          addingState = {
            adding: false,
            additionSuccessful: true,
            error: false,
            errorMessage: '',
          }
          dispatch(
            requestVitalDetailsOfPatient(
              appointment.patient,
              appointment.appointment.id!,
              false
            )
          )
          dispatch(showSuccessAlert('Vitals Added Successfully'))
          dispatch(addVitalsSlice.actions.updateAddVitalsStatus(addingState))
        }
      } else {
        const errorCreatePersonState: VitalAddStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while creating patient',
        }
        dispatch(
          addVitalsSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: VitalAddStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding vitals',
      }
      dispatch(
        addVitalsSlice.actions.updateAddVitalsStatus(errorCreatePersonState)
      )
    }
  }

export const resetAddVitalsState = () => (dispatch: AppDispatch) => {
  dispatch(addVitalsSlice.actions.resetAddVitalsDetails(initialState))
}

export function createBundleObjectForObservationsVitals(
  appointment: FhirAppointmentDetail,
  bodyTemp?: number,
  pulseRate?: number,
  sysBloodPressure?: number,
  diaBloodPressure?: number,
  weight?: number,
  height?: number,
  rrRate?: number
): R4.IBundle {
  const encounter: R4.IEncounter = getEncounterObjectForAppointment(appointment)
  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }

  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
    type: encounter.resourceType,
  }

  if (bodyTemp != null && isNaN(bodyTemp) === false) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.meta = {
      profile: ['http://hl7.org/fhir/StructureDefinition/bodytemp'],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'vital-signs',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'A',
      coding: [
        {
          code: '8310-5',
          display: 'Body Temperature',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: fToC(bodyTemp),
      unit: 'C',
      system: 'http://unitsofmeasure.org',
      code: 'Cel',
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (pulseRate != null && isNaN(pulseRate) === false) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.meta = {
      profile: ['http://hl7.org/fhir/StructureDefinition/heartrate'],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'vital-signs',
          },
        ],
      },
    ]
    observationObject.code = {
      text: 'B',
      coding: [
        {
          code: '8867-4',
          display: 'Pulse Rate',
          system: 'http://loinc.org',
        },
      ],
    }

    observationObject.valueQuantity = {
      value: pulseRate,
      unit: 'bpm',
      system: 'http://unitsofmeasure.org',
      code: '/min',
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (height != null && isNaN(height) === false) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.meta = {
      profile: ['http://hl7.org/fhir/StructureDefinition/bodyheight'],
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'vital-signs',
          },
        ],
      },
    ]
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.code = {
      text: 'C',
      coding: [
        {
          code: '8302-2',
          display: 'Height',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: height,
      unit: 'cm',
      system: 'http://unitsofmeasure.org',
      code: 'cm',
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (weight != null && isNaN(weight) === false) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'vital-signs',
          },
        ],
      },
    ]
    observationObject.meta = {
      profile: ['http://hl7.org/fhir/StructureDefinition/bodyweight'],
    }
    observationObject.code = {
      text: 'D',
      coding: [
        {
          code: '29463-7',
          display: 'Weight',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: weight,
      unit: 'kg',
      system: 'http://unitsofmeasure.org',
      code: 'kg',
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (rrRate != null && isNaN(rrRate) === false) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'vital-signs',
          },
        ],
      },
    ]
    observationObject.meta = {
      profile: ['http://hl7.org/fhir/StructureDefinition/resprate'],
    }

    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.code = {
      text: 'E',
      coding: [
        {
          code: '9279-1',
          display: 'Respiration Rate',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: rrRate,
      unit: 'per min',
      system: 'http://unitsofmeasure.org',
      code: 'pm',
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (
    sysBloodPressure != null &&
    isNaN(sysBloodPressure) === false &&
    diaBloodPressure != null &&
    isNaN(diaBloodPressure) === false
  ) {
    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.category = [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'vital-signs',
          },
        ],
      },
    ]
    observationObject.meta = {
      profile: ['http://hl7.org/fhir/StructureDefinition/bp'],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.code = {
      text: 'F',
      coding: [
        {
          code: '85354-9',
          display: 'Blood Pressure',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.component = [
      {
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '8480-6',
              display: 'Systolic blood pressure',
            },
            {
              system: 'http://snomed.info/sct',
              code: '271649006',
              display: 'Systolic blood pressure',
            },
            {
              system: 'http://acme.org/devices/clinical-codes',
              code: 'bp-s',
              display: 'Systolic Blood pressure',
            },
          ],
        },
        valueQuantity: {
          value: sysBloodPressure,
          unit: 'mm[Hg]',
          system: 'http://unitsofmeasure.org',
          code: 'mm[Hg]',
        },
      },
      {
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '8462-4',
              display: 'Diastolic blood pressure',
            },
          ],
        },
        valueQuantity: {
          value: diaBloodPressure,
          unit: 'mm[Hg]',
          system: 'http://unitsofmeasure.org',
          code: 'mm[Hg]',
        },
      },
    ]

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  if (
    height != null &&
    isNaN(height) === false &&
    weight != null &&
    isNaN(weight) === false
  ) {
    const heightInMeter = height / 100

    const bmiData = (weight / (heightInMeter * heightInMeter)).toFixed(2)

    const observationObject: R4.IObservation = {
      ...getVitalsObservationObject(appointment, encounterRef),
    }
    observationObject.code = {
      text: 'G',
      coding: [
        {
          code: '39156-5',
          display: 'Body Mass Index',
          system: 'http://loinc.org',
        },
      ],
    }
    observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
    observationObject.effectiveDateTime = moment().format(
      'YYYY-MM-DDTHH:mm:ssZ'
    )
    observationObject.status = R4.ObservationStatusKind._final
    observationObject.valueQuantity = {
      value: parseFloat(bmiData),
      unit: 'kg/m2',
      system: 'http://unitsofmeasure.org',
      code: 'kg/m2',
    }

    const entry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: observationObject.resourceType,
      },
      resource: observationObject,
    }
    requestBundle.entry?.push(entry)
  }

  return requestBundle
}

export default addVitalsSlice.reducer
