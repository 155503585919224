import { R4 } from '@ahryman40k/ts-fhir-types'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { User } from 'models/user'
import { WelloOrgDetails } from 'models/welloUnit'
import { getUserRequestTransactionObjectForProfileUpdate } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserMainOrganizationDetails,
  getCurrentUserUnitDetails,
  getUserDetails,
  isOrgAdmin,
  isOrgUser,
  isUnitAdmin,
  updateUserDetailsFromServer,
} from 'services/userDetailsService'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePicker'
import { uploadFileDetails } from 'services/fileApiService'
import { CurrentLoggedInUser } from 'models/currentUserDetails'
import { sleep } from 'utils/dateUtil'

export function getPractitionersListFourUser(
  inputPractitionerList?: PractitionerWithRole[]
) {
  if (inputPractitionerList) {
    if (isOrgAdmin()) {
      const currentOrgId = getCurrentUserMainOrganizationDetails()?.id
      return inputPractitionerList.filter(
        (practitioner: PractitionerWithRole) =>
          practitioner.roleObject?.organization?.reference?.split('/')[1] ===
          currentOrgId
      )
    }
    return inputPractitionerList ?? []
  }

  return []
}

export async function UpdateProfileData(
  mapCenter: any,
  newUser: User,
  userDetails: R4.IPractitioner,
  roleObject: R4.IPractitionerRole,
  qualificationData?: R4.ICoding,
  regNo?: string,
  orgData?: WelloOrgDetails,
  spl?: R4.ICoding,
  languages?: R4.ICoding[],
  splArr?: R4.ICoding[],
  profilePic?: string,
  idProofType?: R4.ICoding,
  proofNumber?: string,
  file?: SelectedFile,
  frontFile?: SelectedFile,
  backFile?: SelectedFile
) {
  let resData: boolean = false
  try {
    if (userDetails) {
      const locationObj: R4.IBundle | undefined =
        getUserRequestTransactionObjectForProfileUpdate(
          mapCenter,
          newUser,
          userDetails,
          roleObject,
          qualificationData,
          regNo,
          orgData,
          spl,
          languages,
          splArr,
          profilePic,
          idProofType,
          proofNumber,
          file,
          frontFile,
          backFile
        )
      if (locationObj) {
        const fhirClient: FHIRApiClient = new FHIRApiClient()
        const response: any = await fhirClient.doCreateFHIRTransaction(
          '',
          locationObj
        )
        const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
          R4.RTTI_Bundle.decode(response)
        if (relatedFhirDecodeRes._tag === 'Right') {
          try {
            let logoAttachment: R4.IAttachment | undefined = {}
            if (file) {
              logoAttachment = {
                data: file.path as string,
                contentType: file.files ? file.files.type : file.type ?? '',
                title: file.name,
              }

              const organization: R4.IOrganization = getCurrentUserUnitDetails()
              if (logoAttachment) {
                await uploadFileDetails(
                  logoAttachment,
                  "Organization.extension('http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo').value.as(Attachment)",
                  'Organization',
                  organization.id ?? ''
                )
              }
            }

            if (frontFile && userDetails) {
              let frontFileAttachment: R4.IAttachment | undefined = {}
              frontFileAttachment = {
                data: frontFile.path as string,
                contentType: frontFile.files
                  ? frontFile.files.type
                  : frontFile.type ?? '',
                title: frontFile.name,
              }
              if (frontFileAttachment) {
                await uploadFileDetails(
                  frontFileAttachment,
                  "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)",
                  'Practitioner',
                  userDetails.id ?? ''
                )
              }
            }

            if (backFile && userDetails) {
              let backFileAttachment: R4.IAttachment | undefined = {}
              backFileAttachment = {
                data: backFile.path as string,
                contentType: backFile.files
                  ? backFile.files.type
                  : backFile.type ?? '',
                title: backFile.name,
              }
              if (backFileAttachment) {
                await uploadFileDetails(
                  backFileAttachment,
                  "Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)",
                  'Practitioner',
                  userDetails.id ?? ''
                )
              }
            }
          } catch (error) {
            //   dispatch(showErrorAlert('Patient created. Error in uploading proofs'))
          }
          const loggedInUser: CurrentLoggedInUser | undefined = getUserDetails()
          if (loggedInUser?.practitioner.id === userDetails.id) {
            const res: boolean = await updateUserDetailsFromServer()

            if (res) {
              resData = true
              await sleep(2000)
            }
          }

          return resData
        }
      }
    }
  } catch (error) {
    console.error(error)
  } finally {
  }
  return resData
}
