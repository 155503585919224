import { R4 } from '@ahryman40k/ts-fhir-types'
import MomentUtils from '@date-io/moment'
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  Grid,
  useTheme,
} from '@material-ui/core'
import Divider from '@mui/material/Divider'
import {
  DeleteOutlineOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Close,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  kBackgroundDefault,
  kBackgroundPaper,
  kDialogueBackground,
  kPrimaryMain,
} from 'configs/styles/muiThemes'
import {
  AssociatedComplaintData,
  ChiefComplaintData,
  ChiefComplaintTerminology,
} from 'models/chiefComplaintData'
import { ChiefComplaintsData } from 'models/chiefComplaintsData'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { LocationData } from 'models/location'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  ConfirmationDialogProps,
  ConfirmationDialog,
} from 'views/components/common/deleteConfirmationDialog'
import ReactVisibilitySensor from 'react-visibility-sensor'
import {
  getChiefComplaintOfAppointment,
  resetChiefComplaintDetails,
  updateChiefComplaintDetails,
} from 'redux/consultation/chiefComplaintSlice/chiefComplaintSlice'
import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { RootState } from 'redux/rootReducer'
import { durationData } from 'utils/constants'
import {
  getCodeValueForChiefComplaints,
  getStringDays,
  getStringMonth,
  getStringValueExtensionsOfUrl,
  getStringValueExtensionsOfUrlForDuration,
  getStringValueExtensionsOfUrlForDurationVal,
  getStringWeeks,
  getStringYear,
  getTextVal,
  getUnitAsString,
  getUnitAsStringForVal,
  getValueRefValueExtensionsOfUrl,
  titleCase,
} from 'utils/fhirResourcesHelper'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotes,
  hasNotesForChief,
  hasNotesForChiefForNote,
  hasNotesForChiefForPatient,
  hasNotesForChiefForPatientText,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { logger } from 'utils/logger'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { getObservationValueCodeableConceptDisplayByComponent } from 'utils/fhirResoureHelpers/observationHelpers'
import { getFinalAssociatedComplaintsData } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { AddChiefComplanits } from './addChiefComplaints'
import { EditChiefComplanits } from './editChiefComplaints'
import OverflowTypography from '../plan/ayurveda/overflowTypography'

interface ChiefComplaintProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
  date?: Date
}

export const ChiefComplaint: React.FC<ChiefComplaintProps> = ({
  fhirAppointmentDetails,
  split,
  date,
}: ChiefComplaintProps) => {
  const theme = useTheme()
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null)

  const [t] = useTranslation(['labelCommon', 'labelCondition'])
  const dispatch = useDispatch()

  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [chiefDataArrayData, setChiefDataArrayData] = useState<
    ChiefComplaintsData[]
  >([])

  const [assiChiefDataArrayData, setAssiChiefDataArrayData] = useState<
    ChiefComplaintsData[]
  >([])
  const [selectedSeverity, setSeverity] = useState<R4.ICoding>()
  const [selectedLocation, setLocations] = useState<R4.ICoding[]>([])
  const chiefComplaintSlice = useSelector(
    (state: RootState) => state.chiefComplaintSlice
  )
  const [duration, setDuration] = React.useState<string>('')
  const [character, setCharacter] = React.useState<string>('')
  const [alleviating, setAlleviating] = React.useState<string>('')
  const [radiation, setRadiation] = React.useState<string>('')
  const [temporal, setTemporal] = React.useState<string>('')
  const [lastUpdated, setLastUpdated] = React.useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const [addChiefComplaints, setAddChiefComplaints] = useState<boolean>(false)
  const [editChiefComplaints, setEditChiefComplaints] = useState<boolean>(false)
  const [chiefComplaintsDetails, setChiefComplaintsDetails] =
    React.useState<ChiefComplaintsData>()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()
  const [showNotesText, setShowNotesText] = React.useState<boolean>(false)
  const [showAssociated, setShowAssociated] = React.useState<boolean>(false)

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  useEffect(() => {
    dispatch(
      getChiefComplaintOfAppointment(
        fhirAppointmentDetails.patient,
        fhirAppointmentDetails.encounter!,
        split,
        undefined,
        date
      )
    )
  }, [dispatch, fhirAppointmentDetails])
  useEffect(() => {
    if (chiefComplaintSlice.updatedConditions) {
      dispatch(resetChiefComplaintDetails)
      dispatch(
        getChiefComplaintOfAppointment(
          fhirAppointmentDetails.patient,
          fhirAppointmentDetails.encounter!,
          split,
          undefined,
          date
        )
      )
    }
  }, [dispatch, chiefComplaintSlice, fhirAppointmentDetails])
  useEffect(() => {
    if (
      chiefComplaintSlice.resultsAvailable &&
      chiefComplaintSlice.chiefCondition
    ) {
      setChiefDataArrayData([])
      updateChiefComplaint(
        chiefComplaintSlice.chiefCondition,
        chiefComplaintSlice.associatedCondition ?? [],
        chiefComplaintSlice.conditionsBasedObservations
      )
    }
    if (chiefComplaintSlice.noResultsAvailable) {
      setChiefDataArrayData([])
      setAssiChiefDataArrayData([])
    }
  }, [chiefComplaintSlice])

  const updateChiefComplaint = (
    condition: R4.ICondition[],
    associated: R4.ICondition[],
    conditionWiseCategorizedObservations?: { [key: string]: R4.IObservation[] }
  ) => {
    const chiefDataArray: ChiefComplaintsData[] = []

    for (let i = 0; i < condition.length; i++) {
      const assichiefDataArray: AssociatedComplaintData[] = []
      const locationArr: LocationData[] = []
      const evidenceArr: ChiefComplaintData[] = []
      const conditionData: R4.ICondition = condition[i]
      let locationData: R4.ICoding[] = []
      let evidenceData: R4.ICoding[] = []

      if (
        conditionData.bodySite?.length &&
        conditionData.bodySite?.length > 0
      ) {
        const location: R4.ICoding[] = conditionData.bodySite?.map(
          (val) => val.coding?.[0] ?? {}
        )
        locationData = location

        setLocations(location)
      }

      if (conditionData.evidence && conditionData.evidence.length > 0) {
        if (
          conditionData.evidence[0].code &&
          conditionData.evidence[0].code.length > 0
        ) {
          const eviArr: R4.ICoding[] = []
          for (let m = 0; m < conditionData.evidence.length; m++) {
            const evi: R4.ICoding[] | undefined = conditionData.evidence[
              m
            ].code?.map((val) => val.coding?.[0] ?? {})

            if (evi) {
              eviArr.push(evi[0])
            }

            evidenceData = eviArr
          }
        }
      }

      if (associated.length > 0) {
        const filteredData = associated.filter(
          (d) =>
            getValueRefValueExtensionsOfUrl(
              d.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-chief-complaint-ext'
            )?.split('/')[1]! === conditionData.id!
        )

        if (filteredData.length > 0) {
          for (let j = 0; j < filteredData.length; j++) {
            const conditionDataAssociated: R4.ICondition = filteredData[j]
            if (conditionDataAssociated.id !== undefined) {
              const additional: AssociatedComplaintData = {
                id: 0,
                typeData: conditionDataAssociated.onsetAge
                  ? getUnitAsStringForVal(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    )
                  : conditionDataAssociated.onsetDateTime
                  ? 'custom'
                  : '',
                cui: conditionDataAssociated.code?.coding?.[0],
                preselectedAssociated:
                  conditionDataAssociated.code as ChiefComplaintTerminology,
                days: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Days'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                weeks: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Weeks'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                months: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Months'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                year: conditionDataAssociated.onsetAge
                  ? getUnitAsString(
                      conditionDataAssociated.onsetAge.unit ?? ''
                    ) === 'Years'
                    ? conditionDataAssociated.onsetAge.value ?? 0
                    : 0
                  : 0,
                date: conditionDataAssociated.onsetDateTime
                  ? moment(conditionDataAssociated.onsetDateTime).toDate()
                  : new Date(),
                preselectedId: conditionDataAssociated.id ?? '',
                patientWording: conditionDataAssociated.note
                  ? hasNotesForChiefForPatientText(
                      conditionDataAssociated.note!
                    ).trim()
                  : '',
                anyOther: false,
                anyOtherData: '',
                checked: true,
              }

              assichiefDataArray.push(additional)
            }
          }
        }
      }
      if (evidenceData.length > 0) {
        for (let j = 0; j < evidenceData.length; j++) {
          const evi = {
            cui: evidenceData[j].code ?? '',

            type: '',
            icd: titleCase(evidenceData[j].display ?? ''),
            synonyms: titleCase(evidenceData[j].display ?? ''),
          }
          evidenceArr.push(evi)
        }
      }

      if (locationData.length > 0) {
        for (let j = 0; j < locationData.length; j++) {
          const loc = {
            cui: '',
            snomed_ct: '',
            display: locationData[j].display ?? '',
          }
          locationArr.push(loc)
        }
      }

      if (conditionData.severity) {
        setSeverity(conditionData.severity.coding?.[0])
      }
      if (conditionData.onsetDateTime) {
        setSelectedDate(moment(conditionData.onsetDateTime).toDate())
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
        )?.trim()
      ) {
        setAlleviating(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
          )!.trim()
        )
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
        )
      ) {
        setCharacter(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
          )!.trim()
        )
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
        )
      ) {
        setRadiation(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
          )!.trim()
        )
      }
      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
        )
      ) {
        setTemporal(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
          )!.trim()
        )
      }

      if (
        getStringValueExtensionsOfUrl(
          conditionData.extension,
          'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
        )
      ) {
        setDuration(
          getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
          )!.trim()
        )
      }
      if (conditionData.meta) {
        setLastUpdated(
          moment(conditionData.meta.lastUpdated ?? '').format('Do MMMM, YYYY')
        )
      }
      const cat: string =
        conditionData.category && conditionData.category[0].coding
          ? conditionData.category[0].coding[0].code ?? ''
          : ''
      let doshaObservation: R4.IObservation | undefined
      let dushyaObservation: R4.IObservation | undefined
      let malaObservation: R4.IObservation | undefined

      if (
        conditionWiseCategorizedObservations &&
        conditionWiseCategorizedObservations[conditionData.id!]
      ) {
        malaObservation = conditionWiseCategorizedObservations[
          conditionData.id!
        ].find(
          (observation) => observation.code?.coding?.[0].code === 'ITA-2.4.1'
        )
        doshaObservation = conditionWiseCategorizedObservations[
          conditionData.id!
        ].find(
          (observation) => observation.code?.coding?.[0].code === 'ITA-2.1.2'
        )
        dushyaObservation = conditionWiseCategorizedObservations[
          conditionData.id!
        ].find(
          (observation) => observation.code?.coding?.[0].code === 'ITA-2.2.2'
        )
      }
      if (cat !== 'associated-complaint') {
        chiefDataArray.push({
          preselectedComplaints:
            conditionData.code! as ChiefComplaintTerminology,
          preselectedSymptoms:
            conditionData.evidence && conditionData.evidence.length > 0
              ? conditionData.evidence[0].code!
              : [],
          preSelectedLocation: locationArr,
          preSelectedDuration: {
            cui:
              getStringValueExtensionsOfUrl(
                conditionData.extension,
                'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
              ) ?? ''.length > 0
                ? getCodeValueForChiefComplaints(
                    conditionData.extension,
                    'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
                  ) ?? ''
                : '',
            snomed_ct: 'http://unitsofmeasure.org',
            display:
              getStringValueExtensionsOfUrl(
                conditionData.extension,
                'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
              ) ?? '',
            label:
              getStringValueExtensionsOfUrl(
                conditionData.extension,
                'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
              ) ?? '',
          },
          id: conditionData.id ?? '',
          selectedCondition: conditionData.code as ChiefComplaintTerminology,
          selectedSeverity: conditionData.severity
            ? conditionData.severity.coding?.[0]
            : undefined,
          selectedLocation: locationData,
          duration:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            ) ?? ''.trim(),
          character: getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
          )!.trim(),
          alleviating: getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
          )!.trim(),
          radiation: getStringValueExtensionsOfUrl(
            conditionData.extension,
            'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
          )!,
          temporal:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
            ) ?? ''.trim(),
          lastUpdated: conditionData.meta
            ? moment(conditionData.meta.lastUpdated ?? '').format(
                'Do MMMM YYYY hh:mm A'
              )
            : '',
          selectedDate: conditionData.onsetDateTime
            ? moment(conditionData.onsetDateTime).toDate()
            : null,
          itemNumber: i + 1,
          notes: hasNotesForChiefForNote(conditionData.note!),
          patientFeeling: hasNotesForChiefForPatient(conditionData.note!),
          category:
            conditionData.category && conditionData.category[0].coding
              ? conditionData.category[0].coding[0].code ?? ''
              : '',
          evidenceData:
            conditionData.evidence && conditionData.evidence.length > 0
              ? conditionData.evidence[0].code!
              : [],
          selectedEvidence: evidenceData,
          preselectedVal: conditionData.onsetAge
            ? conditionData.onsetAge.value ?? 0
            : 0,
          preSelectedUnitType: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '')
            : conditionData.onsetDateTime
            ? 'custom'
            : '',
          unitValForDays: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Days'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitValForMonth: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Months'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitValForYear: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Years'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitValForWeek: conditionData.onsetAge
            ? getUnitAsString(conditionData.onsetAge.unit ?? '') === 'Weeks'
              ? conditionData.onsetAge.value ?? 0
              : 0
            : 0,
          unitTypeDays: conditionData.onsetAge
            ? getUnitAsStringForVal(conditionData.onsetAge.unit ?? '')
            : conditionData.onsetDateTime
            ? 'custom'
            : '',
          associatedCondition: assichiefDataArray,
          painChecked:
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/AllevatingFactor'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Character'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Radiation'
            )!.length > 0 ||
            getStringValueExtensionsOfUrl(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Temporal'
            )!.length > 0 ||
            evidenceArr.length > 0,
          patientWording: conditionData.note
            ? hasNotesForChiefForPatientText(conditionData.note!).trim()
            : '',
          associatedChecked: assichiefDataArray.length > 0,
          painDuration: parseInt(
            getStringValueExtensionsOfUrlForDurationVal(
              conditionData.extension,
              'http://hl7.org/fhir/uv/consultation-chief-complaint/StructureDefinition/Duration'
            ) ?? '1',
            10
          ),
          notesData: hasNotesForChiefForNote(conditionData.note!)
            ? hasNotesForChiefForNote(conditionData.note!)?.text ?? ''.trim()
            : '',
          collapsed: true,
          malaObservation,
          doshaObservation,
          dushyaObservation,
        })

        console.log(
          '----------------chiefDataArray------------------',
          chiefDataArray
        )
      }
      setChiefDataArrayData(chiefDataArray)
      //   setAssiChiefDataArrayData(assichiefDataArray)
    }
  }

  function handleCollapse(rate: boolean, index: number) {
    const values: ChiefComplaintsData[] = [...chiefDataArrayData]
    values[index].collapsed = rate
    setChiefDataArrayData(values)
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box display='flex' flexDirection='column' width='100%'>
        <Box display='flex' width='100%' flexDirection='row' py={0.5}>
          <ReactVisibilitySensor
            offset={{
              top: 100,
              bottom: 30,
            }}
            onChange={(isVisible) => {
              if (isVisible) {
                dispatch(
                  setSelectedSection({ selectedSection: 'chief_complaint' })
                )
              }
            }}
          >
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              id='chief_complaint'
            >
              <Box display='flex' width='100%' flexGrow flexDirection='row'>
                <Box display='flex' flexDirection='column'>
                  <Box py={1.5}>
                    <Typography variant='subtitle1'>
                      {' '}
                      {/* {t('labelCondition:attr_labels.Condition.code')}{' '} */}
                      {t('labelCommon:Chief Complaint')}
                    </Typography>
                  </Box>
                  {split === false && chiefDataArrayData.length > 0 && (
                    <Box
                      display='flex'
                      flexGrow
                      flexDirection='row'
                      justifyContent='flex-start'
                    >
                      {chiefComplaintSlice.updatingConditions === false && (
                        <Tooltip title='' id='chief_add'>
                          <IconButton
                            aria-label='btn_ord_cancel'
                            color='primary'
                            id='chief_add_button'
                            disabled={chiefComplaintSlice.updatingConditions}
                            onClick={() => {
                              setAddChiefComplaints(true)
                            }}
                            style={{ padding: 0 }}
                          >
                            <Typography
                              variant='subtitle2'
                              id='chief_add_label'
                              color={
                                chiefComplaintSlice.updatingConditions
                                  ? 'initial'
                                  : 'primary'
                              }
                              style={{ fontSize: 13 }}
                              component={Link}
                            >
                              {' '}
                              {t(
                                'labelCondition:attr_labels.Condition.code'
                              )}{' '}
                            </Typography>
                            <AddCircleIcon
                              id='chief_add_icon'
                              style={{ height: '22px', padding: 0 }}
                              color={
                                chiefComplaintSlice.updatingConditions
                                  ? 'disabled'
                                  : 'primary'
                              }
                            />{' '}
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                  <Box>
                    {(chiefComplaintSlice.updatingConditions ||
                      chiefComplaintSlice.searchingConditions) && (
                      <CircularProgress size={15} />
                    )}
                  </Box>
                </Box>
              </Box>
              {chiefComplaintSlice.noResultsAvailable && (
                <Box>
                  {chiefDataArrayData.length === 0 && split === false && (
                    <Box
                      display='flex'
                      width='100%'
                      flexDirection='row'
                      paddingBottom={1}
                    >
                      <Tooltip title='' id='chief_tool'>
                        <IconButton
                          aria-label='btn_ord_cancel'
                          id='chief_tool_button'
                          color='primary'
                          style={{ padding: 0 }}
                          onClick={() => {
                            setAddChiefComplaints(true)
                          }}
                        >
                          <Typography
                            variant='subtitle2'
                            color='primary'
                            component={Link}
                          >
                            {' '}
                            {t(
                              'labelCondition:attr_labels.Condition.code'
                            )}{' '}
                          </Typography>
                          <AddCircleIcon
                            id='chief_tool_icon'
                            style={{ height: '22px', padding: 0 }}
                            color='primary'
                          />{' '}
                        </IconButton>
                      </Tooltip>

                      {/* <Box py={1}>
                        <Typography
                          variant='subtitle2'
                          color='primary'
                          component={Link}
                        >
                          {' '}
                          Chief Complaints{' '}
                        </Typography>
                      </Box> */}
                    </Box>
                  )}
                  {split === true && (
                    <Typography
                      variant='subtitle2'
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      No data available
                    </Typography>
                  )}
                </Box>
              )}
              {chiefComplaintSlice.resultsAvailable &&
                chiefComplaintSlice.chiefCondition && (
                  <Box>
                    {chiefDataArrayData.map((val, index: number) => (
                      <Box key={`med_cond${val.id}`} width='100%' py={1}>
                        <Box>
                          <Box
                            paddingX={1}
                            borderRadius={2}
                            style={{
                              backgroundColor: 'lightGrey',
                            }}
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            height={40}
                          >
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Recorded on {val.lastUpdated}
                              </Typography>
                            </Box>
                            <Box
                              justifyContent='flex-end'
                              display='flex'
                              flexGrow={1}
                              alignItems='center'
                            >
                              {!split && (
                                <Box px={1}>
                                  <Tooltip title='Edit' id='chief_tool_edit'>
                                    <IconButton
                                      aria-label='btn_ord_cancel'
                                      color='primary'
                                      id='chief_tool_edit_button'
                                      style={{ padding: 0 }}
                                      onClick={() => {
                                        setEditChiefComplaints(true)
                                        setChiefComplaintsDetails(val)
                                      }}
                                    >
                                      <img
                                        id='chief_tool_edit_img'
                                        src={`${process.env.PUBLIC_URL}/editVector.png`}
                                        alt='Edit'
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}
                              {!split && (
                                <Box display='flex' justifyContent='center'>
                                  <Tooltip
                                    title='Delete'
                                    id='chief_tool_del_img'
                                  >
                                    <Close
                                      fontSize='medium'
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      id='chief_tool_del_ico'
                                      color='primary'
                                      onClick={() => {
                                        setShowDeleteConfirmation({
                                          open: true,
                                          onContinueActionClicked: () => {
                                            setShowDeleteConfirmation({
                                              open: false,
                                            })

                                            const obss = []
                                            if (val.malaObservation) {
                                              obss.push(val.malaObservation)
                                            }
                                            if (val.doshaObservation) {
                                              obss.push(val.doshaObservation)
                                            }
                                            if (val.dushyaObservation) {
                                              obss.push(val.dushyaObservation)
                                            }

                                            dispatch(
                                              updateChiefComplaintDetails(
                                                fhirAppointmentDetails.encounter!,
                                                fhirAppointmentDetails.patient,
                                                val.selectedCondition as ChiefComplaintTerminology,
                                                '',
                                                0,
                                                0,
                                                0,
                                                0,
                                                'delete',
                                                val.id,
                                                false,
                                                '',
                                                val.selectedLocation,
                                                val.selectedDate != null
                                                  ? val.selectedDate
                                                  : new Date(),
                                                val.selectedSeverity,
                                                val.duration,
                                                val.character,
                                                val.alleviating,
                                                val.radiation,
                                                val.temporal,
                                                '',
                                                '',
                                                false,
                                                val.associatedCondition ?? [],
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                obss
                                              )
                                            )
                                            if (
                                              chiefComplaintSlice.updatedConditions
                                            ) {
                                              dispatch(
                                                resetChiefComplaintDetails
                                              )
                                              dispatch(
                                                getChiefComplaintOfAppointment(
                                                  fhirAppointmentDetails.patient,
                                                  fhirAppointmentDetails.encounter!,
                                                  split
                                                )
                                              )
                                            }
                                          },
                                          onCancelActionClicked: () => {
                                            setShowDeleteConfirmation({
                                              open: false,
                                            })
                                          },
                                          displayText:
                                            'Are you sure you want to delete this record?',
                                          continueActionButtonText: 'Delete',
                                          cancelActionButtonText: 'Cancel',
                                        })
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              )}

                              <Box px={1}>
                                <Tooltip title='' id='chief_tool_del_img'>
                                  <IconButton
                                    aria-label='collapse_order_type'
                                    size='small'
                                    onClick={() => {
                                      handleCollapse(!val.collapsed, index)
                                    }}
                                  >
                                    {val.collapsed && <ArrowDropUpOutlined />}
                                    {!val.collapsed && (
                                      <ArrowDropDownOutlined />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                          <Collapse
                            in={val.collapsed}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Box
                              border={2}
                              style={{
                                boxShadow: '0px 0px 8px #ccc',
                                margin: '8px 0px',
                                borderRadius: '8px',
                                borderColor: '#F8F8F8',
                                width: '100%',
                                height: 'auto',
                                backgroundColor: '#F8F8F8',
                              }}
                            >
                              <Grid container>
                                <Grid item xs={3}>
                                  <Box
                                    display='flex'
                                    flexDirection='Column'
                                    width='100%'
                                    style={{
                                      backgroundColor: 'white',
                                    }}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Chief Complaints
                                          </Typography>
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={2.5}
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            // color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,
                                              color: 'black',

                                              fontWeight: 'bold',
                                              lineHeight: 'normal',
                                            }}
                                          >
                                            Mapped Symptoms
                                          </Typography>
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        {getFinalAssociatedComplaintsData(
                                          val.associatedCondition ?? []
                                        ).length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            {getFinalAssociatedComplaintsData(
                                              val.associatedCondition ?? []
                                            ).map(
                                              (
                                                valCondition: AssociatedComplaintData,
                                                index1: number
                                              ) => (
                                                <Box
                                                  key={`med_cond${val.id}`}
                                                  width='100%'
                                                >
                                                  <Box
                                                    justifyContent='flex-start'
                                                    display='flex'
                                                    flexGrow
                                                    height={30}
                                                    alignItems='center'
                                                    px={1}
                                                  >
                                                    <Typography
                                                      variant='subtitle2'
                                                      // color='initial'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,
                                                        color: 'black',

                                                        fontWeight: 'bold',
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      Associated Complaints
                                                    </Typography>
                                                  </Box>

                                                  <Divider
                                                    orientation='horizontal'
                                                    flexItem
                                                    style={{
                                                      height: '2px',
                                                    }}
                                                  />
                                                  <Box
                                                    justifyContent='flex-start'
                                                    display='flex'
                                                    flexGrow
                                                    height={30}
                                                    alignItems='center'
                                                    px={2.5}
                                                    style={{
                                                      backgroundColor:
                                                        kDialogueBackground,
                                                    }}
                                                  >
                                                    <Typography
                                                      variant='subtitle2'
                                                      // color='initial'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,
                                                        color: 'black',

                                                        fontWeight: 'bold',
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      Mapped Symptoms
                                                    </Typography>
                                                  </Box>

                                                  <Divider
                                                    orientation='horizontal'
                                                    flexItem
                                                    style={{
                                                      height: '2px',
                                                    }}
                                                  />
                                                </Box>
                                              )
                                            )}
                                          </Box>
                                        )}

                                        {val.selectedLocation &&
                                          val.selectedLocation.length > 0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  // color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,
                                                    color: 'black',

                                                    fontWeight: 'bold',
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Pain Profile : Pain Area ?
                                                </Typography>
                                              </Box>

                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            </Box>
                                          )}
                                        {val.character.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  kDialogueBackground,
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Describe what the patient is
                                                experiencing?
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.alleviating.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              alignItems='center'
                                              height={40}
                                              px={1}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Alleviating/Aggravating factors:
                                                What makes it better and worse?
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}

                                        {val.radiation.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              alignItems='center'
                                              height={40}
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  kDialogueBackground,
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {t('labelCommon:radiations')}
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}

                                        {val.evidenceData &&
                                          val.evidenceData.length > 0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                flexGrow
                                                alignItems='center'
                                                minHeight={30}
                                                px={1}
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  // color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,
                                                    color: 'black',

                                                    fontWeight: 'bold',
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Associated Symptoms
                                                </Typography>
                                              </Box>

                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            </Box>
                                          )}
                                        {val.temporal.length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={40}
                                              alignItems='center'
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  val.evidenceData &&
                                                  val.evidenceData.length > 0
                                                    ? kDialogueBackground
                                                    : 'white',
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {t('labelCommon:pattern')}
                                              </Typography>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.selectedSeverity && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                            style={{
                                              backgroundColor:
                                                val.temporal.length > 0 &&
                                                val.notes === undefined
                                                  ? val.evidenceData &&
                                                    val.evidenceData.length > 0
                                                    ? 'white'
                                                    : kDialogueBackground
                                                  : 'white',
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {t(
                                                  'labelCondition:attr_labels.Condition.severity'
                                                )}{' '}
                                              </Typography>
                                            </Box>
                                            {val.notes && (
                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            )}
                                          </Box>
                                        )}
                                        {val.notes && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexGrow
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                              style={{
                                                backgroundColor:
                                                  kDialogueBackground,
                                              }}
                                            >
                                              <Typography
                                                variant='subtitle2'
                                                // color='initial'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,
                                                  color: 'black',

                                                  fontWeight: 'bold',
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                Additional Notes
                                              </Typography>
                                            </Box>

                                            {/* <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          /> */}
                                          </Box>
                                        )}
                                      </Box>

                                      <Box
                                        display='flex'
                                        flexGrow={1}
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: 'auto',
                                            color: 'red',
                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid
                                  item
                                  xs={9}
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                >
                                  <Box
                                    display='flex'
                                    flexDirection='Column'
                                    width='100%'
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        {/*     Main Start  */}
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {val.patientFeeling &&
                                            val.patientFeeling.text &&
                                            val.patientFeeling.text.length >
                                              0 && (
                                              <OverflowTypography
                                                text={
                                                  val.patientFeeling.text ?? ''
                                                }
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                  id: 'patientFeeling',
                                                }}
                                              />
                                            )}
                                          {/* {val.patientFeeling &&
                                            val.patientFeeling.text &&
                                            val.patientFeeling.text.length >
                                              0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                style={{
                                                  paddingLeft: 2,
                                                  cursor: 'pointer',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                }}
                                                //   component=''
                                                onClick={() => {
                                                  if (
                                                    val.patientFeeling!.text ??
                                                    ''.length > 130
                                                  ) {
                                                    setFullText(
                                                      val.patientFeeling!
                                                        .text ?? ''
                                                    )
                                                    setShowText(true)
                                                  }
                                                }}
                                              >
                                                {val.patientFeeling.text.slice(
                                                  0,4
                                                  130
                                                )}
                                                {val.patientFeeling.text
                                                  .length > 130
                                                  ? '...'
                                                  : null}
                                              </Typography>
                                            )} */}
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        {/*     Main end  */}

                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexGrow
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            width='40%'
                                          >
                                            {val.selectedCondition &&
                                              val.selectedCondition.text && (
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                  flexGrow
                                                  overflow='auto'
                                                >
                                                  <Box>
                                                    <Typography
                                                      variant='subtitle2'
                                                      color='initial'
                                                      id='mainSymp'
                                                      style={{
                                                        fontFamily: 'Open Sans',
                                                        fontSize: 12,

                                                        fontWeight: 600,
                                                        lineHeight: 'normal',
                                                      }}
                                                    >
                                                      {titleCase(
                                                        val.selectedCondition
                                                          .text ?? ''
                                                      )}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              )}
                                          </Box>
                                          <Box display='flex' width='2%'>
                                            <Divider
                                              orientation='vertical'
                                              flexItem
                                              style={{
                                                height: '30px',

                                                width: '2px',
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            width='29%'
                                          >
                                            <Typography
                                              variant='subtitle2'
                                              //   color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Duration / Start Date
                                            </Typography>
                                          </Box>
                                          <Box display='flex' width='2%'>
                                            <Divider
                                              orientation='vertical'
                                              flexItem
                                              style={{
                                                height: '30px',

                                                width: '2px',
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            width='27%'
                                          >
                                            {val.selectedDate && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                id='duration_date'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {moment(
                                                  val.selectedDate
                                                ).format('Do MMMM, YYYY')}
                                              </Typography>
                                            )}
                                            {val.unitValForDays > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                id='duration_days'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForDays}{' '}
                                                {getStringDays(
                                                  val.unitValForDays
                                                )}
                                              </Typography>
                                            )}
                                            {val.unitValForWeek > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                id='duration_week'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForWeek}{' '}
                                                {getStringWeeks(
                                                  val.unitValForWeek
                                                )}
                                              </Typography>
                                            )}
                                            {val.unitValForMonth > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                id='duration_month'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForMonth}{' '}
                                                {getStringMonth(
                                                  val.unitValForMonth
                                                )}
                                              </Typography>
                                            )}
                                            {val.unitValForYear > 0 && (
                                              <Typography
                                                variant='subtitle2'
                                                color='initial'
                                                id='duration_year'
                                                style={{
                                                  fontFamily: 'Open Sans',
                                                  fontSize: 12,

                                                  fontWeight: 600,
                                                  lineHeight: 'normal',
                                                }}
                                              >
                                                {val.unitValForYear}{' '}
                                                {getStringYear(
                                                  val.unitValForYear
                                                )}
                                              </Typography>
                                            )}
                                          </Box>
                                        </Box>

                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />

                                        {/*     Associated Start  */}
                                        {getFinalAssociatedComplaintsData(
                                          val.associatedCondition ?? []
                                        ).length > 0 && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                          >
                                            {getFinalAssociatedComplaintsData(
                                              val.associatedCondition ?? []
                                            ).map(
                                              (
                                                valCondition,
                                                index1: number
                                              ) => (
                                                <Box
                                                  key={`med_cond${val.id}`}
                                                  width='100%'
                                                >
                                                  <Box
                                                    justifyContent='flex-start'
                                                    display='flex'
                                                    height={30}
                                                    alignItems='center'
                                                    px={1}
                                                  >
                                                    {valCondition.patientWording &&
                                                      valCondition
                                                        .patientWording.length >
                                                        0 && (
                                                        <OverflowTypography
                                                          text={
                                                            valCondition.patientWording ??
                                                            ''
                                                          }
                                                          typographyProps={{
                                                            variant:
                                                              'subtitle2',
                                                            color: 'initial',
                                                            id: `associated${index1}`,
                                                          }}
                                                        />
                                                      )}
                                                  </Box>

                                                  <Divider
                                                    orientation='horizontal'
                                                    flexItem
                                                    style={{
                                                      height: '2px',
                                                    }}
                                                  />
                                                  <Box
                                                    display='flex'
                                                    flexDirection='row'
                                                    width='100%'
                                                    style={{
                                                      backgroundColor:
                                                        kDialogueBackground,
                                                    }}
                                                  >
                                                    <Box
                                                      justifyContent='flex-start'
                                                      display='flex'
                                                      height={30}
                                                      alignItems='center'
                                                      px={1}
                                                      width='40%'
                                                    >
                                                      {valCondition.cui &&
                                                        valCondition.cui
                                                          .display && (
                                                          <Box
                                                            display='flex'
                                                            flexDirection='column'
                                                            width='100%'
                                                            flexGrow
                                                            overflow='auto'
                                                          >
                                                            <Box>
                                                              <Typography
                                                                variant='subtitle2'
                                                                color='initial'
                                                                id={`associated_condition${index1}`}
                                                                style={{
                                                                  fontFamily:
                                                                    'Open Sans',
                                                                  fontSize: 12,

                                                                  fontWeight: 600,
                                                                  lineHeight:
                                                                    'normal',
                                                                }}
                                                              >
                                                                {titleCase(
                                                                  valCondition
                                                                    .cui.display
                                                                )}
                                                              </Typography>
                                                            </Box>
                                                          </Box>
                                                        )}
                                                    </Box>
                                                    <Box
                                                      display='flex'
                                                      width='2%'
                                                    >
                                                      <Divider
                                                        orientation='vertical'
                                                        flexItem
                                                        style={{
                                                          height: '30px',

                                                          width: '2px',
                                                        }}
                                                      />
                                                    </Box>
                                                    <Box
                                                      justifyContent='flex-start'
                                                      display='flex'
                                                      height={30}
                                                      alignItems='center'
                                                      px={1}
                                                      width='29%'
                                                    >
                                                      <Typography
                                                        variant='subtitle2'
                                                        //   color='initial'
                                                        style={{
                                                          fontFamily:
                                                            'Open Sans',
                                                          fontSize: 12,
                                                          color: 'black',
                                                          fontWeight: 'bold',
                                                          lineHeight: 'normal',
                                                        }}
                                                      >
                                                        Duration / Start Date
                                                      </Typography>
                                                    </Box>
                                                    <Box
                                                      display='flex'
                                                      width='2%'
                                                    >
                                                      <Divider
                                                        orientation='vertical'
                                                        flexItem
                                                        style={{
                                                          height: '30px',

                                                          width: '2px',
                                                        }}
                                                      />
                                                    </Box>
                                                    <Box
                                                      justifyContent='flex-start'
                                                      display='flex'
                                                      height={30}
                                                      alignItems='center'
                                                      px={1}
                                                      width='27%'
                                                    >
                                                      {valCondition.cui &&
                                                        valCondition.typeData ===
                                                          'custom' &&
                                                        valCondition.date && (
                                                          <Typography
                                                            variant='subtitle2'
                                                            color='initial'
                                                            id={`associated_condition_date${index1}`}
                                                            style={{
                                                              fontFamily:
                                                                'Open Sans',
                                                              fontSize: 12,

                                                              fontWeight: 600,
                                                              lineHeight:
                                                                'normal',
                                                            }}
                                                          >
                                                            {moment(
                                                              valCondition.date
                                                            ).format(
                                                              'Do MMMM, YYYY'
                                                            )}
                                                          </Typography>
                                                        )}
                                                      {valCondition.days >
                                                        0 && (
                                                        <Typography
                                                          variant='subtitle2'
                                                          color='initial'
                                                          id={`associated_condition_days${index1}`}
                                                          style={{
                                                            fontFamily:
                                                              'Open Sans',
                                                            fontSize: 12,

                                                            fontWeight: 600,
                                                            lineHeight:
                                                              'normal',
                                                          }}
                                                        >
                                                          {valCondition.days}{' '}
                                                          {getStringDays(
                                                            valCondition.days
                                                          )}
                                                        </Typography>
                                                      )}
                                                      {valCondition.weeks >
                                                        0 && (
                                                        <Typography
                                                          variant='subtitle2'
                                                          color='initial'
                                                          id={`associated_condition_weeks${index1}`}
                                                          style={{
                                                            fontFamily:
                                                              'Open Sans',
                                                            fontSize: 12,

                                                            fontWeight: 600,
                                                            lineHeight:
                                                              'normal',
                                                          }}
                                                        >
                                                          {valCondition.weeks}{' '}
                                                          {getStringWeeks(
                                                            valCondition.weeks
                                                          )}
                                                        </Typography>
                                                      )}
                                                      {valCondition.months >
                                                        0 && (
                                                        <Typography
                                                          variant='subtitle2'
                                                          color='initial'
                                                          id={`associated_condition_months${index1}`}
                                                          style={{
                                                            fontFamily:
                                                              'Open Sans',
                                                            fontSize: 12,

                                                            fontWeight: 600,
                                                            lineHeight:
                                                              'normal',
                                                          }}
                                                        >
                                                          {valCondition.months}{' '}
                                                          {getStringMonth(
                                                            valCondition.months
                                                          )}
                                                        </Typography>
                                                      )}
                                                      {valCondition.year >
                                                        0 && (
                                                        <Typography
                                                          variant='subtitle2'
                                                          color='initial'
                                                          id={`associated_condition_year${index1}`}
                                                          style={{
                                                            fontFamily:
                                                              'Open Sans',
                                                            fontSize: 12,

                                                            fontWeight: 600,
                                                            lineHeight:
                                                              'normal',
                                                          }}
                                                        >
                                                          {valCondition.year}{' '}
                                                          {getStringYear(
                                                            valCondition.year
                                                          )}
                                                        </Typography>
                                                      )}
                                                    </Box>
                                                  </Box>
                                                  <Divider
                                                    orientation='horizontal'
                                                    flexItem
                                                    style={{
                                                      height: '2px',
                                                    }}
                                                  />
                                                  {/*     Associated end  */}
                                                </Box>
                                              )
                                            )}
                                          </Box>
                                        )}

                                        {val.selectedLocation &&
                                          val.selectedLocation.length > 0 && (
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='100%'
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                                width='40%'
                                              >
                                                {val.selectedLocation &&
                                                  val.selectedLocation.length >
                                                    0 && (
                                                    <Box
                                                      display='flex'
                                                      flexDirection='column'
                                                      width='100%'
                                                      overflow='auto'
                                                    >
                                                      <Box>
                                                        <OverflowTypography
                                                          text={titleCase(
                                                            val.selectedLocation
                                                              ?.map(
                                                                (e) =>
                                                                  e.display ??
                                                                  ''
                                                              )
                                                              .join(', ')
                                                          )}
                                                          typographyProps={{
                                                            variant:
                                                              'subtitle2',
                                                            color: 'initial',
                                                            id: 'location',
                                                          }}
                                                        />
                                                      </Box>
                                                    </Box>
                                                  )}
                                              </Box>
                                              <Box display='flex' width='2%'>
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    height: '30px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                                width='29%'
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,
                                                    color: 'black',

                                                    fontWeight: 'bold',
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  Onset Duration
                                                </Typography>
                                              </Box>
                                              <Box display='flex' width='2%'>
                                                <Divider
                                                  orientation='vertical'
                                                  flexItem
                                                  style={{
                                                    height: '30px',

                                                    width: '2px',
                                                  }}
                                                />
                                              </Box>
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                height={30}
                                                alignItems='center'
                                                px={1}
                                                width='27%'
                                              >
                                                {val.duration.length > 0 && (
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    id='onset_duration'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 12,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {val.duration.split(' ')[0]}{' '}
                                                    {getTextVal(
                                                      parseInt(
                                                        val.duration.split(
                                                          ' '
                                                        )[0],
                                                        10
                                                      ),
                                                      val.duration.split(' ')[1]
                                                    )}
                                                  </Typography>
                                                )}
                                              </Box>
                                            </Box>
                                          )}
                                        {val.selectedLocation &&
                                          val.selectedLocation.length > 0 && (
                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          )}
                                        {val.character.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            height={30}
                                            alignItems='center'
                                            px={1}
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                            >
                                              {val.character.length > 0 && (
                                                <OverflowTypography
                                                  text={titleCase(
                                                    val.character
                                                  )}
                                                  typographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'initial',
                                                    id: 'character',
                                                  }}
                                                />
                                              )}
                                              {val.character.length === 0 && (
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  None
                                                </Typography>
                                              )}
                                            </Box>
                                          </Box>
                                        )}
                                        {val.character.length > 0 && (
                                          <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          />
                                        )}
                                        {val.alleviating.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            height={40}
                                            alignItems='center'
                                            px={1}
                                          >
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              width='100%'
                                            >
                                              {val.alleviating.length === 0 && (
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                >
                                                  {t(
                                                    'labelCommon:nofactorsFound'
                                                  )}
                                                </Typography>
                                              )}
                                              {val.alleviating.length > 0 && (
                                                <OverflowTypography
                                                  text={titleCase(
                                                    val.alleviating
                                                  )}
                                                  typographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'initial',
                                                    id: 'onset_duration_factor',
                                                  }}
                                                />
                                              )}
                                            </Box>
                                          </Box>
                                        )}
                                        {val.alleviating.length > 0 && (
                                          <Divider
                                            orientation='horizontal'
                                            flexItem
                                            style={{
                                              height: '2px',
                                            }}
                                          />
                                        )}
                                        {val.radiation.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexDirection='column'
                                            style={{
                                              backgroundColor:
                                                kDialogueBackground,
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              height={40}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                              >
                                                {val.radiation.length === 0 && (
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 12,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    None
                                                  </Typography>
                                                )}
                                                {val.radiation.length > 0 && (
                                                  <OverflowTypography
                                                    text={titleCase(
                                                      val.radiation
                                                    )}
                                                    typographyProps={{
                                                      variant: 'subtitle2',
                                                      color: 'initial',
                                                      id: 'onset_duration_raditaon',
                                                    }}
                                                  />
                                                )}
                                              </Box>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.evidenceData &&
                                          val.evidenceData.length > 0 && (
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              flexDirection='column'
                                            >
                                              <Box
                                                justifyContent='flex-start'
                                                display='flex'
                                                minHeight={30}
                                                alignItems='center'
                                                px={1}
                                              >
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    id='evidence'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 12,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    {titleCase(
                                                      val.evidenceData
                                                        ?.map((e) =>
                                                          titleCase(
                                                            e.text ?? ''
                                                          )
                                                        )
                                                        .join(', ')
                                                    )}
                                                  </Typography>
                                                </Box>
                                              </Box>

                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            </Box>
                                          )}
                                        {val.temporal.length > 0 && (
                                          <Box
                                            justifyContent='flex-start'
                                            display='flex'
                                            flexDirection='column'
                                            style={{
                                              backgroundColor:
                                                val.evidenceData &&
                                                val.evidenceData.length > 0
                                                  ? kDialogueBackground
                                                  : 'white',
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              height={40}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                              >
                                                {val.temporal.length > 0 && (
                                                  <OverflowTypography
                                                    text={titleCase(
                                                      val.temporal
                                                    )}
                                                    typographyProps={{
                                                      variant: 'subtitle2',
                                                      color: 'initial',
                                                      id: 'evidence_temp',
                                                    }}
                                                  />
                                                )}
                                                {val.temporal.length === 0 && (
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                    style={{
                                                      fontFamily: 'Open Sans',
                                                      fontSize: 12,

                                                      fontWeight: 600,
                                                      lineHeight: 'normal',
                                                    }}
                                                  >
                                                    None
                                                  </Typography>
                                                )}
                                              </Box>
                                            </Box>

                                            <Divider
                                              orientation='horizontal'
                                              flexItem
                                              style={{
                                                height: '2px',
                                              }}
                                            />
                                          </Box>
                                        )}
                                        {val.selectedSeverity && (
                                          <Box
                                            display='flex'
                                            flexDirection='column'
                                            style={{
                                              backgroundColor:
                                                val.temporal.length > 0 &&
                                                val.notes === undefined
                                                  ? val.evidenceData &&
                                                    val.evidenceData.length > 0
                                                    ? 'white'
                                                    : kDialogueBackground
                                                  : 'white',
                                            }}
                                          >
                                            <Box
                                              justifyContent='flex-start'
                                              display='flex'
                                              height={30}
                                              alignItems='center'
                                              px={1}
                                            >
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                width='100%'
                                              >
                                                <Typography
                                                  variant='subtitle2'
                                                  color='initial'
                                                  style={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: 12,

                                                    fontWeight: 600,
                                                    lineHeight: 'normal',
                                                  }}
                                                  id='evidence_sev'
                                                >
                                                  {titleCase(
                                                    val.selectedSeverity
                                                      .display ?? 'None'
                                                  )}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            {val.notes && (
                                              <Divider
                                                orientation='horizontal'
                                                flexItem
                                                style={{
                                                  height: '2px',
                                                }}
                                              />
                                            )}
                                          </Box>
                                        )}

                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {val.notes &&
                                            val.notes.text &&
                                            val.notes.text.length > 0 && (
                                              <OverflowTypography
                                                text={
                                                  val.notes.text ?? ''.trim()
                                                }
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                  id: 'notes',
                                                }}
                                              />
                                            )}
                                        </Box>
                                        {/* <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      /> */}
                                      </Box>
                                      {/* <Box
                                      display='flex'
                                      flexGrow={1}
                                      justifyContent='flex-end'
                                    >
                                      <Divider
                                        orientation='vertical'
                                        flexItem
                                        style={{
                                          height: 'auto',
                                          color: 'red',
                                          width: '2px',
                                        }}
                                      />
                                    </Box> */}
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                >
                                  {val.doshaObservation &&
                                    ((val.doshaObservation.component &&
                                      val.doshaObservation.component.length >
                                        0) ||
                                      getNotesString(
                                        val.doshaObservation?.note ?? []
                                      )) && (
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height={30}
                                          alignItems='center'
                                          alignContent='center'
                                          borderRadius={2}
                                          style={{
                                            backgroundColor: 'lightGrey',
                                          }}
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            color='initial'
                                            style={{
                                              color: 'black',

                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {' '}
                                            Dosha
                                          </Typography>
                                        </Box>
                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Vata
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief1'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.doshaObservation,
                                                        componentCode:
                                                          'ITA-2.1.1.1',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    {' '}
                                                    Pitta
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief2'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.doshaObservation,
                                                        componentCode:
                                                          'ITA-2.1.2.1',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height='30px'
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',

                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Kapha
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief3'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.doshaObservation,
                                                        componentCode:
                                                          'ITA-2.1.3.1',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Remarks
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  {getNotesFromAllergy(
                                                    val.doshaObservation
                                                      ?.note ?? []
                                                  )?.map((eData) => (
                                                    <Box
                                                      key={eData}
                                                      width='100%'
                                                      display='flex'
                                                      height={30}
                                                      alignItems='center'
                                                    >
                                                      <OverflowTypography
                                                        text={eData ?? ''}
                                                        typographyProps={{
                                                          variant: 'body1',
                                                          color: 'initial',
                                                          id: 'listChief4',
                                                        }}
                                                      />
                                                    </Box>
                                                  ))}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                >
                                  {val.dushyaObservation &&
                                    ((val.dushyaObservation.component &&
                                      val.dushyaObservation.component.length >
                                        0) ||
                                      getNotesString(
                                        val.dushyaObservation?.note ?? []
                                      )) && (
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height={30}
                                          alignItems='center'
                                          alignContent='center'
                                          borderRadius={2}
                                          style={{
                                            backgroundColor: 'lightGrey',
                                          }}
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            color='initial'
                                            style={{
                                              color: 'black',

                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {' '}
                                            Dushya
                                          </Typography>
                                        </Box>
                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Rasa
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief5'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.4',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    {' '}
                                                    Rakta
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief6'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.5',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>

                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Mamsa
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief7'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.8',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Meda
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief8'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.9',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Asthi
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief9'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.10',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Majja
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief10'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.16',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height='30px'
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',

                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Shukra
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief11'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.dushyaObservation,
                                                        componentCode:
                                                          'ITA-2.2.19',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Remarks
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                  id='listChief12'
                                                >
                                                  {getNotesFromAllergy(
                                                    val.dushyaObservation
                                                      ?.note ?? []
                                                  )?.map((eData) => (
                                                    <Box
                                                      key={eData}
                                                      width='100%'
                                                      display='flex'
                                                      height={30}
                                                      alignItems='center'
                                                    >
                                                      <OverflowTypography
                                                        text={eData ?? ''}
                                                        typographyProps={{
                                                          variant: 'body1',
                                                          color: 'initial',
                                                          id: 'listChief13',
                                                        }}
                                                      />
                                                    </Box>
                                                  ))}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                >
                                  {val.malaObservation &&
                                    ((val.malaObservation.component &&
                                      val.malaObservation.component.length >
                                        0) ||
                                      getNotesString(
                                        val.malaObservation?.note ?? []
                                      )) && (
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height={30}
                                          alignItems='center'
                                          alignContent='center'
                                          borderRadius={2}
                                          style={{
                                            backgroundColor: 'lightGrey',
                                          }}
                                          px={1}
                                        >
                                          <Typography
                                            variant='subtitle1'
                                            color='initial'
                                            style={{
                                              color: 'black',

                                              fontWeight: 'bold',
                                            }}
                                          >
                                            Mala
                                          </Typography>
                                        </Box>
                                        <Divider
                                          orientation='horizontal'
                                          flexItem
                                          style={{
                                            height: '2px',
                                          }}
                                        />
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          alignItems='center'
                                          style={{
                                            backgroundColor:
                                              kDialogueBackground,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',
                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Mutra
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief14'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.malaObservation,
                                                        componentCode:
                                                          'ITA-2.4.4',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Purisha
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief15'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.malaObservation,
                                                        componentCode:
                                                          'ITA-2.4.3',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                        <Box
                                          display='flex'
                                          flexDirection='row'
                                          width='100%'
                                          height='30px'
                                        >
                                          <Grid
                                            container
                                            direction='row'
                                            style={{
                                              height: '100%',
                                              margin: '0px',

                                              padding: '0px',
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Sweda
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='body2'
                                                    color='initial'
                                                    id='listChief16'
                                                  >
                                                    {getObservationValueCodeableConceptDisplayByComponent(
                                                      {
                                                        observation:
                                                          val.malaObservation,
                                                        componentCode:
                                                          'ITA-2.4.5',
                                                      }
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={6}
                                              style={{
                                                height: '100%',
                                                margin: '0px',

                                                padding: '0px',
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction='row'
                                                style={{
                                                  height: '100%',
                                                  margin: '0px',

                                                  padding: '0px',
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    borderRight: `2px solid ${theme.palette.divider}`,
                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  <Typography
                                                    variant='subtitle2'
                                                    color='initial'
                                                  >
                                                    Remarks
                                                  </Typography>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={6}
                                                  style={{
                                                    height: '100%',
                                                    margin: '0px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',

                                                    paddingLeft: '8px',
                                                  }}
                                                >
                                                  {getNotesFromAllergy(
                                                    val.dushyaObservation
                                                      ?.note ?? []
                                                  )?.map((eData) => (
                                                    <Box
                                                      key={eData}
                                                      width='100%'
                                                      display='flex'
                                                      height={30}
                                                      alignItems='center'
                                                    >
                                                      <OverflowTypography
                                                        text={eData ?? ''}
                                                        typographyProps={{
                                                          variant: 'body1',
                                                          color: 'initial',
                                                          id: 'listChief17',
                                                        }}
                                                      />
                                                    </Box>
                                                  ))}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>
                                    )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              {showDeleteConfirmation.open && (
                <ConfirmationDialog
                  {...showDeleteConfirmation}
                  id='confirmDialog'
                />
              )}
            </Box>
          </ReactVisibilitySensor>
        </Box>
      </Box>
      <AddChiefComplanits
        open={addChiefComplaints}
        fhirAppointmentDetails={fhirAppointmentDetails}
        onClose={() => {
          setAddChiefComplaints(false)
        }}
        onConditionAdded={() => {
          getChiefComplaintOfAppointment(
            fhirAppointmentDetails.patient,
            fhirAppointmentDetails.encounter!,
            split
          )
          setAddChiefComplaints(false)
          dispatch(resetChiefComplaintDetails)
        }}
      />
      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Symptoms'
      />

      <NoteDialog
        id='notesTile'
        open={showNotesText}
        onClose={() => {
          setFullText('')
          setShowNotesText(false)
        }}
        notesData={fullText!}
      />

      <NoteDialog
        id='notesTile'
        open={showAssociated}
        onClose={() => {
          setFullText('')
          setShowAssociated(false)
        }}
        notesData={fullText!}
        dialogText='Associated Symptoms'
      />
      {chiefComplaintsDetails && (
        <EditChiefComplanits
          open={editChiefComplaints}
          onConditionUpdated={() => {
            dispatch(
              getChiefComplaintOfAppointment(
                fhirAppointmentDetails.patient,
                fhirAppointmentDetails.encounter!,
                split
              )
            )
            setEditChiefComplaints(false)
            setChiefComplaintsDetails(undefined)
            dispatch(resetChiefComplaintDetails())
          }}
          onClose={() => {
            // dispatch(resetChiefComplaintDetails())
            // getChiefComplaintOfAppointment(
            //   fhirAppointmentDetails.patient,
            //   fhirAppointmentDetails.encounter!,
            //   split
            // )
            // setChiefComplaintsDetails(undefined)
            setEditChiefComplaints(false)
          }}
          fhirAppointmentDetails={fhirAppointmentDetails}
          chiefComplaintsData={chiefComplaintsDetails}
        />
      )}
    </MuiPickersUtilsProvider>
  )
}
