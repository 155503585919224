import { R4 } from '@ahryman40k/ts-fhir-types'
import { sign } from 'crypto'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import { doctorAppointmentView, DoctorBase } from 'lib/openApi'
import { Chart } from 'models/bpMeasurements'
import { DateWiseOrders } from 'models/dateSeparatedOrders'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import {
  FhirLabOrderFullDetail,
  TestAndResult,
} from 'models/fhirLabOrderFullDetails'
import { GraphDataSet } from 'models/graphDataSet'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { RealGraphData } from 'models/realGraphData'
import { RealGraphType } from 'models/realGraphType'
import { RoomPrice } from 'models/roomPrice'
import { VitalsData } from 'models/vitalsData'
import { WelloSignExam } from 'models/WelloConditions'
import moment from 'moment'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import {
  getCompleteDateStringWithOutDay,
  geTimeInString,
  geTimeInStringWithDate,
  getIpdInTImeWithDate,
  getShortTimeWithDate,
  getStartAndEndTimeInString,
  getStartAndEndTimeInStringWithDate,
  getStartAndEndTimeWithDateInString,
} from 'utils/dateUtil'
import { logger } from 'utils/logger'
import { getNameOfPatient } from 'wello-web-components'
import {
  getCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConceptList,
  getExtensionValueOfUrl,
  getNameFromHumanName,
  getValueRefValueExtensionsOfUrl,
  isMarried,
} from '../fhirResourcesHelper'
import { getUniqueTempId } from './idHelpers'
import {
  getLabOfferingDetailsFromBundle,
  getPriceComponentFromChangeItem,
} from './planDefinitionHelper'

export function getObserVationFfromEncounter(
  responseBundle: R4.IBundle
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.code
                      ? currentObServation.code.coding
                        ? currentObServation.code.coding[0].code ?? ''
                        : ''
                      : ''
                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : ''
                    const indexOfBP = vitals.findIndex((e) =>
                      getDefaultCodeOfSystemFromCodableConcept(
                        e.code
                      )?.includes(codeData)
                    )
                    vitals.push(currentObServation)

                    // if (indexOfBP < 0) {
                    // }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return vitals
}

export function getObserVationFfromEncounterForGeneralExam(
  responseBundle: R4.IBundle
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.category
                      ? currentObServation.category[0].coding
                        ? currentObServation.category[0].coding[0].code ?? ''
                        : ''
                      : ''
                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : ''
                    const indexOfBP = vitals.findIndex((e) =>
                      getDefaultCodeOfSystemFromCodableConcept(
                        e.code
                      )?.includes(codeData)
                    )
                    if (codeData === 'exam') vitals.push(currentObServation)

                    // if (indexOfBP < 0) {
                    // }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return vitals
}

export function getObserVationFfromEncounterForLabTest(
  responseBundle: R4.IBundle
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.category
                      ? currentObServation.category[0].coding
                        ? currentObServation.category[0].coding[0].code ?? ''
                        : ''
                      : ''
                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : ''
                    const indexOfBP = vitals.findIndex((e) =>
                      getDefaultCodeOfSystemFromCodableConcept(
                        e.code
                      )?.includes(codeData)
                    )
                    if (codeData === 'laboratory')
                      vitals.push(currentObServation)

                    // if (indexOfBP < 0) {
                    // }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return vitals
}

export function getObserVationFfromEncounterFromDischarge(
  responseBundle: R4.IBundle
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.code
                      ? currentObServation.code.coding
                        ? currentObServation.code.coding[0].code ?? ''
                        : ''
                      : ''

                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : ''

                    if (codeData === '11535-2') {
                      vitals.push(currentObServation)
                    } else if (
                      currentObServation.category &&
                      currentObServation.category.length > 0 &&
                      getDefaultCodeOfSystemFromCodableConceptList(
                        currentObServation.category,
                        'http://loinc.org'
                      ) === '11535-2'
                    ) {
                      vitals.push(currentObServation)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return vitals
}

export function getAssesmentFormEncounter(
  responseBundle: R4.IBundle
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.code
                      ? currentObServation.code.coding
                        ? currentObServation.code.coding[0].code ?? ''
                        : ''
                      : ''

                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : ''
                    if (codeData === '10210-3')
                      // vitalsData.push({
                      //   startDate: date,
                      //   observation: currentObServation,
                      // })
                      vitals.push(currentObServation)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return vitals
}

export function getObserVationFfromEncounterForDateWise(
  responseBundle: R4.IBundle
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.code
                      ? currentObServation.code.coding
                        ? currentObServation.code.coding[0].code ?? ''
                        : ''
                      : ''

                    const lastupdatedDate: string = currentObServation.meta
                      ? currentObServation.meta.lastUpdated
                        ? moment(currentObServation.meta.lastUpdated).format(
                            'DD-MM-YYYY'
                          )
                        : ''
                      : ''

                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : lastupdatedDate
                    if (
                      codeData === '8310-5' ||
                      codeData === '8867-4' ||
                      codeData === '8302-2' ||
                      codeData === '29463-7' ||
                      codeData === '9279-1' ||
                      codeData === '85354-9' ||
                      codeData === '8480-6' ||
                      codeData === '  8462-4' ||
                      codeData === '39156-5' ||
                      codeData === '48094003'
                    )
                      vitalsData.push({
                        startDate: currentObServation.issued
                          ? currentObServation.issued
                          : lastupdatedDate,
                        observation: currentObServation,
                      })
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return vitalsData
}

export function getObserVationFfromObserVationDateWise(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  const sortedData = responseBundle.sort((a, b) =>
    a.code!.text! > b.code!.text! ? 1 : b.code!.text! > a.code!.text! ? -1 : 0
  )

  for (let i = 0; i < sortedData.length; i++) {
    const currentObServation: R4.IObservation = sortedData[i]

    const lastupdatedDate: string = currentObServation.meta
      ? currentObServation.meta.lastUpdated
        ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
        : ''
      : ''
    const codeData = currentObServation.code
      ? currentObServation.code.coding
        ? currentObServation.code.coding[0].code ?? ''
        : ''
      : ''

    vitalsData.push({
      startDate: currentObServation.issued
        ? currentObServation.issued
        : lastupdatedDate,
      observation: currentObServation,
    })
  }
  return vitalsData
}

export function getDischargeData(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  const sortedData = responseBundle.sort((a, b) =>
    a.code!.text! > b.code!.text! ? 1 : b.code!.text! > a.code!.text! ? -1 : 0
  )

  for (let i = 0; i < sortedData.length; i++) {
    const currentObServation: R4.IObservation = sortedData[i]

    const lastupdatedDate: string = currentObServation.meta
      ? currentObServation.meta.lastUpdated
        ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
        : ''
      : ''
    const codeData = currentObServation.code
      ? currentObServation.code.coding
        ? currentObServation.code.coding[0].code ?? ''
        : ''
      : ''

    vitalsData.push({
      startDate: currentObServation.issued
        ? currentObServation.issued
        : lastupdatedDate,
      observation: currentObServation,
    })
  }
  return vitalsData
}

export function getVitalsData(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []

  for (let i = 0; i < responseBundle.length; i++) {
    const currentObServation: R4.IObservation = responseBundle[i]

    const lastupdatedDate: string = currentObServation.meta
      ? currentObServation.meta.lastUpdated
        ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
        : ''
      : ''
    const codeData = currentObServation.code
      ? currentObServation.code.coding
        ? currentObServation.code.coding[0].code ?? ''
        : ''
      : ''

    if (
      codeData === '8310-5' ||
      codeData === '8867-4' ||
      codeData === '8302-2' ||
      codeData === '29463-7' ||
      codeData === '9279-1' ||
      codeData === '85354-9' ||
      codeData === '8480-6' ||
      codeData === '8462-4' ||
      codeData === '39156-5'
    ) {
      vitals.push(currentObServation)
    }
  }
  return vitals
}

export function getHabitDataFromObs(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitalsData: VitalsData[] = []

  for (let i = 0; i < responseBundle.length; i++) {
    const currentObServation: R4.IObservation = responseBundle[i]

    const lastupdatedDate: string = currentObServation.meta
      ? currentObServation.meta.lastUpdated
        ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
        : ''
      : ''
    const codeData = currentObServation.code
      ? currentObServation.code.coding
        ? currentObServation.code.coding[0].code ?? ''
        : ''
      : ''

    if (
      codeData === '74009-2' ||
      codeData === '10000-2' ||
      codeData === '74008-4' ||
      codeData === '65515-9' ||
      codeData === '28148-5' ||
      codeData === '10000-1' ||
      codeData === '65968-0' ||
      codeData === '65561-3' ||
      codeData === 'other-habit'
    ) {
      vitalsData.push({
        startDate: currentObServation.issued
          ? currentObServation.issued
          : lastupdatedDate,
        observation: currentObServation,
      })
    }
  }

  return vitalsData
}

export function getTop4HabitList(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []

  for (let i = 0; i < responseBundle.length; i++) {
    const currentObServation: R4.IObservation = responseBundle[i]

    const codeData = currentObServation.code
      ? currentObServation.code.coding
        ? currentObServation.code.coding[0].code ?? ''
        : ''
      : ''

    if (
      codeData === '10000-2' ||
      codeData === '74008-4' ||
      codeData === '65515-9' ||
      codeData === '74009-2' ||
      codeData === '65968-0' ||
      codeData === '65561-3' ||
      codeData === 'other-habit' ||
      codeData === '10000-1'
    ) {
      vitalsData.push(currentObServation)
    }
  }

  return vitalsData
}

export function getSortedObservationForHabit(
  observations: R4.IObservation[]
): R4.IObservation[] {
  const sortedObservation: R4.IObservation[] = []

  const indexOfCoffee = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('10000-2')
  )

  if (indexOfCoffee > -1) {
    sortedObservation.push(observations[indexOfCoffee])
  }

  const indexOfLevel = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('65515-9')
  )

  if (indexOfLevel > -1) {
    sortedObservation.push(observations[indexOfLevel])
  }

  const indexOfExerciseIntensity = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('74008-4')
  )

  if (indexOfExerciseIntensity > -1) {
    sortedObservation.push(observations[indexOfExerciseIntensity])
  }

  const indexOfExerciseDuration = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('74009-2')
  )

  if (indexOfExerciseDuration > -1) {
    sortedObservation.push(observations[indexOfExerciseDuration])
  }

  const indexOfSleepDay = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('65968-0')
  )

  if (indexOfSleepDay > -1) {
    sortedObservation.push(observations[indexOfSleepDay])
  }
  const indexOfSleepNight = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('65561-3')
  )

  if (indexOfSleepNight > -1) {
    sortedObservation.push(observations[indexOfSleepNight])
  }

  const indexOfTea = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('10000-1')
  )

  if (indexOfTea > -1) {
    sortedObservation.push(observations[indexOfTea])
  }
  const indexOfOther = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('other-habit')
  )

  if (indexOfOther > -1) {
    sortedObservation.push(observations[indexOfOther])
  }

  return sortedObservation
}

export function getSortedObservationForAddiction(
  observations: R4.IObservation[]
): R4.IObservation[] {
  const sortedObservation: R4.IObservation[] = []

  const indexOfAlcohol = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('68518-0')
  )

  if (indexOfAlcohol > -1) {
    sortedObservation.push(observations[indexOfAlcohol])
  }

  const indexOfPaan = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('96103-7')
  )

  if (indexOfPaan > -1) {
    sortedObservation.push(observations[indexOfPaan])
  }

  const indexOfDrugs = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('10000-3')
  )

  if (indexOfDrugs > -1) {
    sortedObservation.push(observations[indexOfDrugs])
  }

  const indexOfSmoke = observations.findIndex((e) =>
    getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes('64218-1')
  )

  if (indexOfSmoke > -1) {
    sortedObservation.push(observations[indexOfSmoke])
  }

  return sortedObservation
}

export function getTop4HabitListForTile(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []

  for (let i = 0; i < responseBundle.length; i++) {
    const currentObServation: R4.IObservation = responseBundle[i]

    const codeData = currentObServation.code
      ? currentObServation.code.coding
        ? currentObServation.code.coding[0].code ?? ''
        : ''
      : ''

    if (
      codeData === '10000-2' ||
      codeData === '74008-4' ||
      codeData === '65515-9' ||
      codeData === '74009-2'
    ) {
      vitalsData.push(currentObServation)
    }
  }

  return vitalsData
}

export function getBottom4HabitList(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []

  for (let i = 0; i < responseBundle.length; i++) {
    const currentObServation: R4.IObservation = responseBundle[i]

    const codeData = currentObServation.code
      ? currentObServation.code.coding
        ? currentObServation.code.coding[0].code ?? ''
        : ''
      : ''

    if (
      codeData === '65968-0' ||
      codeData === '65561-3' ||
      codeData === 'other-habit' ||
      codeData === '10000-1'
    ) {
      vitalsData.push(currentObServation)
    }
  }

  return vitalsData
}

export function getAddictionsDataFromObs(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  for (let i = 0; i < responseBundle.length; i++) {
    const currentObServation: R4.IObservation = responseBundle[i]

    const lastupdatedDate: string = currentObServation.meta
      ? currentObServation.meta.lastUpdated
        ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
        : ''
      : ''
    const codeData = currentObServation.code
      ? currentObServation.code.coding
        ? currentObServation.code.coding[0].code ?? ''
        : ''
      : ''

    if (
      codeData === '68518-0' ||
      codeData === '96103-7' ||
      codeData === '10000-3' ||
      codeData === '64218-1' ||
      codeData === '41829006' ||
      codeData !== '21843-8'
    ) {
      vitalsData.push({
        startDate: currentObServation.issued
          ? currentObServation.issued
          : lastupdatedDate,
        observation: currentObServation,
      })
    }
  }
  return vitalsData
}
export function getObserVationFfromObserVationDateWiseForLabTest(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      if (
        (codeData === '3091-6' && type !== 'RFT') ||
        (codeData === '2160-0' && type !== 'RFT') ||
        codeData === '1986-9' ||
        codeData === '1521-4' ||
        (codeData === '718-7' && type !== 'panel') ||
        codeData === '4548-4' ||
        codeData === '1558-6'
      ) {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }
  return vitalsData
}

export function getLabTestData(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: R4.IObservation[] = []
  const observation: any = {}
  const encounters: any = {}
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      if (
        (codeData === '3091-6' && type === 'Test') ||
        (codeData === '2160-0' && type === 'Test') ||
        codeData === '1986-9' ||
        codeData === '1521-4' ||
        (codeData === '718-7' && type === 'Test') ||
        codeData === '4548-4' ||
        codeData === '1558-6'
      ) {
        vitalsData.push(currentObServation)
      }
    }
  }

  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForGeneralExam(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitalsData: VitalsData[] = []

  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      if (
        codeData === '165184000' ||
        codeData === '1759002' ||
        codeData === '1237486008' ||
        codeData === '18165001' ||
        codeData === '3415004' ||
        codeData === '4373005' ||
        codeData === '30746006'
      ) {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }
  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForPhysicalExam(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitalsData: VitalsData[] = []

  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      if (
        codeData! === '165184000' ||
        codeData! === '1759002' ||
        codeData! === '1237486008' ||
        codeData! === '18165001' ||
        codeData! === '3415004' ||
        codeData! === '4373005' ||
        codeData! === '30746006'
      ) {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }
  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForLabPanelOPD(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      if (
        codeData === '6690-2' ||
        codeData === '789-8' ||
        (codeData === '718-7' && type !== 'Test' && type.includes('H')) ||
        codeData === '4544-3' ||
        codeData === '787-2' ||
        codeData === '785-6' ||
        codeData === '786-4' ||
        codeData === '788-0' ||
        codeData === '777-3' ||
        codeData === '736-9' ||
        codeData === '5905-5' ||
        codeData === '713-8' ||
        (codeData === '706-2' && type.includes('H')) ||
        codeData === '751-8' ||
        codeData === '731-0' ||
        codeData === '742-7' ||
        codeData === '711-2' ||
        codeData === '704-7' ||
        codeData === '58413-6'
      ) {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }
  return vitalsData
}

export function getPanel1Data(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      if (
        codeData === '6690-2' ||
        codeData === '789-8' ||
        (codeData === '718-7' && type !== 'Test') ||
        codeData === '4544-3' ||
        codeData === '787-2' ||
        codeData === '785-6' ||
        codeData === '786-4' ||
        codeData === '788-0' ||
        codeData === '777-3' ||
        codeData === '736-9' ||
        codeData === '5905-5' ||
        codeData === '713-8' ||
        (codeData === '706-2' && type.includes('H')) ||
        (codeData === '770-8' && type.includes('H')) ||
        // codeData === '706-2' ||
        codeData === '751-8' ||
        codeData === '731-0' ||
        codeData === '742-7' ||
        codeData === '711-2' ||
        codeData === '704-7' ||
        codeData === '58413-6'
      ) {
        vitalsData.push(currentObServation)
      }
    }
  }

  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForLabPanel2OPD(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      if (codeData === '71491-5' || codeData === '71490-7') {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }
  return vitalsData
}

export function getPanel2Data(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      if (codeData === '71491-5' || codeData === '71490-7') {
        vitalsData.push(currentObServation)
      }
    }
  }
  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForLabPanel3OPD(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      if (
        codeData === '2093-3' ||
        codeData === '2571-8' ||
        codeData === '13458-5' ||
        codeData === '13457-7' ||
        codeData === '11054-4' ||
        codeData === 'I9830-1' ||
        codeData === '2085-9'
      ) {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }
  return vitalsData
}

export function getPanel3Data(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      if (
        codeData === '2093-3' ||
        codeData === '2571-8' ||
        codeData === '13458-5' ||
        codeData === '13457-7' ||
        codeData === '11054-4' ||
        codeData === 'I9830-1' ||
        codeData === '2085-9'
      ) {
        vitalsData.push(currentObServation)
      }
    }
  }
  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForLabPanelLftOPD(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (codeDataText === 'LFT') {
        if (
          codeData === '1975-2' ||
          codeData === '1971-1' ||
          codeData === '2336-6' ||
          codeData === '2324-2' ||
          codeData === '1759-0' ||
          codeData === '2885-2' ||
          (codeData === '1751-7' && codeDataText === 'LFT') ||
          codeData === '1968-7' ||
          codeData === '6768-6' ||
          codeData === '1920-8' ||
          codeData === '1742-6'
        ) {
          vitalsData.push({
            startDate: currentObServation.issued
              ? currentObServation.issued
              : lastupdatedDate,
            observation: currentObServation,
          })
        }
      }
    }
  }
  return vitalsData
}

export function getPaneLftData(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''
      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (codeDataText === 'LFT') {
        if (
          codeData === '1975-2' ||
          codeData === '1971-1' ||
          codeData === '2336-6' ||
          codeData === '2324-2' ||
          codeData === '1759-0' ||
          codeData === '2885-2' ||
          (codeData === '1751-7' && codeDataText === 'LFT') ||
          codeData === '1968-7' ||
          codeData === '6768-6' ||
          codeData === '1920-8' ||
          codeData === '1742-6'
        ) {
          vitalsData.push(currentObServation)
        }
      }
    }
  }

  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForLabPanelRftOPD(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      if (
        codeData === '2345-7' ||
        codeData === '3094-0' ||
        codeData === '2160-0' ||
        codeData === '98979-8' ||
        codeData === '3097-3' ||
        codeData === '2951-2' ||
        codeData === '2823-3' ||
        codeData === '2075-0' ||
        codeData === '2028-9' ||
        codeData === '17861-6' ||
        (codeData === '1751-7' && codeDataText === 'RFT') ||
        codeData === '2777-1'
      ) {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }

  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForHomOPD(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (codeDataText === 'HOMA') {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }

  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForDexaOPD(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (codeDataText === 'DEXA') {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }

  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForSerumLytesOPD(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (codeDataText === 'Serum Lytes') {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }

  return vitalsData
}

export function getPaneRftData(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''
      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (codeDataText === 'RFT') {
        if (
          codeData === '2345-7' ||
          codeData === '3094-0' ||
          (codeData === '2160-0' && codeDataText === 'RFT') ||
          codeData === '98979-8' ||
          codeData === '3097-3' ||
          codeData === '2951-2' ||
          codeData === '2823-3' ||
          codeData === '2075-0' ||
          codeData === '2028-9' ||
          codeData === '17861-6' ||
          (codeData === '1751-7' && codeDataText === 'RFT') ||
          codeData === '2777-1' ||
          codeData === '3084-1' ||
          (codeData === '3091-6' && codeDataText === 'RFT')
        ) {
          vitalsData.push(currentObServation)
        }
      }
    }
  }
  return vitalsData
}

export function getPanelHomaData(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''
      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (codeDataText === 'HOMA') {
        vitalsData.push(currentObServation)
      }
    }
  }
  return vitalsData
}

export function getPanelDexaData(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''
      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (codeDataText === 'DEXA') {
        vitalsData.push(currentObServation)
      }
    }
  }
  return vitalsData
}

export function getPanelSerumLytesData(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''
      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (codeDataText === 'Serum Lytes') {
        vitalsData.push(currentObServation)
      }
    }
  }

  return vitalsData
}

export function getPanelTftData(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''
      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (codeDataText === 'TFT') {
        if (
          codeData === '3024-7' ||
          codeData === '3051-0' ||
          codeData === '11580-8' ||
          codeData === '3053-6' ||
          codeData === '3026-2'
        ) {
          vitalsData.push(currentObServation)
        }
      }
    }
  }
  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForLabPanel4OPD(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const lastupdatedDate: string = currentObServation.meta
        ? currentObServation.meta.lastUpdated
          ? moment(currentObServation.meta.lastUpdated).format('DD-MM-YYYY')
          : ''
        : ''
      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      if (
        codeData === '5811-5' ||
        codeData === '5803-2' ||
        codeData === '5778-6' ||
        codeData === '5767-9' ||
        codeData === '5799-2' ||
        codeData === '20454-5' ||
        codeData === '25428-4' ||
        codeData === '2514-8' ||
        codeData === '5794-3' ||
        codeData === '5809-9'
      ) {
        vitalsData.push({
          startDate: currentObServation.issued
            ? currentObServation.issued
            : lastupdatedDate,
          observation: currentObServation,
        })
      }
    }
  }
  return vitalsData
}

export function getPanel4Data(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitalsData: R4.IObservation[] = []
  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''
      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      const type: string = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''
      if (
        codeData === '5811-5' ||
        codeData === '5803-2' ||
        codeData === '5778-6' ||
        codeData === '34533-0' ||
        codeData === '5767-9' ||
        codeData === '32167-9' ||
        codeData === '5799-2' ||
        codeData === '20454-5' ||
        codeData === '25428-4' ||
        codeData === '2514-8' ||
        codeData === '5794-3' ||
        codeData === '5809-9' ||
        codeData === '5802-4' ||
        codeData === '20405-7' ||
        codeData === '5770-3'
      ) {
        vitalsData.push(currentObServation)
      }
    }
  }

  return vitalsData
}

export function getObserVationFfromObserVationDateWiseForGraph(
  responseBundle: R4.IObservation[]
): GraphDataSet {
  const vitals: R4.IObservation[] = []

  const observation: any = {}
  const encounters: any = {}

  const distolicG: Chart[] = []
  const sisG: Chart[] = []
  const bmiChart: Chart[] = []

  for (let i = 0; i < responseBundle.length; i++) {
    const currentObServation: R4.IObservation = responseBundle[i]
    const codeData = currentObServation.code
      ? currentObServation.code.coding
        ? currentObServation.code.coding[0].code ?? ''
        : ''
      : ''
    if (codeData === '85354-9') {
      if (currentObServation.component !== undefined) {
        const sysComp: R4.IObservation_Component | undefined =
          currentObServation.component.find(
            (val: any) => val.code?.coding?.[0].code === '8480-6'
          )
        const diaComp: R4.IObservation_Component | undefined =
          currentObServation.component.find(
            (val: any) => val.code?.coding?.[0].code === '8462-4'
          )
        const date = currentObServation.issued
          ? moment(currentObServation.issued).format('DD-MM-YYYY')
          : ''
        const sysvitalData: Chart = {
          x: date,
          y: sysComp ? sysComp.valueQuantity?.value ?? 0 : 0,
        }
        const disvitalData: Chart = {
          x: date,
          y: diaComp ? diaComp.valueQuantity?.value ?? 0 : 0,
        }
        distolicG.push(disvitalData)
        sisG.push(sysvitalData)
      }
      const date = currentObServation.issued
        ? moment(currentObServation.issued).format('DD-MM-YYYY')
        : ''
    }
    if (codeData === '39156-5') {
      const dateData = currentObServation.issued
        ? moment(currentObServation.issued).format('DD-MM-YYYY')
        : ''
      const bmi: Chart = {
        x: dateData,
        y: currentObServation.valueQuantity
          ? currentObServation.valueQuantity.value ?? 0
          : 0,
      }
      bmiChart.push(bmi)
    }
  }
  const vitalsData: GraphDataSet = {
    sp: sisG,
    dp: distolicG,
    bmi: bmiChart,
    planName: '',
    startDate: '',
    endDate: '',
    planDateDiffAlloPathy: 0,
    planDateDiffForAyush: 0,
    drugEraCount: 0,
  }
  return vitalsData
}

export function getCodeOfObservationInDropDown(
  observation: R4.ICodeableConcept,
  dropDowns: R4.ICoding[]
) {
  const code = getDefaultCodeOfSystemFromCodableConcept(observation)

  const index = dropDowns.findIndex((e) => e.code === code)

  if (index > -1) {
    return dropDowns[index]
  }
  return dropDowns[0]
}

export function getObserVationFfromEncounterForDateWiseLAbResult(
  responseBundle: R4.IBundle
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.code
                      ? currentObServation.code.coding
                        ? currentObServation.code.coding[0].code ?? ''
                        : ''
                      : ''

                    const lastupdatedDate: string = currentObServation.meta
                      ? currentObServation.meta.lastUpdated
                        ? moment(currentObServation.meta.lastUpdated).format(
                            'DD-MM-YYYY'
                          )
                        : ''
                      : ''

                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : lastupdatedDate
                    if (
                      codeData === '3091-6' ||
                      codeData === '2160-0' ||
                      codeData === '1986-9' ||
                      codeData === '1521-4' ||
                      codeData === '718-7' ||
                      codeData === '4548-4' ||
                      codeData === '1558-6'
                    )
                      vitalsData.push({
                        startDate: currentObServation.issued
                          ? currentObServation.issued
                          : lastupdatedDate,
                        observation: currentObServation,
                      })
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return vitalsData
}

export function getObserVationFfromEncounterForDateWisePanel1(
  responseBundle: R4.IBundle
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.code
                      ? currentObServation.code.coding
                        ? currentObServation.code.coding[0].code ?? ''
                        : ''
                      : ''
                    const type = currentObServation.code
                      ? currentObServation.code.text ?? ''
                      : ''

                    const lastupdatedDate: string = currentObServation.meta
                      ? currentObServation.meta.lastUpdated
                        ? moment(currentObServation.meta.lastUpdated).format(
                            'DD-MM-YYYY'
                          )
                        : ''
                      : ''

                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : lastupdatedDate
                    if (
                      codeData === '6690-2' ||
                      codeData === '789-8' ||
                      (codeData === '718-7' && type !== 'Test') ||
                      codeData === '4544-3' ||
                      codeData === '787-2' ||
                      codeData === '785-6' ||
                      codeData === '786-4' ||
                      codeData === '788-0' ||
                      codeData === '777-3' ||
                      codeData === '736-9' ||
                      codeData === '5905-5' ||
                      codeData === '713-8' ||
                      codeData === '706-2' ||
                      codeData === '751-8' ||
                      codeData === '731-0' ||
                      codeData === '742-7' ||
                      codeData === '711-2' ||
                      codeData === '704-7' ||
                      codeData === '58413-6'
                    )
                      vitalsData.push({
                        startDate: currentObServation.issued
                          ? currentObServation.issued
                          : lastupdatedDate,
                        observation: currentObServation,
                      })
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return vitalsData
}

export function getObserVationFfromEncounterForDateWisePanel2(
  responseBundle: R4.IBundle
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.code
                      ? currentObServation.code.coding
                        ? currentObServation.code.coding[0].code ?? ''
                        : ''
                      : ''

                    const lastupdatedDate: string = currentObServation.meta
                      ? currentObServation.meta.lastUpdated
                        ? moment(currentObServation.meta.lastUpdated).format(
                            'DD-MM-YYYY'
                          )
                        : ''
                      : ''

                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : lastupdatedDate
                    if (codeData === '71491-5' || codeData === '71490-7')
                      vitalsData.push({
                        startDate: currentObServation.issued
                          ? currentObServation.issued
                          : lastupdatedDate,
                        observation: currentObServation,
                      })
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return vitalsData
}

export function getObserVationFfromEncounterForDateWisePanel3(
  responseBundle: R4.IBundle
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.code
                      ? currentObServation.code.coding
                        ? currentObServation.code.coding[0].code ?? ''
                        : ''
                      : ''

                    const lastupdatedDate: string = currentObServation.meta
                      ? currentObServation.meta.lastUpdated
                        ? moment(currentObServation.meta.lastUpdated).format(
                            'DD-MM-YYYY'
                          )
                        : ''
                      : ''

                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : lastupdatedDate
                    if (
                      codeData === '2093-3' ||
                      codeData === '2571-8' ||
                      codeData === '13458-5' ||
                      codeData === '13457-7' ||
                      codeData === '11054-4' ||
                      codeData === 'I9830-1'
                    )
                      vitalsData.push({
                        startDate: currentObServation.issued
                          ? currentObServation.issued
                          : lastupdatedDate,
                        observation: currentObServation,
                      })
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return vitalsData
}

export function getObserVationFfromEncounterForDateWisePanel4(
  responseBundle: R4.IBundle
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  if (responseBundle.total) {
    if (responseBundle.total > 0) {
      if (responseBundle.entry) {
        const entries: R4.IBundle_Entry[] = responseBundle.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'Encounter':
                  encounters[value.resource.id] =
                    value.resource as R4.IEncounter
                  break
                case 'Observation':
                  observation[value.resource.id] =
                    value.resource as R4.IObservation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in encounters) {
          if (key) {
            const currentEncounter: R4.IEncounter = encounters[
              key
            ] as R4.IEncounter

            // eslint-disable-next-line guard-for-in
            for (const key1 in observation) {
              const currentObServation: R4.IObservation = observation[
                key1
              ] as R4.IObservation

              if (currentObServation.encounter) {
                const ref =
                  currentObServation.encounter.reference?.split('/')[1]
                if (ref) {
                  if (currentEncounter.id === ref) {
                    const codeData = currentObServation.code
                      ? currentObServation.code.coding
                        ? currentObServation.code.coding[0].code ?? ''
                        : ''
                      : ''

                    const lastupdatedDate: string = currentObServation.meta
                      ? currentObServation.meta.lastUpdated
                        ? moment(currentObServation.meta.lastUpdated).format(
                            'DD-MM-YYYY'
                          )
                        : ''
                      : ''

                    const date = currentObServation.issued
                      ? moment(currentObServation.issued).format('DD-MM-YYYY')
                      : lastupdatedDate
                    if (
                      codeData === '5811-5' ||
                      codeData === '5803-2' ||
                      codeData === '5778-6' ||
                      codeData === '5767-9' ||
                      codeData === '5799-2' ||
                      codeData === '20454-5' ||
                      codeData === '25428-4' ||
                      codeData === '2514-8' ||
                      codeData === '5794-3' ||
                      codeData === '5809-9'
                    )
                      vitalsData.push({
                        startDate: currentObServation.issued
                          ? currentObServation.issued
                          : lastupdatedDate,
                        observation: currentObServation,
                      })
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return vitalsData
}

export function getLatestObservations(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []

  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      const indexOfBP = vitals.findIndex(
        (e) =>
          getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes(
            codeData
          ) && e.code === codeDataText
      )
      if (indexOfBP < 0) {
        vitals.push(currentObServation)
      }
      vitals.sort((a, b) =>
        a.code!.text! > b.code!.text!
          ? 1
          : b.code!.text! > a.code!.text!
          ? -1
          : 0
      )
    }
  }
  return vitals
}

export function getLatestObservationsByDate(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []

  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      const date = currentObServation.issued
        ? moment(currentObServation.issued).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD')

      const indexOfBP = vitals.findIndex((e) =>
        getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes(codeData)
      )

      const observationFinal = vitals.filter(
        (o) =>
          (moment(date).isAfter(
            o.issued
              ? moment(o.issued).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD')
          ) ||
            moment(date).isBefore(
              o.issued
                ? moment(o.issued).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD')
            )) &&
          getDefaultCodeOfSystemFromCodableConcept(o.code)?.includes(codeData)
      )

      vitals.push(currentObServation)

      vitals.sort((a, b) =>
        a.code!.text! > b.code!.text!
          ? 1
          : b.code!.text! > a.code!.text!
          ? -1
          : 0
      )
    }
  }
  return vitals
}

export function getLatestObservationsForOCA(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []

  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      const indexOfBP = vitals.findIndex((e) =>
        getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes(codeData)
      )
      if (indexOfBP < 0) {
        vitals.push(currentObServation)
      }
    }
  }
  return vitals
}

export function getObserVationFfromEncounterForDateWiseForHistory(
  responseBundle: R4.IObservation[]
): VitalsData[] {
  const vitals: R4.IObservation[] = []
  const vitalsData: VitalsData[] = []
  const observation: any = {}
  const encounters: any = {}

  for (let i = 0; i < responseBundle.length; i++) {
    const lastupdatedDate: string = responseBundle[i].meta
      ? responseBundle[i].meta?.lastUpdated
        ? moment(responseBundle[i].meta?.lastUpdated).format('DD-MM-YYYY')
        : ''
      : ''
    if (responseBundle[i].status && responseBundle[i].status === 'final') {
      vitalsData.push({
        startDate: responseBundle[i].issued
          ? responseBundle[i].issued ?? ''
          : lastupdatedDate ?? '',
        observation: responseBundle[i],
      })
    }
  }

  return vitalsData
}

export function getDataString(signData: WelloSignExam): string {
  let data: string = ''

  if (signData.type) {
    data = signData.condition
      ? `On ${signData.type.display ?? ''} ${
          signData.condition.display ?? ''
        } at ${signData.site ? signData.site.display ?? '' : ''}`
      : ''
  }
  if (signData.type === undefined) {
    data = signData.condition
      ? `On Examination ${signData.condition.display ?? ''} ${
          signData.site ? signData.site.display ?? '' : ''
        }`
      : ''
  }

  return data
}

export function getLatestObservationsPhysical(
  responseBundle: R4.IObservation[]
): R4.IObservation[] {
  const vitals: R4.IObservation[] = []

  if (responseBundle) {
    for (let i = 0; i < responseBundle.length; i++) {
      const currentObServation: R4.IObservation = responseBundle[i]

      const codeData = currentObServation.code
        ? currentObServation.code.coding
          ? currentObServation.code.coding[0].code ?? ''
          : ''
        : ''

      const codeDataText = currentObServation.code
        ? currentObServation.code.text ?? ''
        : ''

      if (
        codeData.includes('165184000') ||
        codeData.includes('1759002') ||
        codeData.includes('1237486008') ||
        codeData.includes('18165001') ||
        codeData.includes('4373005') ||
        codeData.includes('30746006') ||
        codeData.includes('3415004')
      ) {
      } else {
        const indexOfBP = vitals.findIndex(
          (e) =>
            getDefaultCodeOfSystemFromCodableConcept(e.code)?.includes(
              codeData
            ) && e.code === codeDataText
        )
        if (indexOfBP < 0) {
          vitals.push(currentObServation)
        }
      }

      vitals.sort((a, b) =>
        a.code!.text! > b.code!.text!
          ? 1
          : b.code!.text! > a.code!.text!
          ? -1
          : 0
      )
    }
  }
  return vitals
}
