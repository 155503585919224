/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  CircularProgress,
} from '@material-ui/core'
import {
  faEdit,
  faFilePdf,
  faFileWord,
  faTrash,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'

import { kPrimaryLight } from 'configs/styles/muiThemes'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiUpload, FiVideo } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetPatientSearchPatient,
  searchPatients,
} from 'redux/patient/patientSearch/patientSearchSlice'
import { RootState } from 'redux/rootReducer'
import { GenderList, preDefinedCountry } from 'utils/constants'
import {
  maritalListFhirList,
  martialList,
} from 'utils/constants/marital_status_types'
import {
  AddressProofType,
  AddressProofTypeForForeign,
} from 'utils/constants/proof_types'
import {
  getAddressOfThePatient,
  getAddressProofIdNumber,
  getAddressProofTypeCode,
  getAgeOfPatient,
  getCityOfThePatient,
  getFileSizeInMB,
  getGenderOfPatient,
  getIdentifierValueBySystem,
  getMaritalStatusOfPatient,
  getNameFromHumanName,
  getOccupationStatusOfPatient,
  getPatientDateOfBirth,
  getStateOfThePatient,
} from 'utils/fhirResourcesHelper'
import {
  getFhirPatientObjectFromForm,
  getFhirRelatedObjectHSForm,
} from 'utils/formHelper'
import { getFhirRelatedObjectFromForm } from 'utils/labHelpers/patientBulkUploadHelper'
import { WelloPlaceFinder } from 'views/mapComponent/placeAutoComplete'
import {
  Controls,
  Form,
  getLastNameOfPatient,
  getMiddleNameOfPatient,
  getNameOfPatient,
  getTelecomOfPatient,
  WelloLoadingIndicator,
  WelloTextField,
} from 'wello-web-components'

import female from '../../../assets/icons/f.png'
import male from '../../../assets/icons/m.png'
import {
  validateAddress,
  validateCity,
  validateDateForPassportIssueDate,
  validateDateForPassportValidTill,
  validateDob,
  validateEmail,
  validateFathersName,
  validateGender,
  validateHusbandsName,
  validateLastName,
  validateLRNumber,
  validateMaritalStatus,
  validateMiddleName,
  validateMobileNumberForAdd,
  validateName,
  validateNationality,
  validateOtherData,
  validatePermanentAddress,
  validateProfilePic,
  validateTemporaryAddress,
  validateVisaNumber,
  validateVisaType,
  visaCityValidate,
  visaExpiryDateValidateForNewPatient,
  visaFormForPatientDateValidation,
} from '../../../utils/formValidators'
import { WebCamCapturePhoto } from '../administration/actors/micro-components/webcam_capture'
import { PhoneNumber } from '../LeftMenu/PhoneNumber'
import { WelloButton } from '../LeftMenu/WelloButton'
import { WelloDate } from '../LeftMenu/welloDate'
import { WelloEmailInput } from '../LeftMenu/welloEmailInput'
import { WelloFilePicker } from '../LeftMenu/WelloFilePicker'
import { WelloFormItemLabel } from '../LeftMenu/WelloFormItemLabel'
import { WelloSelectFHIRCoding } from '../LeftMenu/WelloSelectFHIRCoding'
import { WelloSelectFormForPatient } from '../LeftMenu/welloSelectFormPatient'
import { WelloSelectGenderPatient } from '../LeftMenu/welloSelectGenderSelectorForPatient'
import { stateList, WelloCountrySelector } from '../LeftMenu/WelloStateSelector'
import { WelloAddressTypeSelector } from '../LeftMenu/WelloType'
import { UseForm } from '../UseForm'
import { ChoosePatient } from './choosePatientDialog'
import { ValueSetSelectSingle } from '../common/SingleSelectFromValueSetUrl'
import { ValueSetSelectSingleForOther } from '../common/singleSelectValueFromValueSetUrlOther'

export interface IFormLabels {
  nameLabel?: string
  mNameLabel?: string
  lNameLabel?: string
  emailLabel?: string
  phoneNumberLabel?: string
  genderLabel?: string
  addressLabel?: string
  photoLabel?: string
  identityLabel?: string
  maritalStatusLabel?: string
  nationalityLabel?: string
  occupationLabel?: string
  fatherNameLabel?: string
  husbandNameLabel?: string
  referenceIdLabel?: string
  addressTypeLabel?: string
  doblabelForKey?: string
}

export interface IPlaceHolderLablesLabels {
  nameLabel?: string
  mNameLabel?: string
  lNameLabel?: string
  emailLabel?: string
  phoneNumberLabel?: string
  genderLabel?: string
  addressLabel?: string
  photoLabel?: string
  identityLabel?: string
  maritalStatusLabel?: string
  nationalityLabel?: string
  occupationLabel?: string
  fatherNameLabel?: string
  husbandNameLabel?: string
  addPatient?: string
  addPhoto?: string
  doblabel?: string
  referenceIdLabel?: string
  addressTypeLabel?: string
}
export interface IUnitAdmin {
  isLab: boolean
  isAppointment: boolean
  children?: React.ReactNode
  id: number
  count: number
  submitButtonText?: string
  resetButtonText?: string
  loading: boolean
  onValidSubmit?: (
    patientDetail: R4.IPatient,
    relatedPersonDetail?: R4.IRelatedPerson,
    relatedPersonHusbandDetail?: R4.IRelatedPerson,
    occupation?: string,
    nationality?: string,
    addressProofFront?: R4.IAttachment,
    addressProofBack?: R4.IAttachment,
    addressProofBoth?: R4.IAttachment,
    referredId?: string,
    relation?: R4.ICoding,
    isPrimary?: boolean,
    passportStartDate?: string,
    expiryDate?: string,
    passportIssueCity?: string,
    passportIssueCountry?: string
  ) => void
  preAddedPatient?: R4.IPatient
  preAddedRelatedPatient?: R4.IRelatedPerson
  onClickToCancel: (val: boolean) => void
  formLabels?: IFormLabels
  placeholderLables?: IPlaceHolderLablesLabels
  popupSate: boolean
}

const errorValues = {
  name: '',
  phone: '',
  email: '',
  address: '',
}

function getInitializeValues(
  patient?: R4.IPatient,
  relatedPerson?: R4.IRelatedPerson
) {
  let initialFValues = {
    id: '0',
    name: '',
    middleName: '',
    lastName: '',
    gender: '',
    phone: '',
    email: '',
    photoUri: '',
    profilePhotoMimeType: '',
    profilePhotoSize: 0,
    imgName: '',

    dob: '',
    passportIssueDate: '',
    passportValidDate: '',

    age: 0,
    width: 60,

    maritalStatus: '',
    addressType: 'home',
    nationality: 'IN',
    occupation: '',
    fathersName: '',
    husbandName: '',
    lrNumber: '',
    address: '',
    tempAddress: '',
    otherData: '',
    addressProofTypeCode: '',
    addressProofId: '',
    addressProofTypeFrontPath: '',
    addressProofTypeFrontPathSize: 0,
    mapAddress: '',
    city: '',
    state: '',
    country: '',
    tempCity: '',
    tempState: '',
    passportIssueCity: '',
    passportIssueCountry: '',

    addressProofTypeFrontName: '',
    addressProofTypeFrontMimeType: '',
    addressProofTypeBackPath: '',
    addressProofTypeBackPathSize: 0,
    addressProofTypeBackFileName: '',
    addressProofTypeBackMimeType: '',
    addressProofTypeBoth: '',
    addressProofTypeBothSize: 0,
    addressProofTypeBothName: '',
    addressProofTypeBothMimeType: '',
    visaNo: '',
    visaType: '',
    visaIssueDate: '',
    visaExpiryDate: '',
    visaIssueCity: '',
    visaIssueCountry: '',
  }
  if (patient != null || relatedPerson != null) {
    initialFValues = {
      id: patient?.id ?? '',
      name: getNameOfPatient(patient!) ?? '',
      middleName: getMiddleNameOfPatient(patient!) ?? '',
      lastName: getLastNameOfPatient(patient!) ?? '',
      gender: getGenderOfPatient(patient!),
      phone:
        getTelecomOfPatient(patient!, R4.ContactPointSystemKind._phone) !==
        undefined
          ? getTelecomOfPatient(patient!, R4.ContactPointSystemKind._phone)!
              .replace(/\D/g, '')
              .slice(-10)
          : '' ?? '',
      email:
        getTelecomOfPatient(patient!, R4.ContactPointSystemKind._email) ?? '',
      photoUri: '',
      profilePhotoMimeType: '',
      imgName: '',
      profilePhotoSize: 0,
      dob: getPatientDateOfBirth(patient!),
      age: getAgeOfPatient(patient!),
      width: 60,
      passportIssueDate: '',
      passportValidDate: '',
      maritalStatus: getMaritalStatusOfPatient(patient!),
      addressType: 'home',
      nationality: 'IN',
      country: '',
      occupation: getOccupationStatusOfPatient(patient!) ?? '',
      fathersName: getNameFromHumanName(relatedPerson?.name ?? []),
      husbandName: '',
      lrNumber:
        getIdentifierValueBySystem(patient!.identifier ?? [], 'labReference') ??
        '',
      address: getAddressOfThePatient(patient!),
      tempAddress: '',
      mapAddress: '',
      city: getCityOfThePatient(patient!),
      state: getStateOfThePatient(patient!),
      tempCity: '',
      tempState: '',
      otherData: '',
      addressProofTypeCode: getAddressProofTypeCode(patient!),
      addressProofId: getAddressProofIdNumber(patient!),
      addressProofTypeFrontPath: '',
      addressProofTypeFrontPathSize: 0,
      addressProofTypeFrontName: '',
      addressProofTypeFrontMimeType: '',
      addressProofTypeBackPath: '',
      addressProofTypeBackPathSize: 0,
      addressProofTypeBackFileName: '',
      addressProofTypeBackMimeType: '',
      addressProofTypeBothSize: 0,
      addressProofTypeBoth: '',
      addressProofTypeBothName: '',
      addressProofTypeBothMimeType: '',
      passportIssueCity: '',
      passportIssueCountry: '',
      visaNo: '',
      visaType: '',
      visaIssueDate: '',
      visaExpiryDate: '',
      visaIssueCity: '',
      visaIssueCountry: '',
    }
  }
  return initialFValues
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  topHeader: {
    color: theme.palette.common.white,
    marginBottom: 5,
  },

  mainPage: {
    maxWidth: 960,
    height: 'auto',
    backgroundColor: '#cdcdcd',
  },
  distance: {
    marginTop: 10,
  },

  large: {
    width: 60,
    height: 60,
  },
  ageLabel: {
    height: 39,
    width: 59,
    borderRadius: 6,
    backgroundColor: '#ffffff',
    marginTop: 16,
    textAlign: 'center',
  },
  overlapping: {
    height: 20,
    display: 'inline-flex',
    align: 'center',
    marginLeft: 5,
    marginTop: 14,
    fontSize: 12,
    fontWeight: 600,
  },
  textLabel: {
    textTransform: 'uppercase',
  },
}))

export const NewPatient: React.FC<IUnitAdmin> = ({
  isLab,
  isAppointment,
  children,
  count,
  id,
  submitButtonText,
  resetButtonText,
  preAddedPatient,
  preAddedRelatedPatient,
  loading,
  onClickToCancel,
  onValidSubmit,
  formLabels = {},
  placeholderLables = {},
  popupSate = false,
}: IUnitAdmin) => {
  const classes = useStyles()
  const initialFValues: any = getInitializeValues(
    preAddedPatient,
    preAddedRelatedPatient
  )
  const kPrimaryColor: string = useTheme().palette.primary.main
  const [openWebCam, setOpenWebCam] = useState<boolean>(false)
  const [referredId, setReferredId] = useState<string>()
  const [isPrimary, setIsPrimary] = useState<boolean>()
  const [relation, setRelation] = useState<R4.ICoding>()
  const [openPopup, setOpenPopup] = useState(popupSate)

  const [dialog, setDialog] = useState<boolean>(false)

  const patientsSearchState = useSelector(
    (state: RootState) => state.patientSearchSlice
  )

  const [valueData, setValueData] = useState('file')
  const [photoType, setPhotoType] = useState('both')
  const [profilePicData, setProfilePicData] = useState('system')
  const [systemPic, setSystemPic] = useState<boolean>(true)

  const [callBackForCapturePhoto, setCallBackForCapturePhoto] = React.useState(
    () => (imageName: any, imageDataType: any, imageUri: any, data?: any) => {}
  )
  const [aspectRatio, setAspectRatio] = useState<number>(1)

  const dispatch = useDispatch()

  function validate(fieldValues = values): boolean | undefined {
    const temp = { ...errors }

    if ('email' in fieldValues)
      temp.email = validateEmail(fieldValues.email, false)
    if ('name' in fieldValues) {
      temp.name = validateName(fieldValues.name, true)
    }

    if ('city' in fieldValues) {
      temp.city = validateCity(fieldValues.city, isLab === false)
    }
    if ('lastName' in fieldValues) {
      temp.lastName = validateLastName(fieldValues.lastName, true)
    }
    if ('dob' in fieldValues) temp.dob = validateDob(fieldValues.dob, true)
    if ('phone' in fieldValues) {
      temp.phone = validateMobileNumberForAdd(fieldValues.phone, true)
    }
    if ('photoUri' in fieldValues) {
      temp.photoUri = validateProfilePic(
        fieldValues.photoUri,
        isLab,
        isAppointment
      )
    }
    if (
      fieldValues.nationality &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality === 'IN')
    ) {
      if ('address' in fieldValues)
        temp.address = validateAddress(fieldValues.address, isLab === false)
    } else if ('address' in fieldValues) {
      temp.address = validatePermanentAddress(
        fieldValues.address,
        isLab === false
      )
    }

    if (
      fieldValues.nationality &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality !== 'IN')
    ) {
      if ('tempAddress' in fieldValues)
        temp.tempAddress = validateTemporaryAddress(
          fieldValues.tempAddress,
          true
        )
    }

    if (
      fieldValues.nationality &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality !== 'IN')
    ) {
      if ('visaNo' in fieldValues)
        temp.visaNo = validateVisaNumber(fieldValues.visaNo, true)
    }

    if (
      fieldValues.nationality &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality !== 'IN')
    ) {
      if ('visaType' in fieldValues)
        temp.visaType = validateVisaType(fieldValues.visaType, true)
    }

    if (
      fieldValues.nationality &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality !== 'IN')
    ) {
      if ('visaIssueDate' in fieldValues)
        temp.visaIssueDate = visaFormForPatientDateValidation(
          fieldValues.passportIssueDate,
          fieldValues.passportValidDate,
          true,
          fieldValues.visaIssueDate
        )
    }

    if (
      fieldValues.nationality &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality !== 'IN')
    ) {
      if ('visaExpiryDate' in fieldValues)
        temp.visaExpiryDate = visaExpiryDateValidateForNewPatient(
          fieldValues.passportIssueDate,
          true,
          fieldValues.visaIssueDate,
          fieldValues.visaExpiryDate
        )
    }

    if (
      fieldValues.nationality &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality !== 'IN')
    ) {
      if ('visaIssueCity' in fieldValues)
        temp.visaIssueCity = visaCityValidate(fieldValues.visaIssueCity, true)
    }

    if (
      fieldValues.nationality &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality !== 'IN')
    ) {
      if ('visaType' in fieldValues)
        temp.visaType = validateVisaType(fieldValues.visaType, true)
    }

    if (
      fieldValues.nationality &&
      isLab === false &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality !== 'IN')
    ) {
      if ('passportIssueDate' in fieldValues)
        temp.passportIssueDate = validateDateForPassportIssueDate(
          fieldValues.passportIssueDate,
          fieldValues.passportValidDate,
          true,
          fieldValues.dob
        )
    }

    if (
      fieldValues.nationality &&
      isLab === false &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality !== 'IN')
    ) {
      if ('passportValidDate' in fieldValues)
        temp.passportValidDate = validateDateForPassportValidTill(
          fieldValues.passportValidDate,
          fieldValues.passportIssueDate,
          true
        )
    }

    if (
      fieldValues.nationality &&
      isLab === false &&
      (fieldValues.nationality.length === 0 || fieldValues.nationality !== 'IN')
    ) {
      if ('passportIssueCity' in fieldValues)
        temp.passportIssueCity = validateCity(
          fieldValues.passportIssueCity,
          true
        )
    }

    if ('lrNumber' in fieldValues)
      temp.lrNumber = validateLRNumber(fieldValues.lrNumber, isLab)

    if ('gender' in fieldValues)
      temp.gender = validateGender(fieldValues.gender, true)
    if ('fathersName' in fieldValues)
      temp.fathersName = validateFathersName(fieldValues.fathersName, false)

    if ('husbandName' in fieldValues)
      temp.husbandName = validateHusbandsName(fieldValues.husbandName, false)
    if ('middleName' in fieldValues)
      temp.middleName = validateMiddleName(fieldValues.middleName, false)

    // if ('fathersName' in fieldValues)
    //   temp.fathersName = validateFathersName(fieldValues.fathersName, true)

    if ('nationality' in fieldValues)
      temp.nationality = validateNationality(fieldValues.nationality, true)

    if ('occupation' in fieldValues) {
      if (fieldValues.occupation === 'other') {
        if ('otherData' in fieldValues)
          temp.otherData = validateOtherData(fieldValues.otherData, true)
      }
    }
    if (isLab === false) {
      if ('addressProofTypeCode' in fieldValues) {
        if (
          fieldValues.addressProofTypeCode === undefined ||
          fieldValues.addressProofTypeCode.length === 0
        ) {
          temp.addressProofTypeCode = 'Proof Type is required'
        } else {
          temp.addressProofTypeCode = ''
        }
      }
    }

    if (isLab === false) {
      if ('addressProofId' in fieldValues) {
        if (fieldValues.addressProofId.length === 0)
          temp.addressProofId = 'Enter Valid Proof ID'
        else {
          temp.addressProofId = ''
        }
      }
    }
    if (isLab === false) {
      if (valueData === 'file') {
        if (photoType !== 'both') {
          if ('addressProofTypeFrontPath' in fieldValues) {
            if (fieldValues.addressProofTypeFrontPath.length === 0) {
              temp.addressProofTypeFrontPath = 'Address Proof photo is required'
            } else if (
              getFileSizeInMB(fieldValues.addressProofTypeFrontPathSize) > 5
            ) {
              temp.addressProofTypeFrontPath =
                'Address Proof photo size must be of <= 5 MB'
            } else {
              temp.addressProofTypeFrontPath = ''
            }
          }
          if ('addressProofTypeBackPath' in fieldValues) {
            if (fieldValues.addressProofTypeBackPath.length === 0) {
              temp.addressProofTypeBackPath = 'Address Proof photo is required'
            } else if (
              getFileSizeInMB(fieldValues.addressProofTypeBackPathSize) > 5
            ) {
              temp.addressProofTypeBackPath =
                'Address Proof photo size must be of <= 5 MB'
            } else {
              temp.addressProofTypeBackPath = ''
            }
          }
        } else if (
          'addressProofTypeBoth' in fieldValues &&
          'addressProofTypeBothSize' in fieldValues
        ) {
          if (fieldValues.addressProofTypeBoth.length === 0) {
            temp.addressProofTypeBoth = 'Address Proof photo is required'
          } else if (
            getFileSizeInMB(fieldValues.addressProofTypeBothSize) > 5
          ) {
            temp.addressProofTypeBoth =
              'Address Proof photo size must be of <= 5 MB'
          } else {
            temp.addressProofTypeBoth = ''
          }
        }
      }
    }
    if (isLab === false) {
      if (valueData === 'camera') {
        if ('addressProofTypeFrontPath' in fieldValues) {
          if (fieldValues.addressProofTypeFrontPath.length === 0) {
            temp.addressProofTypeFrontPath = 'Address Proof photo is required'
          } else {
            temp.addressProofTypeFrontPath = ''
          }
        }
        if ('addressProofTypeBackPath' in fieldValues) {
          if (fieldValues.addressProofTypeBackPath.length === 0) {
            temp.addressProofTypeBackPath = 'Address Proof photo is required'
          } else {
            temp.addressProofTypeBackPath = ''
          }
        }
      }
    }

    if ('maritalStatus' in fieldValues)
      temp.maritalStatus = validateMaritalStatus(
        fieldValues.maritalStatus,
        isLab === false
      )

    setErrors({
      ...temp,
    })

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')

    return undefined
  }

  function handleSubmit() {
    if (validate()) {
      const pat: R4.IPatient | undefined = getFhirPatientObjectFromForm(values)
      const relatedPat: R4.IRelatedPerson | undefined =
        getFhirRelatedObjectFromForm(values)
      const relatedPatHs: R4.IRelatedPerson | undefined =
        getFhirRelatedObjectHSForm(values.pa)
      const occupation: string | undefined =
        values.occupation === 'other' ? values.otherData : values.occupation
      const { nationality } = values
      let addressProofTypeFrontAttachment: R4.IAttachment | undefined
      if (
        values.addressProofTypeFrontPath &&
        values.addressProofTypeFrontMimeType
      ) {
        addressProofTypeFrontAttachment = {
          data: values.addressProofTypeFrontPath,
          contentType: values.addressProofTypeFrontMimeType,
          title: values.addressProofTypeFrontName,
        }
      }
      let addressProofTypeBackAttachment: R4.IAttachment | undefined
      if (
        values.addressProofTypeBackPath &&
        values.addressProofTypeBackMimeType
      ) {
        addressProofTypeBackAttachment = {
          data: values.addressProofTypeBackPath,
          contentType: values.addressProofTypeBackMimeType,
          title: values.addressProofTypeBackFileName,
        }
      }

      let addressProofTypeBothAttachment: R4.IAttachment | undefined
      if (values.addressProofTypeBoth && values.addressProofTypeBothMimeType) {
        addressProofTypeBothAttachment = {
          data: values.addressProofTypeBoth,
          contentType: values.addressProofTypeBothMimeType,
          title: values.addressProofTypeBothFileName,
        }
      }

      if (pat) {
        if (onValidSubmit)
          onValidSubmit(
            pat,
            relatedPat,
            relatedPatHs,
            occupation,
            nationality,
            addressProofTypeFrontAttachment,
            addressProofTypeBackAttachment,
            addressProofTypeBothAttachment,
            referredId,
            relation,
            isPrimary,
            values.passportIssueDate,
            values.passportValidDate,
            values.passportIssueCity,
            values.passportIssueCountry
          )
        /* popupSate = false
        setOpenPopup(popupSate) */
      } else {
      }
    } else {
    }
  }
  const cancel = () => {
    onClickToCancel(false)
    setOpenPopup(false)
  }
  const handleClose = () => {
    onClickToCancel(false)
    setOpenPopup(false)
  }
  const { t } = useTranslation()
  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    handleInputChangeForPhoto,
    uploadPic,
    handleDateChange,
    handleInputChangeForProofs,
    handlePhoneChange,
    handleInputChangeForOccupation,
    handleChangeCity,
    handleChangeState,
    handleChangeCityForTempAddress,
    handleInputChangeNationality,
    handleDateChangeForPassport,
    handleDateChangeForPassportSeperate,
    handleChangeVisaCity,
    handleGenderData,
  } = UseForm(initialFValues, errorValues, true, validate)

  // eslint-disable-next-line no-restricted-globals
  const isSecure = location.protocol !== 'https:'

  const theme = useTheme()

  const occupations = [
    { id: 'govtOfficer', title: 'Govt. Officer' },
    { id: 'Politician', title: 'Politician' },
    { id: 'Self Employed', title: 'Self Employed' },
    { id: 'businessman', title: 'Businessman' },
    { id: 'Doctor', title: 'Doctor' },
    { id: 'Hospitality service', title: 'Hospitality service' },
    { id: 'Pvt Sector Executive', title: 'Pvt Sector Executive' },
    { id: 'Chartered Accountant', title: 'Chartered Accountant' },
    { id: 'Finance Professional', title: 'Finance Professional' },
    { id: 'Housewife', title: 'Housewife' },
    { id: 'Student', title: 'Student' },
  ].sort((a, b) => (a.title! > b.title! ? 1 : b.title! > a.title! ? -1 : 0))

  useEffect(() => {
    if (validateMobileNumberForAdd(values.phone, true).length === 0) {
      dispatch(searchPatients(values.phone, ''))
    }
  }, [values.phone])

  useEffect(() => {
    if (values.phone.length === 10 && dialog === false) {
      if (
        patientsSearchState.resultsAvailable &&
        patientsSearchState.groupDataList
      ) {
        setDialog(true)
      }
    } else if (values.phone.length !== 0) {
      dispatch(resetPatientSearchPatient())
    }
  }, [patientsSearchState.patientList, patientsSearchState.resultsAvailable])

  return (
    <Dialog
      open={openPopup}
      maxWidth='md'
      fullWidth
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {placeholderLables?.addPatient ?? 'Add Patient'}
      </DialogTitle>
      <DialogContent dividers={true}>
        {/* <Paper className={classes.mainPage} square={true} variant='outlined'> */}

        {patientsSearchState.searching && (
          <WelloLoadingIndicator message='Searching' />
        )}

        <Form
          onSubmit={() => {
            handleSubmit()
          }}
          title={placeholderLables?.addPatient ?? 'Add Patient'}
        >
          <Box display='flex' flexDirection='column'>
            <Box
              display='flex'
              width='100%'
              justifyContent='flex-start'
              flexDirection='row'
            >
              <Box
                display='flex'
                flexDirection='row'
                width={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? values.photoUri.length > 0
                      ? '24%'
                      : '23.5%'
                    : '24%'
                }
              >
                <Box paddingRight={1}>
                  <FormControl>
                    <FormLabel id='demo-row-radio-buttons-group-label'>
                      <Typography variant='subtitle2' color='textPrimary'>
                        Capture Type
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      id={`${id}rad_grp`}
                      row
                      aria-label='graphType'
                      value={profilePicData}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        values.imgName = ''
                        values.data = ''
                        values.photoUri = ''

                        setProfilePicData(
                          (event.target as HTMLInputElement).value
                        )
                        if (
                          (event.target as HTMLInputElement).value === 'system'
                        ) {
                          setSystemPic(true)
                        }
                      }}
                    >
                      <Box display='flex' flexDirection='column'>
                        <FormControlLabel
                          value='system'
                          id={`${id}profilePic_system`}
                          control={<Radio />}
                          label={
                            <Typography variant='subtitle2' color='primary'>
                              {' '}
                              File Upload{' '}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          id={`${id}profilePic_Camera`}
                          value='camera'
                          control={<Radio />}
                          label={
                            <Typography variant='subtitle2' color='primary'>
                              Camera
                            </Typography>
                          }
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>

                {values.photoUri.length >= 0 &&
                  profilePicData === 'system' &&
                  systemPic && (
                    <Box
                      paddingLeft={
                        systemPic && values.photoUri.length > 0 ? 0 : 0
                      }
                      paddingTop={
                        systemPic && values.photoUri.length > 0 ? 3 : 3
                      }
                      display='flex'
                      flexDirection='column'
                    >
                      <WelloFilePicker
                        fileTypeToPick='photo'
                        id={`${id}ProfilePicker`}
                        name='profilePic'
                        displaySelectedPhoto='circle'
                        onFileChanged={(selectedFile) => {
                          if (
                            selectedFile.path &&
                            selectedFile.type &&
                            selectedFile.files
                          ) {
                            const data = selectedFile.path
                              .toString()
                              .split(',')[1]
                            values.imgName = data
                            values.data = selectedFile.files.type
                            values.photoUri = selectedFile.path
                            setSystemPic(false)
                          }
                        }}
                        onDeleteClicked={() => {
                          //   handleInputChangeForProofs(
                          //     'back',
                          //     '',
                          //     'addProof_back',
                          //     0,
                          //     ''
                          //   )
                        }}
                        size='small'
                        showSelectedFileName={false}
                        allowUpdate={true}
                        allowDelete={true}
                        required={errors.photoUri && errors.photoUri.length > 0}
                        labelIcon={
                          <h5>
                            <FiUpload />
                          </h5>
                        }
                        labelName={t('labelCommon:select_file')}
                      />
                      <Box p={0.1} px={0.25}>
                        <Typography
                          variant='subtitle2'
                          color='error'
                          style={{ fontWeight: 500 }}
                          id={`${id}error_profile_pic_camera}`}
                        >
                          {errors.photoUri}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                <Box display='flex' flexDirection='column'>
                  {values.photoUri.length === 0 && profilePicData === 'camera' && (
                    <Box display='flex' flexDirection='column'>
                      <Box
                        display='flex'
                        flexDirection='row'
                        style={{ cursor: 'pointer' }}
                        alignItems='center'
                        paddingTop={2.5}
                        id={`${id}profile_pic}`}
                        onClick={() => {
                          setCallBackForCapturePhoto(
                            () =>
                              (
                                imageName: any,
                                dataType: any,
                                image: any,
                                data?: any
                              ) => {
                                values.imgName = imageName
                                values.profilePhotoMimeType = data
                                values.photoUri = image
                              }
                          )
                          console.log(values)
                          setAspectRatio(1)
                          setSystemPic(false)
                          setOpenWebCam(true)
                        }}
                      >
                        <Box paddingRight={0.5}>
                          <h5>
                            {' '}
                            <FiVideo />{' '}
                          </h5>
                        </Box>
                        <Box py={1} display='flex' flexDirection='column'>
                          <Box>
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              id={`${id}device_profile_pic_camera}`}
                            >
                              Camera
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box p={0.4} px={0.25}>
                        <Typography
                          variant='subtitle2'
                          color='error'
                          style={{ fontWeight: 500 }}
                          id={`${id}error_profile_pic_camera}`}
                        >
                          {errors.photoUri}
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  {values.photoUri && systemPic === false && (
                    <>
                      <Box paddingRight={2} paddingTop={2} display='flex'>
                        <Box paddingLeft={1}>
                          <Avatar
                            alt='avatar'
                            src={values.photoUri}
                            className={classes.large}
                          />
                        </Box>
                        <Box>
                          <FontAwesomeIcon
                            id='edit_icon'
                            size='1x'
                            icon={faEdit}
                            style={{ cursor: 'pointer', color: kPrimaryColor }}
                            color={kPrimaryColor}
                            onClick={() => {
                              setCallBackForCapturePhoto(
                                () =>
                                  (
                                    imageName: any,
                                    dataType: any,
                                    image: any,
                                    data?: any
                                  ) => {
                                    values.imgName = imageName
                                    values.profilePhotoMimeType = data
                                    values.photoUri = image
                                  }
                              )
                              if (profilePicData === 'camera') {
                                setAspectRatio(1)
                                setOpenWebCam(true)
                              } else {
                                setSystemPic(true)
                              }
                            }}
                          />
                        </Box>
                        {/* 
                        <Button
                          color='primary'
                          size='small'
                          onClick={() => {
                            setCallBackForCapturePhoto(
                              () =>
                                (
                                  imageName: any,
                                  dataType: any,
                                  image: any,
                                  data?: any
                                ) => {
                                  values.imgName = imageName
                                  values.profilePhotoMimeType = data
                                  values.photoUri = image
                                }
                            )
                            if (profilePicData === 'camera') {
                              setAspectRatio(1)
                              setOpenWebCam(true)
                            } else {
                              setSystemPic(true)
                            }
                          }}
                          id={`${id}_profile_pic_camera_change}`}
                        >
                          Change
                        </Button> */}
                      </Box>
                    </>
                  )}
                </Box>
                {/* <Box p={0.1}>
                  <Typography
                    variant='subtitle2'
                    color='error'
                    id={`${id}error_profile_pic_camera}`}
                  >
                    {errors.photoUri}
                  </Typography>
                </Box> */}
              </Box>

              <Box
                display='flex'
                width='67%'
                // paddingLeft={10}
              >
                <Box
                  paddingLeft={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? values.photoUri.length > 0
                        ? 1.2
                        : 0
                      : 0.5
                  }
                  paddingRight={1}
                  width={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? '32%'
                      : '35%'
                  }
                >
                  <WelloTextField
                    title='First Name'
                    textProps={{
                      required: true,
                      id: 'name',
                      placeholder: placeholderLables?.nameLabel ?? 'First Name',
                      name: 'name',
                      //   style: {
                      //     width: 588,
                      //   },
                      value: values.name,
                      onChange: (changeDescription) => {
                        handleInputChange(changeDescription)
                      },
                      error: errors.name,
                      helperText: errors.name,
                    }}
                  />
                  {/* <Controls.WelloInput
                    name='name'
                    required={false}
                    label='First Name'
                    type='text'
                    value={values.name}
                    error={errors.name}
                    placeholder={placeholderLables?.nameLabel ?? 'First Name'}
                    size='small'
                    onChange={handleInputChange}
                    charsAllowed={100}
                  /> */}
                </Box>
                <Box
                  px={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? 0.5
                      : 1
                  }
                  width={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? '32%'
                      : '35%'
                  }
                >
                  <WelloTextField
                    title='Middle Name'
                    textProps={{
                      required: true,
                      id: 'middleName',
                      placeholder: 'Optional',
                      name: 'middleName',
                      //   style: {
                      //     width: 588,
                      //   },
                      value: values.middleName,
                      onChange: (changeDescription) => {
                        handleInputChange(changeDescription)
                      },
                      error: errors.middleName,
                      helperText: errors.middleName,
                    }}
                  />
                  {/* <Controls.WelloInput
                    name='middleName'
                    required={false}
                    label='Middle Name'
                    type='text'
                    value={values.middleName}
                    error={errors.middleName}
                    placeholder='optional'
                    // width={235}
                    size='small'
                    onChange={handleInputChange}
                    charsAllowed={100}
                  /> */}
                </Box>
                <Box
                  px={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? 0.5
                      : 1
                  }
                  width={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? '32%'
                      : '35%'
                  }
                >
                  <WelloTextField
                    title='Last Name'
                    textProps={{
                      required: true,
                      id: 'lastName',
                      placeholder: placeholderLables?.lNameLabel ?? 'Last Name',
                      name: 'lastName',
                      //   style: {
                      //     width: 588,
                      //   },
                      value: values.lastName,
                      onChange: (changeDescription) => {
                        handleInputChange(changeDescription)
                      },
                      error: errors.lastName,
                      helperText: errors.lastName,
                    }}
                  />
                  {/* <Controls.WelloInput
                    name='lastName'
                    required={false}
                    label='Last Name'
                    type='text'
                    size='small'
                    value={values.lastName}
                    error={errors.lastName}
                    placeholder={placeholderLables?.lNameLabel ?? 'Last Name'}
                    // width={235}
                    onChange={handleInputChange}
                    charsAllowed={100}
                  /> */}
                </Box>

                {values.gender.length !== 0 && values.dob.length !== 0 && (
                  <Box px={1} paddingTop={2}>
                    <Paper className={classes.ageLabel}>
                      {values.gender === 'male' && (
                        <Typography
                          color='primary'
                          className={classes.overlapping}
                        >
                          <img src={male} alt='' height={15.3} width={5.33} />{' '}
                          &nbsp;
                          {values.age === 0
                            ? `${values.age}m`
                            : `${values.age}y`}
                        </Typography>
                      )}

                      {values.gender === 'other' && (
                        <Typography
                          color='primary'
                          className={classes.overlapping}
                        >
                          {/* <img
                                src={male}
                                alt=''
                                height={15.3}
                                width={5.33}
                              />{' '} */}
                          &nbsp;
                          {values.age === 0
                            ? `${values.age}m`
                            : `${values.age}y`}
                        </Typography>
                      )}

                      {values.gender === 'unknown' && (
                        <Typography
                          color='primary'
                          className={classes.overlapping}
                        >
                          &nbsp;
                          {values.age === 0
                            ? `${values.age}m`
                            : `${values.age}y`}
                        </Typography>
                      )}

                      {values.gender === 'female' && (
                        <Typography
                          color='primary'
                          className={classes.overlapping}
                        >
                          <img src={female} alt='' height={15.3} width={5.33} />{' '}
                          &nbsp;
                          {values.age === 0
                            ? `${values.age}m`
                            : `${values.age}y`}
                        </Typography>
                      )}
                    </Paper>{' '}
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              display='flex'
              paddingBottom={1}
              width='100%'
              paddingLeft={
                values.gender.length !== 0 &&
                values.dob.length !== 0 &&
                values.photoUri
                  ? 2
                  : 0
              }
            >
              <Box
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27.5
                }
              >
                <WelloDate
                  name='dob'
                  label='Date Of Birth'
                  placeholder={placeholderLables?.doblabel ?? 'dd/mm/yyyy'}
                  required={false}
                  value={values.dob}
                  width={286}
                  size='small'
                  error={errors.dob}
                  onChange={handleDateChange}
                />
              </Box>
              <Box
                paddingLeft={2}
                width={300}
                display='flex'
                flexDirection='column'
              >
                <WelloFormItemLabel title='Gender' />
                <Box display='flex' flexDirection='row' width='100%'>
                  <Autocomplete
                    style={{
                      borderRadius: '4px',
                    }}
                    fullWidth
                    size='small'
                    id='gender'
                    defaultValue={GenderList.find(
                      (occupation) => occupation.display === values.occupation
                    )}
                    autoFocus
                    disableClearable
                    getOptionSelected={(option, value) =>
                      option.display === value.display
                    }
                    getOptionLabel={(option) => option.display ?? ''}
                    options={GenderList}
                    onChange={(e, changedValue, reason) => {
                      if (changedValue) {
                        handleGenderData('gender', changedValue.id ?? '')
                      }
                    }}
                    autoComplete
                    ChipProps={{
                      deleteIcon: (
                        <ClearOutlined
                          style={{
                            height: '15px',
                            color: 'white',
                          }}
                        />
                      ),
                      style: {
                        backgroundColor: kPrimaryLight,
                        borderRadius: '4px',
                      },
                    }}
                    renderOption={(option) => (
                      <Typography variant='subtitle2'>
                        {option.display}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Select Gender'
                        fullWidth
                        id='gender_search'
                        variant='outlined'
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                </Box>
                <Box paddingLeft={2} id='genderError'>
                  <Typography
                    variant='subtitle2'
                    color='error'
                    style={{ fontWeight: 500 }}
                  >
                    {errors.gender}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display='flex'
              paddingLeft={
                values.gender.length !== 0 &&
                values.dob.length !== 0 &&
                values.photoUri
                  ? 2
                  : 0
              }
            >
              <Box
                paddingTop={0.37}
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27.5
                }
                display='flex'
                flexDirection='column'
                width={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? values.photoUri.length === 0
                      ? '56%'
                      : '57.5%'
                    : '57.5%'
                }
              >
                <WelloFormItemLabel title='Marital Status' />
                <Autocomplete
                  style={{
                    borderRadius: '4px',
                  }}
                  fullWidth
                  size='small'
                  id='maritalStatus'
                  defaultValue={maritalListFhirList.find(
                    (occupation: R4.ICoding) =>
                      occupation.display === values.maritalStatus
                  )}
                  autoFocus
                  disableClearable
                  getOptionSelected={(option, value) =>
                    option.display === value.display
                  }
                  getOptionLabel={(option) => option.display ?? ''}
                  options={maritalListFhirList}
                  onChange={(e, changedValue, reason) => {
                    if (changedValue) {
                      handleGenderData('maritalStatus', changedValue.code ?? '')
                    }
                  }}
                  autoComplete
                  ChipProps={{
                    deleteIcon: (
                      <ClearOutlined
                        style={{
                          height: '15px',
                          color: 'white',
                        }}
                      />
                    ),
                    style: {
                      backgroundColor: kPrimaryLight,
                      borderRadius: '4px',
                    },
                  }}
                  renderOption={(option) => (
                    <Typography variant='subtitle2'>
                      {option.display}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Select Marital Status'
                      fullWidth
                      id='maritalStatus_search'
                      variant='outlined'
                      size='small'
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
                <Box paddingLeft={2} id='maritalStatusError'>
                  <Typography
                    variant='subtitle2'
                    color='error'
                    style={{ fontWeight: 500 }}
                  >
                    {errors.maritalStatus}
                  </Typography>
                </Box>

                {/* <WelloSelectFormForPatient
                  name='maritalStatus'
                  required={true}
                  label='Marital Status'
                  type={
                    placeholderLables?.maritalStatusLabel ??
                    'Select Marital Status'
                  }
                  options={martialList()}
                  value={values.maritalStatus}
                  error={errors.maritalStatus}
                  width={285}
                  onChange={handleInputChange}
                /> */}
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                width={290}
                paddingLeft={1}
                paddingTop={0.2}
              >
                <WelloFormItemLabel title='Nationality' />
                <Autocomplete
                  style={{
                    borderRadius: '4px',
                  }}
                  fullWidth
                  size='small'
                  id='nationality'
                  disableClearable
                  getOptionSelected={(option, value) =>
                    option.display! === value.display!
                  }
                  getOptionLabel={(option) => option.display ?? ''}
                  options={
                    t(
                      'formCEntry:entry_attrs.visitorNationality.value-set'
                    ) as R4.ICoding[]
                  }
                  defaultValue={preDefinedCountry}
                  onChange={(e, changedValue, reason) => {
                    if (e) {
                      handleInputChangeNationality(
                        changedValue,
                        'nationality',
                        'country',
                        'addressProofTypeCode',
                        'visaIssueCountry'
                      )
                    }
                    // handleInputChangeForOccupation(
                    //   'occupation',
                    //   changedValue.title
                    // )
                  }}
                  autoComplete
                  ChipProps={{
                    deleteIcon: (
                      <ClearOutlined
                        style={{
                          height: '15px',
                          color: 'white',
                        }}
                      />
                    ),
                    style: {
                      backgroundColor: kPrimaryLight,
                      borderRadius: '4px',
                    },
                  }}
                  renderOption={(option) => (
                    <Typography variant='subtitle2'>
                      {option.display}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Select Occupation (Optional)'
                      fullWidth
                      variant='outlined'
                      id='nationality_search'
                      size='small'
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        style: {
                          borderRadius: 6,
                          maxWidth: '480px',
                          width: '285px',
                        },
                      }}
                    />
                  )}
                />
                {/* <WelloSelectFHIRCoding
                  title={formLabels?.nationalityLabel ?? 'NATIONALITY'}
                  id={`${id}nationality`}
                  duration='Select Nationality'
                  availableCodings={nationalityValueSet}
                  onChanged={(e) => {
                    if (e) {
                      handleInputChangeNationality(
                        e,
                        'nationality',
                        'country',
                        'addressProofTypeCode'
                      )
                    }
                  }}
                  //   onChanged={(val) => {}}
                  textFieldProps={{
                    fullWidth: true,
                    // required: true,
                    variant: 'outlined',
                    size: 'small',
                    // value: values.nationality,
                    style: {
                      maxWidth: '480px',
                      width: '283px',
                    },
                    // onChange: handleInputChange,
                    name: 'nationality',
                    error: errors.nationality,
                    helperText: errors.nationality,

                    id: 'patient_nationality',
                  }}
                  preSelectedCoding={preDefinedCountry}
                /> */}
                {/* <Controls.WelloSelectNationality
                  name='nationality'
                  required={true}
                  label={formLabels?.nationalityLabel ?? 'NATIONALITY'}
                  type={
                    placeholderLables?.nationalityLabel ?? 'Select Nationality'
                  }
                  value={values.nationality}
                  error={errors.nationality}
                  width={283}
                  onChange={handleInputChange}
                /> */}
              </Box>
            </Box>
            {/* ------------------Address Proof Start------------------------------- */}
            {isLab === false && (
              <Box
                display='flex'
                py={1}
                paddingLeft={
                  values.gender.length !== 0 &&
                  values.dob.length !== 0 &&
                  values.photoUri
                    ? 2
                    : 0
                }
                width='100%'
              >
                <Box
                  paddingLeft={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? 26
                      : 27.5
                  }
                >
                  {values.nationality !== 'IN' && (
                    <WelloTextField
                      title='Passport Number'
                      textProps={{
                        style: {
                          maxWidth: '480px',
                          width: '285px',
                        },
                        id: `address_proof_number`,
                        fullWidth: true,
                        required: true,
                        onChange: handleInputChange,
                        value: values.addressProofId,
                        name: 'addressProofId',
                        error: errors.addressProofId,
                        helperText: errors.addressProofId,
                        size: 'small',
                      }}
                    />
                  )}
                  {values.nationality === 'IN' && (
                    <Box display='flex' flexDirection='column' width={285}>
                      <WelloFormItemLabel title='Proof Type' />
                      <Autocomplete
                        style={{
                          borderRadius: '4px',
                        }}
                        fullWidth
                        size='small'
                        id='patient_address_proof_type'
                        autoFocus
                        disableClearable
                        getOptionSelected={(option, value) =>
                          option.display === value.display
                        }
                        getOptionLabel={(option) => option.display ?? ''}
                        options={
                          values.nationality === 'IN' ||
                          values.nationality.length === 0
                            ? AddressProofType
                            : AddressProofTypeForForeign
                        }
                        onChange={(e, changedValue, reason) => {
                          if (changedValue) {
                            handleGenderData(
                              'addressProofTypeCode',
                              changedValue.code ?? ''
                            )
                          }
                        }}
                        autoComplete
                        ChipProps={{
                          deleteIcon: (
                            <ClearOutlined
                              style={{
                                height: '15px',
                                color: 'white',
                              }}
                            />
                          ),
                          style: {
                            backgroundColor: kPrimaryLight,
                            borderRadius: '4px',
                          },
                        }}
                        renderOption={(option) => (
                          <Typography variant='subtitle2'>
                            {option.display}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder='Select Proof Type'
                            fullWidth
                            id='patient_address_proof_type_search'
                            variant='outlined'
                            size='small'
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />
                      <Box paddingLeft={2} id='addressTypeCodeError'>
                        <Typography
                          variant='subtitle2'
                          color='error'
                          style={{ fontWeight: 500 }}
                        >
                          {errors.addressProofTypeCode}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                {values.nationality === 'IN' && (
                  <Box paddingLeft={2}>
                    <WelloTextField
                      title='Proof Number'
                      textProps={{
                        style: {
                          maxWidth: '480px',
                          width: '285px',
                        },
                        id: `address_proof_number`,
                        fullWidth: true,
                        required: true,
                        onChange: handleInputChange,
                        value: values.addressProofId,
                        name: 'addressProofId',
                        error: errors.addressProofId,
                        helperText: errors.addressProofId,
                        size: 'small',
                      }}
                    />
                  </Box>
                )}
                {values.nationality !== 'IN' && (
                  <Box display='flex' flexDirection='column' paddingLeft={2}>
                    <Box>
                      <WelloPlaceFinder
                        title='Place of issue'
                        nationality={values.nationality.trim()}
                        textProps={{
                          style: {
                            maxWidth: '480px',
                            width: '285px',
                          },
                          required: true,
                          id: 'unit_actor_current_address2',
                          placeholder: 'Your Home address',
                          name: 'passportIssueCity',
                          value: 'Bangalore',
                          onChange: (changedValue: any) => {
                            //   handleLabAddressChange(changedValue.target.value)
                          },
                        }}
                        valueData={values.mapData}
                        mapkey=''
                        onUpdated={(
                          address: string,
                          mapLatlog: {
                            lat: number
                            lng: number
                          }
                        ) => {
                          if (
                            values.nationality === 'IN' ||
                            values.nationality.length === 0
                          ) {
                            if (address.split(',').length > 2) {
                              handleChangeCity(
                                address
                                  .split(',')[0]
                                  .replaceAll(',', '')
                                  .trim(),
                                'passportIssueCity',
                                'passportIssueCountry',
                                address.split(',')[1].replaceAll(',', '').trim()
                              )
                            } else {
                              handleChangeCity(
                                address
                                  .split(',')[0]
                                  .replaceAll(',', '')
                                  .trim(),
                                'passportIssueCity',
                                'passportIssueCountry',
                                address.split(',')[0].replaceAll(',', '').trim()
                              )
                            }
                          } else if (address.split(',').length > 2) {
                            handleChangeCity(
                              address.split(',')[0].replaceAll(',', '').trim(),
                              'passportIssueCity',
                              'passportIssueCountry',
                              address.split(',')[2].replaceAll(',', '').trim()
                            )
                          } else {
                            handleChangeCity(
                              address.split(',')[0].replaceAll(',', '').trim(),
                              'passportIssueCity',
                              'passportIssueCountry',
                              address.split(',')[1].replaceAll(',', '').trim()
                            )
                          }

                          // handleChangeState(address.split(',')[1], 'state')
                        }}
                      />
                      <Box py={0.5} px={1}>
                        <Typography
                          variant='subtitle2'
                          color='error'
                          style={{
                            fontWeight: 500,
                          }}
                        >
                          {errors.passportIssueCity}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {values.nationality !== 'IN' && isLab === false && (
              <Box
                display='flex'
                py={1}
                paddingLeft={
                  values.gender.length !== 0 &&
                  values.dob.length !== 0 &&
                  values.photoUri
                    ? 2
                    : 0
                }
              >
                <Box
                  paddingLeft={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? 26
                      : 27.5
                  }
                >
                  <WelloDate
                    name='passportIssueDate'
                    label='Passport issue Date'
                    placeholder={placeholderLables?.doblabel ?? 'dd/mm/yyyy'}
                    required={false}
                    value={values.passportIssueDate}
                    width={285}
                    size='small'
                    error={errors.passportIssueDate}
                    onChange={handleDateChangeForPassportSeperate}
                  />
                </Box>

                <Box paddingLeft={2} width={300}>
                  <WelloTextField
                    title='Passport expiry date'
                    textProps={{
                      id: `actor_email_unit`,
                      value: moment(values.passportValidDate).format(
                        'YYYY-MM-DD'
                      ),
                      name: 'passportValidDate',

                      error: errors.passportValidDate,
                      helperText: errors.passportValidDate,
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Box>
                              <Tooltip title=''>
                                <IconButton
                                  aria-label='btn_ord_cancel'
                                  color='primary'
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/Shape.png`}
                                    alt=''
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </InputAdornment>
                        ),
                      },
                      inputProps: {
                        min: moment().format('YYYY-MM-DD'),
                        max: moment()
                          .add(20, 'years')
                          .endOf('day')
                          .format('YYYY-MM-DD'),
                      },

                      type: 'date',
                      onChange: (changeData) => {
                        handleDateChangeForPassportSeperate(changeData)
                      },
                    }}
                  />
                </Box>
              </Box>
            )}

            {values.nationality !== 'IN' &&
              isLab === false &&
              validateDateForPassportValidTill(
                values.passportValidDate,
                values.passportIssueDate,
                true
              ).length === 0 &&
              validateDateForPassportIssueDate(
                values.passportIssueDate,
                values.passportValidDate,
                true,
                values.dob
              ).length === 0 && (
                <Box
                  display='flex'
                  py={1}
                  paddingLeft={
                    values.gender.length !== 0 &&
                    values.dob.length !== 0 &&
                    values.photoUri
                      ? 2
                      : 0
                  }
                >
                  <Box
                    paddingLeft={
                      values.gender.length !== 0 && values.dob.length !== 0
                        ? 26
                        : 27.5
                    }
                  >
                    <WelloTextField
                      title='Visa No.'
                      textProps={{
                        style: {
                          maxWidth: '480px',
                          width: '285px',
                        },
                        id: `visa_no`,
                        fullWidth: true,
                        required: true,
                        onChange: handleInputChange,
                        value: values.visaNo,
                        name: 'visaNo',
                        error: errors.visaNo,
                        helperText: errors.visaNo,
                        size: 'small',
                      }}
                    />
                  </Box>

                  <Box paddingLeft={2} width={300}>
                    <WelloSelectFHIRCoding
                      title={t('formCLabel:visaType')}
                      id='visaType'
                      availableCodings={
                        t(
                          'formCEntry:entry_attrs.visaType.value-set'
                        ) as R4.ICoding[]
                      }
                      onChanged={(val) => {}}
                      textFieldProps={{
                        fullWidth: true,
                        required: false,
                        size: 'small',
                        value: values.visaType,
                        style: {
                          maxWidth: '480px',
                          width: '285px',
                        },
                        onChange: handleInputChange,
                        name: 'visaType',
                        error: errors.visaType,
                        helperText: errors.visaType,

                        id: 'patient_visa_type',
                      }}
                    />
                  </Box>
                </Box>
              )}

            {values.nationality !== 'IN' &&
              isLab === false &&
              validateDateForPassportValidTill(
                values.passportValidDate,
                values.passportIssueDate,
                true
              ).length === 0 &&
              validateDateForPassportIssueDate(
                values.passportIssueDate,
                values.passportValidDate,
                true,
                values.dob
              ).length === 0 && (
                <Box
                  display='flex'
                  py={1}
                  paddingLeft={
                    values.gender.length !== 0 &&
                    values.dob.length !== 0 &&
                    values.photoUri
                      ? 2
                      : 0
                  }
                >
                  <Box
                    paddingLeft={
                      values.gender.length !== 0 && values.dob.length !== 0
                        ? 26
                        : 27.5
                    }
                  >
                    <WelloDate
                      name='visaIssueDate'
                      label={t('formCLabel:visaDateOfIssue')}
                      placeholder='Visa Issue Date'
                      required={false}
                      value={values.visaIssueDate}
                      width={285}
                      size='small'
                      error={errors.visaIssueDate}
                      onChange={handleDateChangeForPassportSeperate}
                    />
                  </Box>

                  <Box paddingLeft={2} width={300}>
                    <WelloTextField
                      title='Visa Expiry Date'
                      textProps={{
                        id: `visaExpiryDate`,
                        value: moment(values.visaExpiryDate).format(
                          'YYYY-MM-DD'
                        ),
                        name: 'visaExpiryDate',

                        error: errors.visaExpiryDate,
                        helperText: errors.visaExpiryDate,
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Box>
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='btn_ord_cancel'
                                    color='primary'
                                  >
                                    <img
                                      src={`${process.env.PUBLIC_URL}/Shape.png`}
                                      alt=''
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </InputAdornment>
                          ),
                        },
                        inputProps: {
                          min: moment().format('YYYY-MM-DD'),
                          max: moment()
                            .add(20, 'years')
                            .endOf('day')
                            .format('YYYY-MM-DD'),
                        },

                        type: 'date',
                        onChange: (changeData) => {
                          handleDateChangeForPassportSeperate(changeData)
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}

            {values.nationality !== 'IN' &&
              isLab === false &&
              validateDateForPassportValidTill(
                values.passportValidDate,
                values.passportIssueDate,
                true
              ).length === 0 &&
              validateDateForPassportIssueDate(
                values.passportIssueDate,
                values.passportValidDate,
                true,
                values.dob
              ).length === 0 && (
                <Box
                  paddingRight={1}
                  paddingLeft={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? 28
                      : 27.5
                  }
                  display='flex'
                  flexDirection='row'
                >
                  <Box display='flex' flexDirection='column' width='42.5%'>
                    <Box>
                      <WelloPlaceFinder
                        title='Place of issue (Visa)'
                        nationality={values.nationality.trim()}
                        textProps={{
                          required: true,

                          id: 'patient_visa_city',
                          placeholder: 'Visa City',
                          name: 'visaIssueCity',
                          value: values.visaIssueCity,
                          onChange: (changedValue: any) => {
                            //   handleLabAddressChange(changedValue.target.value)
                          },
                        }}
                        valueData={values.mapData}
                        mapkey=''
                        onUpdated={(
                          address: string,
                          mapLatlog: {
                            lat: number
                            lng: number
                          }
                        ) => {
                          if (address.split(',').length > 2) {
                            handleChangeVisaCity(
                              address.split(',')[0].replaceAll(',', '').trim(),
                              'visaIssueCity'
                            )

                            if (address.split(',').length === 4) {
                              handleChangeVisaCity(
                                address
                                  .split(',')[3]
                                  .replaceAll(',', '')
                                  .trim(),
                                'visaIssueCity'
                              )
                            } else {
                              handleChangeVisaCity(
                                address
                                  .split(',')[2]
                                  .replaceAll(',', '')
                                  .trim(),
                                'visaIssueCity'
                              )
                            }
                          } else {
                            handleChangeVisaCity(
                              address.split(',')[0].replaceAll(',', '').trim(),
                              'visaIssueCity'
                            )
                          }
                        }}
                      />
                    </Box>
                    <Box py={0.5} px={1}>
                      <Typography
                        variant='subtitle2'
                        color='error'
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        {errors.visaIssueCity}
                      </Typography>
                    </Box>
                  </Box>

                  {values.nationality !== 'IN' && (
                    <Box width='47%' paddingRight={1.2} paddingLeft={2.4}>
                      <Box>
                        <WelloTextField
                          title='Country'
                          textProps={{
                            // style: {
                            //   maxWidth: '480px',
                            //   width: '240px',
                            // },
                            id: 'visaCountry',
                            fullWidth: true,
                            required: true,
                            disabled: true,
                            // onChange: handleInputChange,
                            value: values.visaIssueCountry,
                            name: 'visaIssueCountry',
                            // error: errors.addressProofId,
                            // helperText: errors.addressProofId,
                            size: 'small',
                          }}
                        />
                      </Box>
                    </Box>
                  )}

                  {values.nationality.trim() === 'IN' && (
                    <Box
                      width='28.2%'
                      px={1.6}
                      display='flex'
                      flexDirection='column'
                    >
                      <WelloFormItemLabel title='State' />

                      {values.state.length > 0 && (
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          fullWidth
                          size='small'
                          id='state'
                          autoFocus
                          disableClearable
                          getOptionSelected={(option, value) =>
                            option.title === value.title
                          }
                          value={stateList.find(
                            (occupation) => occupation.id === values.state
                          )}
                          getOptionLabel={(option) => option.title ?? ''}
                          options={stateList}
                          onChange={(e, changedValue, reason) => {
                            if (changedValue) {
                              handleChangeState(changedValue.id, 'state')
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.title}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select State'
                              fullWidth
                              id='state_textSearch'
                              variant='outlined'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                      )}
                      {values.state.length === 0 && (
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          fullWidth
                          size='small'
                          id='state'
                          autoFocus
                          disableClearable
                          getOptionSelected={(option, value) =>
                            option.title === value.title
                          }
                          value={stateList.find(
                            (occupation) => occupation.id === values.state
                          )}
                          getOptionLabel={(option) => option.title ?? ''}
                          options={stateList}
                          onChange={(e, changedValue, reason) => {
                            if (changedValue) {
                              handleChangeState(changedValue.id, 'state')
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.title}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select State'
                              fullWidth
                              id='state_textSearch'
                              variant='outlined'
                              size='small'
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                      )}
                      <Box paddingLeft={2} id='stateError'>
                        <Typography
                          variant='subtitle2'
                          color='error'
                          style={{ fontWeight: 500 }}
                        >
                          {errors.state}
                        </Typography>
                      </Box>
                      {/* <WelloCountrySelector
                        title='State'
                        textFieldProps={{
                          value: values.state,
                          placeholder: 'Select State',
                          id: 'state',
                          required: false,
                          name: 'state',
                        }}
                        onChanged={(selectedOccupation) => {
                          if (selectedOccupation?.id) {
                            handleChangeState(selectedOccupation?.id, 'state')
                          }
                        }}
                      /> */}
                    </Box>
                  )}
                </Box>
              )}

            {values.addressProofId &&
              values.addressProofTypeCode &&
              isLab === false && (
                <Box
                  display='flex'
                  py={1}
                  flexDirection='column'
                  paddingLeft={
                    values.gender.length !== 0 &&
                    values.dob.length !== 0 &&
                    values.photoUri
                      ? 2
                      : 0
                  }
                >
                  <Box
                    paddingRight={1}
                    paddingLeft={
                      values.gender.length !== 0 && values.dob.length !== 0
                        ? 26
                        : 27.5
                    }
                  >
                    <FormControl>
                      <FormLabel id='demo-row-radio-buttons-group-label'>
                        <Typography variant='subtitle1' color='textPrimary'>
                          Capture Type
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        id={`${id}rad_grp`}
                        row
                        aria-label='graphType'
                        value={valueData}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setValueData((event.target as HTMLInputElement).value)
                        }}
                      >
                        <Box px={1.5}>
                          <FormControlLabel
                            value='file'
                            id={`${id}rad_file_upload`}
                            control={<Radio />}
                            label='File Upload'
                          />
                          <FormControlLabel
                            id={`${id}rad_camera`}
                            value='camera'
                            control={<Radio />}
                            label='Camera'
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  {valueData === 'file' && (
                    <Box display='flex' flexDirection='column' flexGrow={1}>
                      <Box
                        paddingRight={1}
                        paddingLeft={
                          values.gender.length !== 0 && values.dob.length !== 0
                            ? 30
                            : 31
                        }
                      >
                        <FormControl>
                          <FormLabel id='demo-row-radio-buttons-group-label'>
                            <Typography variant='subtitle1' color='textPrimary'>
                              Type
                            </Typography>
                          </FormLabel>
                          <RadioGroup
                            id={`${id}rad_grp_type`}
                            row
                            aria-label='graphType'
                            value={photoType}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // if (
                              //   (event.target as HTMLInputElement).value ===
                              //   'both'
                              // ) {
                              //   values.addressProofTypeBackPath = ''
                              //   values.addressProofTypeBackMimeType = ''
                              //   values.addressProofTypeBackFileName = ''
                              //   values.addressProofTypeFrontPath = ''
                              //   values.addressProofTypeFrontkMimeType = ''
                              //   values.addressProofTypeFrontFileName = ''
                              // } else {
                              //   values.addressProofTypeBoth = ''
                              //   values.addressProofTypeBothMimeType = ''
                              //   values.addressProofTypeBothFileName = ''
                              // }
                              setPhotoType(
                                (event.target as HTMLInputElement).value
                              )
                            }}
                          >
                            <Box px={1.5}>
                              <FormControlLabel
                                value='both'
                                id={`${id}rad_button_both`}
                                control={<Radio />}
                                label='Front & Back Together'
                              />
                              <FormControlLabel
                                id={`${id}rad_button_seperate`}
                                value='seperate'
                                control={<Radio />}
                                label='Front & Back Separate'
                              />
                            </Box>
                          </RadioGroup>
                        </FormControl>
                      </Box>

                      {photoType === 'both' && (
                        <Box display='flex' py={1}>
                          <Box
                            display='flex'
                            px={2}
                            flexDirection='row'
                            paddingLeft={
                              values.gender.length !== 0 &&
                              values.dob.length !== 0
                                ? 26
                                : 27
                            }
                          >
                            <Box display='flex' px={2} flexDirection='column'>
                              <Box
                                display='flex'
                                flexDirection='row'
                                justifyContent='center'
                              >
                                <WelloFormItemLabel title='Front & Back' />
                              </Box>
                              <WelloFilePicker
                                fileTypeToPick='any'
                                id={`${id}both_file_picker`}
                                name='addressProofTypeBoth'
                                onFileChanged={(selectedFile) => {
                                  console.log(selectedFile)
                                  if (selectedFile.path) {
                                    handleInputChangeForProofs(
                                      'both',
                                      selectedFile.files
                                        ? selectedFile.files.type
                                        : '',
                                      'both',
                                      selectedFile.files
                                        ? selectedFile.files.size
                                        : 0,
                                      selectedFile.path as string
                                    )
                                  }
                                }}
                                onDeleteClicked={() => {
                                  handleInputChangeForProofs(
                                    'both',
                                    '',
                                    'both',
                                    0,
                                    ''
                                  )
                                }}
                                size='medium'
                                showSelectedFileName={true}
                                allowUpdate={true}
                                allowDelete={true}
                                error={errors.addressProofTypeBoth}
                                helperText={errors.addressProofTypeBoth}
                                labelIcon={
                                  <h5>
                                    <FiUpload />
                                  </h5>
                                }
                                labelName={t('labelCommon:select_file')}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {photoType === 'seperate' && (
                        <Box display='flex' py={1}>
                          <Box
                            display='flex'
                            px={2}
                            flexDirection='row'
                            paddingLeft={
                              values.gender.length !== 0 &&
                              values.dob.length !== 0
                                ? 26
                                : 27
                            }
                          >
                            <Box display='flex' px={2} flexDirection='column'>
                              <Box
                                display='flex'
                                flexDirection='row'
                                justifyContent='center'
                              >
                                <WelloFormItemLabel title='Front' />
                              </Box>
                              <WelloFilePicker
                                fileTypeToPick='any'
                                id={`${id}address_front`}
                                name='addressProofTypeFrontPath'
                                onFileChanged={(selectedFile) => {
                                  if (selectedFile.path) {
                                    handleInputChangeForProofs(
                                      'front',
                                      selectedFile.files
                                        ? selectedFile.files.type
                                        : '',
                                      'addProof_front',
                                      selectedFile.files
                                        ? selectedFile.files.size
                                        : 0,
                                      selectedFile.path as string
                                    )
                                  }
                                }}
                                onDeleteClicked={() => {
                                  handleInputChangeForProofs(
                                    'front',
                                    '',
                                    'addProof_front',
                                    0,
                                    ''
                                  )
                                }}
                                size='medium'
                                showSelectedFileName={true}
                                allowUpdate={true}
                                allowDelete={true}
                                required={false}
                                error={errors.addressProofTypeFrontPath}
                                helperText={errors.addressProofTypeFrontPath}
                                labelIcon={
                                  <h5>
                                    <FiUpload />
                                  </h5>
                                }
                                labelName={t('labelCommon:select_file')}
                              />
                              {/* <WelloFilePicker
                              fileTypeToPick='any'
                              id='patient_address_front_file'
                              name='addressProofTypeFrontPath'
                              onFileChanged={(selectedFile) => {
                                if (selectedFile.path) {
                                  handleInputChangeForProofs(
                                    'front',
                                    selectedFile.files
                                      ? selectedFile.files.type
                                      : '',
                                    'addProof_front',
                                    selectedFile.path as string
                                  )
                                }
                                
                              }}
                              size='medium'
                              showSelectedFileName={true}
                              allowUpdate={true}
                              error={errors.addressProofTypeFrontPath}
                              helperText={errors.addressProofTypeFrontPath}
                              labelIcon={
                                <h5>
                                  <FiUpload />
                                </h5>
                              }
                              labelName={t('labelCommon:select_file')}
                            /> */}
                            </Box>
                            <Box display='flex' px={2} flexDirection='column'>
                              <Box
                                display='flex'
                                flexDirection='row'
                                justifyContent='center'
                              >
                                <WelloFormItemLabel title='Back' />
                              </Box>
                              <WelloFilePicker
                                fileTypeToPick='any'
                                id={`${id}address_back`}
                                name='addressProofTypeBackPath'
                                onFileChanged={(selectedFile) => {
                                  if (selectedFile.path) {
                                    handleInputChangeForProofs(
                                      'back',
                                      selectedFile.files
                                        ? selectedFile.files.type
                                        : '',

                                      'addProof_back',
                                      selectedFile.files
                                        ? selectedFile.files.size
                                        : 0,
                                      selectedFile.path as string
                                    )
                                  }
                                }}
                                onDeleteClicked={() => {
                                  handleInputChangeForProofs(
                                    'back',
                                    '',
                                    'addProof_back',
                                    0,
                                    ''
                                  )
                                }}
                                size='medium'
                                showSelectedFileName={true}
                                allowUpdate={true}
                                allowDelete={true}
                                //   required={!values.idProofSameAsAddress}
                                error={errors.addressProofTypeBackPath}
                                helperText={errors.addressProofTypeBackPath}
                                labelIcon={
                                  <h5>
                                    <FiUpload />
                                  </h5>
                                }
                                labelName={t('labelCommon:select_file')}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}

                  {valueData === 'camera' && (
                    <Box display='flex' py={1}>
                      <Box
                        paddingRight={1}
                        paddingLeft={
                          values.gender.length !== 0 && values.dob.length !== 0
                            ? 26
                            : 27
                        }
                      >
                        <WelloFormItemLabel title='Front' />
                        {!values.addressProofTypeFrontPath && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            style={{ cursor: 'pointer' }}
                            id={`${id}address_front_camera`}
                            alignItems='center'
                            onClick={() => {
                              setCallBackForCapturePhoto(
                                () =>
                                  (
                                    imageName: any,
                                    dataType: any,
                                    image: any
                                  ) => {
                                    handleInputChangeForProofs(
                                      'front',
                                      dataType,
                                      'addProof_front',
                                      0,
                                      image
                                    )
                                    /* setSelectedPatientSignature({
                path: image,
                type: dataType,
                local: false,
                name: `ipd_patient_sign${fhirAppointmentDetails.mainServiceRequest.id}`,
              }) */
                                  }
                              )
                              setAspectRatio(16 / 9)
                              setOpenWebCam(true)
                            }}
                          >
                            <Box>
                              <h5>
                                {' '}
                                <FiVideo />{' '}
                              </h5>
                            </Box>

                            <Box p={1} display='flex' flexDirection='column'>
                              <Box>
                                <Typography variant='subtitle2' color='primary'>
                                  Capture Front Part
                                </Typography>
                              </Box>
                              <Box p={1}>
                                <Typography variant='subtitle2' color='error'>
                                  {errors.addressProofTypeFrontPath}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        {values.addressProofTypeFrontPath && (
                          <img
                            src={values.addressProofTypeFrontPath}
                            alt='doc_sign'
                            height='90px/'
                            width='180px'
                          />
                        )}
                        {values.addressProofTypeBackPath && (
                          <Button
                            id={`${id}address_front_camera_change_button`}
                            color='primary'
                            onClick={(e) => {
                              setCallBackForCapturePhoto(
                                () =>
                                  (
                                    imageName: any,
                                    dataType: any,
                                    image: any
                                  ) => {
                                    handleInputChangeForProofs(
                                      'front',
                                      dataType,
                                      'addProof_front',
                                      0,
                                      image
                                    )
                                  }
                              )
                              setAspectRatio(16 / 9)
                              setOpenWebCam(true)
                            }}
                          >
                            Change
                          </Button>
                        )}
                        {/* <WelloFilePicker
      fileTypeToPick='any'
      id='patient_address_front_file'
      name='addressProofTypeFrontPath'
      onFileChanged={(selectedFile) => {
        if (selectedFile.path) {
          handleInputChangeForProofs(
            'front',
            selectedFile.files?.type ?? '',
            selectedFile.name ?? '',
            selectedFile.path as string
          )
        }
        
      }}
      size='medium'
      showSelectedFileName={true}
      allowUpdate={false}
      error={errors.addressProofTypeFrontPath}
      helperText={errors.addressProofTypeFrontPath}
      labelIcon={
        <h5>
          <FiUpload />
        </h5>
      }
      labelName={t('labelCommon:select_file')}
    /> */}
                      </Box>

                      <Box
                        paddingRight={1}
                        paddingLeft={
                          values.gender.length !== 0 && values.dob.length !== 0
                            ? 26
                            : 27
                        }
                      >
                        <WelloFormItemLabel title='Back' />
                        {!values.addressProofTypeBackPath && (
                          <Box
                            display='flex'
                            flexDirection='row'
                            id={`${id}address_back_camera`}
                            style={{ cursor: 'pointer' }}
                            alignItems='center'
                            onClick={() => {
                              setCallBackForCapturePhoto(
                                () =>
                                  (
                                    imageName: any,
                                    dataType: any,
                                    image: any
                                  ) => {
                                    handleInputChangeForProofs(
                                      'back',
                                      dataType,
                                      'addProof_back',
                                      0,
                                      image
                                    )
                                  }
                              )
                              setAspectRatio(16 / 9)
                              setOpenWebCam(true)
                            }}
                          >
                            <Box>
                              <h5>
                                {' '}
                                <FiVideo />{' '}
                              </h5>
                            </Box>

                            <Box p={1} display='flex' flexDirection='column'>
                              <Box>
                                <Typography variant='subtitle2' color='primary'>
                                  Capture Back Part
                                </Typography>
                              </Box>
                              <Box p={1}>
                                <Typography variant='subtitle2' color='error'>
                                  {errors.addressProofTypeBackPath}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}

                        {values.addressProofTypeBackPath && (
                          <img
                            src={values.addressProofTypeBackPath}
                            alt='doc_sign'
                            height='90px/'
                            width='180px'
                          />
                        )}

                        {values.addressProofTypeBackPath && (
                          <Button
                            id={`${id}address_back_camera_change_button`}
                            color='primary'
                            onClick={(e) => {
                              setCallBackForCapturePhoto(
                                () =>
                                  (
                                    imageName: any,
                                    dataType: any,
                                    image: any
                                  ) => {
                                    handleInputChangeForProofs(
                                      'back',
                                      dataType,
                                      'addProof_back',
                                      0,
                                      image
                                    )
                                  }
                              )
                              setAspectRatio(16 / 9)
                              setOpenWebCam(true)
                            }}
                          >
                            Change
                          </Button>
                        )}
                        {/* <WelloFilePicker
      fileTypeToPick='any'
      id='patient_address_back_file'
      name='addressProofTypeBackPath'
      onFileChanged={(selectedFile) => {
        if (selectedFile.path) {
          handleInputChangeForProofs(
            'back',
            selectedFile.files?.type ?? '',
            selectedFile.name ?? '',
            selectedFile.path as string
          )
        }
        
      }}
      size='medium'
      showSelectedFileName={true}
      allowUpdate={false}
      //   required={!values.idProofSameAsAddress}
      error={errors.addressProofTypeBackPath}
      helperText={errors.addressProofTypeBackPath}
      labelIcon={
        <h5>
          <FiUpload />
        </h5>
      }
      labelName={t('labelCommon:select_file')}
    /> */}
                      </Box>
                    </Box>
                  )}
                </Box>
              )}

            {/* ------------------Address Proof End------------------------------- */}
            <Box
              display='flex'
              //   py={1}
              paddingLeft={
                values.gender.length !== 0 &&
                values.dob.length !== 0 &&
                values.photoUri
                  ? 2
                  : 0
              }
            >
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27.5
                }
              >
                <Box display='flex' flexDirection='column' width={283}>
                  <Box display='flex' flexDirection='row' width='100%' py={0.2}>
                    <ValueSetSelectSingleForOther
                      key='occupation_Search'
                      title='Occupation'
                      url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-occupation-vs'
                      id='occupation'
                      fuzzySearch={true}
                      alwaysShowAllOptions={true}
                      disabled={false}
                      onOptionSelected={(selectedKriya) => {
                        // setOccupation(changedValue)
                        if (selectedKriya) {
                          handleInputChangeForOccupation(
                            'occupation',
                            selectedKriya.display ?? ''
                          )
                        }
                      }}
                      helperText={undefined}
                      placeHolder='Select Occupation (Optional)'
                    />
                    {/* <Autocomplete
                      style={{
                        borderRadius: '4px',
                      }}
                      fullWidth
                      size='small'
                      id='occupation'
                      defaultValue={occupations.find(
                        (occupation) => occupation.title === values.occupation
                      )}
                      autoFocus
                      disableClearable
                      getOptionSelected={(option, value) =>
                        option.title === value.title
                      }
                      getOptionLabel={(option) => option.title ?? ''}
                      options={occupations}
                      onChange={(e, changedValue, reason) => {
                        handleInputChangeForOccupation(
                          'occupation',
                          changedValue.title
                        )
                      }}
                      autoComplete
                      ChipProps={{
                        deleteIcon: (
                          <ClearOutlined
                            style={{
                              height: '15px',
                              color: 'white',
                            }}
                          />
                        ),
                        style: {
                          backgroundColor: kPrimaryLight,
                          borderRadius: '4px',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select Occupation (Optional)'
                          fullWidth
                          id='occupation_search'
                          variant='outlined'
                          size='small'
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    /> */}
                  </Box>
                </Box>
                {/* <Controls.WelloOccupation
                  name='occupation'
                  required={true}
                  label={formLabels?.occupationLabel ?? 'OCCUPATION'}
                  type={
                    placeholderLables?.occupationLabel ??
                    'Select Occupation (Optional)'
                  }
                  value={values.occupation}
                  width={285}
                  onChange={handleInputChange}
                  occupations={occupations}
                /> */}
                {values.occupation === 'other' && (
                  <Box paddingTop={1} width='100%'>
                    <Controls.WelloInput
                      name='otherData'
                      required={false}
                      label='Other Value'
                      type='text'
                      size='small'
                      value={values.otherData}
                      error={errors.otherData}
                      placeholder='Occupation'
                      width={285}
                      onChange={handleInputChange}
                      charsAllowed={50}
                    />
                  </Box>
                )}
              </Box>
              <Box px={1}>
                <WelloTextField
                  title={formLabels?.referenceIdLabel ?? 'REFERENCE ID'}
                  textProps={{
                    required: true,
                    id: 'lrNumber',
                    placeholder:
                      placeholderLables?.referenceIdLabel ?? 'Reference Id',

                    name: 'lrNumber',
                    rows: 3,
                    style: {
                      width: 285,
                    },

                    value: values.lrNumber,
                    onChange: (changeDescription) => {
                      handleInputChange(changeDescription)
                    },
                    error: errors.lrNumber || values.lrNumber.length > 30,
                    helperText: errors.lrNumber,
                  }}
                />
                {/* <Controls.WelloInput
                  name='lrNumber'
                  required={false}
                  label={formLabels?.referenceIdLabel ?? 'REFERENCE ID'}
                  type='text'
                  size='small'
                  value={values.lrNumber}
                  error={errors.lrNumber}
                  placeholder={
                    placeholderLables?.referenceIdLabel ?? 'Reference Id'
                  }
                  width={285}
                  onChange={handleInputChange}
                  charsAllowed={30}
                /> */}
              </Box>
            </Box>

            <Box
              display='flex'
              py={1}
              paddingLeft={
                values.gender.length !== 0 &&
                values.dob.length !== 0 &&
                values.photoUri
                  ? 2
                  : 0
              }
            >
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27.5
                }
                paddingTop={0.1}
              >
                <PhoneNumber
                  name='phone'
                  label={
                    values.nationality === 'IN'
                      ? 'Phone Number'
                      : 'Phone Number (in india)'
                  }
                  placeholder={
                    placeholderLables?.phoneNumberLabel ?? 'Phone Number'
                  }
                  type='tel'
                  size='small'
                  value={values.phone}
                  required={false}
                  width={285}
                  error={errors.phone}
                  onChange={handleInputChange}
                />
              </Box>
              <Box paddingLeft={1}>
                <WelloEmailInput
                  name='email'
                  label={formLabels?.emailLabel ?? 'EMAIL ADDRESS'}
                  placeholder={placeholderLables?.emailLabel ?? 'Email Address'}
                  type='email'
                  value={values.email}
                  error={errors.email}
                  required={true}
                  size='small'
                  width={283}
                  onChange={handleInputChange}
                />
              </Box>
            </Box>
            {(values.nationality === 'IN' ||
              values.nationality.length === 0) && (
              <Box
                display='flex'
                py={1}
                paddingLeft={
                  values.gender.length !== 0 &&
                  values.dob.length !== 0 &&
                  values.photoUri
                    ? 2
                    : 0
                }
              >
                <Box
                  paddingRight={1}
                  paddingLeft={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? 26
                      : 27.5
                  }
                >
                  <WelloTextField
                    title={formLabels?.addressLabel ?? 'ADDRESS'}
                    textProps={{
                      required: true,
                      id: 'unit_actor_current_address1',
                      placeholder: 'Your Home Address',
                      name: 'address',
                      rows: 3,
                      style: {
                        width: 588,
                      },
                      rowsMax: 6,
                      multiline: true,
                      value: values.address,
                      onChange: (changeDescription) => {
                        handleInputChange(changeDescription)
                      },
                      error: errors.address,
                      helperText: errors.address,
                    }}
                  />
                  {/* <Controls.WelloTextAreaAddress
                    name='address'
                    required={false}
                    label={formLabels?.addressLabel ?? 'ADDRESS'}
                    type='text'
                    placeholder={
                      placeholderLables?.addressLabel ?? 'Your Home Address'
                    }
                    value={values.address}
                    onChange={handleInputChange}
                    error={errors.address}
                    width={480}
                    size='small'
                    charsAllowed={100}
                  /> */}
                </Box>
              </Box>
            )}

            {values.nationality !== 'IN' && values.nationality.length !== 0 && (
              <Box
                display='flex'
                py={1}
                paddingLeft={
                  values.gender.length !== 0 &&
                  values.dob.length !== 0 &&
                  values.photoUri
                    ? 2
                    : 0
                }
              >
                <Box
                  paddingRight={1}
                  paddingLeft={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? 26
                      : 27.5
                  }
                >
                  <WelloTextField
                    title='Permanent Address'
                    textProps={{
                      required: true,
                      id: 'unit_actor_current_address',
                      placeholder:
                        'Address in county where residing permanently',

                      name: 'address',
                      rows: 3,
                      style: {
                        width: 588,
                      },
                      rowsMax: 6,
                      multiline: true,
                      value: values.address,
                      onChange: (changeDescription) => {
                        handleInputChange(changeDescription)
                      },
                      error: errors.address,
                      helperText: errors.address,
                    }}
                  />
                </Box>
              </Box>
            )}

            <Box
              display='flex'
              paddingLeft={
                values.gender.length !== 0 &&
                values.dob.length !== 0 &&
                values.photoUri
                  ? 2
                  : 0
              }
            >
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27.5
                }
                display='flex'
                flexDirection='row'
              >
                <Box paddingRight={1}>
                  <WelloAddressTypeSelector
                    title={t('labelCommon:placeHolder_addressType')}
                    textFieldProps={{
                      value: values.addressType,
                      placeholder: 'Select',
                      id: 'addressType',
                      required: false,
                      name: 'addressType',
                    }}
                    onChanged={(selectedOccupation) => {
                      if (selectedOccupation?.id) {
                        //   setExistingPatient({
                        //     ...existingPatient,
                        //     addressType: selectedOccupation?.id,
                        //   })
                      }
                    }}
                  />
                  {/* <Controls.WelloType
                    name='addressType'
                    required={true}
                    label={formLabels?.addressTypeLabel ?? 'TYPE'}
                    type={placeholderLables?.addressTypeLabel ?? 'Type'}
                    value={values.addressType}
                    onChange={handleInputChange}
                    width={90}
                  /> */}
                </Box>
              </Box>
              <Box display='flex' flexDirection='column' width='27.5%'>
                <Box>
                  <WelloPlaceFinder
                    title={
                      values.nationality === 'IN' ||
                      values.nationality.length === 0
                        ? 'Select City'
                        : 'Select Permanent Address City'
                    }
                    nationality={values.nationality.trim()}
                    textProps={{
                      required: true,

                      id: 'patient_city',
                      placeholder: 'Your Home address',
                      name: 'city',
                      value: values.city,
                      onChange: (changedValue: any) => {
                        //   handleLabAddressChange(changedValue.target.value)
                      },
                    }}
                    valueData={values.mapData}
                    mapkey=''
                    onUpdated={(
                      address: string,
                      mapLatlog: {
                        lat: number
                        lng: number
                      }
                    ) => {
                      if (
                        values.nationality === 'IN' ||
                        values.nationality.length === 0
                      ) {
                        if (address.split(',').length > 2) {
                          handleChangeCity(
                            address.split(',')[0].replaceAll(',', '').trim(),
                            'city',
                            'state',
                            address.split(',')[1].replaceAll(',', '').trim()
                          )
                        } else {
                          handleChangeCity(
                            address.split(',')[0].replaceAll(',', '').trim(),
                            'city',
                            'state',
                            address.split(',')[0].replaceAll(',', '').trim()
                          )
                        }
                      } else if (address.split(',').length > 2) {
                        handleChangeCity(
                          address.split(',')[0].replaceAll(',', '').trim(),
                          'city',
                          'dummy',
                          address.split(',')[2].replaceAll(',', '').trim()
                        )
                      } else {
                        handleChangeCity(
                          address.split(',')[0].replaceAll(',', '').trim(),
                          'city',
                          'dummy',
                          address.split(',')[1].replaceAll(',', '').trim()
                        )
                      }

                      // handleChangeState(address.split(',')[1], 'state')
                    }}
                  />
                </Box>
                <Box py={0.5} px={1}>
                  <Typography
                    variant='subtitle2'
                    color='error'
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {errors.city}
                  </Typography>
                </Box>
              </Box>

              {values.nationality !== 'IN' && (
                <Box width='28%' px={1.6}>
                  <Box>
                    <WelloTextField
                      title='Country'
                      textProps={{
                        // style: {
                        //   maxWidth: '480px',
                        //   width: '240px',
                        // },
                        id: 'Country',
                        fullWidth: true,
                        required: true,
                        disabled: true,
                        // onChange: handleInputChange,
                        value: values.country,
                        name: 'country',
                        // error: errors.addressProofId,
                        // helperText: errors.addressProofId,
                        size: 'small',
                      }}
                    />
                  </Box>
                </Box>
              )}

              {values.nationality.trim() === 'IN' && (
                <Box
                  width={values.photoUri.length === 0 ? '28.2%' : '29.3%'}
                  px={1.6}
                  display='flex'
                  flexDirection='column'
                >
                  <WelloFormItemLabel title='State' />
                  {values.state.length === 0 && (
                    <Autocomplete
                      style={{
                        borderRadius: '4px',
                      }}
                      fullWidth
                      size='small'
                      id='state'
                      autoFocus
                      disableClearable
                      getOptionSelected={(option, value) =>
                        option.title === value.title
                      }
                      value={stateList.find(
                        (occupation) => occupation.id === values.state
                      )}
                      getOptionLabel={(option) => option.title ?? ''}
                      options={stateList}
                      onChange={(e, changedValue, reason) => {
                        if (changedValue) {
                          handleChangeState(changedValue.id, 'state')
                        }
                      }}
                      autoComplete
                      ChipProps={{
                        deleteIcon: (
                          <ClearOutlined
                            style={{
                              height: '15px',
                              color: 'white',
                            }}
                          />
                        ),
                        style: {
                          backgroundColor: kPrimaryLight,
                          borderRadius: '4px',
                        },
                      }}
                      renderOption={(option) => (
                        <Typography variant='subtitle2'>
                          {option.title}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select State'
                          fullWidth
                          id='state_textSearch'
                          variant='outlined'
                          size='small'
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    />
                  )}
                  {values.state.length > 0 && (
                    <Autocomplete
                      style={{
                        borderRadius: '4px',
                      }}
                      fullWidth
                      size='small'
                      id='state'
                      autoFocus
                      disableClearable
                      getOptionSelected={(option, value) =>
                        option.title === value.title
                      }
                      value={stateList.find(
                        (occupation) => occupation.id === values.state
                      )}
                      getOptionLabel={(option) => option.title ?? ''}
                      options={stateList}
                      onChange={(e, changedValue, reason) => {
                        if (changedValue) {
                          handleChangeState(changedValue.id, 'state')
                        }
                      }}
                      autoComplete
                      ChipProps={{
                        deleteIcon: (
                          <ClearOutlined
                            style={{
                              height: '15px',
                              color: 'white',
                            }}
                          />
                        ),
                        style: {
                          backgroundColor: kPrimaryLight,
                          borderRadius: '4px',
                        },
                      }}
                      renderOption={(option) => (
                        <Typography variant='subtitle2'>
                          {option.title}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select State'
                          fullWidth
                          id='state_textSearch'
                          variant='outlined'
                          size='small'
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    />
                  )}

                  <Box paddingLeft={2} id='stateError'>
                    <Typography
                      variant='subtitle2'
                      color='error'
                      style={{ fontWeight: 500 }}
                    >
                      {errors.state}
                    </Typography>
                  </Box>
                  {/* <WelloCountrySelector
                    title='State'
                    textFieldProps={{
                      value: values.state,
                      placeholder: 'Select State',
                      id: 'state',
                      required: false,
                      name: 'state',
                    }}
                    onChanged={(selectedOccupation) => {
                      if (selectedOccupation?.id) {
                        handleChangeState(selectedOccupation?.id, 'state')
                      }
                    }}
                  /> */}
                </Box>
              )}
            </Box>

            {values.nationality !== 'IN' && values.nationality.length !== 0 && (
              <Box
                display='flex'
                flexDirection='column'
                paddingTop={0.5}
                paddingLeft={
                  values.gender.length !== 0 &&
                  values.dob.length !== 0 &&
                  values.photoUri
                    ? 2
                    : 0
                }
              >
                <Box
                  paddingRight={1}
                  paddingLeft={
                    values.gender.length !== 0 && values.dob.length !== 0
                      ? 26
                      : 27.5
                  }
                >
                  <WelloTextField
                    title='Address in india'
                    textProps={{
                      required: true,
                      id: 'unit_actor_current_address3',
                      placeholder: 'Address in India',

                      name: 'tempAddress',
                      rows: 3,
                      style: {
                        width: 588,
                      },
                      rowsMax: 6,
                      multiline: true,
                      value: values.tempAddress,
                      onChange: (changeDescription) => {
                        handleInputChange(changeDescription)
                      },
                      error: errors.tempAddress,
                      helperText: errors.tempAddress,
                    }}
                  />
                </Box>
                {/* <Box display='flex'>
                  {values.city.length > 0 && values.nationality === 'IN' && (
                    <Box width='35%' px={1.6}>
                      <WelloCountrySelector
                        title='State'
                        textFieldProps={{
                          value: values.state,
                          placeholder: 'Select',
                          id: 'state',
                          required: false,
                          name: 'state',
                        }}
                        onChanged={(selectedOccupation) => {
                          if (selectedOccupation?.id) {
                            handleChangeState(selectedOccupation?.id, 'state')
                          }
                        }}
                      />
                    </Box>
                  )}
                </Box> */}
              </Box>
            )}

            <Box
              display='flex'
              py={1}
              paddingLeft={
                values.gender.length !== 0 &&
                values.dob.length !== 0 &&
                values.photoUri
                  ? 2
                  : 0
              }
            >
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                    ? 26
                    : 27.5
                }
              >
                <WelloTextField
                  title='Father Name'
                  textProps={{
                    required: true,
                    id: 'unit_fathers_name',
                    placeholder:
                      placeholderLables?.fatherNameLabel ??
                      `First, Middle and Last Name`,
                    name: 'fathersName',
                    style: {
                      width: 588,
                    },
                    value: values.fathersName,
                    onChange: (changeDescription) => {
                      handleInputChange(changeDescription)
                    },
                    error: errors.fathersName,
                    helperText: errors.fathersName,
                  }}
                />
                {/* <Controls.WelloInput
                  name='fathersName'
                  required={false}
                  size='small'
                  label={formLabels?.fatherNameLabel ?? `FATHER'S NAME `}
                  type='text'
                  value={values.fathersName}
                  error={errors.fathersName}
                  placeholder={
                    placeholderLables?.fatherNameLabel ??
                    'First, Middle and Last Name'
                  }
                  width={285}
                  onChange={handleInputChange}
                  charsAllowed={300}
                /> */}
              </Box>
            </Box>
            {values.gender === 'female' &&
              values.maritalStatus === 'M' &&
              values.age >= 18 && (
                <Box display='flex' py={1}>
                  <Box
                    paddingRight={1}
                    paddingLeft={
                      values.gender.length !== 0 && values.dob.length !== 0
                        ? values.photoUri.length !== 0
                          ? 27.5
                          : 26
                        : 27.5
                    }
                  >
                    <WelloTextField
                      title='Husband Name'
                      textProps={{
                        required: true,
                        id: 'unit_husbandName_name',
                        placeholder:
                          placeholderLables?.husbandNameLabel ??
                          'First, Middle and Last Name',
                        name: 'husbandName',
                        style: {
                          width: 588,
                        },
                        value: values.husbandName,
                        onChange: (changeDescription) => {
                          handleInputChange(changeDescription)
                        },
                        error: errors.husbandName,
                        helperText: errors.husbandName,
                      }}
                    />
                    {/* <Controls.WelloInput
                      name='husbandName'
                      required={false}
                      size='small'
                      label={formLabels?.husbandNameLabel ?? `Husband’s Name`}
                      type='text'
                      value={values.husbandName}
                      error={errors.husbandName}
                      placeholder={
                        placeholderLables?.husbandNameLabel ??
                        'First, Middle and Last Name'
                      }
                      width={1000}
                      onChange={handleInputChange}
                      charsAllowed={300}
                    /> */}
                  </Box>
                </Box>
              )}
          </Box>
          {dialog &&
            patientsSearchState.groupDataList &&
            patientsSearchState.groupDataList.length > 0 && (
              <ChoosePatient
                id='1'
                open={dialog}
                patients={patientsSearchState.groupDataList ?? []}
                onClose={() => {
                  setDialog(false)
                }}
                onChoose={(
                  patientId: string,
                  relationShip: R4.ICoding,
                  checked: boolean
                ) => {
                  setRelation(relationShip)
                  setReferredId(patientId)
                  setIsPrimary(checked)
                  setDialog(false)
                }}
              />
            )}
          <WebCamCapturePhoto
            id='patient_profile'
            open={openWebCam}
            onBackClick={() => {
              setOpenWebCam(false)
            }}
            cropAspectRatio={aspectRatio}
            onContinueClick={(imgSrc) => {
              const data = imgSrc
                .toString()
                .split(',')[0]
                .replace('data:', '')
                .replace(';base64', '')
              callBackForCapturePhoto(
                imgSrc.toString().split(',')[1],
                imgSrc!.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)![0]!,
                imgSrc,
                data
              )
            }}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        {loading && (
          <Box>
            <CircularProgress />
          </Box>
        )}
        <WelloButton
          type='button'
          text={resetButtonText ?? 'Cancel (esc)'}
          size='small'
          variant='outlined'
          disabled={false}
          backgroundColor='#fefefe'
          color='#4543d9'
          onClick={cancel}
          id='cancel'
        />
        <WelloButton
          type='button'
          text={submitButtonText ?? 'Add Patient'}
          size='small'
          variant='outlined'
          disabled={
            patientsSearchState.groupDataList
              ? patientsSearchState.groupDataList.length > 0 &&
                referredId === undefined
              : false
          }
          backgroundColor={
            patientsSearchState.groupDataList
              ? patientsSearchState.groupDataList.length > 0 &&
                referredId === undefined
                ? 'grey'
                : '#4543d9'
              : '#4543d9'
          }
          //   backgroundColor={
          //     patientsSearchState.patientList ??
          //     ([].length > 0 && refferedId === undefined)
          //       ? 'grey'
          //       : '#4543d9'
          //   }
          color='#fefefe'
          onClick={() => {
            handleSubmit()
          }}
          id='add_patient_save'
        />
      </DialogActions>
    </Dialog>
  )
}
