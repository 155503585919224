import MaterialTable, { Column, MTableToolbar } from '@material-table/core'
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  kBackgroundDefault,
  kDialogueBackground,
  kPrimaryLight,
} from 'configs/styles/muiThemes'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import _ from 'lodash'
import { CPGForTable } from 'models/administration/cpgDetailsForTable'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isPremiumOffer } from 'utils/admisnistration/cpg/cpgListHelper'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import OverflowTwoLineTypography from 'views/components/consultation/plan/ayurveda/overflowTwoLineTypography'
import OverflowTypography from 'views/components/consultation/plan/ayurveda/overflowTypography'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '2px',
      margin: '0px',
    },

    '& .MuiTableRow': {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(33, 150, 243, 0.5)',
        },
      },
    },
  },
})

interface CPGListTableProps {
  medicationsList: CPGForTable[]
  actionComponent?: React.ReactNode

  onActionClick?: (medication: CPGForTable) => void
}

export const CPOfferingListTable: React.FC<CPGListTableProps> = ({
  medicationsList,
  actionComponent,

  onActionClick,
}: CPGListTableProps) => {
  const ref = React.useRef<null | HTMLDivElement>(null)

  const { height, width } = useWindowDimensions()
  const classes = useStyles()
  const convertedData = medicationsList
  const [data, setData] = useState([...convertedData])

  const [selectedRowId, setSelectedRowId] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(false)
  const [openPartnerLabPopup, setOpenPartnerLabPopup] = useState(false)
  const { t } = useTranslation()
  const [nameFilter, setNameFilter] = useState('')
  const [nameFocus, setNameFocus] = useState(true)
  const [addressFilter, setAddressFilter] = useState('')
  const [addressFocus, setAddressFocus] = useState(false)
  const [phoneFilter, setPhoneFilter] = useState('')
  const [phoneFocus, setPhoneFocus] = useState(false)
  const [emailFilter, setEmailFilter] = useState('')
  const [emailFocus, setEmailFocus] = useState(false)
  const [ageFilter, setAgeFilter] = useState('')
  const [ageFocus, setAgeFocus] = useState(false)
  const [statusFilter, setStatusFilter] = useState('')
  const [statusFocus, setStatusFocus] = useState(false)
  const [typeFilter, setTypeFilter] = useState('')
  const [typeFocus, setTypeFocus] = useState(false)

  const [mainFocus, setMainFocus] = useState(false)
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullText, setFullText] = React.useState<string>()

  const handleDarkModeChange = () => {
    // setPreferDarkMode(!preferDarkMode)
    // localStorage.setItem('_tableDarkMode', !preferDarkMode)
  }

  const filterType = (value: any, filed: string) => {
    setTypeFocus(true)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d
          .cpgName!.replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setTypeFilter(value)
    setTypeFocus(true)
    setAddressFilter('')
    setAddressFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setAgeFocus(false)
    setStatusFocus(false)
    setNameFocus(false)
    setMainFocus(false)
  }

  const filterName = (value: any, filed: string) => {
    setNameFocus(true)
    if (value) {
      const filteredData = convertedData.filter((d) =>
        d.cpgName
          .replace(/\s+/g, '')
          .toLowerCase()
          .includes(value.replace(/\s+/g, '').toLowerCase())
      )
      setData(filteredData)
    } else {
      setData([...convertedData])
    }
    setNameFilter(value)
    setAddressFilter('')
    setAddressFocus(false)
    setPhoneFocus(false)
    setEmailFocus(false)
    setAgeFocus(false)
    setStatusFocus(false)
    setTypeFocus(false)
    setMainFocus(false)
  }

  const columns: Column<CPGForTable>[] = [
    {
      title: (
        <Box minWidth='150px' minHeight='12px' maxHeight='12px' pl='24px'>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,

              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Care Plan
          </Typography>
        </Box>
      ),
      cellStyle: {
        paddingLeft: '10px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      width: '30%',
      field: 'cpgName',
      customSort: (a: CPGForTable, b: CPGForTable) =>
        a.cpgName > b.cpgName ? 1 : b.cpgName > a.cpgName ? -1 : 0,

      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (rowData: CPGForTable) => (
        <Box display='flex' alignItems='center'>
          <OverflowTypography
            text={rowData.cpgName ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: true,
            }}
          />
        </Box>
      ),
      filtering: false,
    },

    {
      title: (
        <Box>
          <Typography
            variant='subtitle1'
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
            color='primary'
          >
            Description
          </Typography>
        </Box>
      ),
      cellStyle: {
        paddingLeft: '16px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      field: 'cpgDescription',
      customSort: (a: CPGForTable, b: CPGForTable) =>
        (a.cpgDescription ?? '') > (b.cpgDescription ?? '')
          ? 1
          : (b.cpgDescription ?? '') > (a.cpgDescription ?? '')
          ? -1
          : 0,

      hiddenByColumnsButton: false,
      filtering: false,
      width: '50%',
      render: (rowData: CPGForTable) => (
        <Box display='flex' alignItems='center'>
          <OverflowTwoLineTypography
            text={rowData.cpgDescription ?? ''}
            typographyProps={{
              variant: 'subtitle2',
              noWrap: false,
            }}
          />
        </Box>
      ),
    },
  ]

  if (actionComponent && onActionClick) {
    columns.push({
      title: <Box />,
      field: 'cpgGoal',
      width: '8%',
      cellStyle: {
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      customSort: (a: CPGForTable, b: CPGForTable) =>
        (a.cpgGoal ?? '') > (b.cpgGoal ?? '')
          ? 1
          : (b.cpgGoal ?? '') > (a.cpgGoal ?? '')
          ? -1
          : 0,
      hiddenByColumnsButton: false,
      //   defaultSort: 'asc',
      render: (row: CPGForTable) => (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          onClick={(e) => onActionClick(row)}
        >
          {actionComponent}
        </Box>
      ),
      filtering: false,
    })
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={ref}
    >
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div
              /* className={classes.toolbarWrapper} */
              style={{
                minHeight: '0px',
              }}
            >
              <MTableToolbar {...props} />
            </div>
          ),
          Container: (props) => (
            <Paper
              {...props}
              elevation={0}
              style={{
                maxHeight: '100%',
                height: '100%',
                backgroundColor: useTheme().palette.background.paper,
              }}
              classes={classes}
            />
          ),
        }}
        columns={columns}
        data={data}
        onOrderChange={(orderBy, orderDirection) => {
          const key = orderBy < 1 ? 0 : orderBy
        }}
        onRowClick={(event, rowData) => {
          /* if (rowData && onOrgSelected) {
            onOrgSelected(rowData.orgDetails, rowData.mainOrgId ?? '')
            setSelectedRowId(rowData ? rowData.orgName ?? '' : '')
          } */
        }}
        options={{
          toolbar: false,
          search: false,
          showTitle: false,
          columnResizable: false,
          paginationPosition: 'bottom',
          minBodyHeight: height - 120,
          maxBodyHeight: height - 140,
          padding: 'dense',
          searchFieldVariant: 'outlined',
          filtering: false,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 30],
          //   columnsButton: true,
          // maxBodyHeight: `${height - 190}px`,
          //   minBodyHeight: `${height - 260}px`,
          tableLayout: 'fixed',
          sorting: true,
          thirdSortClick: false,

          headerStyle: {
            backgroundColor: kBackgroundDefault,
            width: 100,
            minHeight: '12px',
            maxHeight: '12px',
            position: 'sticky',
            top: 0,
            zIndex: 100,
            // height: 20,
          },
          selectionProps: (_rowData: any) => ({
            color: 'primary',
          }),
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          rowStyle: (_data: CPGForTable, index: number, _level: number) =>
            index % 2
              ? selectedRow && _data.cpgId === selectedRowId
                ? {
                    backgroundColor: '#9E9DDC',

                    '&:hover': { backgroundColor: 'red' },
                  }
                : {
                    backgroundColor: kDialogueBackground,
                    '&:hover': { backgroundColor: 'red' },
                  }
              : selectedRow && _data.cpgId === selectedRowId
              ? {
                  backgroundColor: '#9E9DDC',

                  '&:hover': { backgroundColor: 'red' },
                }
              : { '&:hover': { backgroundColor: 'red' } },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
          },
          toolbar: {
            nRowsSelected: '{0} row(s) selected',
          },
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No records to display',
            filterRow: {
              filterTooltip: 'Filter',
            },
          },
        }}
      />
    </div>
  )
}
