import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CardContent,
  CardActions,
  Typography,
  Card,
  Paper,
  CircularProgress,
  Grid,
} from '@material-ui/core'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  Label,
} from 'recharts'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestForDateWiseCollectionGraph } from 'redux/administration/graph/dateWiseGraph/dateWiseSearchGraphSearchSlice'

import { RootState } from 'redux/rootReducer'

interface Props {
  date: Date
  endDate: Date
}
export const DateWiseCollection: React.FC<Props> = ({ date, endDate }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const adminGraphSearchSlice = useSelector(
    (state: RootState) => state.dateWiseCollectionSlice
  )

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <Box
          display='flex'
          flexDirection='column'
          style={{ backgroundColor: '#FDFFABB2' }}
        >
          <Typography
            variant='subtitle1'
            color='textSecondary'
            style={{ color: 'green', fontSize: 10 }}
          >
            {`${label}`}
          </Typography>
          {/* <Typography
            variant='subtitle1'
            style={{ color: 'green', fontSize: 10 }}
          >
            Total Appointments : {`${payload?.[0].payload.NoOfAppointments}`}
          </Typography> */}
          <Typography
            variant='subtitle1'
            color='primary'
            style={{ color: 'green', fontSize: 10 }}
          >
            Total Discharge : {`${payload?.[0].payload.TotalDischarge}`}
          </Typography>
          <Typography
            variant='subtitle1'
            color='inherit'
            style={{ color: 'green', fontSize: 10 }}
          >
            Collection : &#x20b9;{' '}
            {`${payload?.[0].payload.collection.toFixed(2)}`}
          </Typography>
        </Box>
      )
    }

    return null
  }

  const [activeBarIndex, setActiveBarIndex] = useState<any>()
  const [toolTipYPosition, setToolTipYPosition] = useState<any>({})

  useEffect(() => {
    const barCharts = document.querySelectorAll('.recharts-bar-rectangle')
    if (!barCharts || !activeBarIndex) return
    // Init tooltip values
    const barChart = barCharts[activeBarIndex]
    setToolTipYPosition(barChart.getBoundingClientRect().height - 40)
  }, [activeBarIndex])

  useEffect(() => {
    dispatch(requestForDateWiseCollectionGraph(date, endDate, 'day'))
  }, [])

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: '#FFFFFF',
      }}
    >
      <Card
        elevation={2}
        style={{
          width: '100%',
        }}
      >
        <CardContent>
          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              width='100%'
            >
              <Box
                display='flex'
                flexGrow={1}
                flexDirection='column'
                justifyContent='center'
                alignContent='center'
              >
                <Box
                  justifyContent='center'
                  width='100%'
                  height='100%'
                  alignContent='center'
                  p={0.5}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Typography
                          variant='subtitle1'
                          color='primary'
                          align='center'
                        >
                          Collection over date(s)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {adminGraphSearchSlice.searchingAppointments && (
                  <Box
                    flexDirection='row'
                    display='flex'
                    alignSelf='center'
                    justifyContent='center'
                  >
                    <CircularProgress size={25} />
                  </Box>
                )}
              </Box>
              <Box
                display='flex'
                flexGrow={4}
                flexDirection='column'
                justifyContent='center'
                alignContent='center'
              >
                {adminGraphSearchSlice.errorWhileSearchingAppointments && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      color='error'
                      align='center'
                    >
                      Error while searching IPD . Please try again
                    </Typography>
                  </Box>
                )}
                {adminGraphSearchSlice.noResultsAvailable && (
                  <Box
                    //   display='flex'
                    //   flexDirection='column'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                    alignContent='center'
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container justifyContent='center'>
                          <Typography
                            variant='subtitle1'
                            color='primary'
                            align='center'
                          >
                            No collection data available.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {adminGraphSearchSlice.collectionData &&
                  adminGraphSearchSlice.collectionData.length > 0 && (
                    <ResponsiveContainer height={200}>
                      <BarChart
                        data={adminGraphSearchSlice.collectionData}
                        barGap={10}
                        margin={{
                          top: 10,
                          right: 15,
                          left: 10,
                          bottom: 60,
                        }}
                        width={1100}
                        onMouseMove={(e) => {
                          setActiveBarIndex(e.activeTooltipIndex)
                        }}
                      >
                        <XAxis
                          dataKey='date'
                          width={30}
                          interval={0}
                          allowDataOverflow={true}
                          type='category'
                          domain={['auto', 'auto']}
                          padding={{ left: 10 }}
                          angle={90}
                          dy={28}
                          dx={5}
                        >
                          <Label
                            value='Date'
                            position='insideBottom'
                            offset={-40}
                            style={{
                              textAnchor: 'middle',
                              fill: 'blue',
                            }}
                          />
                        </XAxis>
                        <YAxis>
                          <Label
                            angle={270}
                            position='left'
                            style={{
                              textAnchor: 'middle',
                              fill: 'blue',
                            }}
                          >
                            Collection in &#x20b9;
                          </Label>
                        </YAxis>
                        <Tooltip
                          content={<CustomTooltip />}
                          // cursor={false}

                          cursor={false}
                          //   position={{ y: 170 - toolTipYPosition }}
                          //   offset={-90}
                          allowEscapeViewBox={{ x: true, y: true }}
                        />

                        <Bar dataKey='collection' fill='#8884d8' barSize={20} />
                      </BarChart>
                    </ResponsiveContainer>
                  )}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  )
}
