import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItem,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { User } from 'models/user'
import { WelloOrgDetails } from 'models/welloUnit'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetUpdateStatus } from 'redux/collectionCenter/partnerLabManagementSlice'
import { requestForProfileUpdate } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerDetails,
  isDoctor,
} from 'services/userDetailsService'
import { UpdateProfileData } from 'utils/admisnistration/users/usersHelper'
import { GenderList } from 'utils/constants'
import { EduOrganizations } from 'utils/constants/edu_organizations'
import { PROFILE_SELF_DESCRIPTION_URL } from 'utils/constants/fhir_vs_ext_constants'
import { Languages } from 'utils/constants/langauages'
import { IdProofType } from 'utils/constants/proof_types'
import { Qualifications } from 'utils/constants/qualifications'
import { Specializations } from 'utils/constants/spcializations'
import {
  getAddress,
  getCodiong,
  getGenderCoding,
  getGenderOfDoctor,
  getLanguagesData,
  getLastNameFromHumanName,
  getNameFromHumanName,
  getOrgFromPractitioner,
  getProfilePicPractitionerForDoc,
  getRegistrationNumber,
  getStringValueExtensionsOfUrl,
  getTelecomFromContactPoints,
  getValueAttachmentFromExtension,
  getValueAttachmentFromExtensionContentType,
  getValueAttachmentFromExtensionDataForUrl,
  getValueCodingFromExtension,
  getWelloOrgOrgFromPractitioner,
} from 'utils/fhirResourcesHelper'
import { getUpdatedPractitionerDetails } from 'utils/fhirResoureHelpers/invoice_helper'
import {
  validateDobForPractioner,
  validateGender,
  validateProfileDescription,
  validateRegistration,
} from 'utils/formValidators'
import { LogoViewer } from 'views/components/common/logoViewer'
import { ValueSetSelectMultiple } from 'views/components/common/multipleSelectFromValueSetUrl'
import { ValueSetSelectSingleDisplaySinge } from 'views/components/common/SingleSelectFromDesination'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { ValueSetSelectSingleForOther } from 'views/components/common/singleSelectValueFromValueSetUrlOther'
import { WelloDatePicker } from 'views/components/LeftMenu/WelloDatePicker'
import {
  SelectedFile,
  WelloFilePicker,
} from 'views/components/LeftMenu/WelloFilePicker'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { WelloQualification } from 'views/components/LeftMenu/welloQualification'
import { WelloSelectAutoComplete } from 'views/components/LeftMenu/WelloSelectAutoComplete'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import {
  validateEmail,
  validateMobileNumber,
  WelloDateInput,
  WelloFormItemLabel,
  WelloGenderSelector,
  WelloTextField,
} from 'wello-web-components'

interface Props {
  practitioner: R4.IPractitioner
  practitionerRole: R4.IPractitionerRole
  collegesList: WelloOrgDetails[]
  open: boolean
  onClose: () => void
  onDone?: () => void
}
export const EditPractitioner: React.FC<Props> = ({
  practitioner,
  practitionerRole,
  collegesList,
  open,
  onClose,
  onDone,
}) => {
  const practitionerManagement = useSelector(
    (state: RootState) => state.practitionerManagement
  )
  const { t } = useTranslation()
  const actorInvitationSetupSlice = useSelector(
    (state: RootState) => state.actorInvitationSetupSlice
  )
  const [updatingStatus, setUpdatingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })
  const [fetchingStatus, setFetchingStatus] = useState<NetworkRequestStatus>({
    initial: true,
  })

  const [loading, setLoading] = useState<boolean>(false)
  const seq = ''
  let lName: string | undefined = ''
  let lastNameData: string | undefined = ''
  let emailL: string | undefined = ''
  let address: string | undefined = ''
  let phoneData: string | undefined = ''
  let datOfBirth: string | undefined
  let genderData: string | undefined
  let profileData: string | undefined
  let qual: R4.ICoding[]
  const dispatch = useDispatch()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [qualificationData, setQualificationData] = useState<R4.ICoding>()
  const [regNo, setRegNo] = useState<string>(
    getRegistrationNumber(practitioner)
  )
  const [orgData, setOrgData] = useState<WelloOrgDetails | undefined>(
    getWelloOrgOrgFromPractitioner(practitioner)
  )
  const [pract, setPract] = useState<R4.IPractitioner>(
    getCurrentUserPractitionerDetails()
  )
  const [spl, setSpl] = useState<R4.ICoding>()
  const [idProofTypeData, setIdProofTypeData] = useState<
    R4.ICoding | undefined
  >(
    practitioner
      ? getValueCodingFromExtension(
          practitioner.extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofType'
        ) ?? undefined
      : undefined
  )
  const [proofNumber, setProofNumber] = useState<string>(
    practitioner
      ? getValueAttachmentFromExtensionDataForUrl(
          practitioner.extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofValue'
        ) ?? ''
      : ''
  )
  const [frontFile, setFrontFile] = useState<SelectedFile>()
  const [backFile, setBackFile] = useState<SelectedFile>()
  const [profilePic, setProfilePic] = useState<string>(
    getProfilePicPractitionerForDoc(practitioner)
  )
  const [languages, setLanguages] = useState<R4.ICoding[]>(
    getLanguagesData(practitioner.communication ?? [])
  )
  const [splArr, setSplArr] = useState<R4.ICoding[]>(
    getLanguagesData(practitionerRole.specialty ?? [])
  )
  const [profileDescription, setProfileDescription] = useState<string>()
  if (practitioner) {
    lName = getNameFromHumanName(practitioner.name ?? [])
    lastNameData = getLastNameFromHumanName(practitioner.name ?? [])
    emailL =
      getTelecomFromContactPoints(
        practitioner.telecom ?? [],
        ContactPointSystemKind._email
      ) ?? ''
    address = practitioner.address ? getAddress(practitioner.address) : ''
    phoneData =
      getTelecomFromContactPoints(
        practitioner.telecom ?? [],
        ContactPointSystemKind._phone
      ) ?? ''

    datOfBirth = moment(practitioner.birthDate).format('YYYY-MM-DD')
    genderData = getGenderOfDoctor(practitioner)
    profileData = getStringValueExtensionsOfUrl(
      practitioner.extension,
      PROFILE_SELF_DESCRIPTION_URL
    )
  }

  const [user, setUser] = useState<User>({
    id: '',
    labName: '',
    lastName: '',
    labAddress: '',
    name: '',
    email: '',
    phone: '',
    secondaryPhone: '',
    address: '',
    gender: '',
    dob: '',
    profileDescription: '',
    qualiFication: [],
    lat: 0,
    lng: 0,
    isEditAvailable: false,
  })

  function getPractionerDetails() {
    setFetchingStatus({
      requesting: true,
    })

    getUpdatedPractitionerDetails(getCurrentUserPractitionerDetails().id ?? '')
      .then((e) => {
        if ('resourceType' in e!) {
          setFetchingStatus({
            requesting: false,
            requestSuccess: true,
          })

          setPract(e)
        } else {
          setFetchingStatus({
            requesting: false,
            requestError: true,
          })
          dispatch(showErrorAlert('Error while fetching signatures'))
        }
      })
      .catch((e) => {
        setFetchingStatus({
          requesting: false,
          requestError: true,
        })
        dispatch(showErrorAlert('Error while fetching signatures'))
      })
  }

  function UpdateProfile() {
    setUpdatingStatus({
      requesting: true,
    })
    const mapCenter = {
      lat: 0,
      lng: 0,
    }
    setLoading(true)
    UpdateProfileData(
      mapCenter,
      user,
      pract,
      practitionerRole,
      qualificationData,
      regNo,
      orgData,
      spl,
      languages,
      splArr,
      profilePic,
      idProofTypeData,
      proofNumber,
      undefined,
      frontFile,
      backFile
    )
      .then((e) => {
        if (e === true) {
          setUpdatingStatus({
            requesting: false,
            requestSuccess: true,
          })
          if (onDone) onDone()
          setLoading(false)
          dispatch(showSuccessAlert('Profile Details Updated Successfully'))
        } else {
          setUpdatingStatus({
            requesting: false,
            requestError: true,
          })
          setLoading(false)
          dispatch(showErrorAlert('Error while updating'))
        }
      })
      .catch((e) => {
        setLoading(false)
        setUpdatingStatus({
          requesting: false,
          requestError: true,
        })
        dispatch(showErrorAlert('Error while updating'))
      })
  }

  function resetDetails() {
    setUser({
      id: '',
      labName: '',
      labAddress: '',
      lastName: '',
      name: '',
      email: '',
      phone: '',
      secondaryPhone: '',
      address: '',
      gender: '',
      dob: '',
      profileDescription: '',
      qualiFication: [],
      lat: 0,
      lng: 0,
      isEditAvailable: false,
    })
    setQualificationData(undefined)
    setRegNo(getRegistrationNumber(practitioner))
  }

  function handleNameChange(name: string) {
    setUser({ ...user, name, isEditAvailable: true })
  }
  function handleDob(date: any) {
    setUser({ ...user, dob: date, isEditAvailable: true })
  }

  function handleEmailChange(event: any) {
    setUser({ ...user, email: event.target.value, isEditAvailable: true })
  }

  function handlePhoneNumberChange(phone: string) {
    setUser({ ...user, phone, isEditAvailable: true })
  }

  function handleAddressChange(event: any) {
    setUser({ ...user, address: event.target.value, isEditAvailable: true })
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    if (!user.name || user.name?.length === 0) {
      hasErrorsInData = true
    }

    if (
      !user.email ||
      user.email?.length === 0 ||
      validateEmail(user.email, true).length > 0
    ) {
      hasErrorsInData = true
    }

    if (
      !user.phone ||
      user.phone?.length === 0 ||
      validateMobileNumber(user.phone, true).length > 0
    ) {
      hasErrorsInData = true
    }

    if (!user.address || user.address?.length === 0) {
      hasErrorsInData = true
    }

    if (!hasErrorsInData) {
      const mapCenter = {
        lat: 0,
        lng: 0,
      }

      UpdateProfile()
    }
    setHasErrors(hasErrorsInData)
  }

  function handleCancel() {
    setUser({
      id: '',
      labName: '',
      labAddress: '',
      name: lName || '',
      lastName: lastNameData ?? '',
      email: emailL || '',
      phone: phoneData || '',
      secondaryPhone: '',
      address: address || '',
      gender: genderData || '',
      dob: datOfBirth || '',
      profileDescription: profileData || '',
      qualiFication: [],
      lat: 0,
      lng: 0,
      isEditAvailable: false,
    })
    setQualificationData(undefined)
    setRegNo(getRegistrationNumber(practitioner))
  }

  useEffect(() => {
    getPractionerDetails()
    setUser({
      id: '',
      labName: '',
      labAddress: '',
      name: lName || '',
      lastName: lastNameData ?? '',
      email: emailL || '',
      phone: phoneData || '',
      secondaryPhone: '',
      address: address || '',
      gender: genderData || '',
      dob: datOfBirth || '',
      profileDescription: profileData || '',
      qualiFication: [],
      lat: 0,
      lng: 0,
      isEditAvailable: false,
    })

    return () => {}
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        // resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'> Update Profile</DialogTitle>
      <DialogContent>
        <Box width='100%' px={1}>
          {practitioner && (
            <Box display='flex' flexDirection='column'>
              <Box display='flex' flexDirection='row'>
                <Box
                  display='flex'
                  //   flexGrow={1}
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  p={0.5}
                  width='10%'
                >
                  <Box px={0.5}>
                    <WelloFilePicker
                      fileTypeToPick='photo'
                      id='unit_actor_profile_pic_edit_page'
                      labelOrientation='column'
                      name='profilePic'
                      allowUpdate={true}
                      displaySelectedPhoto='circle'
                      preSelectedFile={{
                        type: 'image',
                        path: profilePic,
                      }}
                      inputProps={{}}
                      onFileChanged={(selectedField) => {
                        if (selectedField.path) {
                          setProfilePic(selectedField.path as string)
                          setUser({ ...user, isEditAvailable: true })
                        }
                      }}
                      required={false}
                    />
                  </Box>
                </Box>

                <Box
                  display='flex'
                  //   flexGrow={1}
                  alignItems='flex-end'
                  justifyContent='flex-start'
                  p={0.5}
                  width='30%'
                >
                  <WelloTextField
                    title={t('labelCommon:userName')}
                    //   value=lab.labName
                    textProps={{
                      id: `actor_name_unit_${t}`,
                      value: user.name,
                      defaultValue: 'test',
                      error:
                        hasErrors && (!user.name || user.name?.length === 0),
                      helperText:
                        hasErrors && (!user.name || user.name?.length === 0)
                          ? t('labelCommon:validName')
                          : '',
                      onChange: (changedValue) => {
                        handleNameChange(changedValue.target.value)
                      },
                    }}
                  />
                </Box>

                <Box
                  display='flex'
                  //   flexGrow={1}
                  alignItems='flex-end'
                  justifyContent='center'
                  p={0.5}
                  width='30%'
                >
                  <WelloTextField
                    title={t('labelCommon:email_address')}
                    //   value=lab.labName
                    textProps={{
                      id: `actor_email_unit`,
                      value: user.email,
                      defaultValue: 'test',
                      disabled: true,
                      error:
                        hasErrors && validateEmail(user.email, true).length > 0,
                      helperText:
                        hasErrors && validateEmail(user.email, true).length > 0
                          ? t('labelCommon:validEmail')
                          : '',
                      onChange: (changedValue) => {
                        handleEmailChange(changedValue.target.value)
                      },
                    }}
                  />
                </Box>
                <Box
                  display='flex'
                  //   flexGrow={1}
                  alignItems='flex-end'
                  justifyContent='center'
                  p={0.5}
                  width='30%'
                >
                  <WelloPhoneNumberField
                    textProps={{
                      id: `actor_phone_unit_${t}`,
                      value: user.phone,
                      countryCodeEditable: false,
                      defaultCountry: 'in',
                      fullWidth: true,
                      disabled: true,
                      name: t('phone_name_label'),
                      inputMode: 'tel',
                      onlyCountries: ['in'],
                      disableDropdown: false,
                      inputProps: { maxLength: 13 },
                      onChange: (
                        e:
                          | React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >
                          | string
                      ) => {
                        if (typeof e === 'string') {
                          handlePhoneNumberChange(e)
                        }
                      },
                      regions: ['asia'],
                      autoFormat: false,
                      size: 'small',
                      variant: 'outlined',
                      error:
                        hasErrors &&
                        validateMobileNumber(user.phone, true).length > 0,
                      helperText:
                        hasErrors &&
                        validateMobileNumber(user.phone, true).length > 0
                          ? t('labelCommon:validPhone')
                          : '',

                      required: true,
                    }}
                    title={t('labelCommon:phone_number_label')}
                  />
                </Box>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Box
                  display='flex'
                  //   flexGrow={1}
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  p={0.5}
                  width='10%'
                />
                <Box
                  display='flex'
                  //   flexGrow={1}
                  alignItems='flex-start'
                  justifyContent='center'
                  p={0.5}
                  width='30%'
                >
                  <WelloDatePicker
                    title={t('labelCommon:Date of Birth')}
                    textProps={{
                      id: 'unit_actor_dob',
                      fullWidth: true,
                      required: true,
                      'aria-required': true,
                      name: 'dob',
                      onChange: (changeData) => {
                        handleDob(changeData.target.value)
                      },
                      value: user.dob,
                      inputProps: {
                        min: '1871-01-01',
                        max: moment().format('YYYY-MM-DD'),
                      },

                      error:
                        validateDobForPractioner(user.dob, true).length > 0,
                      helperText:
                        validateDobForPractioner(user.dob, true).length > 0
                          ? validateDobForPractioner(user.dob, true)
                          : '',
                    }}
                  />
                </Box>

                <Box
                  display='flex'
                  //   flexGrow={1}
                  alignItems='flex-start'
                  justifyContent='center'
                  p={0.5}
                  width='30%'
                >
                  <Box display='flex' flexDirection='column' width='100%'>
                    <WelloFormItemLabel title='Gender' />
                    <Autocomplete
                      style={{
                        borderRadius: '4px',
                      }}
                      fullWidth
                      size='small'
                      componentName='gender'
                      autoFocus
                      disableClearable
                      defaultValue={getGenderCoding(GenderList, user.gender)}
                      multiple={false}
                      getOptionSelected={(option, value) =>
                        option.display === value.display
                      }
                      getOptionLabel={(option) => option.display ?? ''}
                      options={GenderList}
                      onChange={(e, changedValue, reason) => {
                        if (changedValue) {
                          setUser({
                            ...user,
                            gender: changedValue.id ?? 'male',
                            isEditAvailable: true,
                          })
                        }
                      }}
                      autoComplete
                      ChipProps={{
                        deleteIcon: (
                          <ClearOutlined
                            style={{
                              height: '15px',
                              color: 'white',
                            }}
                          />
                        ),
                        style: {
                          backgroundColor: kPrimaryLight,
                          borderRadius: '4px',
                        },
                      }}
                      renderOption={(option) => (
                        <Typography variant='subtitle2'>
                          {option.display}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder='Select Gender'
                          fullWidth
                          variant='outlined'
                          error={validateGender(user.gender, true).length > 0}
                          // helperText={
                          //   errors.gender === undefined
                          //     ? 'Gender is required'
                          //     : undefined
                          // }
                          size='small'
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                        />
                      )}
                    />
                    <Box paddingLeft={1} id='genderError'>
                      <Typography
                        variant='subtitle2'
                        color='error'
                        style={{ fontWeight: 500 }}
                      >
                        {validateGender(user.gender, true).length > 0
                          ? validateGender(user.gender, true)
                          : ''}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <WelloGenderSelector
                    title={t('labelCommon:gender_label')}
                    textFieldProps={{
                      value: user.gender,
                      id: 'unit_actor_select_gender',
                      name: 'gender',
                      disabled: false,
                      error: validateGender(user.gender, true).length > 0,
                      helperText:
                        validateGender(user.gender, true).length > 0
                          ? validateGender(user.gender, true)
                          : '',
                    }}
                    onChanged={(selectedGender) => {
                      if (selectedGender?.code) {
                        setUser({ ...user, gender: selectedGender?.code })
                      }
                    }}
                  /> */}
                </Box>
                <Box
                  width='30%'
                  p={0.5}
                  display='flex'
                  flexDirection='column'
                  alignItems='flex-start'
                  justifyContent='flex-start'
                >
                  <ValueSetSelectMultiple
                    key='wellopathy-care-team-roles-vs'
                    title={t('labelCommon:languages_title')}
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-indian-languages-vs'
                    id='onboardingLanguageList'
                    fuzzySearch={true}
                    disabled={false}
                    onOptionSelected={(selectedServiceType) => {
                      setUser({ ...user, isEditAvailable: true })
                      setLanguages(selectedServiceType as R4.ICoding[])
                    }}
                    // error={
                    //   props.hasError &&
                    //   (props.practitioner.communication === undefined ||
                    //     props.practitioner.communication?.length === 0) &&
                    //   language.length === 0
                    // }
                    helperText={undefined}
                    preSelectedOptions={languages}
                  />
                  {languages.length === 0 && (
                    <Box px={1}>
                      <Typography variant='subtitle2' color='error'>
                        Language is required
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              {practitioner.qualification?.map(
                (org: R4.IPractitioner_Qualification, index: number) => (
                  <Box
                    key={`unit_actor_small_details_${org.id}`}
                    display='flex'
                    flexDirection='column'
                    width='100%'
                  >
                    <Box
                      key={`unit_actor_small_details_${org.id}`}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                    >
                      <Box
                        display='flex'
                        //   flexGrow={1}
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        p={0.5}
                        width='10%'
                      />
                      <Box minWidth={282} maxWidth={282} p={0.5}>
                        <ValueSetSelectSingleForOther
                          key='wellopathy-spl'
                          title={t('labelCommon:qualification_label')}
                          url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-qualifications-vs'
                          id='med_units'
                          fuzzySearch={true}
                          disabled={false}
                          onOptionSelected={(
                            conditionsData: R4.ICoding | undefined
                          ) => {
                            if (conditionsData) {
                              setQualificationData(conditionsData)
                            }
                          }}
                          alwaysShowAllOptions={true}
                          helperText={undefined}
                          placeHolder='Qualification'
                          preSelectedOptions={getCodiong(org.code)}
                          profile={true}
                        />
                        {/* <WelloQualification
                          title={t('labelCommon:qualification_label')}
                          availableCodings={Qualifications}
                          onChanged={(e) => {
                            setQualificationData(e)
                          }}
                          textFieldProps={{
                            disabled: false,
                          }}
                          preSelectedCoding={getCodiong(org.code)}
                        /> */}
                      </Box>

                      <Box
                        width='30%'
                        p={0.5}
                        display='flex'
                        //   flexGrow={1}
                        alignItems='flex-start'
                        justifyContent='flex-start'
                      >
                        <WelloTextField
                          title={t('labelCommon:reg_num_label')}
                          textProps={{
                            disabled: false,
                            value: regNo,
                            error: validateRegistration(regNo, true).length > 0,
                            helperText:
                              validateRegistration(regNo, true).length > 0
                                ? validateRegistration(regNo, true)
                                : '',
                            onChange: (changedValue) => {
                              setRegNo(changedValue.target.value)
                            },
                          }}
                        />
                      </Box>

                      <Box
                        width='30%'
                        p={0.5}
                        display='flex'
                        //   flexGrow={1}
                        alignItems='flex-start'
                        justifyContent='flex-start'
                      >
                        <WelloSelectAutoComplete
                          title={t('labelCommon:medical_council_label')}
                          availableOptions={collegesList}
                          displayField='name'
                          id={`medical_qualification_reg_num_${index}`}
                          valuesField='id'
                          onOptionSelected={(selectedOrganization) => {
                            const selectedOpt: WelloOrgDetails =
                              selectedOrganization as WelloOrgDetails
                            setOrgData(selectedOpt)
                          }}
                          preSelectedOption={orgData}
                          disabled={false}
                        />
                      </Box>
                    </Box>
                    {isDoctor() && (
                      <Box
                        key={`unit_actor_small_details_${org.id}`}
                        display='flex'
                        flexDirection='row'
                        width='100%'
                      >
                        <Box
                          display='flex'
                          //   flexGrow={1}
                          alignItems='flex-start'
                          justifyContent='flex-start'
                          p={0.5}
                          width='10%'
                        />

                        <Box
                          display='flex'
                          flexGrow={1}
                          alignItems='flex-start'
                          justifyContent='flex-start'
                          p={0.5}
                          width='30%'
                        >
                          <WelloSelectAutoComplete
                            title={t('labelCommon:specialization')}
                            availableOptions={Specializations}
                            displayField='display'
                            id={`medical_qualification_specialization_${index}`}
                            valuesField='code'
                            onOptionSelected={(selectedOrganization) => {
                              setUser({ ...user, isEditAvailable: true })
                              setSpl(selectedOrganization)
                            }}
                            preSelectedOption={getValueCodingFromExtension(
                              org.extension,
                              'http://wellopathy.com/fhir/india/core/qualificationSpecialization'
                            )}
                            disabled={false}
                          />
                        </Box>
                        <Box
                          width='60%'
                          p={0.5}
                          display='flex'
                          flexDirection='column'
                        >
                          <WelloFormItemLabel title='Specialty' />
                          <Autocomplete
                            id='tf_search_tests'
                            multiple={true}
                            onOpen={() => {}}
                            onClose={() => {
                              //   dispatch(resetState())
                            }}
                            style={{
                              borderRadius: '4px',
                            }}
                            disableClearable
                            fullWidth
                            size='small'
                            getOptionSelected={(option, value) =>
                              option.display === value.display
                            }
                            disabled={false}
                            defaultValue={splArr}
                            getOptionLabel={(option) => option.display ?? ''}
                            options={Specializations}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            onChange={(e, changedValue, reason, detail) => {
                              setUser({ ...user, isEditAvailable: true })
                              setSplArr(changedValue)
                            }}
                            ChipProps={{
                              deleteIcon: (
                                <ClearOutlined
                                  style={{
                                    height: '15px',
                                    color: 'white',
                                  }}
                                />
                              ),
                              style: {
                                backgroundColor: kPrimaryLight,
                                borderRadius: '4px',
                              },
                            }}
                            renderOption={(option, { inputValue }) => {
                              const matches = match(
                                _.capitalize(option.display ?? ''),
                                inputValue
                              )
                              const parts = parse(
                                _.capitalize(option.display ?? ''),
                                matches
                              )
                              return (
                                <ListItem id={`lab_test_opt_${option.code}`}>
                                  <div>
                                    {parts.map((part, indexData) => (
                                      <span
                                        key={part.text}
                                        style={{
                                          backgroundColor: part.highlight
                                            ? kPrimaryLight
                                            : undefined,
                                          fontWeight: part.highlight
                                            ? 700
                                            : 400,
                                        }}
                                      >
                                        {part.text}
                                      </span>
                                    ))}
                                  </div>
                                </ListItem>
                              )
                            }}
                            filterOptions={(x) => x}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t(
                                  'labelCommon:type_atleast_3_chars'
                                )}
                                variant='outlined'
                                onChange={(e) => {
                                  //   if (e.target.value.length > 2) {
                                  //     dispatch(searchAllergy(e.target.value))
                                  //     setText(e.target.value)
                                  //   }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>{params.InputProps.endAdornment}</>
                                  ),
                                }}
                              />
                            )}
                          />
                          {splArr.length === 0 && (
                            <Box px={1}>
                              <Typography variant='subtitle2' color='error'>
                                Specialty is required
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                )
              )}

              <Box display='flex' flexDirection='row'>
                <Box
                  display='flex'
                  //   flexGrow={1}
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  p={0.5}
                  width='10%'
                />
                <Box
                  display='flex'
                  // flexGrow={1}
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  width='45%'
                  p={0.5}
                >
                  <WelloTextField
                    title='Profile Description'
                    textProps={{
                      id: `actor_name_unit_${t}`,
                      value: user.profileDescription,
                      disabled: false,
                      type: 'text',
                      rows: 4,
                      rowsMax: 6,
                      multiline: true,
                      error:
                        validateProfileDescription(
                          user.profileDescription,
                          true
                        ).length > 0,
                      helperText:
                        validateProfileDescription(
                          user.profileDescription,
                          true
                        ).length > 0
                          ? validateProfileDescription(
                              user.profileDescription,
                              true
                            )
                          : '',
                      onChange: (changedValue) => {
                        setUser({
                          ...user,
                          profileDescription: changedValue.target.value,
                          isEditAvailable: true,
                        })
                      },
                    }}
                  />
                </Box>
                <Box
                  display='flex'
                  // flexGrow={1}
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  width='45%'
                  p={0.5}
                >
                  <WelloTextField
                    title='Address'
                    textProps={{
                      id: `actor_name_unit_${t}`,
                      value: user.address,
                      type: 'text',
                      rows: 2,
                      rowsMax: 4,
                      multiline: true,
                      error:
                        hasErrors &&
                        (!user.address || user.address?.length === 0),
                      helperText:
                        hasErrors &&
                        (!user.address || user.address?.length === 0)
                          ? 'Enter valid address'
                          : '',
                      onChange: (changedValue) => {
                        handleAddressChange(changedValue)
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box
                display='flex'
                width='100%'
                flexDirection='row'
                justifyContent='flex-start'
                py={1}
              >
                <Box
                  display='flex'
                  //   flexGrow={1}
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  p={0.5}
                  width='10%'
                />
                <Box
                  display='flex'
                  width='30%'
                  flexDirection='column'
                  justifyContent='flex-start'
                >
                  <Box display='flex' flexGrow={1} width='100%' p={0.5}>
                    <WelloSelectFHIRCoding
                      title={t('labelCommon:select_proof_type')}
                      preSelectedCoding={idProofTypeData}
                      id='unit_actor_id_proof_type'
                      availableCodings={IdProofType}
                      onChanged={(val) => {
                        if (val) {
                          setUser({ ...user, isEditAvailable: true })
                          setIdProofTypeData(val)
                        }
                      }}
                      textFieldProps={{
                        fullWidth: true,
                        size: 'small',
                        required: true,
                        name: 'identityProofTypeCode',
                        id: 'unit_actor_id_proof_type',
                      }}
                    />
                  </Box>

                  <Box
                    display='flex'
                    flexGrow={1}
                    width='100%'
                    p={0.5}
                    justifyContent='flex-start'
                  >
                    <WelloTextField
                      title='Proof Number'
                      textProps={{
                        id: `unit_actor_id_proof_number`,
                        required: true,
                        value: proofNumber,
                        name: 'identityProofNumber',
                        error: proofNumber.length === 0,
                        helperText:
                          proofNumber.length === 0
                            ? 'Proof Number is required'
                            : '',
                        fullWidth: true,
                        size: 'small',
                        onChange: (e) => {
                          setUser({ ...user, isEditAvailable: true })
                          setProofNumber(e.target.value)
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                  width='60%'
                  paddingLeft={2}
                >
                  <Box
                    display='flex'
                    width='50%'
                    flexDirection='column'
                    justifyContent='flex-start'
                    py={1}
                  >
                    <Box width='100%' justifyContent='flex-start'>
                      <Typography variant='subtitle2'>
                        Identity Proof Front
                      </Typography>
                    </Box>
                    {practitioner &&
                      getValueAttachmentFromExtension(
                        practitioner.extension,
                        'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                      ) === '1' && (
                        <Box
                          display='flex'
                          width='50%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          py={0.5}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            justifyContent='flex-start'
                            alignContent='center'
                          >
                            <Box
                              display='flex'
                              width='100%'
                              flexDirection='row'
                              justifyContent='flex-start'
                              alignItems='flex-start'
                              height='90%'
                            >
                              <LogoViewer
                                contentType={getValueAttachmentFromExtensionContentType(
                                  practitioner.extension,
                                  'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                                )}
                                decodingRequired={true}
                                imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)"
                                resourceId={practitioner.id ?? ''}
                                resourceType='Practitioner'
                                onSelectionChanged={(value: SelectedFile) => {
                                  setUser({ ...user, isEditAvailable: true })
                                  setFrontFile(value)
                                }}
                                update={true}
                                purpose='frontEdit'
                              />
                              {/* <AttachmentImage
                        contentType='image/png'
                        decodingRequired={true}
                        imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)"
                        resourceId={userDetails.practitionerObject.id ?? ''}
                        resourceType='Practitioner'
                      /> */}
                            </Box>
                          </Box>
                        </Box>
                      )}

                    {getValueAttachmentFromExtension(
                      practitioner.extension,
                      'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                    ) === '0' && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-end'
                        flexGrow={1}
                        width='100%'
                      >
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                        >
                          <WelloFilePicker
                            fileTypeToPick='photo'
                            id='unit_actor_profile_pic_front'
                            labelOrientation='column'
                            name='logo'
                            labelName='Identity Proof Front'
                            allowUpdate={true}
                            displaySelectedPhoto='square'
                            preSelectedFile={frontFile}
                            inputProps={{}}
                            onFileChanged={(selectedField: SelectedFile) => {
                              setUser({ ...user, isEditAvailable: true })
                              if (selectedField) setFrontFile(selectedField)
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Box
                    display='flex'
                    width='50%'
                    flexDirection='column'
                    justifyContent='flex-start'
                    py={1}
                  >
                    <Box width='100%' justifyContent='flex-start'>
                      <Typography variant='subtitle2'>
                        Identity Proof Back
                      </Typography>
                    </Box>

                    {getValueAttachmentFromExtension(
                      practitioner.extension,
                      'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                    ) === '1' && (
                      <Box
                        display='flex'
                        width='50%'
                        flexDirection='row'
                        justifyContent='flex-start'
                        py={1}
                        px={1}
                        border={1}
                        borderRadius={2}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          flexGrow={1}
                          width='100%'
                        >
                          <Box
                            display='flex'
                            width='50%'
                            flexDirection='row'
                            justifyContent='center'
                            alignItems='flex-start'
                            height='90%'
                          >
                            <LogoViewer
                              contentType={getValueAttachmentFromExtensionContentType(
                                practitioner.extension,
                                'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                              )}
                              decodingRequired={true}
                              imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)"
                              resourceId={practitioner.id ?? ''}
                              resourceType='Practitioner'
                              onSelectionChanged={(value: SelectedFile) => {
                                setUser({ ...user, isEditAvailable: true })
                                setBackFile(value)
                              }}
                              update={true}
                              purpose='backEdit'
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}

                    {getValueAttachmentFromExtension(
                      practitioner.extension,
                      'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                    ) === '0' && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-end'
                        flexGrow={1}
                        width='100%'
                      >
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                        >
                          <WelloFilePicker
                            fileTypeToPick='photo'
                            id='unit_actor_profile_pic_back'
                            labelOrientation='column'
                            name='logo'
                            labelName='Identity Proof Back'
                            allowUpdate={true}
                            displaySelectedPhoto='square'
                            preSelectedFile={backFile}
                            inputProps={{}}
                            onFileChanged={(selectedField: SelectedFile) => {
                              setUser({ ...user, isEditAvailable: true })
                              if (selectedField) setBackFile(selectedField)
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          <Divider style={{ border: 1 }} />
        </Box>
      </DialogContent>
      <DialogActions>
        {loading && (
          <Box px={1}>
            <CircularProgress />
          </Box>
        )}

        <Button
          //   color='primary'
          onClick={() => {
            onClose()
          }}
          variant='outlined'
          disableElevation
          size='small'
          disabled={practitionerManagement.userDetailsUpdating}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          size='small'
          color='primary'
          disabled={
            practitionerManagement.userDetailsUpdating ||
            validateDobForPractioner(user.dob, true).length > 0 ||
            isDoctor()
              ? validateProfileDescription(user.profileDescription, true)
                  .length > 0
              : false || isDoctor()
              ? languages.length === 0
              : false || proofNumber.length === 0
          }
          onClick={() => {
            handleSubmit()
          }}
        >
          {t('labelCommon:Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
