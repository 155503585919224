/* eslint-disable no-alert */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { BpCollection } from 'models/bpData'
import {
  GroupedLabResults,
  GroupedLabResultsByName,
} from 'models/groupedLabResults'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserPractitionerRoleDetails } from 'services/userDetailsService'
import {
  getSortedObservation,
  getSortedObservationForAddLab,
  getSortedObservationForAddLabForPanel,
  getSortedObservationForIpd,
} from 'utils/appointment_handle/vitals_util'
import {
  getLabTestData,
  getLatestObservations,
  getLatestObservationsByDate,
  getLatestObservationsForOCA,
  getObserVationFfromEncounterForDateWise,
  getObserVationFfromObserVationDateWise,
  getObserVationFfromObserVationDateWiseForDexaOPD,
  getObserVationFfromObserVationDateWiseForHomOPD,
  getObserVationFfromObserVationDateWiseForLabPanel2OPD,
  getObserVationFfromObserVationDateWiseForLabPanel3OPD,
  getObserVationFfromObserVationDateWiseForLabPanel4OPD,
  getObserVationFfromObserVationDateWiseForLabPanelLftOPD,
  getObserVationFfromObserVationDateWiseForLabPanelOPD,
  getObserVationFfromObserVationDateWiseForLabPanelRftOPD,
  getObserVationFfromObserVationDateWiseForLabTest,
  getObserVationFfromObserVationDateWiseForSerumLytesOPD,
  getPanel1Data,
  getPanel2Data,
  getPanel3Data,
  getPanel4Data,
  getPanelDexaData,
  getPaneLftData,
  getPanelHomaData,
  getPanelSerumLytesData,
  getPanelTftData,
  getPaneRftData,
} from 'utils/fhirResoureHelpers/ipdObservationHelper'
import {
  getLabResultsPanel,
  getLabResultsWithName,
} from 'utils/fhirResoureHelpers/observationHelpers'
import { getPatientIdentifiersForSearch } from 'utils/fhirResoureHelpers/patientHelpers'
import { logger } from 'utils/logger'
import { LabTestDetails } from './labTestDetailStatusType'

const initialState: LabTestDetails = {
  searchingConditions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  errorWhileSearchingProcedures: false,
  showAppointment: false,
}

const labTestDetailsSliceOPD = createSlice({
  name: 'labTestDetailsSliceOPD',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<LabTestDetails>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.searchingConditions = action.payload.searchingConditions
      state.resultsAvailable = action.payload.resultsAvailable
      state.vitalsList = action.payload.vitalsList
      state.vitalData = action.payload.vitalData
      state.panel1 = action.payload.panel1
      state.panel2 = action.payload.panel2
      state.panel3 = action.payload.panel3
      state.lft = action.payload.lft
      state.rft = action.payload.rft
      state.panel4 = action.payload.panel4
      state.groupedLabTests = action.payload.groupedLabTests
      state.groupedPanel1 = action.payload.groupedPanel1
      state.groupedPanel2 = action.payload.groupedPanel2
      state.groupedPanel3 = action.payload.groupedPanel3
      state.groupedLft = action.payload.groupedLft
      state.groupedRft = action.payload.groupedRft
      state.groupedTft = action.payload.groupedTft
      state.groupedPanel4 = action.payload.groupedPanel4
      state.groupedHoma = action.payload.groupedHoma
      state.groupedDexa = action.payload.groupedDexa
      state.groupedSerumLytes = action.payload.groupedSerumLytes
      state.errorReason = action.payload.errorReason
      state.errorWhileSearchingProcedures =
        action.payload.errorWhileSearchingProcedures
      state.showAppointment = action.payload.showAppointment
      state.groupedResultsByName = action.payload.groupedResultsByName
    },
  },
})

export const requestLabTestDetailsDetailsOfPatient =
  (
    selectedPatient: R4.IPatient,
    appointmentId: string,
    split: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: LabTestDetails = {
      searchingConditions: true,
      errorWhileSearchingProcedures: false,
      resultsAvailable: false,
      noResultsAvailable: false,
      showAppointment: false,
    }
    dispatch(labTestDetailsSliceOPD.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const searchParameters: any = {
        patient: `Patient/${selectedPatient.id}`,
        'encounter.appointment': appointmentId,
        category: 'laboratory',
        _count: 500,
      }

      const searchParametersSplit: any = {
        category: 'laboratory',

        _count: 500,
      }

      if (getPatientIdentifiersForSearch(selectedPatient).length > 0) {
        searchParametersSplit['patient:Patient.identifier'] =
          getPatientIdentifiersForSearch(selectedPatient)
      }

      let response: any

      const endDate = `${moment(
        moment(moment(moment()).format('YYYY-MM-DD'))
          .startOf('day')
          .utc()
          .format()
      )
        .subtract(90, 'days')
        .toISOString()}`
      if (split === false) {
        response = await fhirClient.doGetResourceForAppointment(
          '/Observation?_sort=_lastUpdated',
          appointmentId,
          searchParameters
        )
      } else {
        response = await fhirClient.doGetResourceForAppointment(
          `/Observation?date=gt${endDate}&_sort=_lastUpdated&value-quantity:missing=false`,
          appointmentId,
          searchParametersSplit
        )
      }

      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingProcedures = true
        state.searchingConditions = false
        state.showAppointment = false

        dispatch(labTestDetailsSliceOPD.actions.updatedStatus(state))
      } else {
        const proceduresResponse: R4.IBundle = resp.right
        if (proceduresResponse.entry && proceduresResponse.entry.length > 0) {
          const observationData: R4.IObservation[] =
            proceduresResponse.entry?.map(
              (item) => item.resource as R4.IObservation
            ) ?? [].sort()

          observationData.sort((a, b) =>
            (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
              ? -1
              : (a.issued ?? '') < (b.issued ?? '')
              ? 1
              : 0
          )

          const finalObservationData: R4.IObservation[] =
            getLatestObservationsByDate(observationData)

          const vitalLisDataFinal: VitalsData[] = []

          const groupedLabPanelResultLatest: GroupedLabResultsByName[] =
            getLabResultsWithName(finalObservationData).sort((a, b) =>
              (a.date ? a.date : '') > (b.date ? b.date : '')
                ? -1
                : (a.date ?? '') < (b.date ?? '')
                ? 1
                : 0
            )

          if (observationData.length > 0) {
            if (groupedLabPanelResultLatest.length > 0) {
              state.resultsAvailable = true
              state.searchingConditions = false
              state.noResultsAvailable = false
              state.vitalData = vitalLisDataFinal
              state.groupedResultsByName = groupedLabPanelResultLatest

              state.errorReason = undefined
              state.errorWhileSearchingProcedures = false
              dispatch(labTestDetailsSliceOPD.actions.updatedStatus(state))
            } else {
              const errorSearchDoctor: LabTestDetails = {
                searchingConditions: false,
                errorWhileSearchingProcedures: false,
                resultsAvailable: false,
                noResultsAvailable: true,
                showAppointment: false,
              }
              dispatch(
                labTestDetailsSliceOPD.actions.updatedStatus(errorSearchDoctor)
              )
            }
          } else {
            const errorSearchDoctor: LabTestDetails = {
              searchingConditions: false,
              errorWhileSearchingProcedures: false,
              resultsAvailable: false,
              noResultsAvailable: true,
              showAppointment: false,
            }
            dispatch(
              labTestDetailsSliceOPD.actions.updatedStatus(errorSearchDoctor)
            )
          }
        } else {
          const errorSearchDoctor: LabTestDetails = {
            searchingConditions: false,
            errorWhileSearchingProcedures: false,
            resultsAvailable: false,
            noResultsAvailable: true,
            showAppointment: false,
          }
          dispatch(
            labTestDetailsSliceOPD.actions.updatedStatus(errorSearchDoctor)
          )
        }
      } /* */
    } catch (error) {
      logger.error(error)
      const errorSearchDoctor: LabTestDetails = {
        searchingConditions: false,
        errorWhileSearchingProcedures: true,
        resultsAvailable: false,
        errorReason: 'Error while searching',
        showAppointment: false,
      }
      dispatch(labTestDetailsSliceOPD.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function updateEnrolment(bpCollection: BpCollection): Promise<string> {
  const enRolClient: EnrolCient = new EnrolCient()
  try {
    const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
      `/enrolment/update-bp`,
      bpCollection
    )
    return response.BPCategory
  } catch (error) {
    return ''
  }
}

async function updateAppointment(): Promise<string> {
  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const enRolClient: EnrolCient = new EnrolCient()
  //   const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
  //     `/enrolment/update-bp`,
  //     bpCollection
  //   )

  return 'test'
}

async function getServiceRequestId(patientId: string): Promise<string> {
  logger.info('Patient invitation body')
  let serviceRequestId: string = ''
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any = await fhirClient.doGetResource(
    `/ServiceRequest?code=chronic-care-enrolment&subject=${patientId}`
  )
  logger.info('Patient invitation Response')
  logger.info(response)
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Patient un decoded', respDecoded.right)
    const responseBundel: R4.IBundle = respDecoded.right
    if (responseBundel.entry) {
      const entryObj = responseBundel.entry?.[0].resource as R4.IServiceRequest
      serviceRequestId =
        entryObj && entryObj.status === 'active' ? entryObj.id ?? ' ' : ''
      return serviceRequestId
    }
    return serviceRequestId
  }

  return serviceRequestId
}

export default labTestDetailsSliceOPD.reducer
