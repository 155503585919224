import { R4 } from '@ahryman40k/ts-fhir-types'
import axios, { AxiosResponse } from 'axios'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import _ from 'lodash'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import { DietItem1 } from 'models/mealTypes'
import { DietEngineAPIClient } from 'services/dietEngineService'
import { getErrorResponse } from 'utils/apiClientHelper'
import { getAccessToken } from 'utils/authHelpers'
import { logger } from 'utils/logger'

interface LlmDietPlan {
  id: number
  row: number
  date: string
  earlyMorningTimeDuration?: string
  earlyMorningData?: R4.ICoding[]
  breakfastTimeDuration?: string
  breakfastData?: R4.ICoding[]
  midMorningTimeDuration?: string
  midMorningData?: R4.ICoding[]
  lunchTimeDuration?: string
  lunchData?: R4.ICoding[]
  eveningSnackTimeDuration?: string
  eveningSnackData?: R4.ICoding[]
  dinnerTimeDuration: string
  dinnerData?: R4.ICoding[]
  nightTimeDuration: string
  nightData?: R4.ICoding[]
}

interface Header {
  display: string
  time: string
  displayMealType: string
}

export interface DisplayTableData {
  header: Header[]
  data: any[][]
}

export interface DietPair {
  code: string
  name: string
}

export interface EachItem {
  date: string
  time: string
  itemValue: R4.ICoding[]
  itemDisplay: string
}

export function convertLlmOutputToTable(
  inputData: LlmDietPlan[]
): DisplayTableData {
  const tableHeader = getTableHeaderFromLLmData(inputData)
  console.log('-------------tableHeader----------------')
  console.log(tableHeader)
  const tableData = getTableDataFromLlmData(inputData, tableHeader)

  console.log('-------------tableData----------------')
  console.log(tableData)

  return { header: tableHeader, data: tableData }
}

function getTableDataFromLlmData(
  inputData: LlmDietPlan[],
  tableHeader: Header[]
) {
  const tableData: any = []
  inputData.forEach((inputDatum) => {
    const row: any[] = [inputDatum.date]

    const data = inputDatum as any

    tableHeader.forEach((header: Header) => {
      if (header.time !== 'time-Of-day') {
        const timeData = data[header.time]
        if (timeData) {
          const dietWithAccompanimentString: string = timeData
            .map((item: any) => item.display)
            .join(', ')
          row.push([
            {
              date: inputDatum.date,
              time: header.time,
              itemValue: timeData,
              itemDisplay: dietWithAccompanimentString,
            },
          ])
        } else {
          row.push([])
        }
      }
    })

    console.log('-------Step 5:---The row is---------')
    console.log(row)

    tableData.push(row)

    console.log('-------Step 6:---The table data is---------')
    console.log(row)
  })

  return tableData
}

function getTableHeaderFromLLmData(inputData: LlmDietPlan[]) {
  const headerMap = new Map<string, Header>()

  headerMap.set('time-Of-day', {
    display: 'Time of Day',
    time: 'time-Of-day',
    displayMealType: 'meal-type-of-day',
  })

  for (const inputDatum of inputData) {
    if (
      inputDatum.earlyMorningTimeDuration &&
      inputDatum.earlyMorningTimeDuration.length > 0 &&
      inputDatum.earlyMorningData &&
      inputDatum.earlyMorningData.length > 0
    ) {
      if (!headerMap.has('earlyMorning')) {
        headerMap.set('earlyMorning', {
          display: inputDatum.earlyMorningTimeDuration,
          time: 'earlyMorningData',
          displayMealType: 'Morning',
        })
      }
    }
    if (
      inputDatum.breakfastTimeDuration &&
      inputDatum.breakfastTimeDuration.length > 0 &&
      inputDatum.breakfastData &&
      inputDatum.breakfastData.length > 0
    ) {
      if (!headerMap.has('breakfast')) {
        headerMap.set('breakfast', {
          display: inputDatum.breakfastTimeDuration,
          time: 'breakfastData',
          displayMealType: 'Breakfast',
        })
      }
    }

    if (
      inputDatum.midMorningTimeDuration &&
      inputDatum.midMorningTimeDuration.length > 0 &&
      inputDatum.midMorningData &&
      inputDatum.midMorningData.length > 0
    ) {
      if (!headerMap.has('midMorning')) {
        headerMap.set('midMorning', {
          display: inputDatum.midMorningTimeDuration,
          time: 'midMorningData',
          displayMealType: 'Mid Morning Snack',
        })
      }
    }

    if (
      inputDatum.lunchTimeDuration &&
      inputDatum.lunchTimeDuration.length > 0 &&
      inputDatum.lunchData &&
      inputDatum.lunchData.length > 0
    ) {
      if (!headerMap.has('lunch')) {
        headerMap.set('lunch', {
          display: inputDatum.lunchTimeDuration,
          time: 'lunchData',
          displayMealType: 'Lunch',
        })
      }
    }

    if (
      inputDatum.eveningSnackTimeDuration &&
      inputDatum.eveningSnackTimeDuration.length > 0 &&
      inputDatum.eveningSnackData &&
      inputDatum.eveningSnackData.length > 0
    ) {
      if (!headerMap.has('eveningSnack')) {
        headerMap.set('eveningSnack', {
          display: inputDatum.eveningSnackTimeDuration,
          time: 'eveningSnackData',
          displayMealType: 'Evening Snack',
        })
      }
    }

    if (
      inputDatum.dinnerTimeDuration &&
      inputDatum.dinnerTimeDuration.length > 0 &&
      inputDatum.dinnerData &&
      inputDatum.dinnerData.length > 0
    ) {
      if (!headerMap.has('dinner')) {
        headerMap.set('dinner', {
          display: inputDatum.dinnerTimeDuration,
          time: 'dinnerData',
          displayMealType: 'Dinner',
        })
      }
    }

    if (
      inputDatum.nightTimeDuration &&
      inputDatum.nightTimeDuration.length > 0 &&
      inputDatum.nightData &&
      inputDatum.nightData.length > 0
    ) {
      if (!headerMap.has('night')) {
        headerMap.set('night', {
          display: inputDatum.nightTimeDuration,
          time: 'nightData',
          displayMealType: 'Night',
        })
      }
    }
  }

  const headerArray = Array.from(headerMap.values()) // or [...headerMap.values()]
  console.log('-------------headerArray----------------')
  console.log(headerArray)

  return headerArray
}

export function fillToSeven(arr: R4.ICoding[][], noOfDays: number) {
  const originalLength = arr.length
  let i = 0

  while (arr.length < noOfDays) {
    arr.push(arr[i])
    i = (i + 1) % originalLength
  }

  return arr
}

function parseDate(dateStr: string): Date {
  const [day, month, year] = dateStr.split('-').map(Number)
  return new Date(year, month - 1, day)
}

export function daysBetween(dateStr1: string, dateStr2: string): number {
  const firstDate: Date = parseDate(dateStr1)
  const secondDate: Date = parseDate(dateStr2)

  if (isNaN(firstDate.getTime()) || isNaN(secondDate.getTime())) {
    throw new Error('Invalid date format')
  }

  const timeDifference: number = secondDate.getTime() - firstDate.getTime()
  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
}

export function getRangeOfDates(startDate: string, endDate: string) {
  console.log('---The start and end date is---')
  console.log(startDate)

  console.log('---The end date is---')
  console.log(endDate)

  const start = new Date(startDate.split('-').reverse().join('-'))
  const end = new Date(endDate.split('-').reverse().join('-'))

  const dateArray = []
  const currentDate = start

  while (currentDate <= end) {
    dateArray.push(
      new Date(currentDate)
        .toISOString()
        .slice(0, 10)
        .split('-')
        .reverse()
        .join('-')
    )
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dateArray
}

function parseDate1(dateStr: string): Date {
  const [day, month, year] = dateStr.split('-').map(Number)
  return new Date(year, month - 1, day)
}

export function getDateRange(
  startDateStr: string,
  endDateStr: string
): string[] {
  const startDate: Date = parseDate1(startDateStr)
  const endDate: Date = parseDate1(endDateStr)
  const dateRange: string[] = []

  const currentDate: Date = new Date(startDate)

  while (currentDate <= endDate) {
    dateRange.push(currentDate.toISOString().slice(0, 10))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dateRange
}

function convertTimeValueToTimeCoding(timeValue: string): R4.ICoding {
  let timeCoding

  if (
    timeValue === '5:00-5:30 AM' ||
    timeValue === '5:30-6:00 AM' ||
    timeValue === '6:00-6:30 AM' ||
    timeValue === '6:30-7:00 AM' ||
    timeValue === '7:00-7:30 AM'
  ) {
    timeCoding = {
      system: 'http://hl7.org/fhir/event-timing',
      code: 'MORN.early',
      display: 'Early Morning',
    }
  } else if (
    timeValue === '7:30-8:00 AM' ||
    timeValue === '8:00-8:30 AM' ||
    timeValue === '8:30-9:00 AM' ||
    timeValue === '9:00-9:30 AM'
  ) {
    timeCoding = {
      system: 'http://hl7.org/fhir/event-timing',
      code: 'MORN',
      display: 'Breakfast',
    }
  } else if (
    timeValue === '9:30-10:00 AM' ||
    timeValue === '10:00-10:30 AM' ||
    timeValue === '10:30-11:00 AM' ||
    timeValue === '11:00-11:30 AM'
  ) {
    timeCoding = {
      system: 'http://hl7.org/fhir/event-timing',
      code: 'MORN.late',
      display: 'Mid Morning',
    }
  } else if (
    timeValue === '11:30-12:00 PM' ||
    timeValue === '12:00-12:30 PM' ||
    timeValue === '12:30-1:00 PM' ||
    timeValue === '1:00-1:30 PM' ||
    timeValue === '1:30-2:00 PM' ||
    timeValue === '2:00-2:30 PM' ||
    timeValue === '2:30-3:00 PM'
  ) {
    timeCoding = {
      system: 'http://hl7.org/fhir/event-timing',
      code: 'NOON',
      display: 'Lunch',
    }
  } else if (
    timeValue === '3:00-3:30 PM' ||
    timeValue === '3:30-4:00 PM' ||
    timeValue === '4:00-4:30 PM' ||
    timeValue === '4:30-5:00 PM' ||
    timeValue === '5:00-5:30 PM' ||
    timeValue === '5:30-6:00 PM' ||
    timeValue === '6:00-6:30 PM'
  ) {
    timeCoding = {
      system: 'http://hl7.org/fhir/event-timing',
      code: 'EVE',
      display: 'Evening Snack',
    }
  } else if (
    timeValue === '6:30-7:00 PM' ||
    timeValue === '7:00-7:30 PM' ||
    timeValue === '7:30-8:00 PM' ||
    timeValue === '8:00-8:30 PM' ||
    timeValue === '7:00-7:30 AM'
  ) {
    timeCoding = {
      system: 'http://hl7.org/fhir/event-timing',
      code: 'NIGHT',
      display: 'Dinner',
    }
  } else {
    timeCoding = {
      system: 'http://hl7.org/fhir/event-timing',
      code: 'PCV',
      display: 'Bed Time',
    }
  }

  return timeCoding
}

export function convertChartDataToCarePlanData(
  chartData: DisplayTableData,
  preselectedTimings: DietTiming[]
): any {
  const finalCarePlanDietList: any = []

  const availableTimeDurations: string[] = []

  chartData.header.slice(1).map((element) => {
    availableTimeDurations.push(element.display)
  })

  chartData.data.map((item) => {
    let dateValue
    const arrTimeWiseDiets: any = []

    if (item && item.length > 0) {
      dateValue = item[0]
      dateValue = dateValue.split('-').reverse().join('-')
    }

    let requiredTimeCoding: any
    let requiredTimePeriod: string = ''
    let requiredDietItems: R4.ICoding[] = []

    item.slice(1).map((element, index) => {
      requiredTimePeriod = availableTimeDurations[index]
      requiredTimeCoding = convertTimeValueToTimeCoding(requiredTimePeriod)
      requiredTimePeriod = requiredTimePeriod.replace(' ', '')

      if (element[0]) requiredDietItems = element[0].itemValue

      arrTimeWiseDiets.push({
        timeCoding: requiredTimeCoding,
        timePeriod: requiredTimePeriod,
        dietItems: requiredDietItems,
      })
    })

    finalCarePlanDietList.push({
      dietDate: dateValue,
      timeWiseDiets: arrTimeWiseDiets,
    })
  })

  return finalCarePlanDietList
}

export function getPreselectedTimeForTheUser(
  peslectedTimings: DietTiming[],
  timeOfTheDay: string
): DietTiming | undefined {
  return peslectedTimings.find((item) => item.timeOfTheDay === timeOfTheDay)
}

export function regexExpressionFirst(result: string): R4.ICoding[] {
  const arrWithSystem: R4.ICoding[] = []

  const codeMatch = result.match(/Diet code:\s*([A-Z0-9.]+)/)
  const nameMatch = result.match(/Diet name:\s*([^,]+)/)

  const arr: DietPair[] = []

  if (codeMatch && nameMatch) {
    arr.push({
      code: codeMatch[1],
      name: nameMatch[1],
    })
  }

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionToCheckStringContainsDietCodeARWithHifen(
  result: string
): R4.ICoding[] {
  const regex = /([A-Z0-9.]+) - (.+)/g

  const arr = []
  let match

  // Loop through all matches
  while ((match = regex.exec(result)) !== null) {
    const code = match[1].trim()
    const name = match[2].trim()
    arr.push({ code, name })
  }

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionToCheckStringContainsDietCodeAR(
  result: string
): boolean {
  const regex = /AR\d+/g
  const formattedAnswer = regex.test(result)
  return formattedAnswer
}

export function regexExpressionToCheckDietStringPresentAfterDietCode(
  result: string
): boolean {
  const arr: string[] = result.split(', ').map((item) => item.trim())
  const hasIncompleteElement = arr.some((item) =>
    /^[A-Za-z]+\d+(\.\d+)+$/.test(item)
  )
  return hasIncompleteElement
}

export function regexExpressionForStringMeetsCriteriaOf(
  result: string
): R4.ICoding[] {
  const regex = /Diet code ([\w\.]+) with diet name ([\w\s]+)/g
  const arr: DietPair[] = []
  let match: RegExpExecArray | null

  while ((match = regex.exec(result)) !== null) {
    const dietCode: string = match[1].trim()
    const dietName: string = match[2].trim()
    arr.push({ code: dietCode, name: dietName })
  }

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionForStringHavingDietCodeAndWith(
  result: string
): R4.ICoding[] {
  let arr = []
  const regex = /Diet code (\S+) with diet name ([A-Za-z\s]+)/g

  let match

  // Loop through all matches
  while ((match = regex.exec(result)) !== null) {
    const code = match[1]
    const name = match[2].trim()
    arr.push({ code, name })
  }

  arr = arr.map((item) => ({
    ...item,
    name: item.name.split(',')[0].trim(),
  }))

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionForStringWithHereAre(
  result: string
): R4.ICoding[] {
  let arr: DietPair[] = result
    .split('\n')
    .filter((item: string) => item.includes('Diet Code:'))
    .map((item: any) => {
      const codeMatch = item.match(/Diet Code: ([^,]+)/)
      const nameMatch = item.match(/Diet Name: ([^,]+)/)

      return {
        code: codeMatch ? codeMatch[1] : '',
        name: nameMatch ? nameMatch[1] : '',
      }
    })

  arr = arr.map((item) => ({
    ...item,
    name: item.name.split(',')[0].trim(),
  }))

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionForStringWithAreAsFollows(
  result: string
): R4.ICoding[] {
  let arr: DietPair[] =
    result
      .match(/Diet Code: ([^,]+), Diet Name: ([^,]+)/g)
      ?.map((item: any) => {
        const [, code, name] =
          item.match(/Diet Code: ([^,]+), Diet Name: ([^,]+)/) || []
        return { code, name }
      }) || []

  arr = arr.map((item) => ({
    ...item,
    name: item.name.split(',')[0].trim(),
  }))

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item: any) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionForStringThatContainsARWithNewLine(
  result: string
): R4.ICoding[] {
  let arr: DietPair[] = result.split('\n').map((item: any) => {
    const [code, rest] = item.split(' - ')
    const name = rest.split(' - ')[0].trim()
    return { code, name }
  })

  arr = arr.map((item) => ({
    ...item,
    name: item.name.split(',')[0].trim(),
  }))

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionForStringThatContainsARWithCommaSeparated(
  result: string
): R4.ICoding[] {
  let arr: DietPair[] = result.split(',').map((item: any) => {
    const [code, name] = item.split(' - ').map((str: any) => str.trim())
    return { code, name }
  })

  arr = arr.map((item) => ({
    ...item,
    name: item.name.split(',')[0].trim(),
  }))

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionForStringThatContainsARWithoutComma(
  result: string
): R4.ICoding[] {
  const regex = /(\S+) - ([^,]+)/g

  let arr = []
  let match

  while ((match = regex.exec(result)) !== null) {
    arr.push({ code: match[1], name: match[2] })
  }

  arr = arr.map((item) => ({
    ...item,
    name: item.name.split(',')[0].trim(),
  }))

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionForStringThatContainsDietCodeDietName(
  result: string
): R4.ICoding[] {
  const regex = /Diet Code: ([\w\.]+), Diet Name: ([\w\s]+)/g
  let arr: DietPair[] = []
  let match: RegExpExecArray | null

  while ((match = regex.exec(result)) !== null) {
    const dietCode: string = match[1]
    const dietName: string = match[2].trim()
    arr.push({ code: dietCode, name: dietName })
  }

  arr = arr.map((item) => ({
    ...item,
    name: item.name.split(',')[0].trim(),
  }))

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionDietCodeWithDietName(
  result: string
): R4.ICoding[] {
  const regex = /Diet code:\s*(\S+),\s*Diet name:\s*([^,]+)/
  const match = result.match(regex)
  if (match) {
    let arr = [{ code: match[1], name: match[2] }]

    arr = arr.map((item) => ({
      ...item,
      name: item.name.split(',')[0].trim(),
    }))

    const arrWithoutRepetition: DietPair[] = arr.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (k: any) => k.code === value.code && k.name === value.name
        )
    )

    const arrWithSystem: R4.ICoding[] = []

    arrWithoutRepetition.map((item) => {
      arrWithSystem.push({
        system:
          'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
        code: item.code,
        display: item.name,
      })
    })

    return arrWithSystem
  }
  return []
}

export function regexExpressionForAnswerWithFlowerBracket(
  result: string
): R4.ICoding[] {
  let arr =
    result.match(/\('([^']*)', '([^']*)', '[^']*'\)/g)?.map((item) => {
      const parts = item.match(/\('([^']*)', '([^']*)'/)
      return {
        code: parts ? parts[1] : '',
        name: parts ? parts[2] : '',
      }
    }) || []

  arr = arr.map((item) => ({
    ...item,
    name: item.name.split(',')[0].trim(),
  }))

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function regexExpressionForDifferentDietCodeString(result: string) {
  let arr: DietPair[] = []

  const regex = /diet_code:\s*([\w.]+),\s*diet_name:\s*([^,]+)/g
  let match: RegExpExecArray | null

  while ((match = regex.exec(result)) !== null) {
    arr.push({ code: match[1], name: match[2] })
  }

  arr = arr.map((item) => ({
    ...item,
    name: item.name.split(',')[0].trim(),
  }))

  const arrWithoutRepetition: DietPair[] = arr.filter(
    (value, index, self) =>
      index ===
      self.findIndex((k: any) => k.code === value.code && k.name === value.name)
  )

  const arrWithSystem: R4.ICoding[] = []

  arrWithoutRepetition.map((item) => {
    arrWithSystem.push({
      system:
        'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
      code: item.code,
      display: item.name,
    })
  })

  return arrWithSystem
}

export function getResetDietTypes(data: any) {
  const output: any = []
  data.map((item: any) => {
    output.push({
      id: item.id,
      checked: false,
      data: item.data,
    })
  })
  return output
}

export async function doPostQuestionForDietEngineLlm(
  question: string
): Promise<any> {
  const apiClient = new DietEngineAPIClient()

  try {
    const resp = await apiClient.doPost('/get-diets', question)

    return resp
  } catch (error) {
    logger.error(error)
    throw error
  }
}
