import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { GroupPatient } from 'models/groupPatient'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PatientTile } from 'views/components/patients/pateintTile'
import { WelloFormItemLabel } from 'wello-web-components'
import { ValueSetSelectSingle } from '../common/SingleSelectFromValueSetUrl'
import { ValueSetSelectSingleForOther } from '../common/singleSelectValueFromValueSetUrlOther'
import { FamilyHistoryMasterSelector } from '../ipdconusltation/subjective/familyMemberMasterSelector'

interface Props {
  id: string
  open: boolean
  onClose: () => void
  onChoose: (
    patientId: string,
    relationShip: R4.ICoding,
    checked: boolean
  ) => void
  patients?: GroupPatient[]
}
export const ChoosePatient: React.FC<Props> = ({
  id,
  open,
  onClose,
  onChoose,
  patients,
}) => {
  const { t } = useTranslation()

  const [selectedPatient, setSelectedPatient] = useState<string>()
  const [selected, setSelected] = useState<boolean>(false)
  const [selectedRelationShip, setSelectedRelationShip] = useState<R4.ICoding>()
  const [isActiveCondition, setIsActiveCondtion] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)

  const handleChange = (event: any) => {
    setChecked(event.target.checked)
  }

  function resetForm() {
    setSelected(false)
    setSelectedRelationShip(undefined)
    setSelectedPatient(undefined)
    setIsActiveCondtion(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetForm()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Matched Patients</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
                resetForm()
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          overflow: 'auto',
        }}
      >
        {patients && patients.length > 0 && (
          <Box width='100%' display='flex' flexDirection='column'>
            <Box>
              <WelloFormItemLabel
                title='Do you know any of these person(s) ? If Yes, Please Select'
                displayDoneLabel={false}
              />
            </Box>
            {patients.map((patientData: GroupPatient, index: number) => (
              <Box
                display='flex'
                flexDirection='column'
                key={patientData.patient.id}
                py={1}
              >
                <Box width='2%'>
                  {selectedPatient &&
                    selectedPatient === patientData.patient.id && (
                      <img
                        src={`${process.env.PUBLIC_URL}/dot_icon.png`}
                        alt='icon'
                        height='10'
                      />
                    )}
                </Box>
                <Box
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Box style={{ cursor: 'pointer' }} width='50%'>
                    <PatientTile
                      patient={patientData.patient}
                      isSelected={patientData.isPrimary}
                      onSelected={(selectedOptions: string) => {
                        if (selectedOptions === patientData.patient.id) {
                          setSelected(true)
                        } else {
                          setSelected(false)
                        }
                        setSelectedPatient(selectedOptions)
                        setSelectedRelationShip(undefined)
                        setChecked(false)
                      }}
                    />
                  </Box>
                  {selectedPatient &&
                    selectedPatient === patientData.patient.id && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        width='30%'
                        px={1}
                      >
                        {/* <Box display='flex' flexDirection='row' px={1}>
                          <WelloFormItemLabel title='Select Relationship' />{' '}
                        </Box> */}
                        <Box>
                          <ValueSetSelectSingleForOther
                            key='wellopathy-relationship-vs_medicines'
                            title='Select Relationship'
                            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-relationship-vs'
                            id='opd_relations'
                            fuzzySearch={true}
                            disabled={false}
                            onOptionSelected={(
                              conditionsData: R4.ICoding | undefined
                            ) => {
                              if (conditionsData) {
                                setSelectedRelationShip(conditionsData)
                              }
                            }}
                            helperText={undefined}
                          />
                        </Box>
                      </Box>
                    )}

                  {selectedPatient &&
                    selectedPatient === patientData.patient.id &&
                    patientData.isPrimary === false && (
                      <Box display='flex' flexDirection='row' py={4}>
                        <Box paddingLeft={1}>
                          <Checkbox
                            edge='start'
                            checked={checked}
                            onChange={handleChange}
                            tabIndex={-1}
                            size='small'
                            disableRipple
                            color='primary'
                            inputProps={{ 'aria-labelledby': 'consent' }}
                            id='billing'
                          />
                        </Box>
                        <Box py={1.3}>
                          <Typography variant='subtitle2' color='initial'>
                            Mark as primary
                          </Typography>
                        </Box>
                      </Box>
                    )}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (selectedPatient && selectedRelationShip) {
              onChoose(selectedPatient, selectedRelationShip, checked)
            }
          }}
          variant='outlined'
          disableElevation
          disabled={
            selectedPatient === undefined || selectedRelationShip === undefined
          }
        >
          Yes
        </Button>

        <Button
          onClick={() => {
            onClose()
            resetForm()
          }}
          variant='outlined'
          disableElevation
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}
