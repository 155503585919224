import { R4 } from '@ahryman40k/ts-fhir-types'
import { IPatient } from '@ahryman40k/ts-fhir-types/lib/R4'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Step,
  StepButton,
  StepConnector,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core'
import { CloseOutlined, ExpandMore } from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Autocomplete } from '@material-ui/lab'
import { kDialogueBackground, kPrimaryMain } from 'configs/styles/muiThemes'
import { LLMDietPlanOutPut } from 'models/dietPlan/LLMDietPLanOutput'
import {
  AccompanimentType,
  BatchCookingTypes,
  CourseTypes,
  CuisineTypes,
  DietItem1,
  EaseOfPreparationTypes,
  EasyRestaurantRecipeTypes,
  MealTypes,
} from 'models/mealTypes'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { addDietDetails } from 'redux/consultation/dietPlan/addDietPlan/addDietPlanSlice'
import { RootState } from 'redux/rootReducer'
import {
  convertChartDataToCarePlanData,
  convertLlmOutputToTable,
  DietPair,
  DisplayTableData,
  doPostQuestionForDietEngineLlm,
  fillToSeven,
  getRangeOfDates,
  getResetDietTypes,
  regexExpressionFirst,
  regexExpressionForDifferentDietCodeString,
  regexExpressionForStringHavingDietCodeAndWith,
  regexExpressionForStringMeetsCriteriaOf,
  regexExpressionForStringThatContainsARWithCommaSeparated,
  regexExpressionForStringThatContainsARWithNewLine,
  regexExpressionForStringWithHereAre,
  regexExpressionToCheckStringContainsDietCodeAR,
  regexExpressionToCheckStringContainsDietCodeARWithHifen,
} from 'utils/careplan_utils/llmDietPlanHelper'
import { getOnlyDate, sleep } from 'utils/dateUtil'
import {
  createBundleTransactionForNutritionOrders,
  getNutritionOrdersForDietBasedOnCodes,
  postBundleTransactionForNutritionOrders,
} from 'utils/fhirResoureHelpers/dietPlanHelpers'
import { WelloLoadingIndicator } from 'wello-web-components'
import { FHIRApiClient } from 'services/fhirApiServices'
import { OutComeClient } from 'services/outComeClient'
import axios from 'axios'
import { getAccessToken } from 'utils/authHelpers'
import { getMainDish, getSideDish } from 'utils/careplan_utils/llmQueries'
import { DietChartComponent } from './dietChart'
import DragAndDrop from './dietDragAndDrop'

interface Props {
  open: boolean
  appointmentStartDate?: string
  encounterID: string
  patient: IPatient
  appointmentReference?: string
  onClose: () => void
  onDietAddedForCarePlan: () => void
}

const morningDietTimings: string[] = [
  '5:00-5:30 AM',
  '5:30-6:00 AM',
  '6:00-6:30 AM',
  '6:30-7:00 AM',
  '7:00-7:30 AM',
]
const breakfastDietTimings: string[] = [
  '7:30-8:00 AM',
  '8:00-8:30 AM',
  '8:30-9:00 AM',
  '9:00-9:30 AM',
]
const midMorningDietTimings: string[] = [
  '9:30-10:00 AM',
  '10:00-10:30 AM',
  '10:30-11:00 AM',
  '11:00-11:30 AM',
]
const lunchDietTimings: string[] = [
  '11:30-12:00 PM',
  '12:00-12:30 PM',
  '12:30-1:00 PM',
  '1:00-1:30 PM',
  '1:30-2:00 PM',
  '2:00-2:30 PM',
  '2:30-3:00 PM',
]
const eveningDietTimings: string[] = [
  '3:00-3:30 PM',
  '3:30-4:00 PM',
  '4:00-4:30 PM',
  '4:30-5:00 PM',
  '5:00-5:30 PM',
  '5:30-6:00 PM',
  '6:00-6:30 PM',
]
const dinnerDietTimings: string[] = [
  '6:30-7:00 PM',
  '7:00-7:30 PM',
  '7:30-8:00 PM',
  '8:00-8:30 PM',
  '8:30-9:00 PM',
]
const nightDietTimings: string[] = ['9:00-9:30 PM', '9:30-10:00 PM']

// const availableCuisines: string[] = [
//   'Andhra',
//   'Continental',
//   'General',
//   'Indian (North Indian)',
//   'Indian (Punjabi)',
//   'International',
//   'Karnataka',
//   'Mangalorean',
//   'Middle Eastern',
//   'North Indian',
//   'South Asian',
//   'South Indian',
//   'Tamil Nadu',
//   'Various',
// ]

const availableCuisines: string[] = [
  'Andhra',
  'Asian',
  'Continental',
  'General',
  'Gujarati',
  'Indian',
  'International',
  'Karnataka',
  'Mangalorean',
  'Middle Eastern',
  'North Indian',
  'South Asian',
  'South Indian',
  'Tamil Nadu',
]

// const availableCourses: string[] = [
//   'Appetizer',
//   'Appetizer/Side Dish',
//   'Beverage',
//   'Beverage/Side dish',
//   'Bread',
//   'Breakfast',
//   'Dessert',
//   'Dessert/Salad',
//   'Drink',
//   'Light Meal',
//   'Main course',
//   'Main Dish',
//   'Salad',
//   'Side Dish',
//   'Snack',
//   'Soup',
// ]

const availableCourses: string[] = [
  'Appetizer',
  'Beverage',
  'Bread',
  'Dessert',
  'Light Meal',
  'Main Course',
  'Main Dish',
  'Salad',
  'Side Dish',
  'Snack',
  'Soup',
]

const availableEaseOfPreparations: string[] = ['Easy', 'Moderate']

const availableBatchCookings: string[] = ['Yes', 'No']

const availableEasyRestaurantRecipes: string[] = ['Yes', 'No']

const morningDietTypes: MealTypes[] = morningDietTimings.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const breakfastDietTypes: MealTypes[] = breakfastDietTimings.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const midMorningDietTypes: MealTypes[] = midMorningDietTimings.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const lunchDietTypes: MealTypes[] = lunchDietTimings.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const eveningDietTypes: MealTypes[] = eveningDietTimings.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const dinnerDietTypes: MealTypes[] = dinnerDietTimings.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const nightDrinkDietTypes: MealTypes[] = nightDietTimings.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const cuisineTypes: CuisineTypes[] = availableCuisines.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const courseTypes: CourseTypes[] = availableCourses.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const easeOfPreparationTypes: MealTypes[] = availableEaseOfPreparations.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const batchCookingTypes: MealTypes[] = availableBatchCookings.map(
  (item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  })
)

const easyRestaurantRecipeTypes: MealTypes[] =
  availableEasyRestaurantRecipes.map((item: string, index: number) => ({
    id: index + 1,
    checked: false,
    data: item,
  }))

const steps = ['Meal Planning', 'Meal Scheduling', 'Diet Chart']

export const DietLlmManagement: React.FC<Props> = ({
  open,
  appointmentStartDate,
  onClose,
  onDietAddedForCarePlan,
  encounterID,
  appointmentReference,
  patient,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])

  const CustomConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector)

  const dietTimeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )

  const [activeStep, setActiveStep] = React.useState<number>(0)
  const [completed, setCompleted] = React.useState([])

  const [openCuisineAddDialogue, setOpenCuisineAddDialogue] =
    useState<boolean>(false)
  const [openMealAndCourseAddDialogue, setOpenMealAndCourseAddDialogue] =
    useState<boolean>(false)
  const [openCategoriesAddDialogue, setOpenCategoriesAddDialogue] =
    useState<boolean>(false)

  const [displayCuisineCheckbox, setDisplayCuisineCheckbox] =
    useState<boolean>(false)
  const [displayCourseCheckbox, setDisplayCourseCheckbox] =
    useState<boolean>(false)
  const [
    displayPreparationCookingEasyRecipeCheckbox,
    setDisplayPreparationCookingEasyRecipeCheckbox,
  ] = useState<boolean>(false)

  const [accompCheckbox, setAccompCheckbox] = useState<boolean>(false)

  const listOfCourseSelected: string[] = []
  const listOfCuisineSelected: string[] = []
  const listOfEaseOfPreparationSelected: string[] = []
  const listOfBatchCookingSelected: string[] = []
  const listOfEasyRestaurantRecipeSelected: string[] = []

  let earlyMorningDietValues: R4.ICoding[] = []
  let breakfastDietValues: R4.ICoding[] = []
  let morningSnackDietValues: R4.ICoding[] = []
  let lunchDietValues: R4.ICoding[] = []
  let eveningSnackDietValues: R4.ICoding[] = []
  let dinnerDietValues: R4.ICoding[] = []
  let nightDrinkDietValues: R4.ICoding[] = []

  const paperColor = useTheme().palette.background.paper

  const [earlyMorningChecked, setEarlyMorningChecked] = useState<boolean>(false)
  const [earlyMorningTwoChecked, setEarlyMorningTwoChecked] =
    useState<boolean>(false)
  const [earlyMorningThreeChecked, setEarlyMorningThreeChecked] =
    useState<boolean>(false)
  const [earlyMorningFourChecked, setEarlyMorningFourChecked] =
    useState<boolean>(false)
  const [earlyMorningFiveChecked, setEarlyMorningFiveChecked] =
    useState<boolean>(false)

  const [breakfastChecked, setBreakfastChecked] = useState<boolean>(false)
  const [breakfastTwoChecked, setBreakfastTwoChecked] = useState<boolean>(false)
  const [breakfastThreeChecked, setBreakfastThreeChecked] =
    useState<boolean>(false)
  const [breakfastFourChecked, setBreakfastFourChecked] =
    useState<boolean>(false)

  const [morningSnackChecked, setMorningSnackChecked] = useState<boolean>(false)
  const [morningSnackTwoChecked, setMorningSnackTwoChecked] =
    useState<boolean>(false)
  const [morningSnackThreeChecked, setMorningSnackThreeChecked] =
    useState<boolean>(false)
  const [morningSnackFourChecked, setMorningSnackFourChecked] =
    useState<boolean>(false)

  const [lunchChecked, setLunchChecked] = useState<boolean>(false)
  const [lunchTwoChecked, setLunchTwoChecked] = useState<boolean>(false)
  const [lunchThreeChecked, setLunchThreeChecked] = useState<boolean>(false)
  const [lunchFourChecked, setLunchFourChecked] = useState<boolean>(false)
  const [lunchFiveChecked, setLunchFiveChecked] = useState<boolean>(false)
  const [lunchSixChecked, setLunchSixChecked] = useState<boolean>(false)
  const [lunchSevenChecked, setLunchSevenChecked] = useState<boolean>(false)

  const [eveningSnackChecked, setEveningSnackChecked] = useState<boolean>(false)
  const [eveningSnackTwoChecked, setEveningSnackTwoChecked] =
    useState<boolean>(false)
  const [eveningSnackThreeChecked, setEveningSnackThreeChecked] =
    useState<boolean>(false)
  const [eveningSnackFourChecked, setEveningSnackFourChecked] =
    useState<boolean>(false)
  const [eveningSnackFiveChecked, setEveningSnackFiveChecked] =
    useState<boolean>(false)
  const [eveningSnackSixChecked, setEveningSnackSixChecked] =
    useState<boolean>(false)
  const [eveningSnackSevenChecked, setEveningSnackSevenChecked] =
    useState<boolean>(false)

  const [dinnerChecked, setDinnerChecked] = useState<boolean>(false)
  const [dinnerTwoChecked, setDinnerTwoChecked] = useState<boolean>(false)
  const [dinnerThreeChecked, setDinnerThreeChecked] = useState<boolean>(false)
  const [dinnerFourChecked, setDinnerFourChecked] = useState<boolean>(false)
  const [dinnerFiveChecked, setDinnerFiveChecked] = useState<boolean>(false)

  const [nightDrinkChecked, setNightDrinkChecked] = useState<boolean>(false)
  const [nightDrinkTwoChecked, setNightDrinkTwoChecked] =
    useState<boolean>(false)

  const [earlyMorningTime, setEarlyMorningTime] = useState<string>('')
  const [breakfastTime, setBreakfastTime] = useState<string>('')
  const [midMorningTime, setMidMorningTime] = useState<string>('')
  const [lunchTime, setLunchTime] = useState<string>('')
  const [eveningTime, setEveningTime] = useState<string>('')
  const [dinnerTime, setDinnerTime] = useState<string>('')
  const [nightDrinkTime, setNightDrinkTime] = useState<string>('')

  const [columnsCount, setColumnsCount] = useState<number>(0)

  const [accompanimentDiet, setAccompanimentDiet] = useState<string>('')
  const [accompanimentDietIndex, setAccompanimentDietIndex] =
    useState<number>(0)
  const [accompanimentDietMealType, setAccompanimentDietMealType] =
    useState<string>('')

  const [additionalDiet, setAdditionalDiet] = useState<string>('')
  const [additionalDietMealType, setAdditionalDietMealType] =
    useState<string>('')

  const [showEdit, setShowEdit] = useState<boolean>(false)
  const [editDietIndex, setEditDietIndex] = useState<number>(0)

  const [editMealType, setEditMealType] = useState<string>('')

  const [defaultValuesForEditDiet, setDefaultValuesForEditDiet] = useState<
    R4.ICoding[]
  >([])

  const [noResultsForAccompaniment, setNoResultsForAccompaniment] =
    useState(false)
  const [noResultsForAdditionalDiet, setNoResultsForAdditionalDiet] =
    useState(false)

  const [dietDragDropTableData, setDietDragDropTableData] =
    useState<DisplayTableData>()

  const handleStep = (step: any) => () => {
    setActiveStep(step)
  }

  const onSearchClicked = async () => {
    setFetchAllTableDetails(true)
    setDisplayTable(false)
    setActiveStep(0)
    setDietDragDropTableData(undefined)
    setFinalChartData(undefined)
    setEarlyMorningChecked(false)
    setBreakfastChecked(false)
    setMorningSnackChecked(false)
    setLunchChecked(false)
    setEveningSnackChecked(false)
    setDinnerChecked(false)
    setNightDrinkChecked(false)

    courses.map((item, courseIndex) => {
      if (item.checked) listOfCourseSelected.push(item.data)
    })

    cuisines.map((item, cuisineIndex) => {
      if (item.checked) listOfCuisineSelected.push(item.data)
    })

    easeOfPreparations.map((item, easeOfPreparationIndex) => {
      if (item.checked) listOfEaseOfPreparationSelected.push(item.data)
    })

    batchCookings.map((item, batchCookingIndex) => {
      if (item.checked) listOfBatchCookingSelected.push(item.data)
    })

    easyRestaurantRecipes.map((item, easyRestaurantRecipeIndex) => {
      if (item.checked) listOfEasyRestaurantRecipeSelected.push(item.data)
    })

    let countOfColumns: number = 0

    if (morningDiets[0].checked) {
      countOfColumns += 1
      setEarlyMorningChecked(true)

      earlyMorningDietValues = await getAnswerForEachMealType(
        'morning',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = earlyMorningDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEarlyMorningDietItemsWithCheck(arr2)
      setEarlyMorningTime(morningDiets[0].data)
    }

    if (morningDiets[1].checked) {
      countOfColumns += 1
      setEarlyMorningTwoChecked(true)

      earlyMorningDietValues = await getAnswerForEachMealType(
        'morning',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = earlyMorningDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEarlyMorningDietItemsWithCheck(arr2)
      setEarlyMorningTime(morningDiets[1].data)
    }

    if (morningDiets[2].checked) {
      countOfColumns += 1
      setEarlyMorningThreeChecked(true)
      earlyMorningDietValues = await getAnswerForEachMealType(
        'morning',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = earlyMorningDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEarlyMorningDietItemsWithCheck(arr2)
      setEarlyMorningTime(morningDiets[2].data)
    }

    if (morningDiets[3].checked) {
      countOfColumns += 1
      setEarlyMorningFourChecked(true)

      earlyMorningDietValues = await getAnswerForEachMealType(
        'morning',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = earlyMorningDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEarlyMorningDietItemsWithCheck(arr2)
      setEarlyMorningTime(morningDiets[3].data)
    }

    if (morningDiets[4].checked) {
      countOfColumns += 1
      setEarlyMorningFiveChecked(true)

      earlyMorningDietValues = await getAnswerForEachMealType(
        'morning',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = earlyMorningDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEarlyMorningDietItemsWithCheck(arr2)
      setEarlyMorningTime(morningDiets[4].data)
    }

    if (breakfastDiets[0].checked) {
      countOfColumns += 1
      setBreakfastChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'breakfast or morning breakfast',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        breakfastDietValues = await getAnswerForEachMealType(
          'breakfast or morning breakfast',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = breakfastDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setBreakfastDietItemsWithCheck(arr2)
      setBreakfastTime(breakfastDiets[0].data)
    }

    if (breakfastDiets[1].checked) {
      countOfColumns += 1
      setBreakfastTwoChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'breakfast or morning breakfast',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        breakfastDietValues = await getAnswerForEachMealType(
          'breakfast or morning breakfast',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = breakfastDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setBreakfastDietItemsWithCheck(arr2)
      setBreakfastTime(breakfastDiets[1].data)
    }

    if (breakfastDiets[2].checked) {
      countOfColumns += 1
      setBreakfastThreeChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'breakfast or morning breakfast',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        breakfastDietValues = await getAnswerForEachMealType(
          'breakfast or morning breakfast',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = breakfastDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setBreakfastDietItemsWithCheck(arr2)
      setBreakfastTime(breakfastDiets[2].data)
    }

    if (breakfastDiets[3].checked) {
      countOfColumns += 1
      setBreakfastFourChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'breakfast or morning breakfast',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        breakfastDietValues = await getAnswerForEachMealType(
          'breakfast or morning breakfast',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = breakfastDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setBreakfastDietItemsWithCheck(arr2)
      setBreakfastTime(breakfastDiets[3].data)
    }

    if (midMorningDiets[0].checked) {
      countOfColumns += 1
      setMorningSnackChecked(true)

      morningSnackDietValues = await getAnswerForEachMealType(
        'morning snack or mid day snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = morningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setMidMorningDietItemsWithCheck(arr2)
      setMidMorningTime(midMorningDiets[0].data)
    }

    if (midMorningDiets[1].checked) {
      countOfColumns += 1
      setMorningSnackTwoChecked(true)
      morningSnackDietValues = await getAnswerForEachMealType(
        'morning snack or mid day snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = morningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setMidMorningDietItemsWithCheck(arr2)
      setMidMorningTime(midMorningDiets[1].data)
    }

    if (midMorningDiets[2].checked) {
      countOfColumns += 1
      setMorningSnackThreeChecked(true)
      morningSnackDietValues = await getAnswerForEachMealType(
        'morning snack or mid day snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = morningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setMidMorningDietItemsWithCheck(arr2)
      setMidMorningTime(midMorningDiets[2].data)
    }

    if (midMorningDiets[3].checked) {
      countOfColumns += 1
      setMorningSnackFourChecked(true)

      morningSnackDietValues = await getAnswerForEachMealType(
        'morning snack or mid day snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = morningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setMidMorningDietItemsWithCheck(arr2)
      setMidMorningTime(midMorningDiets[3].data)
    }

    if (lunchDiets[0].checked) {
      countOfColumns += 1
      setLunchChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        lunchDietValues = await getAnswerForEachMealType(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = lunchDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setLunchDietItemsWithCheck(arr2)
      setLunchTime(lunchDiets[0].data)
    }

    if (lunchDiets[1].checked) {
      countOfColumns += 1
      setLunchTwoChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        lunchDietValues = await getAnswerForEachMealType(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = lunchDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setLunchDietItemsWithCheck(arr2)
      setLunchTime(lunchDiets[1].data)
    }

    if (lunchDiets[2].checked) {
      countOfColumns += 1
      setLunchThreeChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        lunchDietValues = await getAnswerForEachMealType(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = lunchDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setLunchDietItemsWithCheck(arr2)
      setLunchTime(lunchDiets[2].data)
    }

    if (lunchDiets[3].checked) {
      countOfColumns += 1
      setLunchFourChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        lunchDietValues = await getAnswerForEachMealType(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = lunchDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setLunchDietItemsWithCheck(arr2)
      setLunchTime(lunchDiets[3].data)
    }

    if (lunchDiets[4].checked) {
      countOfColumns += 1
      setLunchFiveChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        lunchDietValues = await getAnswerForEachMealType(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = lunchDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setLunchDietItemsWithCheck(arr2)
      setLunchTime(lunchDiets[4].data)
    }

    if (lunchDiets[5].checked) {
      countOfColumns += 1
      setLunchSixChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        lunchDietValues = await getAnswerForEachMealType(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = lunchDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setLunchDietItemsWithCheck(arr2)
      setLunchTime(lunchDiets[5].data)
    }

    if (lunchDiets[6].checked) {
      countOfColumns += 1
      setLunchSevenChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        lunchDietValues = await getAnswerForEachMealType(
          'lunch or afternoon',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = lunchDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setLunchDietItemsWithCheck(arr2)
      setLunchTime(lunchDiets[6].data)
    }

    if (eveningDiets[0].checked) {
      countOfColumns += 1
      setEveningSnackChecked(true)

      eveningSnackDietValues = await getAnswerForEachMealType(
        'evening snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = eveningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEveningDietItemsWithCheck(arr2)
      setEveningTime(eveningDiets[0].data)
    }

    if (eveningDiets[1].checked) {
      countOfColumns += 1
      setEveningSnackTwoChecked(true)

      eveningSnackDietValues = await getAnswerForEachMealType(
        'evening snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = eveningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEveningDietItemsWithCheck(arr2)
      setEveningTime(eveningDiets[1].data)
    }

    if (eveningDiets[2].checked) {
      countOfColumns += 1
      setEveningSnackThreeChecked(true)

      eveningSnackDietValues = await getAnswerForEachMealType(
        'evening snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = eveningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEveningDietItemsWithCheck(arr2)
      setEveningTime(eveningDiets[2].data)
    }

    if (eveningDiets[3].checked) {
      countOfColumns += 1
      setEveningSnackFourChecked(true)

      eveningSnackDietValues = await getAnswerForEachMealType(
        'evening snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = eveningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEveningDietItemsWithCheck(arr2)
      setEveningTime(eveningDiets[3].data)
    }

    if (eveningDiets[4].checked) {
      countOfColumns += 1
      setEveningSnackFiveChecked(true)

      eveningSnackDietValues = await getAnswerForEachMealType(
        'evening snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = eveningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEveningDietItemsWithCheck(arr2)
      setEveningTime(eveningDiets[4].data)
    }

    if (eveningDiets[5].checked) {
      countOfColumns += 1
      setEveningSnackSixChecked(true)

      eveningSnackDietValues = await getAnswerForEachMealType(
        'evening snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = eveningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEveningDietItemsWithCheck(arr2)
      setEveningTime(eveningDiets[5].data)
    }

    if (eveningDiets[6].checked) {
      countOfColumns += 1
      setEveningSnackSevenChecked(true)

      eveningSnackDietValues = await getAnswerForEachMealType(
        'evening snack',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )

      const arr2 = eveningSnackDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))

      setEveningDietItemsWithCheck(arr2)
      setEveningTime(eveningDiets[6].data)
    }

    if (dinnerDiets[0].checked) {
      countOfColumns += 1
      setDinnerChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'dinner',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        dinnerDietValues = await getAnswerForEachMealType(
          'dinner',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = dinnerDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setDinnerDietItemsWithCheck(arr2)
      setDinnerTime(dinnerDiets[0].data)
    }

    if (dinnerDiets[1].checked) {
      countOfColumns += 1
      setDinnerTwoChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'dinner',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        dinnerDietValues = await getAnswerForEachMealType(
          'dinner',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = dinnerDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setDinnerDietItemsWithCheck(arr2)
      setDinnerTime(dinnerDiets[1].data)
    }

    if (dinnerDiets[2].checked) {
      countOfColumns += 1
      setDinnerThreeChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'dinner',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        dinnerDietValues = await getAnswerForEachMealType(
          'dinner',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = dinnerDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setDinnerDietItemsWithCheck(arr2)
      setDinnerTime(dinnerDiets[2].data)
    }

    if (dinnerDiets[3].checked) {
      countOfColumns += 1
      setDinnerFourChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'dinner',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        dinnerDietValues = await getAnswerForEachMealType(
          'dinner',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = dinnerDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setDinnerDietItemsWithCheck(arr2)
      setDinnerTime(dinnerDiets[3].data)
    }

    if (dinnerDiets[4].checked) {
      countOfColumns += 1
      setDinnerFiveChecked(true)

      let arr2
      if (
        listOfCourseSelected.includes('Main Dish') &&
        listOfCourseSelected.includes('Side Dish')
      ) {
        arr2 = await getAnswerForMealTypeLunch(
          'dinner',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )
      } else {
        dinnerDietValues = await getAnswerForEachMealType(
          'dinner',
          listOfCuisineSelected,
          listOfCourseSelected,
          listOfEaseOfPreparationSelected,
          listOfBatchCookingSelected,
          listOfEasyRestaurantRecipeSelected
        )

        arr2 = dinnerDietValues.map((item, index) => ({
          id: index,
          checked: false,
          data: [item],
          dietWithAccompaniment: item.display ?? '',
        }))
      }

      setDinnerDietItemsWithCheck(arr2)
      setDinnerTime(dinnerDiets[4].data)
    }

    if (nightDrinkDiets[0].checked) {
      countOfColumns += 1
      setNightDrinkChecked(true)
      nightDrinkDietValues = await getAnswerForEachMealType(
        'night',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )
      const arr2: DietItem1[] = nightDrinkDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))
      setNightDietItemsWithCheck(arr2)
      setNightDrinkTime(nightDrinkDiets[0].data)
    }

    if (nightDrinkDiets[1].checked) {
      countOfColumns += 1
      setNightDrinkTwoChecked(true)
      nightDrinkDietValues = await getAnswerForEachMealType(
        'night',
        listOfCuisineSelected,
        listOfCourseSelected,
        listOfEaseOfPreparationSelected,
        listOfBatchCookingSelected,
        listOfEasyRestaurantRecipeSelected
      )
      const arr2: DietItem1[] = nightDrinkDietValues.map((item, index) => ({
        id: index,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      }))
      setNightDietItemsWithCheck(arr2)
      setNightDrinkTime(nightDrinkDiets[1].data)
    }

    console.log('---The trueCount is---')
    console.log(countOfColumns)

    setColumnsCount(countOfColumns)

    setFetchAllTableDetails(false)
    setMealWidgetDisplay(true)
    setDisplayTable(true)
    setDisplayOptionsSelected(true)
    setExpanded(false)
  }

  const createWeeklyMealSchedulingDietPlan = async () => {
    const rangeOfDates = getRangeOfDates(
      moment(startDate).format('DD-MM-YYYY'),
      moment(tillDate).format('DD-MM-YYYY')
    )

    const noOfDays: number = rangeOfDates.length

    let tempArr: R4.ICoding[][] = []
    tempArr = earlyMorningDietItemsWithCheck
      .filter((item) => item.checked)
      .map((item) => item.data)
    const fullEarlyMorningData: R4.ICoding[][] = fillToSeven(tempArr, noOfDays)

    tempArr = breakfastDietItemsWithCheck
      .filter((item) => item.checked)
      .map((item) => item.data)
    const fullBreakfastData: R4.ICoding[][] = fillToSeven(tempArr, noOfDays)

    tempArr = midMorningDietItemsWithCheck
      .filter((item) => item.checked)
      .map((item) => item.data)
    const fullMidMorningData: R4.ICoding[][] = fillToSeven(tempArr, noOfDays)

    tempArr = lunchDietItemsWithCheck
      .filter((item) => item.checked)
      .map((item) => item.data)
    const fullLunchData: R4.ICoding[][] = fillToSeven(tempArr, noOfDays)

    tempArr = eveningDietItemsWithCheck
      .filter((item) => item.checked)
      .map((item) => item.data)
    const fullEveningSnackData: R4.ICoding[][] = fillToSeven(tempArr, noOfDays)

    tempArr = dinnerDietItemsWithCheck
      .filter((item) => item.checked)
      .map((item) => item.data)
    const fullDinnerData: R4.ICoding[][] = fillToSeven(tempArr, noOfDays)

    tempArr = nightDietItemsWithCheck
      .filter((item) => item.checked)
      .map((item) => item.data)
    const fullBedTimeData: R4.ICoding[][] = fillToSeven(tempArr, noOfDays)

    const arrDragDrop = Array.from({ length: noOfDays }, (v, i) => ({
      id: i,
      row: i,
      date: rangeOfDates[i],
      earlyMorningTimeDuration: earlyMorningTime,
      earlyMorningData: fullEarlyMorningData[i],
      breakfastTimeDuration: breakfastTime,
      breakfastData: fullBreakfastData[i],
      midMorningTimeDuration: midMorningTime,
      midMorningData: fullMidMorningData[i],
      lunchTimeDuration: lunchTime,
      lunchData: fullLunchData[i],
      eveningSnackTimeDuration: eveningTime,
      eveningSnackData: fullEveningSnackData[i],
      dinnerTimeDuration: dinnerTime,
      dinnerData: fullDinnerData[i],
      nightTimeDuration: nightDrinkTime,
      nightData: fullBedTimeData[i],
    }))

    console.log(JSON.stringify(arrDragDrop))
    const tableData = convertLlmOutputToTable(arrDragDrop)

    console.log('------table data---------')
    console.log(tableData)

    setDietDragDropTableData(tableData)
    setChartDataAfterEachDragDrop(tableData)
    setActiveStep(1)
  }

  const createWeeklyDietChart = async () => {
    setFinalChartData(chartDataAfterEachDragDrop)
    setActiveStep(2)
  }

  const createDietDataForCarePlan = async () => {
    const carePlanData = convertChartDataToCarePlanData(
      chartDataAfterEachDragDrop as DisplayTableData,
      dietTimeSearchSlice.prSelectedCoding ?? []
    )
    console.log('------The final care plan data is---------')
    console.log(carePlanData)
    console.log('------')

    addCurrentDetails(carePlanData)
  }

  const searchForAccompaniments = async () => {
    setFetchAccompanimentDetails(true)
    let newAccompanimentDietValues: R4.ICoding[] = []

    newAccompanimentDietValues = await getAnswerForEachMealTypeAccompaniment()

    const newAccompanimentDietValuesSorted: R4.ICoding[] = [
      ...newAccompanimentDietValues,
    ].sort((a, b) => a.display!.localeCompare(b.display!))

    const arr: AccompanimentType[] = newAccompanimentDietValuesSorted.map(
      (item: any, index: number) => ({
        id: index + 1,
        checked: false,
        data: item,
      })
    )

    setAccompanimentValues(arr)
    setAccompCheckbox(true)
    setFetchAccompanimentDetails(false)
  }

  const handleAddAdditionalDiets = async () => {
    let newArr: DietItem1[] = []
    const selectedValues: R4.ICoding[][] = additionalDietValues
      .filter((item) => item.checked)
      .map((item) => item.data)

    const selectedValuesWithCheck: DietItem1[] = selectedValues.map(
      (item, index) => ({
        id: index + 1,
        checked: false,
        data: item,
        dietWithAccompaniment: item[0].display ?? '',
      })
    )

    if (additionalDietMealType == 'morning') {
      newArr = earlyMorningDietItemsWithCheck
      newArr.push(...selectedValuesWithCheck)
      setEarlyMorningDietItemsWithCheck(newArr)
    }

    if (additionalDietMealType == 'breakfast') {
      newArr = breakfastDietItemsWithCheck
      newArr.push(...selectedValuesWithCheck)
      setBreakfastDietItemsWithCheck(newArr)
    }

    if (additionalDietMealType == 'morning snack') {
      newArr = midMorningDietItemsWithCheck
      newArr.push(...selectedValuesWithCheck)
      setMidMorningDietItemsWithCheck(newArr)
    }

    if (additionalDietMealType == 'lunch') {
      newArr = lunchDietItemsWithCheck
      newArr.push(...selectedValuesWithCheck)
      setLunchDietItemsWithCheck(newArr)
    }

    if (additionalDietMealType == 'evening snack') {
      newArr = eveningDietItemsWithCheck
      newArr.push(...selectedValuesWithCheck)
      setEveningDietItemsWithCheck(newArr)
    }

    if (additionalDietMealType == 'dinner') {
      newArr = dinnerDietItemsWithCheck
      newArr.push(...selectedValuesWithCheck)
      setDinnerDietItemsWithCheck(newArr)
    }

    if (additionalDietMealType == 'night drink') {
      newArr = nightDietItemsWithCheck
      newArr.push(...selectedValuesWithCheck)
      setNightDietItemsWithCheck(newArr)
    }

    await sleep(1000)

    dispatch(showSuccessAlert('Additional diets added successfully'))

    setAdditionalDietSearchSuccessful(false)
    setAdditionalDietCheckbox(false)
    setAdditionalDietValues([])
  }

  const handleAddAccompaniments = async () => {
    let newArr: DietItem1[] = []
    const selectedValues: R4.ICoding[] = accompanimentValues
      .filter((item) => item.checked)
      .map((item) => item.data)

    if (accompanimentDietMealType == 'morning') {
      if (
        accompanimentDietIndex >= 0 &&
        accompanimentDietIndex < earlyMorningDietItemsWithCheck.length
      ) {
        newArr = earlyMorningDietItemsWithCheck
        selectedValues.map((item) => {
          newArr[accompanimentDietIndex].data.push(item)
        })
      }

      const arr2: DietItem1[] = newArr.map((item) => {
        const dietWithAccompaniment = item.data.map((d) => d.display).join(', ')
        return {
          ...item,
          dietWithAccompaniment,
        }
      })

      setEarlyMorningDietItemsWithCheck(arr2)
    }

    if (accompanimentDietMealType == 'breakfast') {
      if (
        accompanimentDietIndex >= 0 &&
        accompanimentDietIndex < breakfastDietItemsWithCheck.length
      ) {
        newArr = breakfastDietItemsWithCheck
        selectedValues.map((item) => {
          newArr[accompanimentDietIndex].data.push(item)
        })
      }

      const arr2: DietItem1[] = newArr.map((item) => {
        const dietWithAccompaniment = item.data.map((d) => d.display).join(', ')
        return {
          ...item,
          dietWithAccompaniment,
        }
      })

      setBreakfastDietItemsWithCheck(arr2)
    }

    if (accompanimentDietMealType == 'morning snack') {
      if (
        accompanimentDietIndex >= 0 &&
        accompanimentDietIndex < midMorningDietItemsWithCheck.length
      ) {
        newArr = midMorningDietItemsWithCheck
        selectedValues.map((item) => {
          newArr[accompanimentDietIndex].data.push(item)
        })
      }

      const arr2: DietItem1[] = newArr.map((item) => {
        const dietWithAccompaniment = item.data.map((d) => d.display).join(', ')
        return {
          ...item,
          dietWithAccompaniment,
        }
      })

      setMidMorningDietItemsWithCheck(arr2)
    }

    if (accompanimentDietMealType == 'lunch') {
      if (
        accompanimentDietIndex >= 0 &&
        accompanimentDietIndex < lunchDietItemsWithCheck.length
      ) {
        newArr = lunchDietItemsWithCheck
        selectedValues.map((item) => {
          newArr[accompanimentDietIndex].data.push(item)
        })
      }

      const arr2: DietItem1[] = newArr.map((item) => {
        const dietWithAccompaniment = item.data.map((d) => d.display).join(', ')
        return {
          ...item,
          dietWithAccompaniment,
        }
      })

      setLunchDietItemsWithCheck(arr2)
    }

    if (accompanimentDietMealType == 'evening snack') {
      if (
        accompanimentDietIndex >= 0 &&
        accompanimentDietIndex < eveningDietItemsWithCheck.length
      ) {
        newArr = eveningDietItemsWithCheck
        selectedValues.map((item) => {
          newArr[accompanimentDietIndex].data.push(item)
        })
      }

      const arr2: DietItem1[] = newArr.map((item) => {
        const dietWithAccompaniment = item.data.map((d) => d.display).join(', ')
        return {
          ...item,
          dietWithAccompaniment,
        }
      })

      setEveningDietItemsWithCheck(arr2)
    }

    if (accompanimentDietMealType == 'dinner') {
      if (
        accompanimentDietIndex >= 0 &&
        accompanimentDietIndex < dinnerDietItemsWithCheck.length
      ) {
        newArr = dinnerDietItemsWithCheck
        selectedValues.map((item) => {
          newArr[accompanimentDietIndex].data.push(item)
        })
      }

      const arr2: DietItem1[] = newArr.map((item) => {
        const dietWithAccompaniment = item.data.map((d) => d.display).join(', ')
        return {
          ...item,
          dietWithAccompaniment,
        }
      })

      setDinnerDietItemsWithCheck(arr2)
    }

    if (accompanimentDietMealType == 'night drink') {
      if (
        accompanimentDietIndex >= 0 &&
        accompanimentDietIndex < nightDietItemsWithCheck.length
      ) {
        newArr = nightDietItemsWithCheck
        selectedValues.map((item) => {
          newArr[accompanimentDietIndex].data.push(item)
        })
      }

      const arr2: DietItem1[] = newArr.map((item) => {
        const dietWithAccompaniment = item.data.map((d) => d.display).join(', ')
        return {
          ...item,
          dietWithAccompaniment,
        }
      })

      setNightDietItemsWithCheck(arr2)
    }

    await sleep(1000)

    dispatch(showSuccessAlert('Accompaniments added successfully'))

    setAccompCheckbox(false)
    setAccompanimentValues([])
  }

  const searchForAdditionalDiets = async () => {
    setFetchAdditionalDietDetails(true)
    setAdditionalDietSearchSuccessful(true)

    let newAdditionalDietValues: R4.ICoding[] = []

    newAdditionalDietValues = await getAnswerForEachMealTypeAdditionalDiet()

    const newAdditionalDietValuesSorted: R4.ICoding[] = [
      ...newAdditionalDietValues,
    ].sort((a, b) => a.display!.localeCompare(b.display!))

    const arr: DietItem1[] = newAdditionalDietValuesSorted.map(
      (item: any, index: number) => ({
        id: index + 1,
        checked: false,
        data: [item],
        dietWithAccompaniment: item.display ?? '',
      })
    )

    setAdditionalDietValues(arr)
    setAdditionalDietCheckbox(true)
    setFetchAdditionalDietDetails(false)
  }

  function handleEditDiet(editDietMealType: string, dietIndex: number) {
    setShowEdit(true)
    setEditMealType(editDietMealType)
    setEditDietIndex(dietIndex)

    if (editDietMealType == 'morning') {
      setDefaultValuesForEditDiet(
        earlyMorningDietItemsWithCheck[dietIndex].data
      )
    }
    if (editDietMealType == 'breakfast') {
      setDefaultValuesForEditDiet(breakfastDietItemsWithCheck[dietIndex].data)
    }
    if (editDietMealType == 'morning snack') {
      setDefaultValuesForEditDiet(midMorningDietItemsWithCheck[dietIndex].data)
    }
    if (editDietMealType == 'lunch') {
      setDefaultValuesForEditDiet(lunchDietItemsWithCheck[dietIndex].data)
    }
    if (editDietMealType == 'evening snack') {
      setDefaultValuesForEditDiet(eveningDietItemsWithCheck[dietIndex].data)
    }
    if (editDietMealType == 'dinner') {
      setDefaultValuesForEditDiet(dinnerDietItemsWithCheck[dietIndex].data)
    }
    if (editDietMealType == 'night drink') {
      setDefaultValuesForEditDiet(nightDietItemsWithCheck[dietIndex].data)
    }
  }

  const editDietValues = async () => {
    console.log('-----------The default values for edit------------')
    console.log(defaultValuesForEditDiet)

    let arrVal: DietItem1[] = []
    if (editMealType == 'morning') {
      if (
        editDietIndex >= 0 &&
        editDietIndex < earlyMorningDietItemsWithCheck.length
      ) {
        arrVal = earlyMorningDietItemsWithCheck
        arrVal[editDietIndex].data = defaultValuesForEditDiet

        const arr: DietItem1[] = arrVal.map((item) => {
          const dietWithAccompaniment = item.data
            .map((d) => d.display)
            .join(', ')
          return {
            ...item,
            dietWithAccompaniment,
          }
        })

        setEarlyMorningDietItemsWithCheck(arr)
      }
    }

    if (editMealType == 'breakfast') {
      if (
        editDietIndex >= 0 &&
        editDietIndex < breakfastDietItemsWithCheck.length
      ) {
        arrVal = breakfastDietItemsWithCheck
        arrVal[editDietIndex].data = defaultValuesForEditDiet

        const arr: DietItem1[] = arrVal.map((item) => {
          const dietWithAccompaniment = item.data
            .map((d) => d.display)
            .join(', ')
          return {
            ...item,
            dietWithAccompaniment,
          }
        })

        setBreakfastDietItemsWithCheck(arr)
      }
    }

    if (editMealType == 'morning snack') {
      if (
        editDietIndex >= 0 &&
        editDietIndex < midMorningDietItemsWithCheck.length
      ) {
        arrVal = midMorningDietItemsWithCheck
        arrVal[editDietIndex].data = defaultValuesForEditDiet

        const arr: DietItem1[] = arrVal.map((item) => {
          const dietWithAccompaniment = item.data
            .map((d) => d.display)
            .join(', ')
          return {
            ...item,
            dietWithAccompaniment,
          }
        })

        setMidMorningDietItemsWithCheck(arr)
      }
    }

    if (editMealType == 'lunch') {
      if (
        editDietIndex >= 0 &&
        editDietIndex < lunchDietItemsWithCheck.length
      ) {
        arrVal = lunchDietItemsWithCheck
        arrVal[editDietIndex].data = defaultValuesForEditDiet

        const arr: DietItem1[] = arrVal.map((item) => {
          const dietWithAccompaniment = item.data
            .map((d) => d.display)
            .join(', ')
          return {
            ...item,
            dietWithAccompaniment,
          }
        })

        setLunchDietItemsWithCheck(arr)
      }
    }

    if (editMealType == 'evening snack') {
      if (
        editDietIndex >= 0 &&
        editDietIndex < eveningDietItemsWithCheck.length
      ) {
        arrVal = eveningDietItemsWithCheck
        arrVal[editDietIndex].data = defaultValuesForEditDiet

        const arr: DietItem1[] = arrVal.map((item) => {
          const dietWithAccompaniment = item.data
            .map((d) => d.display)
            .join(', ')
          return {
            ...item,
            dietWithAccompaniment,
          }
        })

        setEveningDietItemsWithCheck(arr)
      }
    }

    if (editMealType == 'dinner') {
      if (
        editDietIndex >= 0 &&
        editDietIndex < dinnerDietItemsWithCheck.length
      ) {
        arrVal = dinnerDietItemsWithCheck
        arrVal[editDietIndex].data = defaultValuesForEditDiet

        const arr: DietItem1[] = arrVal.map((item) => {
          const dietWithAccompaniment = item.data
            .map((d) => d.display)
            .join(', ')
          return {
            ...item,
            dietWithAccompaniment,
          }
        })

        setDinnerDietItemsWithCheck(arr)
      }
    }

    if (editMealType == 'night drink') {
      if (
        editDietIndex >= 0 &&
        editDietIndex < nightDietItemsWithCheck.length
      ) {
        arrVal = nightDietItemsWithCheck
        arrVal[editDietIndex].data = defaultValuesForEditDiet

        const arr: DietItem1[] = arrVal.map((item) => {
          const dietWithAccompaniment = item.data
            .map((d) => d.display)
            .join(', ')
          return {
            ...item,
            dietWithAccompaniment,
          }
        })

        setNightDietItemsWithCheck(arr)
      }
    }

    await sleep(1000)

    dispatch(showSuccessAlert('Edited successfully'))
  }

  const cellSx = { width: '20%' }

  const useStyles = makeStyles((theme: Theme) => ({
    table: {
      padding: '3px',
      margin: '0px',
      height: '50vh',
      '& .MuiTableCell-root': {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        padding: '4px',
        margin: '0px',

        maxWidth: '12%',
        overflow: 'ellipse',
      },
    },
    tableCategories: {
      padding: '3px',
      margin: '0px',
      '& .MuiTableCell-root': {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        padding: '4px',
        margin: '0px',

        maxWidth: '12%',
        overflow: 'ellipse',
      },
    },
    tableDietCompliance: {
      padding: '3px',
      margin: '0px',
      height: 150,
      '& .MuiTableCell-root': {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        padding: '4px',
        margin: '0px',

        maxWidth: '12%',
        overflow: 'ellipse',
      },
    },
    dialogCustomizedWidth: {
      'max-width': '100%',
      minHeight: '100vh',
      maxHeight: '100vh',
    },
    dialogCustomizedWidthSub: {
      'max-width': '40%',
      minHeight: '45vh',
      maxHeight: '45vh',
    },
    dialogCustomizedWidthEdit: {
      'max-width': '30%',
      minHeight: '30vh',
      maxHeight: '30vh',
    },
    dialogCustomizedWidthAccompaniment: {
      'max-width': '40%',
      minHeight: '40vh',
      maxHeight: '40vh',
    },
    dialogCustomizedWidthMealCourse: {
      'max-width': '40%',
      minHeight: '100vh',
      maxHeight: '100vh',
    },
    dialogCustomizedWidthCategories: {
      'max-width': '40%',
      minHeight: '65vh',
      maxHeight: '65vh',
    },
    titleText: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: '19px',
      letterSpacing: '0px',
      textAlign: 'center',
      color: '#333333',
      fontWeight: 'bold',
      // backgroundColor: 'lightGrey'
      backgroundColor: '#f5f5f5',
    },
  }))
  const classes = useStyles()

  const [displayTable, setDisplayTable] = useState<boolean>(false)

  const [chartDataAfterEachDragDrop, setChartDataAfterEachDragDrop] =
    useState<DisplayTableData>()
  const [finalChartData, setFinalChartData] = useState<DisplayTableData>()

  const [fetchAccompanimentDetails, setFetchAccompanimentDetails] =
    useState<boolean>(false)
  const [fetchAdditionalDietDetails, setFetchAdditionalDietDetails] =
    useState<boolean>(false)

  const [fetchAllTableDetails, setFetchAllTableDetails] =
    useState<boolean>(false)
  const [mealWidgetDisplay, setMealWidgetDisplay] = useState<boolean>(false)

  const [accompanimentValues, setAccompanimentValues] = useState<
    AccompanimentType[]
  >([])

  const [accompanimentChecked, setAccompanimentChecked] =
    useState<boolean>(false)

  const [additionalDietValues, setAdditionalDietValues] = useState<DietItem1[]>(
    []
  )
  const [additionalDietSearchSuccessful, setAdditionalDietSearchSuccessful] =
    useState<boolean>(false)

  const [additionalDietCheckbox, setAdditionalDietCheckbox] =
    useState<boolean>(false)

  const [startDate, setStartDate] = useState<Date>(getOnlyDate(new Date()))
  const [tillDate, setTillDate] = useState<Date>(getOnlyDate(new Date()))

  const dispatch = useDispatch()

  const [easeOfPreparations, setEaseOfPreparations] = useState(
    easeOfPreparationTypes
  )
  const [batchCookings, setBatchCookings] = useState(batchCookingTypes)
  const [courses, setCourses] = useState(courseTypes)
  const [easyRestaurantRecipes, setEasyRestaurantRecipes] = useState(
    easyRestaurantRecipeTypes
  )
  const [cuisines, setCuisines] = useState(cuisineTypes)

  const [earlyMorningDietItemsWithCheck, setEarlyMorningDietItemsWithCheck] =
    useState<DietItem1[]>([])
  const [breakfastDietItemsWithCheck, setBreakfastDietItemsWithCheck] =
    useState<DietItem1[]>([])
  const [midMorningDietItemsWithCheck, setMidMorningDietItemsWithCheck] =
    useState<DietItem1[]>([])
  const [lunchDietItemsWithCheck, setLunchDietItemsWithCheck] = useState<
    DietItem1[]
  >([])
  const [eveningDietItemsWithCheck, setEveningDietItemsWithCheck] = useState<
    DietItem1[]
  >([])
  const [dinnerDietItemsWithCheck, setDinnerDietItemsWithCheck] = useState<
    DietItem1[]
  >([])
  const [nightDietItemsWithCheck, setNightDietItemsWithCheck] = useState<
    DietItem1[]
  >([])

  const [morningDiets, setMorningDiets] = useState(morningDietTypes)
  const [breakfastDiets, setBreakfastDiets] = useState(breakfastDietTypes)
  const [midMorningDiets, setMidMorningDiets] = useState(midMorningDietTypes)
  const [lunchDiets, setLunchDiets] = useState(lunchDietTypes)
  const [eveningDiets, setEveningDiets] = useState(eveningDietTypes)
  const [dinnerDiets, setDinnerDiets] = useState(dinnerDietTypes)
  const [nightDrinkDiets, setNightDrinkDiets] = useState(nightDrinkDietTypes)

  const [noResultsForFiltersEarlyMorning, setNoResultsForFiltersEarlyMorning] =
    useState<boolean>(false)
  const [noResultsForFiltersBreakfast, setNoResultsForFiltersBreakfast] =
    useState<boolean>(false)
  const [noResultsForFiltersMidMorning, setNoResultsForFiltersMidMorning] =
    useState<boolean>(false)
  const [noResultsForFiltersLunch, setNoResultsForFiltersLunch] =
    useState<boolean>(false)
  const [noResultsForFiltersEveningSnack, setNoResultsForFiltersEveningSnack] =
    useState<boolean>(false)
  const [noResultsForFiltersDinner, setNoResultsForFiltersDinner] =
    useState<boolean>(false)
  const [noResultsForFiltersNight, setNoResultsForFiltersNight] =
    useState<boolean>(false)

  const [
    searchDietRecommendationsSecondTime,
    setSearchDietRecommendationsSecondTime,
  ] = useState<boolean>(false)

  const [expanded, setExpanded] = useState<boolean>(true)
  const [displayOptionsSelected, setDisplayOptionsSelected] = useState(false)
  const [dietSelected, setDietSelected] = useState(false)

  const [dietTableSearch, setDietTableSearch] = useState(false)
  const [enableCuisineAdd, setEnableCuisineAdd] = useState(false)
  const [anyMealTimeSelected, setAnyMealTimeSelected] = useState(false)

  const handleChangeMorningDietType = (event: boolean, index: number) => {
    const values: MealTypes[] = [...morningDiets]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      breakfastDiets.some((item) => item.checked) ||
      midMorningDiets.some((item) => item.checked) ||
      lunchDiets.some((item) => item.checked) ||
      eveningDiets.some((item) => item.checked) ||
      dinnerDiets.some((item) => item.checked) ||
      nightDrinkDiets.some((item) => item.checked)
    )
      setAnyMealTimeSelected(true)
    else setAnyMealTimeSelected(false)

    if (
      cuisines.some((item) => item.checked) &&
      (values.some((item) => item.checked) ||
        breakfastDiets.some((item) => item.checked) ||
        midMorningDiets.some((item) => item.checked) ||
        lunchDiets.some((item) => item.checked) ||
        eveningDiets.some((item) => item.checked) ||
        dinnerDiets.some((item) => item.checked) ||
        nightDrinkDiets.some((item) => item.checked))
    ) {
      setDietTableSearch(true)
    } else setDietTableSearch(false)

    setMorningDiets(values)
  }

  const handleChangeBreakfastDietType = (event: boolean, index: number) => {
    const values: MealTypes[] = [...breakfastDiets]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      morningDiets.some((item) => item.checked) ||
      midMorningDiets.some((item) => item.checked) ||
      lunchDiets.some((item) => item.checked) ||
      eveningDiets.some((item) => item.checked) ||
      dinnerDiets.some((item) => item.checked) ||
      nightDrinkDiets.some((item) => item.checked)
    )
      setAnyMealTimeSelected(true)
    else setAnyMealTimeSelected(false)

    if (
      cuisines.some((item) => item.checked) &&
      (values.some((item) => item.checked) ||
        morningDiets.some((item) => item.checked) ||
        midMorningDiets.some((item) => item.checked) ||
        lunchDiets.some((item) => item.checked) ||
        eveningDiets.some((item) => item.checked) ||
        dinnerDiets.some((item) => item.checked) ||
        nightDrinkDiets.some((item) => item.checked))
    ) {
      setDietTableSearch(true)
    } else setDietTableSearch(false)

    setBreakfastDiets(values)
  }

  const handleChangeMidMorningDietType = (event: boolean, index: number) => {
    const values: MealTypes[] = [...midMorningDiets]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      morningDiets.some((item) => item.checked) ||
      breakfastDiets.some((item) => item.checked) ||
      lunchDiets.some((item) => item.checked) ||
      eveningDiets.some((item) => item.checked) ||
      dinnerDiets.some((item) => item.checked) ||
      nightDrinkDiets.some((item) => item.checked)
    )
      setAnyMealTimeSelected(true)
    else setAnyMealTimeSelected(false)

    if (
      cuisines.some((item) => item.checked) &&
      (values.some((item) => item.checked) ||
        breakfastDiets.some((item) => item.checked) ||
        morningDiets.some((item) => item.checked) ||
        lunchDiets.some((item) => item.checked) ||
        eveningDiets.some((item) => item.checked) ||
        dinnerDiets.some((item) => item.checked) ||
        nightDrinkDiets.some((item) => item.checked))
    ) {
      setDietTableSearch(true)
    } else setDietTableSearch(false)

    setMidMorningDiets(values)
  }

  const handleChangeLunchDietType = (event: boolean, index: number) => {
    const values: MealTypes[] = [...lunchDiets]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      breakfastDiets.some((item) => item.checked) ||
      morningDiets.some((item) => item.checked) ||
      midMorningDiets.some((item) => item.checked) ||
      eveningDiets.some((item) => item.checked) ||
      dinnerDiets.some((item) => item.checked) ||
      nightDrinkDiets.some((item) => item.checked)
    )
      setAnyMealTimeSelected(true)
    else setAnyMealTimeSelected(false)

    if (
      cuisines.some((item) => item.checked) &&
      (values.some((item) => item.checked) ||
        breakfastDiets.some((item) => item.checked) ||
        midMorningDiets.some((item) => item.checked) ||
        morningDiets.some((item) => item.checked) ||
        eveningDiets.some((item) => item.checked) ||
        dinnerDiets.some((item) => item.checked) ||
        nightDrinkDiets.some((item) => item.checked))
    ) {
      setDietTableSearch(true)
    } else setDietTableSearch(false)

    setLunchDiets(values)
  }

  const handleChangeEveningDietType = (event: boolean, index: number) => {
    const values: MealTypes[] = [...eveningDiets]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      breakfastDiets.some((item) => item.checked) ||
      morningDiets.some((item) => item.checked) ||
      midMorningDiets.some((item) => item.checked) ||
      lunchDiets.some((item) => item.checked) ||
      dinnerDiets.some((item) => item.checked) ||
      nightDrinkDiets.some((item) => item.checked)
    )
      setAnyMealTimeSelected(true)
    else setAnyMealTimeSelected(false)

    if (
      cuisines.some((item) => item.checked) &&
      (values.some((item) => item.checked) ||
        breakfastDiets.some((item) => item.checked) ||
        midMorningDiets.some((item) => item.checked) ||
        lunchDiets.some((item) => item.checked) ||
        morningDiets.some((item) => item.checked) ||
        dinnerDiets.some((item) => item.checked) ||
        nightDrinkDiets.some((item) => item.checked))
    ) {
      setDietTableSearch(true)
    } else setDietTableSearch(false)

    setEveningDiets(values)
  }

  const handleChangeDinnerDietType = (event: boolean, index: number) => {
    const values: MealTypes[] = [...dinnerDiets]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      morningDiets.some((item) => item.checked) ||
      breakfastDiets.some((item) => item.checked) ||
      midMorningDiets.some((item) => item.checked) ||
      lunchDiets.some((item) => item.checked) ||
      eveningDiets.some((item) => item.checked) ||
      nightDrinkDiets.some((item) => item.checked)
    )
      setAnyMealTimeSelected(true)
    else setAnyMealTimeSelected(false)

    if (
      cuisines.some((item) => item.checked) &&
      (values.some((item) => item.checked) ||
        breakfastDiets.some((item) => item.checked) ||
        midMorningDiets.some((item) => item.checked) ||
        lunchDiets.some((item) => item.checked) ||
        eveningDiets.some((item) => item.checked) ||
        morningDiets.some((item) => item.checked) ||
        nightDrinkDiets.some((item) => item.checked))
    ) {
      setDietTableSearch(true)
    } else setDietTableSearch(false)

    setDinnerDiets(values)
  }

  const handleChangeNightDrinkDietType = (event: boolean, index: number) => {
    const values: MealTypes[] = [...nightDrinkDiets]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      morningDiets.some((item) => item.checked) ||
      breakfastDiets.some((item) => item.checked) ||
      midMorningDiets.some((item) => item.checked) ||
      lunchDiets.some((item) => item.checked) ||
      eveningDiets.some((item) => item.checked) ||
      dinnerDiets.some((item) => item.checked)
    )
      setAnyMealTimeSelected(true)
    else setAnyMealTimeSelected(false)

    if (
      cuisines.some((item) => item.checked) &&
      (values.some((item) => item.checked) ||
        breakfastDiets.some((item) => item.checked) ||
        midMorningDiets.some((item) => item.checked) ||
        lunchDiets.some((item) => item.checked) ||
        eveningDiets.some((item) => item.checked) ||
        dinnerDiets.some((item) => item.checked) ||
        morningDiets.some((item) => item.checked))
    ) {
      setDietTableSearch(true)
    } else setDietTableSearch(false)

    setNightDrinkDiets(values)
  }

  const handleChangeAccompaniments = (event: boolean, index: number) => {
    const values: any[] = [...accompanimentValues]
    values[index].checked = event
    setAccompanimentValues(values)

    const hasCheckedTrue = accompanimentValues.some((item) => item.checked)
    setAccompanimentChecked(hasCheckedTrue)
  }

  const handleChangeAdditionalDiets = (event: boolean, index: number) => {
    const values: DietItem1[] = [...additionalDietValues]
    values[index].checked = event
    setAdditionalDietValues(values)

    const hasCheckedTrue = additionalDietValues.some((item) => item.checked)
    setAccompanimentChecked(hasCheckedTrue)
  }

  const handleChangeCuisine = (
    event: boolean,
    condition: CuisineTypes,
    index: number
  ) => {
    const values: CuisineTypes[] = [...cuisines]
    values[index].checked = event

    if (values.some((item) => item.checked)) setEnableCuisineAdd(true)
    else setEnableCuisineAdd(false)

    if (
      values.some((item) => item.checked) &&
      (morningDiets.some((item) => item.checked) ||
        breakfastDiets.some((item) => item.checked) ||
        midMorningDiets.some((item) => item.checked) ||
        lunchDiets.some((item) => item.checked) ||
        eveningDiets.some((item) => item.checked) ||
        dinnerDiets.some((item) => item.checked) ||
        nightDrinkDiets.some((item) => item.checked))
    ) {
      setDietTableSearch(true)
    } else setDietTableSearch(false)

    setCuisines(values)

    if (
      cuisines.length != 0 ||
      courses.length != 0 ||
      easeOfPreparations.length != 0 ||
      batchCookings.length != 0 ||
      easyRestaurantRecipes.length != 0
    ) {
      setDisplayOptionsSelected(true)
    }
  }

  const handleChangeCourse = (
    event: boolean,
    condition: CourseTypes,
    index: number
  ) => {
    const values: CourseTypes[] = [...courses]
    values[index].checked = event
    setCourses(values)
  }

  const handleChangeEaseOfPreparation = (
    event: boolean,
    condition: MealTypes,
    index: number
  ) => {
    const values: EaseOfPreparationTypes[] = [...easeOfPreparations]
    values[index].checked = event
    setEaseOfPreparations(values)
  }

  const handleChangeBatchCooking = (
    event: boolean,
    condition: BatchCookingTypes,
    index: number
  ) => {
    const values: BatchCookingTypes[] = [...batchCookings]
    values[index].checked = event
    setBatchCookings(values)
  }

  const handleChangeEasyRestaurantRecipe = (
    event: boolean,
    condition: EasyRestaurantRecipeTypes,
    index: number
  ) => {
    const values: EasyRestaurantRecipeTypes[] = [...easyRestaurantRecipes]
    values[index].checked = event
    setEasyRestaurantRecipes(values)
  }

  const handleChangeFinalEarlyMorningValues = (
    event: boolean,
    condition: CuisineTypes,
    index: number
  ) => {
    const values: any[] = [...earlyMorningDietItemsWithCheck]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      breakfastDietItemsWithCheck.some((item) => item.checked) ||
      midMorningDietItemsWithCheck.some((item) => item.checked) ||
      lunchDietItemsWithCheck.some((item) => item.checked) ||
      eveningDietItemsWithCheck.some((item) => item.checked) ||
      dinnerDietItemsWithCheck.some((item) => item.checked) ||
      nightDietItemsWithCheck.some((item) => item.checked)
    ) {
      setDietSelected(true)
    } else setDietSelected(false)

    setEarlyMorningDietItemsWithCheck(values)
  }

  const handleChangeFinalBreakfastValues = (
    event: boolean,
    condition: CuisineTypes,
    index: number
  ) => {
    const values: any[] = [...breakfastDietItemsWithCheck]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      earlyMorningDietItemsWithCheck.some((item) => item.checked) ||
      midMorningDietItemsWithCheck.some((item) => item.checked) ||
      lunchDietItemsWithCheck.some((item) => item.checked) ||
      eveningDietItemsWithCheck.some((item) => item.checked) ||
      dinnerDietItemsWithCheck.some((item) => item.checked) ||
      nightDietItemsWithCheck.some((item) => item.checked)
    ) {
      setDietSelected(true)
    } else setDietSelected(false)

    setBreakfastDietItemsWithCheck(values)
  }

  const handleChangeFinalMidMorningValues = (
    event: boolean,
    condition: CuisineTypes,
    index: number
  ) => {
    const values: any[] = [...midMorningDietItemsWithCheck]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      breakfastDietItemsWithCheck.some((item) => item.checked) ||
      earlyMorningDietItemsWithCheck.some((item) => item.checked) ||
      lunchDietItemsWithCheck.some((item) => item.checked) ||
      eveningDietItemsWithCheck.some((item) => item.checked) ||
      dinnerDietItemsWithCheck.some((item) => item.checked) ||
      nightDietItemsWithCheck.some((item) => item.checked)
    ) {
      setDietSelected(true)
    } else setDietSelected(false)

    setMidMorningDietItemsWithCheck(values)
  }

  const handleChangeFinalLunchValues = (
    event: boolean,
    condition: CuisineTypes,
    index: number
  ) => {
    const values: any[] = [...lunchDietItemsWithCheck]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      breakfastDietItemsWithCheck.some((item) => item.checked) ||
      midMorningDietItemsWithCheck.some((item) => item.checked) ||
      earlyMorningDietItemsWithCheck.some((item) => item.checked) ||
      eveningDietItemsWithCheck.some((item) => item.checked) ||
      dinnerDietItemsWithCheck.some((item) => item.checked) ||
      nightDietItemsWithCheck.some((item) => item.checked)
    ) {
      setDietSelected(true)
    } else setDietSelected(false)

    setLunchDietItemsWithCheck(values)
  }

  const handleChangeFinalEveningSnackValues = (
    event: boolean,
    condition: CuisineTypes,
    index: number
  ) => {
    const values: any[] = [...eveningDietItemsWithCheck]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      breakfastDietItemsWithCheck.some((item) => item.checked) ||
      midMorningDietItemsWithCheck.some((item) => item.checked) ||
      lunchDietItemsWithCheck.some((item) => item.checked) ||
      earlyMorningDietItemsWithCheck.some((item) => item.checked) ||
      dinnerDietItemsWithCheck.some((item) => item.checked) ||
      nightDietItemsWithCheck.some((item) => item.checked)
    ) {
      setDietSelected(true)
    } else setDietSelected(false)

    setEveningDietItemsWithCheck(values)
  }

  const handleChangeFinalDinnerValues = (
    event: boolean,
    condition: CuisineTypes,
    index: number
  ) => {
    const values: any[] = [...dinnerDietItemsWithCheck]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      breakfastDietItemsWithCheck.some((item) => item.checked) ||
      midMorningDietItemsWithCheck.some((item) => item.checked) ||
      lunchDietItemsWithCheck.some((item) => item.checked) ||
      eveningDietItemsWithCheck.some((item) => item.checked) ||
      earlyMorningDietItemsWithCheck.some((item) => item.checked) ||
      nightDietItemsWithCheck.some((item) => item.checked)
    ) {
      setDietSelected(true)
    } else setDietSelected(false)

    setDinnerDietItemsWithCheck(values)
  }

  const handleChangeFinalBedTimeValues = (
    event: boolean,
    condition: CuisineTypes,
    index: number
  ) => {
    const values: any[] = [...nightDietItemsWithCheck]
    values[index].checked = event

    if (
      values.some((item) => item.checked) ||
      breakfastDietItemsWithCheck.some((item) => item.checked) ||
      midMorningDietItemsWithCheck.some((item) => item.checked) ||
      lunchDietItemsWithCheck.some((item) => item.checked) ||
      eveningDietItemsWithCheck.some((item) => item.checked) ||
      dinnerDietItemsWithCheck.some((item) => item.checked) ||
      earlyMorningDietItemsWithCheck.some((item) => item.checked)
    ) {
      setDietSelected(true)
    } else setDietSelected(false)

    setNightDietItemsWithCheck(values)
  }

  async function getAnswerForMealTypeLunch(
    mealTime: string,
    listOfCuisines: string[],
    listOfCourses: string[],
    listOfEaseOfPreparations: string[],
    listOfBatchCookings: string[],
    listOfEasyRestaurantRecipes: string[]
  ): Promise<DietItem1[]> {
    let cuisineString = ''
    let courseString = ''
    let easeOfPreparationString = ''
    let batchCookingString = ''
    let easyRestaurantRecipeString = ''

    if (listOfCuisines.length !== 0) {
      cuisineString = ` and cuisine as ${listOfCuisines[0]}`
      for (let m = 1; m < listOfCuisines.length; m++) {
        cuisineString = `${cuisineString} or ${listOfCuisines[m]}`
      }
    }
    if (listOfCourses.length !== 0) {
      courseString = ` and course as ${listOfCourses[0]}`
      for (let m = 1; m < listOfCourses.length; m++) {
        courseString = `${courseString} or ${listOfCourses[m]}`
      }
    }
    if (listOfEaseOfPreparations.length !== 0) {
      easeOfPreparationString = ` and ease of preparation as ${listOfEaseOfPreparations[0]}`
      for (let m = 1; m < listOfEaseOfPreparations.length; m++) {
        easeOfPreparationString = `${easeOfPreparationString} or ${listOfEaseOfPreparations[m]}`
      }
    }
    if (listOfBatchCookings.length !== 0) {
      batchCookingString = ` and batch cooking as ${listOfBatchCookings[0]}`
      for (let m = 1; m < listOfBatchCookings.length; m++) {
        batchCookingString = `${batchCookingString} or ${listOfBatchCookings[m]}`
      }
    }
    if (listOfEasyRestaurantRecipes.length !== 0) {
      easyRestaurantRecipeString = ` and easy restaurant recipe as ${listOfEasyRestaurantRecipes[0]}`
      for (let m = 1; m < listOfEasyRestaurantRecipes.length; m++) {
        easyRestaurantRecipeString = `${easyRestaurantRecipeString} or ${listOfEasyRestaurantRecipes[m]}`
      }
    }

    let mainDishList: R4.ICoding[] = []
    let sideDishList: R4.ICoding[] = []

    mainDishList = await getMainDish(
      mealTime,
      cuisineString,
      easeOfPreparationString,
      batchCookingString,
      easyRestaurantRecipeString
    )

    sideDishList = await getSideDish(
      mealTime,
      cuisineString,
      easeOfPreparationString,
      batchCookingString,
      easyRestaurantRecipeString
    )

    console.log(`---The ${mealTime} Main Dish---`)
    console.log(mainDishList)

    console.log(`---The ${mealTime} Side Dish---`)
    console.log(sideDishList)

    if (mainDishList.length > 0 && sideDishList.length > 0) {
      const arr: R4.ICoding[][] = sideDishList.map((item, index) => [
        mainDishList[index % mainDishList.length],
        item,
      ])
      const arrWithCheck: DietItem1[] = arr.map((item, index) => ({
        id: index + 1,
        checked: false,
        data: item,
        dietWithAccompaniment: item.map((i) => i.display ?? '').join(', '),
      }))

      return arrWithCheck
    }

    return []
  }

  async function getAnswerForEachMealType(
    durationOfDay: string,
    listOfCuisines: string[],
    listOfCourses: string[],
    listOfEaseOfPreparations: string[],
    listOfBatchCookings: string[],
    listOfEasyRestaurantRecipes: string[]
  ): Promise<R4.ICoding[]> {
    const duration = durationOfDay
    let question = ''

    let cuisineString = ''
    let courseString = ''
    let easeOfPreparationString = ''
    let batchCookingString = ''
    let easyRestaurantRecipeString = ''

    if (listOfCuisines.length !== 0) {
      cuisineString = ` and cuisine as ${listOfCuisines[0]}`
      for (let m = 1; m < listOfCuisines.length; m++) {
        cuisineString = `${cuisineString} or ${listOfCuisines[m]}`
      }
    }
    if (listOfCourses.length !== 0) {
      courseString = ` and course as ${listOfCourses[0]}`
      for (let m = 1; m < listOfCourses.length; m++) {
        courseString = `${courseString} or ${listOfCourses[m]}`
      }
    }
    if (listOfEaseOfPreparations.length !== 0) {
      easeOfPreparationString = ` and ease of preparation as ${listOfEaseOfPreparations[0]}`
      for (let m = 1; m < listOfEaseOfPreparations.length; m++) {
        easeOfPreparationString = `${easeOfPreparationString} or ${listOfEaseOfPreparations[m]}`
      }
    }
    if (listOfBatchCookings.length !== 0) {
      batchCookingString = ` and batch cooking as ${listOfBatchCookings[0]}`
      for (let m = 1; m < listOfBatchCookings.length; m++) {
        batchCookingString = `${batchCookingString} or ${listOfBatchCookings[m]}`
      }
    }
    if (listOfEasyRestaurantRecipes.length !== 0) {
      easyRestaurantRecipeString = ` and easy restaurant recipe as ${listOfEasyRestaurantRecipes[0]}`
      for (let m = 1; m < listOfEasyRestaurantRecipes.length; m++) {
        easyRestaurantRecipeString = `${easyRestaurantRecipeString} or ${listOfEasyRestaurantRecipes[m]}`
      }
    }

    question = `Give me seven days diet code and diet name with meal time as ${duration}${courseString}${cuisineString}${easeOfPreparationString}${batchCookingString}${easyRestaurantRecipeString}. Please give me diet code also`

    console.log('-----The question sent to llm----')
    console.log(question)

    const dataToSend = {
      query: question,
    }

    try {
      const questionForDietEngine = JSON.stringify(dataToSend)

      const result: any = await doPostQuestionForDietEngineLlm(
        questionForDietEngine
      )

      const finalResult = result.answer

      console.log('-----The response for main question is----')
      console.log(finalResult)

      if (
        finalResult.includes('No results') ||
        finalResult.includes('no results') ||
        finalResult.includes('No result') ||
        finalResult.includes('no result') ||
        finalResult.includes('SQLResult:') ||
        finalResult.includes('SQLQuery') ||
        finalResult.includes('no diet') ||
        finalResult.includes('no diets')
      ) {
        const questionSecond = `Give me seven days unique diet code and diet name with meal time as ${duration}. Please give me diet code also.`

        if (duration === 'morning') setNoResultsForFiltersEarlyMorning(true)
        if (duration === 'breakfast or morning breakfast')
          setNoResultsForFiltersBreakfast(true)
        if (duration === 'morning snack or mid day snack')
          setNoResultsForFiltersMidMorning(true)
        if (duration === 'lunch or afternoon') setNoResultsForFiltersLunch(true)
        if (duration === 'evening snack')
          setNoResultsForFiltersEveningSnack(true)
        if (duration === 'dinner') setNoResultsForFiltersDinner(true)
        if (duration === 'night') setNoResultsForFiltersNight(true)

        console.log('---The second question is----')
        console.log(questionSecond)

        const secondDataToSend = {
          query: questionSecond,
        }

        try {
          const secondQuestionForDietEngine = JSON.stringify(secondDataToSend)
          const secondResponse: any = await doPostQuestionForDietEngineLlm(
            secondQuestionForDietEngine
          )
          const finalResultSecond = secondResponse.answer

          console.log('---The second answer is----')
          console.log(finalResultSecond)

          if (
            finalResultSecond.includes('Here are') ||
            finalResultSecond.includes('are:') ||
            finalResultSecond.includes('are as follows')
          ) {
            const outputArr: R4.ICoding[] =
              regexExpressionForStringWithHereAre(finalResultSecond)
            if (outputArr.length !== 0) return outputArr
          }

          if (finalResultSecond.includes('Diet code')) {
            const outputArr: R4.ICoding[] =
              regexExpressionFirst(finalResultSecond)
            if (outputArr.length !== 0) return outputArr
          }

          if (
            regexExpressionToCheckStringContainsDietCodeAR(finalResultSecond) &&
            finalResultSecond.includes('-')
          ) {
            const outputArr: R4.ICoding[] =
              regexExpressionToCheckStringContainsDietCodeARWithHifen(
                finalResultSecond
              )
            if (outputArr.length !== 0) return outputArr
          }

          if (
            regexExpressionToCheckStringContainsDietCodeAR(finalResultSecond)
          ) {
            let outputArr: R4.ICoding[] = []
            if (finalResultSecond.includes('\n'))
              outputArr =
                regexExpressionForStringThatContainsARWithNewLine(
                  finalResultSecond
                )
            else
              outputArr =
                regexExpressionForStringThatContainsARWithCommaSeparated(
                  finalResultSecond
                )

            return outputArr
          }
        } catch (err) {
          console.log('---Printing the error---')
          console.log(err)
        }
      }

      if (
        finalResult.includes('Here are') ||
        finalResult.includes('are:') ||
        finalResult.includes('are as follows')
      ) {
        const outputArr: R4.ICoding[] =
          regexExpressionForStringWithHereAre(finalResult)
        if (outputArr.length !== 0) return outputArr
      }

      if (finalResult.includes('that meets the criteria of')) {
        const outputArr: R4.ICoding[] =
          regexExpressionForStringMeetsCriteriaOf(finalResult)
        if (outputArr.length !== 0) return outputArr
      }

      if (finalResult.includes('Diet code') && finalResult.includes('with')) {
        const outputArr: R4.ICoding[] =
          regexExpressionForStringHavingDietCodeAndWith(finalResult)
        if (outputArr.length !== 0) return outputArr
      }

      if (finalResult.includes('Diet code')) {
        const outputArr: R4.ICoding[] = regexExpressionFirst(finalResult)
        if (outputArr.length !== 0) {
          console.log('--------The output after it includes Diet code is------')
          return outputArr
        }
      }

      if (
        regexExpressionToCheckStringContainsDietCodeAR(finalResult) &&
        finalResult.includes('-')
      ) {
        const outputArr: R4.ICoding[] =
          regexExpressionToCheckStringContainsDietCodeARWithHifen(finalResult)
        if (outputArr.length !== 0) {
          return outputArr
        }
      }

      if (regexExpressionToCheckStringContainsDietCodeAR(finalResult)) {
        let outputArr: R4.ICoding[] = []
        if (finalResult.includes('\n'))
          outputArr =
            regexExpressionForStringThatContainsARWithNewLine(finalResult)
        else
          outputArr =
            regexExpressionForStringThatContainsARWithCommaSeparated(
              finalResult
            )

        return outputArr
      }

      const questionSecond = `Give me seven days unique diet code and diet name with meal time as ${duration}. Please give me diet code also.`

      if (duration === 'morning') setNoResultsForFiltersEarlyMorning(true)
      if (duration === 'breakfast or morning breakfast')
        setNoResultsForFiltersBreakfast(true)
      if (duration === 'morning snack or mid day snack')
        setNoResultsForFiltersMidMorning(true)
      if (duration === 'lunch or afternoon') setNoResultsForFiltersLunch(true)
      if (duration === 'evening snack') setNoResultsForFiltersEveningSnack(true)
      if (duration === 'dinner') setNoResultsForFiltersDinner(true)
      if (duration === 'night') setNoResultsForFiltersNight(true)

      console.log('---The second question is----')
      console.log(questionSecond)

      const secondDataToSend = {
        query: questionSecond,
      }

      try {
        const secondQuestionForDietEngine = JSON.stringify(secondDataToSend)
        const secondResponse: any = await doPostQuestionForDietEngineLlm(
          secondQuestionForDietEngine
        )
        const finalResultSecond = secondResponse.answer

        console.log('---The second answer is----')
        console.log(finalResultSecond)

        if (
          finalResultSecond.includes('Here are') ||
          finalResultSecond.includes('are:') ||
          finalResultSecond.includes('are as follows')
        ) {
          const outputArr: R4.ICoding[] =
            regexExpressionForStringWithHereAre(finalResultSecond)
          if (outputArr.length !== 0) return outputArr
        }

        if (finalResultSecond.includes('Diet code')) {
          const outputArr: R4.ICoding[] =
            regexExpressionFirst(finalResultSecond)
          if (outputArr.length !== 0) return outputArr
        }

        if (
          regexExpressionToCheckStringContainsDietCodeAR(finalResultSecond) &&
          finalResultSecond.includes('-')
        ) {
          const outputArr: R4.ICoding[] =
            regexExpressionToCheckStringContainsDietCodeARWithHifen(
              finalResultSecond
            )
          if (outputArr.length !== 0) return outputArr
        }

        if (regexExpressionToCheckStringContainsDietCodeAR(finalResultSecond)) {
          let outputArr: R4.ICoding[] = []
          if (finalResultSecond.includes('\n'))
            outputArr =
              regexExpressionForStringThatContainsARWithNewLine(
                finalResultSecond
              )
          else
            outputArr =
              regexExpressionForStringThatContainsARWithCommaSeparated(
                finalResultSecond
              )

          return outputArr
        }
      } catch (err) {
        console.log('---Printing the error---')
        console.log(err)
      }
    } catch (err) {
      console.log('---Printing the error---')
      console.log(err)
    }

    return []
  }

  async function getAnswerForEachMealTypeAccompaniment(): Promise<
    R4.ICoding[]
  > {
    const question = `Give me diet name like ${accompanimentDiet}. Give diet code also. Ignore case. Give the output as json array`

    console.log(
      '--------The question that is sent to llm for accompaniments is---------'
    )
    console.log(question)

    const dataToSend = {
      query: question,
    }

    try {
      const accompanimentQuestionForDietEngine = JSON.stringify(dataToSend)
      const response: any = await doPostQuestionForDietEngineLlm(
        accompanimentQuestionForDietEngine
      )
      const finalResult = response.answer

      if (
        finalResult.includes('No results') ||
        finalResult.includes('no results') ||
        finalResult.includes('No result') ||
        finalResult.includes('no result') ||
        finalResult.includes('SQLResult:') ||
        finalResult.includes('SQLQuery') ||
        finalResult.includes('no diet') ||
        finalResult.includes('no diets')
      ) {
        setNoResultsForAccompaniment(true)
        setAccompanimentValues([])
        return []
      }

      const cleanedArr: any[] = JSON.parse(finalResult.replace(/\\/g, ''))
      console.log('---The cleaned array is---')
      console.log(cleanedArr)

      if (cleanedArr.length === 0) setNoResultsForAccompaniment(true)

      let arr: DietPair[] = cleanedArr.map((item: any) => ({
        code: item.diet_code,
        name: item.diet_name,
      }))

      arr = arr.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (k: any) => k.code === value.code && k.name === value.name
          )
      )

      const arrWithSystem: any = []

      arr.map((item) => {
        arrWithSystem.push({
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
          code: item.code,
          display: item.name,
        })
      })

      return arrWithSystem
    } catch (err) {
      setNoResultsForAccompaniment(true)
      console.log('---Printing the error---')
      console.log(err)
    }

    return []
  }

  async function getAnswerForEachMealTypeAdditionalDiet(): Promise<
    R4.ICoding[]
  > {
    const question = `Give me diet name like ${additionalDiet}. Give diet code also. Ignore case. Give the output as json array`

    console.log(
      '--------The question that is sent to llm for additional diet is---------'
    )
    console.log(question)

    const dataToSend = {
      query: question,
    }

    try {
      const additionalDietQuestionForDietEngine = JSON.stringify(dataToSend)
      const response: any = await doPostQuestionForDietEngineLlm(
        additionalDietQuestionForDietEngine
      )
      const finalResult = response.answer

      console.log('---The response for additional diet is---')
      console.log(finalResult)

      if (
        finalResult.includes('No results') ||
        finalResult.includes('no results') ||
        finalResult.includes('No result') ||
        finalResult.includes('no result') ||
        finalResult.includes('SQLResult:') ||
        finalResult.includes('SQLQuery') ||
        finalResult.includes('no diet') ||
        finalResult.includes('no diets')
      ) {
        setNoResultsForAdditionalDiet(true)
        setAdditionalDietValues([])
        return []
      }

      const cleanedArr: any[] = JSON.parse(finalResult.replace(/\\/g, ''))
      if (cleanedArr.length === 0) setNoResultsForAdditionalDiet(true)

      let arr: DietPair[] = cleanedArr.map((item: any) => ({
        code: item.diet_code,
        name: item.diet_name,
      }))

      arr = arr.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (k: any) => k.code === value.code && k.name === value.name
          )
      )

      const arrWithSystem: any = []

      arr.map((item) => {
        arrWithSystem.push({
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-food-ontology-cs',
          code: item.code,
          display: item.name,
        })
      })

      return arrWithSystem
    } catch (err) {
      setNoResultsForAdditionalDiet(true)
      console.log('---Printing the error---')
      console.log(err)
    }

    return []
  }

  const [openAddDietDialogue, setOpenAddDietDialogue] = useState<boolean>(false)

  const [openAdditionalDietDialogue, setOpenAdditionalDietDialogue] =
    useState<boolean>(false)

  function addCurrentDetails(kriyasToUpload: LLMDietPlanOutPut[]) {
    if (kriyasToUpload && kriyasToUpload.length > 0) {
      console.log('---The diets to upload are----')
      console.log(kriyasToUpload)
      console.log('----------------- nos -----------------')
      console.log(
        getNutritionOrdersForDietBasedOnCodes(
          kriyasToUpload,
          encounterID,
          patient,
          dietTimeSearchSlice.prSelectedCoding ?? []
        )
      )
      dispatch(
        addDietDetails(
          encounterID,
          patient.id!,
          startDate,
          tillDate,
          undefined,
          undefined,
          getNutritionOrdersForDietBasedOnCodes(
            kriyasToUpload,
            encounterID,
            patient,
            dietTimeSearchSlice.prSelectedCoding ?? []
          ),
          undefined,
          undefined
        )
      )
    }
  }

  const addDietPlanSlice = useSelector(
    (state: RootState) => state.addDietPlanSlice
  )

  useEffect(() => {
    if (addDietPlanSlice.additionSuccessful) {
      onDietAddedForCarePlan()
    }
  }, [dispatch, addDietPlanSlice, onDietAddedForCarePlan])

  const dataForChartAfterEachDragDrop = (dragDropData: DisplayTableData) => {
    console.log('----Chart data after drag and drop')
    console.log(dragDropData)
    console.log('---------------')
    setChartDataAfterEachDragDrop(dragDropData)
  }

  const handleAccordionToggle = () => {
    if (expanded === true) {
      setExpanded(false)
    } else {
      setExpanded(true)
    }
  }

  function getResetCuisineTypes(data: CuisineTypes[]): CuisineTypes[] {
    const output: CuisineTypes[] = []
    data.map((item) => {
      output.push({
        id: item.id,
        checked: false,
        data: item.data,
      })
    })
    return output
  }

  function getResetCourseTypes(data: CourseTypes[]): CourseTypes[] {
    const output: CourseTypes[] = []
    data.map((item) => {
      output.push({
        id: item.id,
        checked: false,
        data: item.data,
      })
    })
    return output
  }

  function getResetEasyRestaurantTypes(
    data: EasyRestaurantRecipeTypes[]
  ): EasyRestaurantRecipeTypes[] {
    const output: EasyRestaurantRecipeTypes[] = []
    data.map((item) => {
      output.push({
        id: item.id,
        checked: false,
        data: item.data,
      })
    })
    return output
  }

  function getResetBatchCookingTypes(
    data: BatchCookingTypes[]
  ): BatchCookingTypes[] {
    const output: BatchCookingTypes[] = []
    data.map((item) => {
      output.push({
        id: item.id,
        checked: false,
        data: item.data,
      })
    })
    return output
  }

  function getResetEaseOfPreparationTypes(
    data: EaseOfPreparationTypes[]
  ): EaseOfPreparationTypes[] {
    const output: EaseOfPreparationTypes[] = []
    data.map((item) => {
      output.push({
        id: item.id,
        checked: false,
        data: item.data,
      })
    })
    return output
  }

  function resetFilters() {
    setCuisines(getResetCuisineTypes(cuisineTypes))
    setCourses(getResetCourseTypes(courseTypes))
    setEasyRestaurantRecipes(
      getResetEasyRestaurantTypes(easyRestaurantRecipeTypes)
    )
    setBatchCookings(getResetBatchCookingTypes(batchCookingTypes))
    setEaseOfPreparations(
      getResetEaseOfPreparationTypes(easeOfPreparationTypes)
    )

    setMorningDiets(getResetDietTypes(morningDietTypes))
    setBreakfastDiets(getResetDietTypes(breakfastDietTypes))
    setMidMorningDiets(getResetDietTypes(midMorningDietTypes))
    setLunchDiets(getResetDietTypes(lunchDietTypes))
    setEveningDiets(getResetDietTypes(eveningDietTypes))
    setDinnerDiets(getResetDietTypes(dinnerDietTypes))
    setNightDrinkDiets(getResetDietTypes(nightDrinkDietTypes))
  }

  useEffect(() => {
    resetFilters()
  }, [])

  return (
    <Dialog
      fullScreen={true}
      open={open}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>
        <Box display='flex' flexDirection='row'>
          <Box flexGrow={4}>Diet Plan</Box>
          <Box>
            <CloseOutlined
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                onClose()
              }}
              id='close_order'
            />
          </Box>
        </Box>
      </DialogTitle>
      {addDietPlanSlice.adding && (
        <DialogContent
          dividers={true}
          style={{
            backgroundColor: '#ededed',
            padding: 0,
            overflowWrap: 'normal',
            overscrollBehaviorX: 'none',
            margin: 0,
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            alignContent='center'
            justifyContent='center'
            py={1}
            px={1}
          >
            <Box
              display='flex'
              flexDirection='row'
              alignContent='center'
              justifyContent='center'
            >
              <CircularProgress size={25} />
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              alignContent='center'
              justifyContent='center'
            >
              <Typography variant='subtitle1' color='primary'>
                Adding Diet Plan
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      )}
      {!addDietPlanSlice.adding && (
        <DialogContent
          dividers={true}
          style={{
            backgroundColor: '#ededed',
            padding: 0,
            overflowWrap: 'normal',
            overscrollBehaviorX: 'none',
            margin: 0,
          }}
        >
          <Box display='flex' flexDirection='column' width='100%' py={1} px={1}>
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              paddingRight={1}
            >
              <Button>
                <Typography variant='subtitle1' color='primary'>
                  Meal Plans
                </Typography>
              </Button>
              <Button>
                <Typography variant='subtitle1' color='primary'>
                  Recipes Library
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='center'
            alignItems='center'
            width='100%'
            py={1}
            px={1}
          >
            <Box display='flex' flexDirection='row' width='90%'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    paddingRight={1}
                  >
                    <Accordion
                      expanded={expanded === true}
                      onChange={() => handleAccordionToggle()}
                    >
                      <AccordionSummary
                        aria-controls='panel1a-content'
                        id='lab_test'
                        expandIcon={<ExpandMore />}
                        className={classes.titleText}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='center'
                          width='100%'
                          height='100%'
                          alignContent='center'
                        >
                          <Typography
                            variant='subtitle1'
                            color='initial'
                            align='center'
                          >
                            Meal Filters
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          display='flex'
                          flexDirection='column'
                          // py={0.25}
                          width='100%'
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                justifyContent='flex-start'
                                paddingTop={0.5}
                                paddingBottom={1}
                                py={1}
                                paddingLeft={1.5}
                                paddingRight={1}
                                style={{
                                  backgroundColor: 'primary',
                                  cursor: 'pointer',
                                }}
                              >
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='btn_ord_cancel'
                                    color='primary'
                                    onClick={() => {
                                      setOpenCuisineAddDialogue(true)
                                    }}
                                    style={{ padding: 0 }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{ fontSize: 13 }}
                                    >
                                      Cuisines
                                    </Typography>
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                justifyContent='flex-start'
                                paddingTop={0.5}
                                paddingBottom={1}
                                py={1}
                                paddingLeft={1.5}
                                paddingRight={1}
                                style={{
                                  backgroundColor: 'primary',
                                  cursor: 'pointer',
                                }}
                              >
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='btn_ord_cancel'
                                    color='primary'
                                    onClick={() => {
                                      setOpenMealAndCourseAddDialogue(true)
                                    }}
                                    style={{ padding: 0 }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{ fontSize: 13 }}
                                    >
                                      Meal Type & Course
                                    </Typography>
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                justifyContent='flex-start'
                                paddingTop={0.5}
                                paddingBottom={1}
                                py={1}
                                paddingLeft={1.5}
                                style={{
                                  backgroundColor: 'primary',
                                  cursor: 'pointer',
                                }}
                              >
                                <Tooltip title=''>
                                  <IconButton
                                    aria-label='btn_ord_cancel'
                                    color='primary'
                                    onClick={() => {
                                      setOpenCategoriesAddDialogue(true)
                                    }}
                                    style={{ padding: 0 }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                      style={{ fontSize: 13 }}
                                    >
                                      Categories
                                    </Typography>
                                    {/* <AddCircleIcon
                                              style={{ height: '22px', padding: 0 }}
                                              color='primary'
                                          />{' '} */}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                paddingLeft={1}
                              >
                                <Box>
                                  {' '}
                                  <Typography
                                    variant='subtitle2'
                                    color='initial'
                                  >
                                    Start Date
                                  </Typography>
                                </Box>
                                <Box px={1}>
                                  {' '}
                                  <TextField
                                    variant='outlined'
                                    value={moment
                                      .utc(startDate)
                                      .local()
                                      .format('YYYY-MM-DD')}
                                    size='small'
                                    onChange={(val) => {
                                      setStartDate(new Date(val.target.value))
                                    }}
                                    type='date'
                                    inputProps={{
                                      min: moment
                                        .utc(getOnlyDate(new Date()))
                                        .local()
                                        .format('YYYY-MM-DD'),
                                      style: {
                                        paddingRight: '2px',
                                        textAlign: 'center',
                                        paddingLeft: '2px',
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                paddingLeft={1}
                              >
                                <Box>
                                  {' '}
                                  <Typography
                                    variant='subtitle2'
                                    color='initial'
                                  >
                                    End Date
                                  </Typography>
                                </Box>
                                <Box px={1}>
                                  {' '}
                                  <TextField
                                    variant='outlined'
                                    value={moment
                                      .utc(tillDate)
                                      .local()
                                      .format('YYYY-MM-DD')}
                                    size='small'
                                    onChange={(val) => {
                                      setTillDate(new Date(val.target.value))
                                    }}
                                    type='date'
                                    inputProps={{
                                      min: moment
                                        .utc(startDate)
                                        .local()
                                        .format('YYYY-MM-DD'),
                                      style: {
                                        paddingRight: '2px',
                                        textAlign: 'center',
                                        paddingLeft: '2px',
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                justifyContent='flex-end'
                                width='100%'
                                paddingRight={1}
                              >
                                <Button
                                  variant='contained'
                                  color='primary'
                                  disabled={!dietTableSearch}
                                  onClick={onSearchClicked}
                                >
                                  Search
                                </Button>
                              </Box>
                            </Grid>
                            {displayOptionsSelected && (
                              <Grid item xs={12}>
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  paddingRight={1}
                                >
                                  <TableContainer
                                    // component={Paper}
                                    style={{
                                      width: '100%',
                                      margin: '0px',
                                      padding: '0px',
                                    }}
                                  >
                                    <Table
                                      className={classes.tableCategories}
                                      aria-label='simple table'
                                    >
                                      <TableHead
                                        style={{
                                          backgroundColor: kDialogueBackground,
                                        }}
                                      >
                                        <TableRow
                                          style={{
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          <TableCell style={cellSx}>
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Cuisines
                                            </Typography>
                                          </TableCell>
                                          <TableCell style={cellSx}>
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Courses
                                            </Typography>
                                          </TableCell>
                                          <TableCell style={cellSx}>
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Ease of Preparation
                                            </Typography>
                                          </TableCell>
                                          <TableCell style={cellSx}>
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,
                                                color: 'black',

                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Batch Cooking
                                            </Typography>
                                          </TableCell>
                                          <TableCell style={cellSx}>
                                            <Typography
                                              variant='subtitle2'
                                              // color='initial'
                                              style={{
                                                color: 'black',
                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              Easy Restaurant Recipe
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow key='1'>
                                          <TableCell style={cellSx}>
                                            {displayCuisineCheckbox && (
                                              <Box
                                                display='flex'
                                                flexDirection='row'
                                                justifyContent='center'
                                                alignItems='center'
                                                width='100%'
                                                py={1}
                                                px={1}
                                              >
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                  paddingRight={1}
                                                >
                                                  {cuisines.map(
                                                    (e: any, index: any) => (
                                                      <Box
                                                        display='flex'
                                                        flexDirection='row'
                                                        key='1'
                                                      >
                                                        {e.checked && (
                                                          <Box>
                                                            <Typography
                                                              variant='subtitle2'
                                                              color='initial'
                                                            >
                                                              {e.data}
                                                            </Typography>
                                                          </Box>
                                                        )}
                                                      </Box>
                                                    )
                                                  )}
                                                </Box>
                                              </Box>
                                            )}
                                          </TableCell>
                                          <TableCell style={cellSx}>
                                            {displayCourseCheckbox && (
                                              <Box
                                                display='flex'
                                                flexDirection='row'
                                                justifyContent='center'
                                                alignItems='center'
                                                width='100%'
                                                py={1}
                                                px={1}
                                              >
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                  paddingRight={1}
                                                >
                                                  {courses.map(
                                                    (e: any, index: any) => (
                                                      <Box
                                                        display='flex'
                                                        flexDirection='row'
                                                        key='1'
                                                      >
                                                        {e.checked && (
                                                          <Box>
                                                            <Typography
                                                              variant='subtitle2'
                                                              color='initial'
                                                            >
                                                              {e.data}
                                                            </Typography>
                                                          </Box>
                                                        )}
                                                      </Box>
                                                    )
                                                  )}
                                                </Box>
                                              </Box>
                                            )}
                                          </TableCell>
                                          <TableCell style={cellSx}>
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              justifyContent='center'
                                              alignItems='center'
                                              width='100%'
                                              py={1}
                                              px={1}
                                            >
                                              {displayPreparationCookingEasyRecipeCheckbox && (
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                  paddingRight={1}
                                                >
                                                  {easeOfPreparations.map(
                                                    (e: any, index: any) => (
                                                      <Box
                                                        display='flex'
                                                        flexDirection='row'
                                                        key='1'
                                                      >
                                                        {e.checked && (
                                                          <Box>
                                                            <Typography
                                                              variant='subtitle2'
                                                              color='initial'
                                                            >
                                                              {e.data}
                                                            </Typography>
                                                          </Box>
                                                        )}
                                                      </Box>
                                                    )
                                                  )}
                                                </Box>
                                              )}
                                            </Box>
                                          </TableCell>
                                          <TableCell style={cellSx}>
                                            {displayPreparationCookingEasyRecipeCheckbox && (
                                              <Box
                                                display='flex'
                                                flexDirection='row'
                                                justifyContent='center'
                                                alignItems='center'
                                                width='100%'
                                                py={1}
                                                px={1}
                                              >
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                  paddingRight={1}
                                                >
                                                  {batchCookings.map(
                                                    (e: any, index: any) => (
                                                      <Box
                                                        display='flex'
                                                        flexDirection='row'
                                                        key='1'
                                                      >
                                                        {e.checked && (
                                                          <Box>
                                                            <Typography
                                                              variant='subtitle2'
                                                              color='initial'
                                                            >
                                                              {e.data}
                                                            </Typography>
                                                          </Box>
                                                        )}
                                                      </Box>
                                                    )
                                                  )}
                                                </Box>
                                              </Box>
                                            )}
                                          </TableCell>
                                          <TableCell style={cellSx}>
                                            {displayPreparationCookingEasyRecipeCheckbox && (
                                              <Box
                                                display='flex'
                                                flexDirection='row'
                                                justifyContent='center'
                                                alignItems='center'
                                                width='100%'
                                                py={1}
                                                px={1}
                                              >
                                                <Box
                                                  display='flex'
                                                  flexDirection='column'
                                                  width='100%'
                                                  paddingRight={1}
                                                >
                                                  {easyRestaurantRecipes.map(
                                                    (e: any, index: any) => (
                                                      <Box
                                                        display='flex'
                                                        flexDirection='row'
                                                        key='1'
                                                      >
                                                        {e.checked && (
                                                          <Box>
                                                            <Typography
                                                              variant='subtitle2'
                                                              color='initial'
                                                            >
                                                              {e.data}
                                                            </Typography>
                                                          </Box>
                                                        )}
                                                      </Box>
                                                    )
                                                  )}
                                                </Box>
                                              </Box>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {fetchAllTableDetails && (
            <WelloLoadingIndicator message='Waiting for Meal planner' />
          )}

          {!fetchAllTableDetails && mealWidgetDisplay === true && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              paddingTop={4}
              px={1}
              style={{ width: '100%', height: '40px' }}
            >
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                width='90%'
                paddingRight={1}
              >
                <Box
                  sx={{ width: '100%' }}
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  flexDirection='row'
                  bgcolor={kPrimaryMain}
                >
                  <Box px={30} borderRadius={12} bgcolor={kPrimaryMain}>
                    <Stepper
                      nonLinear
                      activeStep={activeStep}
                      style={{ borderRadius: 3, backgroundColor: kPrimaryMain }}
                      connector={<CustomConnector />}
                    >
                      {steps.map((label, index) => (
                        <Step
                          key={label}
                          completed={completed[index]}
                          style={{ marginLeft: index !== 0 ? '70px' : '0' }}
                        >
                          <StepButton
                            color='red'
                            onClick={handleStep(index)}
                            id={`${label}${index}`}
                          >
                            <Typography
                              variant='subtitle2'
                              style={{
                                textTransform: 'uppercase',
                                color: paperColor,
                              }}
                            >
                              {t('labelCommon:' + `${label}`)}
                              {/* {t('labelCommon:view_profile')} */}
                            </Typography>
                          </StepButton>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {activeStep === 0 && displayTable && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              width='100%'
              px={1}
              paddingTop={5}
            >
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                width='90%'
                paddingRight={1}
              >
                <TableContainer
                  component={Paper}
                  style={{
                    width: '100%',
                    margin: '0px',
                    padding: '0px',
                  }}
                >
                  <Table className={classes.table} aria-label='simple table'>
                    <TableHead
                      style={{
                        backgroundColor: kDialogueBackground,
                      }}
                    >
                      <TableRow
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {earlyMorningChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              5:00-5:30 AM (Morning)
                            </Typography>
                          </TableCell>
                        )}
                        {earlyMorningTwoChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              5:30-6:00 AM (Morning)
                            </Typography>
                          </TableCell>
                        )}
                        {earlyMorningThreeChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              align='center'
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              6:00-6:30 AM (Morning)
                            </Typography>
                          </TableCell>
                        )}
                        {earlyMorningFourChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              6:30-7:00 AM (Morning)
                            </Typography>
                          </TableCell>
                        )}
                        {earlyMorningFiveChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              7:00-7:30 AM (Morning)
                            </Typography>
                          </TableCell>
                        )}

                        {breakfastChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              7:30-8:00 AM (Breakfast)
                            </Typography>
                          </TableCell>
                        )}
                        {breakfastTwoChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              8:00-8:30 AM (Breakfast)
                            </Typography>
                          </TableCell>
                        )}
                        {breakfastThreeChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              8:30-9:00 AM (Breakfast)
                            </Typography>
                          </TableCell>
                        )}
                        {breakfastFourChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              9:00-9:30 AM (Breakfast)
                            </Typography>
                          </TableCell>
                        )}

                        {morningSnackChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              9:30-10:00 AM (Mid Morning Snack)
                            </Typography>
                          </TableCell>
                        )}
                        {morningSnackTwoChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              10:00-10:30 AM (Mid Morning Snack)
                            </Typography>
                          </TableCell>
                        )}
                        {morningSnackThreeChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              10:30-11:00 AM (Mid Morning Snack)
                            </Typography>
                          </TableCell>
                        )}
                        {morningSnackFourChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              11:00-11:30 AM (Mid Morning Snack)
                            </Typography>
                          </TableCell>
                        )}

                        {lunchChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              11:30-12:00 PM (Lunch)
                            </Typography>
                          </TableCell>
                        )}
                        {lunchTwoChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              12:00-12:30 PM (Lunch)
                            </Typography>
                          </TableCell>
                        )}
                        {lunchThreeChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              12:30-1:00 PM (Lunch)
                            </Typography>
                          </TableCell>
                        )}
                        {lunchFourChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              1:00-1:30 PM (Lunch)
                            </Typography>
                          </TableCell>
                        )}
                        {lunchFiveChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              1:30-2:00 PM (Lunch)
                            </Typography>
                          </TableCell>
                        )}
                        {lunchSixChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              2:00-2:30 PM (Lunch)
                            </Typography>
                          </TableCell>
                        )}
                        {lunchSevenChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              2:30-3:00 PM (Lunch)
                            </Typography>
                          </TableCell>
                        )}

                        {eveningSnackChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              3:00-3:30 PM (Evening Snack)
                            </Typography>
                          </TableCell>
                        )}
                        {eveningSnackTwoChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              3:30-4:00 PM (Evening Snack)
                            </Typography>
                          </TableCell>
                        )}
                        {eveningSnackThreeChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              4:00-4:30 PM (Evening Snack)
                            </Typography>
                          </TableCell>
                        )}
                        {eveningSnackFourChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              4:30-5:00 PM (Evening Snack)
                            </Typography>
                          </TableCell>
                        )}
                        {eveningSnackFiveChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              5:00-5:30 PM (Evening Snack)
                            </Typography>
                          </TableCell>
                        )}
                        {eveningSnackSixChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              5:30-6:00 PM (Evening Snack)
                            </Typography>
                          </TableCell>
                        )}
                        {eveningSnackSevenChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              6:00-6:30 PM (Evening Snack)
                            </Typography>
                          </TableCell>
                        )}

                        {dinnerChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              6:30-7:00 PM (Dinner)
                            </Typography>
                          </TableCell>
                        )}
                        {dinnerTwoChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              7:00-7:30 PM (Dinner)
                            </Typography>
                          </TableCell>
                        )}
                        {dinnerThreeChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              7:30-8:00 PM (Dinner)
                            </Typography>
                          </TableCell>
                        )}
                        {dinnerFourChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              8:00-8:30 PM (Dinner)
                            </Typography>
                          </TableCell>
                        )}
                        {dinnerFiveChecked && (
                          <TableCell style={cellSx}>
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              8:30-9:00 PM (Dinner)
                            </Typography>
                          </TableCell>
                        )}

                        {nightDrinkChecked && (
                          <TableCell style={cellSx}>
                            {' '}
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              9:00-9:30 PM (Night Drink)
                            </Typography>
                          </TableCell>
                        )}
                        {nightDrinkTwoChecked && (
                          <TableCell style={cellSx}>
                            {' '}
                            <Typography
                              variant='subtitle2'
                              align='center'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              9:30-10:00 PM (Night Drink)
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key='1'>
                        {(earlyMorningChecked ||
                          earlyMorningTwoChecked ||
                          earlyMorningThreeChecked ||
                          earlyMorningFourChecked ||
                          earlyMorningFiveChecked) && (
                          <TableCell style={cellSx}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              justifyContent='center'
                              alignItems='center'
                              width='100%'
                              py={1}
                              px={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                paddingRight={1}
                              >
                                {noResultsForFiltersEarlyMorning &&
                                  earlyMorningDietItemsWithCheck.length !==
                                    0 && (
                                    <Box paddingLeft={2} paddingBottom={2}>
                                      <Typography>
                                        No results found with chosen filters.
                                        Giving unique early morning diets
                                        instead.
                                      </Typography>
                                    </Box>
                                  )}
                                {earlyMorningDietItemsWithCheck.length ===
                                  0 && (
                                  <Box padding={1}>
                                    No results found for this meal time. Please
                                    adjust your filters and try again, or click
                                    'Add More' to add recipes manually without
                                    using filters
                                  </Box>
                                )}
                                {earlyMorningDietItemsWithCheck.map(
                                  (e: any, earlyMorningDietIndex: any) => (
                                    <Grid
                                      key='early_morning_diet'
                                      container
                                      spacing={2}
                                    >
                                      <Grid item xs={1}>
                                        <Box>
                                          <Checkbox
                                            edge='start'
                                            checked={e.checked}
                                            onChange={(event) => {
                                              handleChangeFinalEarlyMorningValues(
                                                event.target.checked,
                                                e,
                                                earlyMorningDietIndex
                                              )
                                            }}
                                            style={{
                                              paddingTop: 0,
                                            }}
                                            tabIndex={0}
                                            size='small'
                                            color='primary'
                                            id={`left${earlyMorningDietIndex}`}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Box>
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                          >
                                            {e.dietWithAccompaniment}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box>
                                          <Tooltip
                                            title='Edit'
                                            id='chief_tool_edit'
                                          >
                                            <IconButton
                                              aria-label='btn_ord_cancel'
                                              color='primary'
                                              id='vitals_tool_edit_button'
                                              style={{ padding: 0 }}
                                              disabled={e.data.length === 1}
                                              onClick={() => {
                                                handleEditDiet(
                                                  'morning',
                                                  earlyMorningDietIndex
                                                )
                                              }}
                                            >
                                              <img
                                                id='vital_tool_edit_img'
                                                src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                alt='Edit'
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box paddingRight={2}>
                                          <Tooltip
                                            title='Add Accompaniment'
                                            id='proc_1'
                                          >
                                            <IconButton
                                              color='primary'
                                              id='proc_2'
                                              onClick={() => {
                                                setAccompanimentDietMealType(
                                                  'morning'
                                                )
                                                setAccompanimentDietIndex(
                                                  earlyMorningDietIndex
                                                )
                                                setOpenAddDietDialogue(true)
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              <AddCircleIcon
                                                style={{
                                                  height: '22px',
                                                  padding: 0,
                                                }}
                                                color='primary'
                                                id='proc_3'
                                              />{' '}
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                                <Box>
                                  <Button
                                    variant='text'
                                    size='small'
                                    onClick={() => {
                                      setAdditionalDietMealType('morning')
                                      setOpenAdditionalDietDialogue(true)
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                    >
                                      {' '}
                                      + Add More
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        )}

                        {(breakfastChecked ||
                          breakfastTwoChecked ||
                          breakfastThreeChecked ||
                          breakfastFourChecked) && (
                          <TableCell style={cellSx}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              justifyContent='center'
                              alignItems='center'
                              width='100%'
                              py={1}
                              px={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                paddingRight={1}
                              >
                                {noResultsForFiltersBreakfast &&
                                  breakfastDietItemsWithCheck.length !== 0 && (
                                    <Box paddingLeft={2} paddingBottom={2}>
                                      <Typography>
                                        No results found with chosen filters.
                                        Giving unique breakfast diets instead.
                                      </Typography>
                                    </Box>
                                  )}
                                {breakfastDietItemsWithCheck.length === 0 && (
                                  <Box padding={1}>
                                    No results found for this meal time. Please
                                    adjust your filters and try again, or click
                                    'Add More' to add recipes manually without
                                    using filters
                                  </Box>
                                )}
                                {breakfastDietItemsWithCheck.map(
                                  (e: any, breakfastDietIndex: any) => (
                                    <Grid
                                      key='breakfast_diet'
                                      container
                                      spacing={2}
                                    >
                                      <Grid item xs={1}>
                                        <Box>
                                          <Checkbox
                                            edge='start'
                                            checked={e.checked}
                                            onChange={(event) => {
                                              handleChangeFinalBreakfastValues(
                                                event.target.checked,
                                                e,
                                                breakfastDietIndex
                                              )
                                            }}
                                            style={{
                                              paddingTop: 0,
                                            }}
                                            tabIndex={0}
                                            size='small'
                                            color='primary'
                                            id={`left${breakfastDietIndex}`}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Box>
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                          >
                                            {e.dietWithAccompaniment}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box>
                                          <Tooltip
                                            title='Edit'
                                            id='chief_tool_edit'
                                          >
                                            <IconButton
                                              aria-label='btn_ord_cancel'
                                              color='primary'
                                              id='vitals_tool_edit_button'
                                              style={{ padding: 0 }}
                                              disabled={e.data.length === 1}
                                              onClick={() => {
                                                handleEditDiet(
                                                  'breakfast',
                                                  breakfastDietIndex
                                                )
                                              }}
                                            >
                                              <img
                                                id='vital_tool_edit_img'
                                                src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                alt='Edit'
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box
                                          paddingRight={
                                            columnsCount > 5 ? 8 : 2
                                          }
                                        >
                                          <Tooltip
                                            title='Add Accompaniment'
                                            id='proc_1'
                                          >
                                            <IconButton
                                              color='primary'
                                              id='proc_2'
                                              onClick={() => {
                                                setAccompanimentDietMealType(
                                                  'breakfast'
                                                )
                                                setAccompanimentDietIndex(
                                                  breakfastDietIndex
                                                )
                                                setOpenAddDietDialogue(true)
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              <AddCircleIcon
                                                style={{
                                                  height: '22px',
                                                  padding: 0,
                                                }}
                                                color='primary'
                                                id='proc_3'
                                              />{' '}
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                                <Box>
                                  <Button
                                    variant='text'
                                    size='small'
                                    onClick={() => {
                                      setAdditionalDietMealType('breakfast')
                                      setOpenAdditionalDietDialogue(true)
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                    >
                                      {' '}
                                      + Add More
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        )}

                        {(morningSnackChecked ||
                          morningSnackTwoChecked ||
                          morningSnackThreeChecked ||
                          morningSnackFourChecked) && (
                          <TableCell style={cellSx}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              justifyContent='center'
                              alignItems='center'
                              width='100%'
                              py={1}
                              px={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                paddingRight={1}
                              >
                                {noResultsForFiltersMidMorning &&
                                  midMorningDietItemsWithCheck.length !== 0 && (
                                    <Box paddingLeft={2} paddingBottom={2}>
                                      <Typography>
                                        No results found with chosen filters.
                                        Giving unique mid morning diets instead.
                                      </Typography>
                                    </Box>
                                  )}
                                {midMorningDietItemsWithCheck.length === 0 && (
                                  <Box padding={1}>
                                    No results found for this meal time. Please
                                    adjust your filters and try again, or click
                                    'Add More' to add recipes manually without
                                    using filters
                                  </Box>
                                )}
                                {midMorningDietItemsWithCheck.map(
                                  (e: any, midMorningDietIndex: any) => (
                                    <Grid
                                      key='mid_morning_diet'
                                      container
                                      spacing={2}
                                    >
                                      <Grid item xs={1}>
                                        <Box>
                                          <Checkbox
                                            edge='start'
                                            checked={e.checked}
                                            onChange={(event) => {
                                              handleChangeFinalMidMorningValues(
                                                event.target.checked,
                                                e,
                                                midMorningDietIndex
                                              )
                                            }}
                                            style={{
                                              paddingTop: 0,
                                            }}
                                            tabIndex={0}
                                            size='small'
                                            color='primary'
                                            id={`left${midMorningDietIndex}`}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Box>
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                          >
                                            {e.dietWithAccompaniment}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box>
                                          <Tooltip
                                            title='Edit'
                                            id='chief_tool_edit'
                                          >
                                            <IconButton
                                              aria-label='btn_ord_cancel'
                                              color='primary'
                                              id='vitals_tool_edit_button'
                                              style={{ padding: 0 }}
                                              disabled={e.data.length === 1}
                                              onClick={() => {
                                                handleEditDiet(
                                                  'morning snack',
                                                  midMorningDietIndex
                                                )
                                              }}
                                            >
                                              <img
                                                id='vital_tool_edit_img'
                                                src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                alt='Edit'
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box paddingRight={2}>
                                          <Tooltip
                                            title='Add Accompaniment'
                                            id='proc_1'
                                          >
                                            <IconButton
                                              color='primary'
                                              id='proc_2'
                                              onClick={() => {
                                                setAccompanimentDietMealType(
                                                  'morning snack'
                                                )
                                                setAccompanimentDietIndex(
                                                  midMorningDietIndex
                                                )
                                                setOpenAddDietDialogue(true)
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              <AddCircleIcon
                                                style={{
                                                  height: '22px',
                                                  padding: 0,
                                                }}
                                                color='primary'
                                                id='proc_3'
                                              />{' '}
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                                <Box>
                                  <Button
                                    variant='text'
                                    size='small'
                                    onClick={() => {
                                      setAdditionalDietMealType('morning snack')
                                      setOpenAdditionalDietDialogue(true)
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                    >
                                      {' '}
                                      + Add More
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        )}

                        {(lunchChecked ||
                          lunchTwoChecked ||
                          lunchThreeChecked ||
                          lunchFourChecked ||
                          lunchFiveChecked ||
                          lunchSixChecked ||
                          lunchSevenChecked) && (
                          <TableCell style={cellSx}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              justifyContent='center'
                              alignItems='center'
                              width='100%'
                              py={1}
                              px={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                paddingRight={1}
                              >
                                {noResultsForFiltersLunch &&
                                  lunchDietItemsWithCheck.length !== 0 && (
                                    <Box paddingLeft={2} paddingBottom={2}>
                                      <Typography>
                                        No results found with chosen filters.
                                        Giving unique lunch diets instead.
                                      </Typography>
                                    </Box>
                                  )}
                                {lunchDietItemsWithCheck.length === 0 && (
                                  <Box padding={1}>
                                    No results found for this meal time. Please
                                    adjust your filters and try again, or click
                                    'Add More' to add recipes manually without
                                    using filters
                                  </Box>
                                )}
                                {lunchDietItemsWithCheck.map(
                                  (e: any, lunchDietIndex: any) => (
                                    <Grid
                                      key='lunch_diet'
                                      container
                                      spacing={2}
                                    >
                                      <Grid item xs={1}>
                                        <Box>
                                          <Checkbox
                                            edge='start'
                                            checked={e.checked}
                                            onChange={(event) => {
                                              handleChangeFinalLunchValues(
                                                event.target.checked,
                                                e,
                                                lunchDietIndex
                                              )
                                            }}
                                            style={{
                                              paddingTop: 0,
                                            }}
                                            tabIndex={0}
                                            size='small'
                                            color='primary'
                                            id={`left${lunchDietIndex}`}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Box>
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                          >
                                            {e.dietWithAccompaniment}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box>
                                          <Tooltip
                                            title='Edit'
                                            id='chief_tool_edit'
                                          >
                                            <IconButton
                                              aria-label='btn_ord_cancel'
                                              color='primary'
                                              id='vitals_tool_edit_button'
                                              style={{ padding: 0 }}
                                              disabled={e.data.length === 1}
                                              onClick={() => {
                                                handleEditDiet(
                                                  'lunch',
                                                  lunchDietIndex
                                                )
                                              }}
                                            >
                                              <img
                                                id='vital_tool_edit_img'
                                                src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                alt='Edit'
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box paddingRight={2}>
                                          <Tooltip
                                            title='Add Accompaniment'
                                            id='proc_1'
                                          >
                                            <IconButton
                                              color='primary'
                                              id='proc_2'
                                              onClick={() => {
                                                setAccompanimentDietMealType(
                                                  'lunch'
                                                )
                                                setAccompanimentDietIndex(
                                                  lunchDietIndex
                                                )
                                                setOpenAddDietDialogue(true)
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              <AddCircleIcon
                                                style={{
                                                  height: '22px',
                                                  padding: 0,
                                                }}
                                                color='primary'
                                                id='proc_3'
                                              />{' '}
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                                <Box>
                                  <Button
                                    variant='text'
                                    size='small'
                                    onClick={() => {
                                      setAdditionalDietMealType('lunch')
                                      setOpenAdditionalDietDialogue(true)
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                    >
                                      {' '}
                                      + Add More
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        )}

                        {(eveningSnackChecked ||
                          eveningSnackTwoChecked ||
                          eveningSnackThreeChecked ||
                          eveningSnackFourChecked ||
                          eveningSnackFiveChecked ||
                          eveningSnackSixChecked ||
                          eveningSnackSevenChecked) && (
                          <TableCell style={cellSx}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              justifyContent='center'
                              alignItems='center'
                              width='100%'
                              py={1}
                              px={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                paddingRight={1}
                              >
                                {noResultsForFiltersEveningSnack &&
                                  eveningDietItemsWithCheck.length !== 0 && (
                                    <Box paddingLeft={2} paddingBottom={2}>
                                      <Typography>
                                        No results found with chosen filters.
                                        Giving unique evening diets instead.
                                      </Typography>
                                    </Box>
                                  )}
                                {eveningDietItemsWithCheck.length === 0 && (
                                  <Box padding={1}>
                                    No results found for this meal time. Please
                                    adjust your filters and try again, or click
                                    'Add More' to add recipes manually without
                                    using filters
                                  </Box>
                                )}
                                {eveningDietItemsWithCheck.map(
                                  (e: any, eveningDietIndex: any) => (
                                    <Grid
                                      key='evening_diet'
                                      container
                                      spacing={2}
                                    >
                                      <Grid item xs={1}>
                                        <Box>
                                          <Checkbox
                                            edge='start'
                                            checked={e.checked}
                                            onChange={(event) => {
                                              handleChangeFinalEveningSnackValues(
                                                event.target.checked,
                                                e,
                                                eveningDietIndex
                                              )
                                            }}
                                            style={{
                                              paddingTop: 0,
                                            }}
                                            tabIndex={0}
                                            size='small'
                                            color='primary'
                                            id={`left${eveningDietIndex}`}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Box>
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                          >
                                            {e.dietWithAccompaniment}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box>
                                          <Tooltip
                                            title='Edit'
                                            id='chief_tool_edit'
                                          >
                                            <IconButton
                                              aria-label='btn_ord_cancel'
                                              color='primary'
                                              id='vitals_tool_edit_button'
                                              style={{ padding: 0 }}
                                              disabled={e.data.length === 1}
                                              onClick={() => {
                                                handleEditDiet(
                                                  'evening snack',
                                                  eveningDietIndex
                                                )
                                              }}
                                            >
                                              <img
                                                id='vital_tool_edit_img'
                                                src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                alt='Edit'
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box paddingRight={2}>
                                          <Tooltip
                                            title='Add Accompaniment'
                                            id='proc_1'
                                          >
                                            <IconButton
                                              color='primary'
                                              id='proc_2'
                                              onClick={() => {
                                                setAccompanimentDietMealType(
                                                  'evening snack'
                                                )
                                                setAccompanimentDietIndex(
                                                  eveningDietIndex
                                                )
                                                setOpenAddDietDialogue(true)
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              <AddCircleIcon
                                                style={{
                                                  height: '22px',
                                                  padding: 0,
                                                }}
                                                color='primary'
                                                id='proc_3'
                                              />{' '}
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                                <Box>
                                  <Button
                                    variant='text'
                                    size='small'
                                    onClick={() => {
                                      setAdditionalDietMealType('evening snack')
                                      setOpenAdditionalDietDialogue(true)
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                    >
                                      {' '}
                                      + Add More
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        )}

                        {(dinnerChecked ||
                          dinnerTwoChecked ||
                          dinnerThreeChecked ||
                          dinnerFourChecked ||
                          dinnerFiveChecked) && (
                          <TableCell style={cellSx}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              justifyContent='center'
                              alignItems='center'
                              width='100%'
                              py={1}
                              px={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                paddingRight={1}
                              >
                                {noResultsForFiltersDinner &&
                                  dinnerDietItemsWithCheck.length !== 0 && (
                                    <Box paddingLeft={2} paddingBottom={2}>
                                      <Typography>
                                        No results found with chosen filters.
                                        Giving unique dinner diets instead.
                                      </Typography>
                                    </Box>
                                  )}
                                {dinnerDietItemsWithCheck.length === 0 && (
                                  <Box padding={1}>
                                    No results found for this meal time. Please
                                    adjust your filters and try again, or click
                                    'Add More' to add recipes manually without
                                    using filters
                                  </Box>
                                )}
                                {dinnerDietItemsWithCheck.map(
                                  (e: any, dinnerDietIndex: any) => (
                                    <Grid
                                      key='dinner_diet'
                                      container
                                      spacing={2}
                                    >
                                      <Grid item xs={1}>
                                        <Box>
                                          <Checkbox
                                            edge='start'
                                            checked={e.checked}
                                            onChange={(event) => {
                                              handleChangeFinalDinnerValues(
                                                event.target.checked,
                                                e,
                                                dinnerDietIndex
                                              )
                                            }}
                                            style={{
                                              paddingTop: 0,
                                            }}
                                            tabIndex={0}
                                            size='small'
                                            color='primary'
                                            id={`left${dinnerDietIndex}`}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Box>
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                          >
                                            {e.dietWithAccompaniment}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box>
                                          <Tooltip
                                            title='Edit'
                                            id='chief_tool_edit'
                                          >
                                            <IconButton
                                              aria-label='btn_ord_cancel'
                                              color='primary'
                                              id='vitals_tool_edit_button'
                                              style={{ padding: 0 }}
                                              disabled={e.data.length === 1}
                                              onClick={() => {
                                                handleEditDiet(
                                                  'dinner',
                                                  dinnerDietIndex
                                                )
                                              }}
                                            >
                                              <img
                                                id='vital_tool_edit_img'
                                                src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                alt='Edit'
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box paddingRight={2}>
                                          <Tooltip
                                            title='Add Accompaniment'
                                            id='proc_1'
                                          >
                                            <IconButton
                                              color='primary'
                                              id='proc_2'
                                              onClick={() => {
                                                setAccompanimentDietMealType(
                                                  'dinner'
                                                )
                                                setAccompanimentDietIndex(
                                                  dinnerDietIndex
                                                )
                                                setOpenAddDietDialogue(true)
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              <AddCircleIcon
                                                style={{
                                                  height: '22px',
                                                  padding: 0,
                                                }}
                                                color='primary'
                                                id='proc_3'
                                              />{' '}
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                                <Box>
                                  <Button
                                    variant='text'
                                    size='small'
                                    onClick={() => {
                                      setAdditionalDietMealType('dinner')
                                      setOpenAdditionalDietDialogue(true)
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                    >
                                      {' '}
                                      + Add More
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        )}

                        {(nightDrinkChecked || nightDrinkTwoChecked) && (
                          <TableCell style={cellSx}>
                            <Box
                              display='flex'
                              flexDirection='row'
                              justifyContent='center'
                              alignItems='center'
                              width='100%'
                              py={1}
                              px={1}
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                                paddingRight={1}
                              >
                                {noResultsForFiltersNight &&
                                  nightDietItemsWithCheck.length !== 0 && (
                                    <Box paddingLeft={2} paddingBottom={2}>
                                      <Typography>
                                        No results found with chosen filters.
                                        Giving unique night diets instead.
                                      </Typography>
                                    </Box>
                                  )}
                                {nightDietItemsWithCheck.length === 0 && (
                                  <Box padding={2}>
                                    No results found for this meal time. Please
                                    adjust your filters and try again, or click
                                    'Add More' to add recipes manually without
                                    using filters
                                  </Box>
                                )}
                                {nightDietItemsWithCheck.map(
                                  (e: any, nightDietIndex: any) => (
                                    <Grid
                                      key='night_diet'
                                      container
                                      spacing={2}
                                    >
                                      <Grid item xs={1}>
                                        <Box>
                                          <Checkbox
                                            edge='start'
                                            checked={e.checked}
                                            onChange={(event) => {
                                              handleChangeFinalBedTimeValues(
                                                event.target.checked,
                                                e,
                                                nightDietIndex
                                              )
                                            }}
                                            style={{
                                              paddingTop: 0,
                                            }}
                                            tabIndex={0}
                                            size='small'
                                            color='primary'
                                            id={`left${nightDietIndex}`}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Box>
                                          <Typography
                                            variant='subtitle2'
                                            color='initial'
                                          >
                                            {e.dietWithAccompaniment}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box>
                                          <Tooltip
                                            title='Edit'
                                            id='chief_tool_edit'
                                          >
                                            <IconButton
                                              aria-label='btn_ord_cancel'
                                              color='primary'
                                              id='vitals_tool_edit_button'
                                              style={{ padding: 0 }}
                                              disabled={e.data.length === 1}
                                              onClick={() => {
                                                handleEditDiet(
                                                  'night drink',
                                                  nightDietIndex
                                                )
                                              }}
                                            >
                                              <img
                                                id='vital_tool_edit_img'
                                                src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                alt='Edit'
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Box paddingRight={2}>
                                          <Tooltip
                                            title='Add Accompaniment'
                                            id='proc_1'
                                          >
                                            <IconButton
                                              color='primary'
                                              id='proc_2'
                                              onClick={() => {
                                                setAccompanimentDietMealType(
                                                  'night drink'
                                                )
                                                setAccompanimentDietIndex(
                                                  nightDietIndex
                                                )
                                                setOpenAddDietDialogue(true)
                                              }}
                                              style={{ padding: 0 }}
                                            >
                                              <AddCircleIcon
                                                style={{
                                                  height: '22px',
                                                  padding: 0,
                                                }}
                                                color='primary'
                                                id='proc_3'
                                              />{' '}
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                                <Box>
                                  <Button
                                    variant='text'
                                    size='small'
                                    onClick={() => {
                                      setAdditionalDietMealType('night drink')
                                      setOpenAdditionalDietDialogue(true)
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='primary'
                                    >
                                      {' '}
                                      + Add More
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}

          {activeStep === 0 && displayTable && (
            <Box
              display='flex'
              justifyContent='right'
              alignItems='right'
              width='100%'
              px={1}
              paddingTop={1}
              paddingRight={10}
              paddingBottom={2}
            >
              <Box
                display='flex'
                alignItems='right'
                width='90%'
                paddingRight={2}
                justifyContent='right'
                alignContent='right'
              >
                <Button
                  variant='contained'
                  color='primary'
                  size='medium'
                  disableElevation
                  disabled={!dietSelected}
                  onClick={(e) => {
                    createWeeklyMealSchedulingDietPlan()
                  }}
                >
                  Create Meal Plan
                </Button>
              </Box>
            </Box>
          )}

          {activeStep === 1 && dietDragDropTableData && (
            <DragAndDrop
              tableData={dietDragDropTableData}
              tableDataAfterDragDrop={dataForChartAfterEachDragDrop}
            />
          )}

          {activeStep === 1 && dietDragDropTableData && (
            <Box
              display='flex'
              justifyContent='right'
              alignItems='right'
              width='100%'
              px={1}
              paddingTop={1}
              paddingRight={11}
              paddingBottom={2}
            >
              <Box
                display='flex'
                alignItems='right'
                width='90%'
                paddingRight={1}
                justifyContent='right'
                alignContent='right'
              >
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  disableElevation
                  onClick={() => {
                    createWeeklyDietChart()
                  }}
                >
                  Create Diet Chart
                </Button>
              </Box>
            </Box>
          )}

          {activeStep === 2 && finalChartData && (
            <DietChartComponent
              tableDataForChart={finalChartData as DisplayTableData}
            />
          )}

          {activeStep === 2 && finalChartData && (
            <Box
              display='flex'
              justifyContent='right'
              alignItems='right'
              width='100%'
              px={1}
              paddingTop={1}
              paddingRight={11}
              paddingBottom={2}
            >
              <Box
                display='flex'
                alignItems='right'
                width='90%'
                paddingRight={1}
                justifyContent='right'
                alignContent='right'
              >
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  disableElevation
                  onClick={() => {
                    createDietDataForCarePlan()
                  }}
                >
                  Save to Care Plan
                </Button>
              </Box>
            </Box>
          )}

          {openAddDietDialogue && (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              width='100%'
              py={1}
              px={1}
            >
              <Dialog
                open={open}
                onClose={() => {
                  setOpenAddDietDialogue(false)
                }}
                aria-labelledby='responsive-dialog-title'
                PaperProps={{
                  style: {
                    height: '250px',
                    width: '500px',
                  },
                }}
                disableBackdropClick={true}
              >
                <DialogTitle id='scroll-dialog-title'>
                  <Box display='flex' flexDirection='row'>
                    <Box flexGrow={4}>Search Accompaniment</Box>
                    <Box>
                      <CloseOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          setOpenAddDietDialogue(false)
                        }}
                        id='close_order'
                      />
                    </Box>
                  </Box>
                </DialogTitle>
                <DialogContent
                  dividers={true}
                  style={{
                    backgroundColor: '#ededed',
                    padding: 0,
                    overflowWrap: 'normal',
                    overscrollBehaviorX: 'none',
                    margin: 0,
                  }}
                >
                  {fetchAccompanimentDetails && (
                    <WelloLoadingIndicator message='Fetching Accompaniment' />
                  )}
                  {!fetchAccompanimentDetails && (
                    <Box>
                      <Grid container>
                        <Grid item xs={10}>
                          <Box
                            display='flex'
                            alignItems='center'
                            paddingY={2}
                            paddingLeft={2}
                            paddingRight={1}
                          >
                            <TextField
                              variant='outlined'
                              size='small'
                              fullWidth
                              onChange={(event) => {
                                setAccompanimentDiet(event.target.value)
                              }}
                              // size='small'
                              autoFocus={true}
                              placeholder='Search By Meal'
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/search.png`}
                                      alt='s'
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            display='flex'
                            alignItems='center'
                            paddingY={2}
                            paddingRight={2}
                          >
                            <Button
                              variant='contained'
                              color='primary'
                              disableElevation
                              disabled={accompanimentDiet == ''}
                              onClick={() => {
                                searchForAccompaniments()
                                setNoResultsForAccompaniment(false)
                              }}
                            >
                              Search
                            </Button>
                          </Box>
                        </Grid>
                        {noResultsForAccompaniment && (
                          <Box paddingLeft={22}>
                            <Typography variant='subtitle2' color='initial'>
                              No results found
                            </Typography>
                          </Box>
                        )}
                        {!noResultsForAccompaniment && accompCheckbox && (
                          <Grid item xs={12}>
                            <Box
                              marginLeft={2}
                              marginY={1}
                              display='flex'
                              justifyContent='flex-start'
                              flexDirection='column'
                            >
                              {accompanimentValues.length !== 0 &&
                                accompanimentValues.map(
                                  (e: any, index: any) => (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      key='1'
                                    >
                                      <Box paddingLeft={0.5}>
                                        <Checkbox
                                          edge='start'
                                          // checked={e.checked}
                                          onChange={(event) => {
                                            handleChangeAccompaniments(
                                              event.target.checked,
                                              index
                                            )
                                          }}
                                          style={{
                                            paddingTop: 0,
                                          }}
                                          tabIndex={0}
                                          size='small'
                                          color='primary'
                                          id={`left${index}`}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          {e.data.display}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )
                                )}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </DialogContent>
                <DialogActions>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    paddingRight={1}
                  >
                    <Button
                      onClick={() => {
                        setAccompCheckbox(false)
                        setOpenAddDietDialogue(false)
                        setAccompanimentDiet('')
                        setAccompanimentChecked(false)
                        setNoResultsForAccompaniment(false)
                      }}
                      id='cancel'
                      variant='outlined'
                      disableElevation
                    >
                      {t('labelCommon:cancel')}
                    </Button>
                    <Button
                      color='primary'
                      onClick={() => {
                        // setOpenAddDietDialogue(false)
                        handleAddAccompaniments()
                        setOpenAddDietDialogue(false)
                        setAccompanimentDiet('')
                        setAccompanimentChecked(false)
                      }}
                      variant='contained'
                      disabled={!accompanimentChecked}
                    >
                      Add
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          )}

          {openAdditionalDietDialogue && (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              width='100%'
              py={1}
              px={1}
            >
              <Dialog
                open={open}
                onClose={() => {
                  setOpenAdditionalDietDialogue(false)
                }}
                aria-labelledby='responsive-dialog-title'
                PaperProps={{
                  style: {
                    height: '250px',
                    width: '500px',
                  },
                }}
                disableBackdropClick={true}
              >
                <DialogTitle id='scroll-dialog-title'>
                  <Box display='flex' flexDirection='row'>
                    <Box flexGrow={4}>Search Additional Diet</Box>
                    <Box>
                      <CloseOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          setOpenAdditionalDietDialogue(false)
                        }}
                        id='close_order'
                      />
                    </Box>
                  </Box>
                </DialogTitle>
                <DialogContent
                  dividers={true}
                  style={{
                    backgroundColor: '#ededed',
                    padding: 0,
                    overflowWrap: 'normal',
                    overscrollBehaviorX: 'none',
                    margin: 0,
                  }}
                >
                  {fetchAdditionalDietDetails && (
                    <WelloLoadingIndicator message='Fetching Additional Diet' />
                  )}
                  {!fetchAdditionalDietDetails && (
                    <Box>
                      <Grid container>
                        <Grid item xs={10}>
                          <Box
                            display='flex'
                            alignItems='center'
                            paddingY={2}
                            paddingLeft={2}
                            paddingRight={1}
                          >
                            <TextField
                              variant='outlined'
                              size='small'
                              fullWidth
                              onChange={(event) => {
                                setAdditionalDiet(event.target.value)
                              }}
                              // size='small'
                              autoFocus={true}
                              placeholder='Search By Meal'
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/search.png`}
                                      alt='s'
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            display='flex'
                            alignItems='center'
                            paddingY={2}
                            paddingRight={2}
                          >
                            <Button
                              variant='contained'
                              color='primary'
                              disableElevation
                              disabled={additionalDiet == ''}
                              onClick={() => {
                                searchForAdditionalDiets()
                                setNoResultsForAdditionalDiet(false)
                              }}
                            >
                              Search
                            </Button>
                          </Box>
                        </Grid>
                        {noResultsForAdditionalDiet && (
                          <Box paddingLeft={22}>
                            <Typography variant='subtitle2' color='initial'>
                              No results found
                            </Typography>
                          </Box>
                        )}
                        {!noResultsForAdditionalDiet && additionalDietCheckbox && (
                          <Grid item xs={12}>
                            <Box
                              marginLeft={2}
                              marginY={1}
                              display='flex'
                              justifyContent='flex-start'
                              flexDirection='column'
                            >
                              {additionalDietValues.length !== 0 &&
                                additionalDietValues.map(
                                  (e: any, index: any) => (
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      key='1'
                                    >
                                      <Box paddingLeft={0.5}>
                                        <Checkbox
                                          edge='start'
                                          // checked={e.checked}
                                          onChange={(event) => {
                                            handleChangeAdditionalDiets(
                                              event.target.checked,
                                              index
                                            )
                                          }}
                                          style={{
                                            paddingTop: 0,
                                          }}
                                          tabIndex={0}
                                          size='small'
                                          color='primary'
                                          id={`left${index}`}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography
                                          variant='subtitle2'
                                          color='initial'
                                        >
                                          {e.dietWithAccompaniment}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )
                                )}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </DialogContent>
                <DialogActions>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    paddingRight={1}
                  >
                    <Button
                      onClick={() => {
                        setAdditionalDietCheckbox(false)
                        setOpenAdditionalDietDialogue(false)
                        setAdditionalDiet('')
                        setAdditionalDietCheckbox(false)
                        setNoResultsForAdditionalDiet(false)
                      }}
                      id='cancel'
                      variant='outlined'
                      disableElevation
                    >
                      {t('labelCommon:cancel')}
                    </Button>
                    <Button
                      color='primary'
                      onClick={() => {
                        handleAddAdditionalDiets()
                        setOpenAdditionalDietDialogue(false)
                        setAdditionalDiet('')
                        setAccompanimentChecked(false)
                      }}
                      variant='contained'
                      disabled={!accompanimentChecked}
                    >
                      Add
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          )}

          {showEdit && (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              width='100%'
              py={1}
              px={1}
            >
              <Dialog
                open={open}
                onClose={() => {
                  setShowEdit(false)
                }}
                aria-labelledby='responsive-dialog-title'
                classes={{ paperFullWidth: classes.dialogCustomizedWidthEdit }}
                fullWidth
                disableBackdropClick={true}
              >
                <DialogTitle id='scroll-dialog-title'>
                  <Box display='flex' flexDirection='row'>
                    <Box flexGrow={4}>Edit Accompaniment</Box>
                    <Box>
                      <CloseOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          setShowEdit(false)
                        }}
                        id='close_order'
                      />
                    </Box>
                  </Box>
                </DialogTitle>
                <DialogContent
                  dividers={true}
                  style={{
                    backgroundColor: '#ededed',
                    padding: 0,
                    overflowWrap: 'normal',
                    overscrollBehaviorX: 'none',
                    margin: 0,
                  }}
                >
                  <Box
                    display='flex'
                    width='100%'
                    flexDirection='column'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      width='100%'
                      flexDirection='column'
                      flexGrow={1}
                      margin={2}
                      paddingRight={4}
                    >
                      <Autocomplete
                        multiple
                        id='1'
                        getOptionSelected={(option, value) =>
                          option.display === value.display
                        }
                        size='medium'
                        getOptionLabel={(option) => option.display ?? ''}
                        options={defaultValuesForEditDiet}
                        fullWidth
                        onChange={(e, changedValue) => {
                          setDefaultValuesForEditDiet(changedValue)
                        }}
                        value={defaultValuesForEditDiet}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id='search'
                            label='Edit Diet'
                            variant='outlined'
                            size='medium'
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>{params.InputProps.endAdornment}</>
                              ),
                              style: {
                                borderRadius: 6,
                                //   maxWidth: '80px',
                                width: '400x',
                              },
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    paddingRight={1}
                  >
                    <Button
                      onClick={() => {
                        setShowEdit(false)
                      }}
                      id='cancel'
                      variant='outlined'
                      disableElevation
                    >
                      {t('labelCommon:cancel')}
                    </Button>
                    <Button
                      color='primary'
                      disabled={!(defaultValuesForEditDiet.length > 0)}
                      onClick={() => {
                        editDietValues()
                        setShowEdit(false)
                      }}
                      variant='contained'
                    >
                      Update
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          )}

          {searchDietRecommendationsSecondTime && (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              width='100%'
              py={1}
              px={1}
            >
              <Dialog
                open={open}
                onClose={() => {
                  setSearchDietRecommendationsSecondTime(false)
                }}
                aria-labelledby='responsive-dialog-title'
                classes={{ paperFullWidth: classes.dialogCustomizedWidthEdit }}
                fullWidth
                disableBackdropClick={true}
              >
                <DialogTitle id='scroll-dialog-title'>
                  <Box display='flex' flexDirection='row'>
                    <Box flexGrow={4}>Search Meal Recommendations</Box>
                    <Box>
                      <CloseOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          setSearchDietRecommendationsSecondTime(false)
                        }}
                        id='close_order'
                      />
                    </Box>
                  </Box>
                </DialogTitle>
                <DialogContent
                  dividers={true}
                  style={{
                    backgroundColor: '#ededed',
                    padding: 0,
                    overflowWrap: 'normal',
                    overscrollBehaviorX: 'none',
                    margin: 0,
                  }}
                >
                  <Box
                    display='flex'
                    width='100%'
                    flexDirection='column'
                    flexGrow={1}
                  >
                    <Box
                      display='flex'
                      width='100%'
                      flexDirection='column'
                      flexGrow={1}
                      margin={2}
                      paddingRight={4}
                    >
                      <Typography>
                        Modifying any Meal filters will reset the current diet
                        recommendations. Are you sure you want to proceed?
                      </Typography>
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    paddingRight={1}
                  >
                    <Button
                      onClick={() => {
                        setSearchDietRecommendationsSecondTime(false)
                      }}
                      id='cancel'
                      variant='outlined'
                      disableElevation
                    >
                      {t('labelCommon:cancel')}
                    </Button>
                    <Button
                      color='primary'
                      disabled={!(defaultValuesForEditDiet.length > 0)}
                      onClick={() => {
                        editDietValues()
                        setShowEdit(false)
                      }}
                      variant='contained'
                    >
                      Yes
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          )}

          {openCuisineAddDialogue && (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              width='100%'
              py={1}
              px={1}
            >
              <Dialog
                open={open}
                onClose={() => {
                  setOpenCuisineAddDialogue(false)
                }}
                aria-labelledby='responsive-dialog-title'
                classes={{ paperFullWidth: classes.dialogCustomizedWidthSub }}
                fullWidth
                disableBackdropClick={true}
              >
                <DialogTitle id='scroll-dialog-title'>
                  Select Cuisines
                </DialogTitle>
                <DialogContent dividers={true}>
                  <Box
                    marginLeft={2}
                    marginY={1}
                    display='flex'
                    justifyContent='flex-start'
                    flexDirection='column'
                  >
                    <Grid container>
                      {cuisines.map((e, index) => (
                        <Grid key='cuisines_options' item xs={12} sm={6} md={4}>
                          <Box display='flex' alignItems='center'>
                            <Checkbox
                              style={{
                                paddingTop: 0,
                              }}
                              checked={e.checked}
                              tabIndex={0}
                              size='small'
                              color='primary'
                              id={`left${index}`}
                              onChange={(event) => {
                                handleChangeCuisine(
                                  event.target.checked,
                                  e,
                                  index
                                )
                              }}
                            />
                            <Typography
                              style={{ paddingBottom: '10px' }}
                              variant='subtitle2'
                              color='initial'
                            >
                              {e.data}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    paddingRight={1}
                  >
                    <Button
                      onClick={() => {
                        setOpenCuisineAddDialogue(false)
                      }}
                      id='cancel'
                      variant='outlined'
                      disableElevation
                    >
                      {t('labelCommon:cancel')}
                    </Button>
                    <Button
                      color='primary'
                      disabled={!enableCuisineAdd}
                      onClick={() => {
                        setDisplayCuisineCheckbox(true)
                        setOpenCuisineAddDialogue(false)
                      }}
                      variant='contained'
                    >
                      Add
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          )}

          {openMealAndCourseAddDialogue && (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              width='100%'
            >
              <Dialog
                open={open}
                onClose={() => {
                  setOpenMealAndCourseAddDialogue(false)
                }}
                aria-labelledby='responsive-dialog-title'
                classes={{
                  paperFullWidth: classes.dialogCustomizedWidthMealCourse,
                }}
                fullWidth
                disableBackdropClick={true}
              >
                <DialogTitle id='scroll-dialog-title'>
                  Select Meal Type and Course
                </DialogTitle>
                <DialogContent dividers={true}>
                  <Box>
                    <Box
                      marginLeft={5}
                      marginY={1}
                      display='flex'
                      justifyContent='center'
                      flexDirection='column'
                    >
                      <Typography
                        variant='subtitle2'
                        align='center'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Morning
                      </Typography>
                      <Box
                        marginY={1}
                        display='flex'
                        justifyContent='flex-start'
                        flexDirection='column'
                      >
                        <Divider
                          orientation='horizontal'
                          variant='fullWidth'
                          flexItem
                          style={{ width: '100%', height: '2px' }}
                          color='primary'
                        />
                      </Box>
                      <Grid container>
                        {morningDiets.map((e, index) => (
                          <Grid
                            key='morning_diets_timing'
                            item
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <Box display='flex' alignItems='center'>
                              <Checkbox
                                checked={e.checked}
                                onChange={(event) => {
                                  handleChangeMorningDietType(
                                    event.target.checked,
                                    index
                                  )
                                }}
                                style={{
                                  paddingTop: 0,
                                }}
                                tabIndex={0}
                                size='small'
                                color='primary'
                                id={`left${index}`}
                              />
                              <Typography
                                style={{ paddingBottom: '10px' }}
                                variant='subtitle2'
                                color='initial'
                              >
                                {e.data}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box
                      paddingTop={3}
                      marginLeft={5}
                      marginY={1}
                      display='flex'
                      justifyContent='center'
                      flexDirection='column'
                    >
                      <Typography
                        variant='subtitle2'
                        align='center'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Breakfast
                      </Typography>
                      <Box
                        marginY={1}
                        display='flex'
                        justifyContent='flex-start'
                        flexDirection='column'
                      >
                        <Divider
                          orientation='horizontal'
                          variant='fullWidth'
                          flexItem
                          style={{ width: '100%', height: '2px' }}
                          color='primary'
                        />
                      </Box>
                      <Grid container>
                        {breakfastDiets.map((e, index) => (
                          <Grid
                            key='breakfast_diet_time'
                            item
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <Box display='flex' alignItems='center'>
                              <Checkbox
                                checked={e.checked}
                                onChange={(event) => {
                                  handleChangeBreakfastDietType(
                                    event.target.checked,
                                    index
                                  )
                                }}
                                style={{
                                  paddingTop: 0,
                                }}
                                tabIndex={0}
                                size='small'
                                color='primary'
                                id={`left${index}`}
                              />
                              <Typography
                                style={{ paddingBottom: '10px' }}
                                variant='subtitle2'
                                color='initial'
                              >
                                {e.data}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box
                      paddingTop={4}
                      marginLeft={5}
                      marginY={1}
                      display='flex'
                      justifyContent='center'
                      flexDirection='column'
                    >
                      <Typography
                        variant='subtitle2'
                        align='center'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Mid Morning
                      </Typography>
                      <Box
                        marginY={1}
                        display='flex'
                        justifyContent='flex-start'
                        flexDirection='column'
                      >
                        <Divider
                          orientation='horizontal'
                          variant='fullWidth'
                          flexItem
                          style={{ width: '100%', height: '2px' }}
                          color='primary'
                        />
                      </Box>
                      <Grid container>
                        {midMorningDiets.map((e, index) => (
                          <Grid
                            key='mid_morning_timing'
                            item
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <Box display='flex' alignItems='center'>
                              <Checkbox
                                checked={e.checked}
                                onChange={(event) => {
                                  handleChangeMidMorningDietType(
                                    event.target.checked,
                                    index
                                  )
                                }}
                                style={{
                                  paddingTop: 0,
                                }}
                                tabIndex={0}
                                size='small'
                                color='primary'
                                id={`left${index}`}
                              />
                              <Typography
                                style={{ paddingBottom: '10px' }}
                                variant='subtitle2'
                                color='initial'
                              >
                                {e.data}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box
                      paddingTop={3}
                      marginLeft={5}
                      marginY={1}
                      display='flex'
                      justifyContent='center'
                      flexDirection='column'
                    >
                      <Typography
                        variant='subtitle2'
                        align='center'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Lunch
                      </Typography>
                      <Box
                        marginY={1}
                        display='flex'
                        justifyContent='flex-start'
                        flexDirection='column'
                      >
                        <Divider
                          orientation='horizontal'
                          variant='fullWidth'
                          flexItem
                          style={{ width: '100%', height: '2px' }}
                          color='primary'
                        />
                      </Box>
                      <Grid container>
                        {lunchDiets.map((e, index) => (
                          <Grid key='lunch_timing' item xs={12} sm={6} md={4}>
                            <Box display='flex' alignItems='center'>
                              <Checkbox
                                checked={e.checked}
                                onChange={(event) => {
                                  handleChangeLunchDietType(
                                    event.target.checked,
                                    index
                                  )
                                }}
                                style={{
                                  paddingTop: 0,
                                }}
                                tabIndex={0}
                                size='small'
                                color='primary'
                                id={`left${index}`}
                              />
                              <Typography
                                style={{ paddingBottom: '10px' }}
                                variant='subtitle2'
                                color='initial'
                              >
                                {e.data}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box
                      paddingTop={3}
                      marginLeft={5}
                      marginY={1}
                      display='flex'
                      justifyContent='center'
                      flexDirection='column'
                    >
                      <Typography
                        variant='subtitle2'
                        align='center'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Evening Snack
                      </Typography>
                      <Box
                        marginY={1}
                        display='flex'
                        justifyContent='flex-start'
                        flexDirection='column'
                      >
                        <Divider
                          orientation='horizontal'
                          variant='fullWidth'
                          flexItem
                          style={{ width: '100%', height: '2px' }}
                          color='primary'
                        />
                      </Box>
                      <Grid container>
                        {eveningDiets.map((e, index) => (
                          <Grid
                            key='evening_snack_timing'
                            item
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <Box display='flex' alignItems='center'>
                              <Checkbox
                                checked={e.checked}
                                onChange={(event) => {
                                  handleChangeEveningDietType(
                                    event.target.checked,
                                    index
                                  )
                                }}
                                style={{
                                  paddingTop: 0,
                                }}
                                tabIndex={0}
                                size='small'
                                color='primary'
                                id={`left${index}`}
                              />
                              <Typography
                                style={{ paddingBottom: '10px' }}
                                variant='subtitle2'
                                color='initial'
                              >
                                {e.data}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box
                      paddingTop={3}
                      marginLeft={5}
                      marginY={1}
                      display='flex'
                      justifyContent='center'
                      flexDirection='column'
                    >
                      <Typography
                        align='center'
                        variant='subtitle2'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Dinner
                      </Typography>
                      <Box
                        marginY={1}
                        display='flex'
                        justifyContent='flex-start'
                        flexDirection='column'
                      >
                        <Divider
                          orientation='horizontal'
                          variant='fullWidth'
                          flexItem
                          style={{ width: '100%', height: '2px' }}
                          color='primary'
                        />
                      </Box>
                      <Grid container>
                        {dinnerDiets.map((e, index) => (
                          <Grid key='dinner_timing' item xs={12} sm={6} md={4}>
                            <Box display='flex' alignItems='center'>
                              <Checkbox
                                checked={e.checked}
                                onChange={(event) => {
                                  handleChangeDinnerDietType(
                                    event.target.checked,
                                    index
                                  )
                                }}
                                style={{
                                  paddingTop: 0,
                                }}
                                tabIndex={0}
                                size='small'
                                color='primary'
                                id={`left${index}`}
                              />
                              <Typography
                                style={{ paddingBottom: '10px' }}
                                variant='subtitle2'
                                color='initial'
                              >
                                {e.data}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box
                      paddingTop={3}
                      marginLeft={5}
                      marginY={1}
                      display='flex'
                      justifyContent='center'
                      flexDirection='column'
                    >
                      <Typography
                        variant='subtitle2'
                        align='center'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                      >
                        Night Drink
                      </Typography>
                      <Box
                        marginY={1}
                        display='flex'
                        justifyContent='flex-start'
                        flexDirection='column'
                      >
                        <Divider
                          orientation='horizontal'
                          variant='fullWidth'
                          flexItem
                          style={{ width: '100%', height: '2px' }}
                          color='primary'
                        />
                      </Box>
                      <Grid container>
                        {nightDrinkDiets.map((e, index) => (
                          <Grid
                            key='night_drink_timing'
                            item
                            xs={12}
                            sm={6}
                            md={4}
                          >
                            <Box display='flex' alignItems='center'>
                              <Checkbox
                                checked={e.checked}
                                onChange={(event) => {
                                  handleChangeNightDrinkDietType(
                                    event.target.checked,
                                    index
                                  )
                                }}
                                style={{
                                  paddingTop: 0,
                                }}
                                tabIndex={0}
                                size='small'
                                color='primary'
                                id={`left${index}`}
                              />
                              <Typography
                                style={{ paddingBottom: '10px' }}
                                variant='subtitle2'
                                color='initial'
                              >
                                {e.data}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>

                  <Box paddingTop={3}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height={40}
                      style={{
                        backgroundColor: 'lightGrey',
                      }}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                        align='center'
                      >
                        Courses
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={2}
                    marginY={1}
                    display='flex'
                    justifyContent='flex-start'
                    flexDirection='column'
                  >
                    <Grid container>
                      {courses.map((e, index) => (
                        <Grid item key={`${e.data}`} xs={12} sm={6} md={4}>
                          <Box display='flex' alignItems='center'>
                            <Checkbox
                              style={{
                                paddingTop: 0,
                              }}
                              checked={e.checked}
                              tabIndex={0}
                              size='small'
                              color='primary'
                              id={`left${index}`}
                              onChange={(event) => {
                                handleChangeCourse(
                                  event.target.checked,
                                  e,
                                  index
                                )
                              }}
                            />
                            <Typography
                              style={{ paddingBottom: '10px' }}
                              variant='subtitle2'
                              color='initial'
                            >
                              {e.data}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    paddingRight={1}
                  >
                    <Button
                      onClick={() => {
                        setOpenMealAndCourseAddDialogue(false)
                      }}
                      id='cancel'
                      variant='outlined'
                      disableElevation
                    >
                      {t('labelCommon:cancel')}
                    </Button>
                    <Button
                      color='primary'
                      disabled={!anyMealTimeSelected}
                      onClick={() => {
                        setDisplayCourseCheckbox(true)
                        setOpenMealAndCourseAddDialogue(false)
                      }}
                      variant='contained'
                    >
                      Add
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          )}

          {openCategoriesAddDialogue && (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              alignItems='center'
              width='100%'
            >
              <Dialog
                open={open}
                onClose={() => {
                  setOpenCategoriesAddDialogue(false)
                }}
                aria-labelledby='responsive-dialog-title'
                classes={{
                  paperFullWidth: classes.dialogCustomizedWidthCategories,
                }}
                fullWidth
                disableBackdropClick={true}
              >
                <DialogTitle id='scroll-dialog-title'>
                  Select Categories
                </DialogTitle>
                <DialogContent dividers={true}>
                  <Box>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height={40}
                      style={{
                        backgroundColor: 'lightGrey',
                      }}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                        align='center'
                      >
                        Ease of Preparation
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={2}
                    marginY={1}
                    display='flex'
                    justifyContent='flex-start'
                    flexDirection='column'
                  >
                    <Grid container>
                      {easeOfPreparations.map((e, index) => (
                        <Grid
                          key='ease_of_preparation_options'
                          item
                          xs={12}
                          sm={6}
                          md={4}
                        >
                          <Box display='flex' alignItems='center'>
                            <Checkbox
                              checked={e.checked}
                              style={{
                                paddingTop: 0,
                              }}
                              tabIndex={0}
                              size='small'
                              color='primary'
                              id={`left${index}`}
                              onChange={(event) => {
                                handleChangeEaseOfPreparation(
                                  event.target.checked,
                                  e,
                                  index
                                )
                              }}
                            />
                            <Typography
                              style={{ paddingBottom: '10px' }}
                              variant='subtitle2'
                              color='initial'
                            >
                              {e.data}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>

                  <Box paddingTop={4}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height={40}
                      style={{
                        backgroundColor: 'lightGrey',
                      }}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                        align='center'
                      >
                        Batch Cooking
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={2}
                    marginY={1}
                    display='flex'
                    justifyContent='flex-start'
                    flexDirection='column'
                  >
                    <Grid container>
                      {batchCookings.map((e, index) => (
                        <Grid
                          key='batch_cooking_options'
                          item
                          xs={12}
                          sm={6}
                          md={4}
                        >
                          <Box display='flex' alignItems='center'>
                            <Checkbox
                              checked={e.checked}
                              style={{
                                paddingTop: 0,
                              }}
                              tabIndex={0}
                              size='small'
                              color='primary'
                              id={`left${index}`}
                              onChange={(event) => {
                                handleChangeBatchCooking(
                                  event.target.checked,
                                  e,
                                  index
                                )
                              }}
                            />
                            <Typography
                              style={{ paddingBottom: '10px' }}
                              variant='subtitle2'
                              color='initial'
                            >
                              {e.data}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>

                  <Box paddingTop={4}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height={40}
                      style={{
                        backgroundColor: 'lightGrey',
                      }}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          fontFamily: 'Open Sans',
                          fontSize: 12,
                          color: 'black',

                          fontWeight: 'bold',
                          lineHeight: 'normal',
                        }}
                        align='center'
                      >
                        Easy Restaurant Recipe
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={2}
                    marginY={1}
                    display='flex'
                    justifyContent='flex-start'
                    flexDirection='column'
                  >
                    <Grid container>
                      {easyRestaurantRecipes.map((e, index) => (
                        <Grid
                          key='easy_restaurant_recipe_options'
                          item
                          xs={12}
                          sm={6}
                          md={4}
                        >
                          <Box display='flex' alignItems='center'>
                            <Checkbox
                              checked={e.checked}
                              style={{
                                paddingTop: 0,
                              }}
                              tabIndex={0}
                              size='small'
                              color='primary'
                              id={`left${index}`}
                              onChange={(event) => {
                                handleChangeEasyRestaurantRecipe(
                                  event.target.checked,
                                  e,
                                  index
                                )
                              }}
                            />
                            <Typography
                              style={{ paddingBottom: '10px' }}
                              variant='subtitle2'
                              color='initial'
                            >
                              {e.data}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Box
                    width='100%'
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-end'
                    paddingRight={1}
                  >
                    <Button
                      onClick={() => {
                        setOpenCategoriesAddDialogue(false)
                      }}
                      id='cancel'
                      variant='outlined'
                      disableElevation
                    >
                      {t('labelCommon:cancel')}
                    </Button>
                    <Button
                      color='primary'
                      onClick={() => {
                        setDisplayPreparationCookingEasyRecipeCheckbox(true)
                        setOpenCategoriesAddDialogue(false)
                      }}
                      variant='contained'
                    >
                      Add
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </DialogContent>
      )}
    </Dialog>
  )
}
