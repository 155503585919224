import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  Button,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
} from '@material-ui/core'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/rootReducer'

import useWindowDimensions from 'utils/hooks/useDimensionHook'

import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import moment from 'moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
  requestAppointmentForReport,
  resetReportSlice,
} from 'redux/ipd/Report/dashboardReportSearchSlice'
import MomentUtils from '@date-io/moment'
import { ReportDataTable } from 'views/pages/clinic/Report/IPD/reportDataTable'
import { CalendarToday } from '@material-ui/icons'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { desktopScreenWidth } from 'utils/screen_utils'
import { fetchIPDAppointmentDetails } from 'redux/clinic/ipdDetails/ipdAppointmentHandler/ipdAppointmentHandlerSlice'
import { useWindowSize } from 'rooks'
import { IPDDetails } from 'views/components/ipd/IpdDetails'
import { IPDSplitDetails } from 'views/components/ipd/ipdSplitAdmin'
import {
  validateAdmissionEndDate,
  valiDateReportAdmissionDate,
} from 'utils/formValidators'
import { IPDOrgAdmissionDashboardList } from 'views/components/ipd/ipdAppointmentList/ipdOrgadmissionList'
import { ValueSetSelectMultiple } from 'views/components/common/MultiSelectFromValueSetUrlNew'
import { serviceType } from 'utils/constants'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'

import { OrgAdminAppointmentList } from 'views/components/ipd/ipdAppointmentList/ipdOrgAdminAppointmentList'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { ReferralHeaderTile } from '../../referrals/referralList/refrrralHeaderTile'
import { AdmissionDashboardHeader } from './admissionDashboardHeaderTile'
import { AdmissionDashboardHeaderDayCare } from './dayCareHeaderTile'
import { AppointmentHeaderTile } from './appointmentHeaderTile'
// import { ReportDataTable } from './reportDataTable'
// import { AddTherapy } from './addTherapy';

export interface IUnitAdmin {
  onTap?: () => void
  onAdd?: () => void
}

export const OrgAdminDashBoard: React.FC<IUnitAdmin> = ({ onTap, onAdd }) => {
  const dashboardReportSearchSlice = useSelector(
    (state: RootState) => state.dashboardReportSearchSlice
  )

  const appointmentReportSearchSlice = useSelector(
    (state: RootState) => state.dashboardReportAppointmentSearchSlice
  )

  const [rangeStart, setRangeStart] = React.useState<Date>(
    moment().subtract(30, 'days').toDate()
  )
  const { innerWidth, innerHeight, outerHeight, outerWidth } = useWindowSize()
  const [rangeEnd, setRangeEnd] = React.useState<Date>(new Date())
  const [name, setName] = React.useState<string>('')
  const [typeDataForDisplay, setTypeDataForDisplay] =
    React.useState<R4.ICoding>(serviceType[0])
  const [phone, setPhone] = React.useState<string>('')
  const [type, setType] = useState('ipd')
  const [typeData, setTypeData] = useState('dateWise')
  const [startMonth, setStartMonth] = React.useState<Date>(
    moment().subtract(90, 'days').toDate()
  )
  const [endMonth, setEndMonth] = React.useState<Date>(new Date())
  const [appointmentType, setAppointmentType] = React.useState<string>('person')

  const dispatch = useDispatch()

  const { width } = useWindowDimensions()
  const [selectedIpd, setSelectedIPd] = useState<FhirClinicIpdDetails>()

  const [showPopup, setShowPopup] = React.useState<boolean>(false)

  const handleDateChangeForRangeStart = (date: MaterialUiPickersDate) => {
    setRangeStart(date !== null ? moment(date).toDate() : new Date())
  }

  const handleDateChangeForRangeEnd = (date: MaterialUiPickersDate) => {
    setRangeEnd(date !== null ? moment(date).toDate() : new Date())
  }

  const handleStartMonthChange = (date: MaterialUiPickersDate) => {
    setStartMonth(date !== null ? moment(date).toDate() : new Date())
  }

  const handleEndMonthChange = (date: MaterialUiPickersDate) => {
    setEndMonth(date !== null ? moment(date).toDate() : new Date())
  }

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='column'
            width='100%'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            {typeDataForDisplay.code && typeDataForDisplay.code === 'ipd' && (
              <Box display='flex' flexDirection='column' width='100%'>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column'>
                    <Box paddingTop={1} display='flex' flexDirection='row'>
                      <Box minWidth='80px' paddingLeft={1} paddingRight={0.5}>
                        <Box
                          py={1}
                          px={0.25}
                          display='flex'
                          flexDirection='row'
                        >
                          <Typography
                            variant='subtitle2'
                            style={{
                              textTransform: 'uppercase',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                          >
                            Time Period
                          </Typography>
                        </Box>
                        {/* <WelloFormItemLabel title=' Time Period' /> */}
                      </Box>
                      <FormControl>
                        <FormLabel id='demo-row-radio-buttons-group-label'>
                          {/* <Typography
                      variant='subtitle1'
                      color='textPrimary'
                      style={{ paddingLeft: 6 }}
                    >
                      Time Period
                    </Typography> */}
                        </FormLabel>
                        <RadioGroup
                          id='add_unit_address_choice_rad_group'
                          row
                          aria-label='graphType'
                          value={typeData}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setSelectedIPd(undefined)
                            setTypeData(
                              (event.target as HTMLInputElement).value
                            )
                            if (
                              (event.target as HTMLInputElement).value ===
                              'dateWise'
                            ) {
                              dispatch(resetReportSlice())
                              setName('')
                              setRangeStart(
                                moment().subtract(30, 'days').toDate()
                              )
                              setPhone('')

                              setStartMonth(
                                moment().subtract(90, 'days').toDate()
                              )
                              setEndMonth(new Date())
                            } else {
                              dispatch(resetReportSlice())
                              setName('')
                              setPhone('')
                              setRangeEnd(new Date())
                              setStartMonth(
                                moment().subtract(90, 'days').toDate()
                              )
                            }
                          }}
                        >
                          <Box px={1.5}>
                            <FormControlLabel
                              value='dateWise'
                              control={<Radio color='primary' />}
                              label={
                                <Typography variant='subtitle2'>
                                  By Date{' '}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value='MonthWise'
                              control={<Radio color='primary' />}
                              label={
                                <Typography variant='subtitle2'>
                                  By Month{' '}
                                </Typography>
                              }
                            />
                          </Box>
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box px={1} display='flex' flexDirection='row' paddingTop={1}>
                    <Box minWidth='80px' paddingLeft={1} paddingRight={2.4}>
                      <Box py={1} px={0.25} display='flex' flexDirection='row'>
                        <Typography
                          variant='subtitle2'
                          style={{
                            textTransform: 'uppercase',
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                        >
                          Visit Type
                        </Typography>
                      </Box>
                      {/* <WelloFormItemLabel title='Visit Type' /> */}
                    </Box>
                    <FormControl>
                      <FormLabel id='demo-row-radio-buttons-group-label'>
                        {/* <Typography
                    variant='subtitle1'
                    color='textPrimary'
                    style={{ paddingLeft: 6 }}
                  >
                    Visit Type
                  </Typography> */}
                      </FormLabel>
                      <RadioGroup
                        id='add_unit_address_choice_rad_group'
                        row
                        aria-label='graphType'
                        value={type}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setType((event.target as HTMLInputElement).value)
                          setSelectedIPd(undefined)
                          dispatch(resetReportSlice())

                          // if ((event.target as HTMLInputElement).value === 'ipd') {
                          //   setType('ipd')
                          //   setRangeStart(undefined)
                          //   setRangeEnd(undefined)
                          //   setName('')
                          //   setPhone('')

                          //   dispatch(
                          //     requestAppointmentForReport(
                          //       '281685003',
                          //       '',
                          //       '',
                          //       rangeStart,
                          //       rangeEnd
                          //     )
                          //   )
                          // } else if (
                          //   (event.target as HTMLInputElement).value === 'dayCare'
                          // ) {
                          //   setType('dayCare')
                          //   setRangeStart(undefined)
                          //   setRangeEnd(undefined)setEndMonth
                          //   setName('')
                          //   setPhone('')
                          //   dispatch(
                          //     requestAppointmentForReport(
                          //       '304303009',
                          //       '',
                          //       '',
                          //       rangeStart,
                          //       rangeEnd
                          //     )
                          //   )
                          // } else {
                          //   setType('consultation')
                          //   setRangeStart(undefined)
                          //   setRangeEnd(undefined)
                          //   setName('')
                          //   setPhone('')

                          //   dispatch(
                          //     requestAppointmentForReport(
                          //       '33022008',
                          //       '',
                          //       '',
                          //       rangeStart,
                          //       rangeEnd
                          //     )
                          //   )
                          // }
                        }}
                      >
                        <Box px={1.5} display='flex' flexDirection='row'>
                          <Box>
                            <FormControlLabel
                              value='ipd'
                              control={<Radio color='primary' />}
                              label={
                                <Typography variant='subtitle2'>
                                  IPD{' '}
                                </Typography>
                              }
                            />
                          </Box>
                          <Box px={3.5}>
                            <FormControlLabel
                              value='dayCare'
                              control={<Radio color='primary' />}
                              label={
                                <Typography variant='subtitle2'>
                                  Day Care{' '}
                                </Typography>
                              }
                            />
                          </Box>
                          <Box>
                            <FormControlLabel
                              value='consultation'
                              control={<Radio color='primary' />}
                              label={
                                <Typography variant='subtitle2'>
                                  OPD{' '}
                                </Typography>
                              }
                            />
                          </Box>
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Box display='flex' flexDirection='row' px={1}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    {typeData === 'dateWise' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='70%'
                        // justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='33%'
                          justifyContent='space-between'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Box paddingRight={1}>
                            <WelloFormItemLabel title='Start Date' />
                            <Box>
                              <KeyboardDatePicker
                                clearable
                                id='check_out_date_picker'
                                value={rangeStart}
                                size='small'
                                format='DD-MM-YYYY'
                                error={
                                  valiDateReportAdmissionDate(
                                    moment(rangeStart).format('YYYY-MM-DD'),
                                    moment(rangeEnd).format('YYYY-MM-DD'),
                                    false
                                  ).length > 0
                                }
                                helperText={
                                  valiDateReportAdmissionDate(
                                    moment(rangeStart).format('YYYY-MM-DD'),
                                    moment(rangeEnd).format('YYYY-MM-DD'),
                                    false
                                  ).length > 0
                                    ? valiDateReportAdmissionDate(
                                        moment(rangeStart).format('YYYY-MM-DD'),
                                        moment(rangeEnd).format('YYYY-MM-DD'),
                                        false
                                      )
                                    : ''
                                }
                                onChange={handleDateChangeForRangeStart}
                                inputVariant='outlined'
                              />
                            </Box>
                          </Box>
                          <Box>
                            <WelloFormItemLabel title='End Date' />
                            <Box>
                              <KeyboardDatePicker
                                clearable
                                id='check_In_date_picker'
                                maxDate={moment()}
                                size='small'
                                disabled={rangeStart === undefined}
                                value={rangeEnd}
                                error={
                                  validateAdmissionEndDate(
                                    moment(rangeStart).format('YYYY-MM-DD'),
                                    moment(rangeEnd).format('YYYY-MM-DD'),
                                    false
                                  ).length > 0
                                }
                                helperText={
                                  validateAdmissionEndDate(
                                    moment(rangeStart).format('YYYY-MM-DD'),
                                    moment(rangeEnd).format('YYYY-MM-DD'),
                                    false
                                  ).length > 0
                                    ? validateAdmissionEndDate(
                                        moment(rangeStart).format('YYYY-MM-DD'),
                                        moment(rangeEnd).format('YYYY-MM-DD'),
                                        false
                                      )
                                    : ''
                                }
                                format='DD-MM-YYYY'
                                onChange={handleDateChangeForRangeEnd}
                                inputVariant='outlined'
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box px={1}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box display='flex' flexDirection='column'>
                              <WelloFormItemLabel title='Patient Name' />
                              <TextField
                                variant='outlined'
                                onChange={(event) => {
                                  setSelectedIPd(undefined)
                                  setName(event.target.value)
                                  //   if (type === 'ipd') {
                                  //     dispatch(
                                  //       requestAppointmentForReport(
                                  //         '281685003',
                                  //         event.target.value,
                                  //         phone,
                                  //         rangeStart,
                                  //         rangeEnd
                                  //       )
                                  //     )
                                  //   } else if (type === 'dayCare') {
                                  //     dispatch(
                                  //       requestAppointmentForReport(
                                  //         '304303009',

                                  //         event.target.value,
                                  //         phone,
                                  //         rangeStart,
                                  //         rangeEnd
                                  //       )
                                  //     )
                                  //   } else {
                                  //     dispatch(
                                  //       requestAppointmentForReport(
                                  //         '33022008',
                                  //         event.target.value,
                                  //         phone,
                                  //         rangeStart,
                                  //         rangeEnd
                                  //       )
                                  //     )
                                  //   }
                                }}
                                size='small'
                                autoFocus={true}
                                placeholder='Patient Name'
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/search.png`}
                                        alt='s'
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box display='flex' flexDirection='column' px={1}>
                              <WelloFormItemLabel title='Phone' />
                              <TextField
                                variant='outlined'
                                onChange={(event) => {
                                  setSelectedIPd(undefined)
                                  if (!Number.isNaN(event.target.value)) {
                                    setPhone(event.target.value)
                                  }
                                }}
                                size='small'
                                autoFocus={true}
                                placeholder='Enter 10 digit mobile number'
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/search.png`}
                                        alt='s'
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box
                              display='flex'
                              flexDirection='column'
                              px={1}
                              paddingTop={4}
                            >
                              <Button
                                onClick={() => {
                                  setSelectedIPd(undefined)
                                  if (type === 'ipd') {
                                    dispatch(
                                      requestAppointmentForReport(
                                        'dateWise',
                                        '281685003',
                                        name,
                                        phone,
                                        rangeStart,
                                        rangeEnd,
                                        undefined,
                                        endMonth
                                      )
                                    )
                                  } else if (type === 'dayCare') {
                                    dispatch(
                                      requestAppointmentForReport(
                                        'dateWise',
                                        '304903009',
                                        name,
                                        phone,
                                        rangeStart,
                                        rangeEnd,
                                        undefined,
                                        endMonth
                                      )
                                    )
                                  } else {
                                    dispatch(
                                      requestAppointmentForReport(
                                        'dateWise',
                                        '33022008',

                                        name,
                                        phone,
                                        rangeStart,
                                        rangeEnd,
                                        undefined,
                                        endMonth
                                      )
                                    )
                                  }
                                }}
                                variant='contained'
                                color='primary'
                                disableElevation
                                // disabled={name.length === 0 && phone.length === 0}
                              >
                                Search
                              </Button>
                            </Box>
                          </div>
                        </Box>
                      </Box>
                    )}

                    {typeData === 'MonthWise' && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        width='70%'
                        // justifyContent='space-between'
                        alignContent='center'
                        alignItems='center'
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          width='33%'
                          justifyContent='space-between'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Box paddingRight={1}>
                            <WelloFormItemLabel title='Start Month' />
                            <DatePicker
                              views={['month', 'year']}
                              openTo='month'
                              autoOk={true}
                              style={{
                                cursor: 'pointer',
                              }}
                              inputVariant='outlined'
                              size='small'
                              maxDate={moment(new Date())}
                              value={startMonth}
                              onChange={handleStartMonthChange}
                              InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                  <CalendarToday
                                    fontSize='small'
                                    style={{
                                      margin: '2px 8px',
                                    }}
                                  />
                                ),
                                margin: 'dense',
                              }}
                            />
                          </Box>
                          <Box display='flex' flexDirection='column'>
                            <WelloFormItemLabel title='End Month' />
                            <DatePicker
                              views={['month', 'year']}
                              openTo='month'
                              autoOk={true}
                              style={{
                                cursor: 'pointer',
                              }}
                              inputVariant='outlined'
                              size='small'
                              value={endMonth}
                              disabled={startMonth === undefined}
                              maxDate={moment()}
                              minDate={moment(startMonth)}
                              onChange={handleEndMonthChange}
                              InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                  <CalendarToday
                                    fontSize='small'
                                    style={{
                                      margin: '2px 8px',
                                    }}
                                  />
                                ),
                                margin: 'dense',
                              }}
                            />
                          </Box>
                        </Box>
                        <Box px={1}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box display='flex' flexDirection='column'>
                              <WelloFormItemLabel title='Patient Name' />
                              <TextField
                                variant='outlined'
                                onChange={(event) => {
                                  setSelectedIPd(undefined)
                                  setName(event.target.value)
                                  //   if (type === 'ipd') {
                                  //     dispatch(
                                  //       requestAppointmentForReport(
                                  //         '281685003',
                                  //         event.target.value,
                                  //         phone,
                                  //         rangeStart,
                                  //         rangeEnd
                                  //       )
                                  //     )
                                  //   } else if (type === 'dayCare') {
                                  //     dispatch(
                                  //       requestAppointmentForReport(
                                  //         '304303009',

                                  //         event.target.value,
                                  //         phone,
                                  //         rangeStart,
                                  //         rangeEnd
                                  //       )
                                  //     )
                                  //   } else {
                                  //     dispatch(
                                  //       requestAppointmentForReport(
                                  //         '33022008',
                                  //         event.target.value,
                                  //         phone,
                                  //         rangeStart,
                                  //         rangeEnd
                                  //       )
                                  //     )
                                  //   }
                                }}
                                size='small'
                                autoFocus={true}
                                placeholder='Patient Name'
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/search.png`}
                                        alt='s'
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box display='flex' flexDirection='column' px={1}>
                              <WelloFormItemLabel title='Phone' />
                              <TextField
                                variant='outlined'
                                onChange={(event) => {
                                  if (!Number.isNaN(event.target.value)) {
                                    setSelectedIPd(undefined)
                                    // if (event.target.value.length === 10) {
                                    setPhone(event.target.value)
                                    // if (type === 'ipd') {
                                    //   dispatch(
                                    //     requestAppointmentForReport(
                                    //       '281685003',

                                    //       name,
                                    //       event.target.value,
                                    //       rangeStart,
                                    //       rangeEnd
                                    //     )
                                    //   )
                                    // } else if (type === 'dayCare') {
                                    //   dispatch(
                                    //     requestAppointmentForReport(
                                    //       '304303009',

                                    //       name,
                                    //       event.target.value,
                                    //       rangeStart,
                                    //       rangeEnd
                                    //     )
                                    //   )
                                    // } else {
                                    //   dispatch(
                                    //     requestAppointmentForReport(
                                    //       '33022008',

                                    //       name,
                                    //       event.target.value,
                                    //       rangeStart,
                                    //       rangeEnd
                                    //     )
                                    //   )
                                    // }
                                  }
                                }}
                                size='small'
                                autoFocus={true}
                                placeholder='Enter 10 digit mobile number'
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      <img
                                        src={`${process.env.PUBLIC_URL}/search.png`}
                                        alt='s'
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box
                              display='flex'
                              flexDirection='column'
                              px={1}
                              paddingTop={4}
                            >
                              <Button
                                onClick={() => {
                                  if (type === 'ipd') {
                                    dispatch(resetReportSlice())
                                    setSelectedIPd(undefined)
                                    dispatch(
                                      requestAppointmentForReport(
                                        typeData,
                                        '281685003',
                                        name,
                                        phone,
                                        rangeStart,
                                        rangeEnd,
                                        startMonth,
                                        endMonth
                                      )
                                    )
                                  } else if (type === 'dayCare') {
                                    dispatch(resetReportSlice())
                                    setSelectedIPd(undefined)
                                    dispatch(
                                      requestAppointmentForReport(
                                        typeData,
                                        '304903009',
                                        name,
                                        phone,
                                        rangeStart,
                                        rangeEnd,
                                        startMonth,
                                        endMonth
                                      )
                                    )
                                  } else {
                                    setSelectedIPd(undefined)
                                    dispatch(resetReportSlice())
                                    dispatch(
                                      requestAppointmentForReport(
                                        typeData,
                                        '33022008',

                                        name,
                                        phone,
                                        rangeStart,
                                        rangeEnd,
                                        startMonth,
                                        endMonth
                                      )
                                    )
                                  }
                                }}
                                variant='contained'
                                color='primary'
                                disableElevation
                                disabled={
                                  name.length === 0 &&
                                  phone.length === 0 &&
                                  rangeStart &&
                                  !rangeEnd
                                }
                              >
                                Search
                              </Button>
                            </Box>
                          </div>
                        </Box>
                      </Box>
                    )}
                  </MuiPickersUtilsProvider>
                </Box>
                {dashboardReportSearchSlice.searchingAppointments && (
                  <Box
                    display='flex'
                    flexGrow={1}
                    flexDirection='column'
                    justifyContent='center'
                    alignContent='center'
                    overflow='auto'
                  >
                    <Box
                      flexDirection='row'
                      display='flex'
                      alignSelf='center'
                      justifyContent='center'
                    >
                      <CircularProgress size={25} />
                    </Box>
                  </Box>
                )}

                <Box
                  display='flex'
                  flexGrow={4}
                  flexDirection='column'
                  justifyContent='center'
                  alignContent='center'
                  overflow='auto'
                  style={{
                    backgroundColor: '#ececec',
                  }}
                >
                  {dashboardReportSearchSlice.noResultsAvailable && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                    >
                      {type === 'ipd' && (
                        <Typography
                          variant='subtitle1'
                          color='initial'
                          align='center'
                        >
                          No IPD Data Available
                        </Typography>
                      )}
                      {type === 'dayCare' && (
                        <Typography
                          variant='subtitle1'
                          color='initial'
                          align='center'
                        >
                          No Day Care Data Available
                        </Typography>
                      )}

                      {type === 'consultation' && (
                        <Typography
                          variant='subtitle1'
                          color='initial'
                          align='center'
                        >
                          No Consultation Data Available
                        </Typography>
                      )}
                    </Box>
                  )}
                  {dashboardReportSearchSlice.errorWhileSearchingOrders && (
                    <Box
                      //   display='flex'
                      //   flexDirection='column'
                      justifyContent='center'
                      width='100%'
                      height='100%'
                      alignContent='center'
                      paddingLeft={50}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container justifyContent='center'>
                            <Typography
                              variant='subtitle1'
                              color='error'
                              align='center'
                            >
                              Error while searching IPD . Please try again
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {dashboardReportSearchSlice.resultsAvailable &&
                    dashboardReportSearchSlice.availableAppointments &&
                    selectedIpd === undefined &&
                    type === 'ipd' && (
                      <Box>
                        <AdmissionDashboardHeader />
                      </Box>
                    )}
                  {dashboardReportSearchSlice.resultsAvailable &&
                    dashboardReportSearchSlice.availableAppointments &&
                    selectedIpd === undefined &&
                    type === 'dayCare' && (
                      <Box>
                        <AdmissionDashboardHeaderDayCare />
                      </Box>
                    )}

                  {dashboardReportSearchSlice.resultsAvailable &&
                    dashboardReportSearchSlice.availableAppointments &&
                    selectedIpd === undefined &&
                    type === 'consultation' && (
                      <Box paddingTop={typeData === 'MonthWise' ? 5 : 0}>
                        <AdmissionDashboardHeaderDayCare />
                      </Box>
                    )}
                  {dashboardReportSearchSlice.resultsAvailable && (
                    <Box
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height='100%'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'

                        //  paddingTop={0.2}
                      >
                        {dashboardReportSearchSlice.availableAppointments!.map(
                          (e, index) => (
                            <Box
                              display='flex'
                              //   height='100%'
                              flexDirection='column'
                              key={e.admission_date}
                              //  paddingTop={0.2}
                            >
                              <IPDOrgAdmissionDashboardList
                                usersData={e}
                                type={type}
                                onAppointmentSelected={(
                                  appointmentDetails: FhirClinicIpdDetails
                                ) => {
                                  setSelectedIPd(appointmentDetails)

                                  dispatch(
                                    fetchIPDAppointmentDetails(
                                      appointmentDetails.serviceRequest.id!
                                    )
                                  )
                                }}
                                isOdd={index % 2 === 0}
                                dense={selectedIpd !== undefined}
                                isSelected={
                                  selectedIpd !== undefined &&
                                  e.availableAppointments.serviceRequest.id ===
                                    selectedIpd?.serviceRequest.id &&
                                  innerWidth! > desktopScreenWidth
                                }
                              />
                            </Box>

                            // <ReportDataTable
                            //   usersData={
                            //     dashboardReportSearchSlice.availableAppointments ?? []
                            //   }
                            //   type={type}
                            //   onAppointmentSelected={(
                            //     appointmentDetails: FhirClinicIpdDetails
                            //   ) => {
                            //     setSelectedIPd(appointmentDetails)

                            //     dispatch(
                            //       fetchIPDAppointmentDetails(
                            //         appointmentDetails.serviceRequest.id!
                            //       )
                            //     )
                            //   }}
                            // />
                          )
                        )}
                      </Box>
                      {selectedIpd && innerWidth! > desktopScreenWidth && (
                        <Box display='flex' width='80%' flexGrow={2}>
                          <IPDSplitDetails
                            ipdDetailsDataFinal={selectedIpd}
                            type={type}
                            onCloseClicked={() => {
                              setSelectedIPd(undefined)
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </div>
  )
}
