import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { WelloImaging } from 'models/WelloConditions'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { RootState } from 'redux/rootReducer'
import { ImagingTechnics } from 'utils/constants/availableImagings'
import { Specializations } from 'utils/constants/spcializations'
import { logger } from 'utils/logger'
import {
  addNewImagingPrescriptions,
  UpdateImaging,
} from 'utils/sopaNotes/plan/imagingPlanHelpers'
import { ValueSetSelectMultipleWithDesignation } from 'views/components/common/MultipleSelectFromValueSetUrlWithDesination'
import { ValueSetSelectMultiple } from 'views/components/common/MultiSelectFromValueSetUrl'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { ValueSetSelectSingleForOther } from 'views/components/common/singleSelectValueFromValueSetUrlOther'
import { WelloMultiSelectAutoComplete } from 'views/components/LeftMenu/WelloMultiSelectAutoComplete'

interface Props {
  open: boolean
  patientReference: R4.IReference
  encounterReference: R4.IReference
  onClose: () => void
  onLabDiagnosticsAdded: () => void
  existingImaging: WelloImaging
  appointmentId: string
}
export const EditImagingTest: React.FC<Props> = ({
  open,
  onClose,
  onLabDiagnosticsAdded,
  patientReference,
  encounterReference,
  existingImaging,
  appointmentId,
}) => {
  const { t } = useTranslation(['common'])
  const [selectedPlanningDefs, setSelectedPlanDefs] = useState<R4.ICoding[]>([])

  function resetForm() {
    setSelectedPlanDefs([])
  }

  const [currentCondition, setCurrentCondition] = useState<WelloImaging>({
    id: existingImaging.id,
    existingObs: existingImaging.existingObs,
    category: existingImaging.category,
    coding: existingImaging.coding,
  })

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const dispatch = useDispatch()

  const preselectedCodes = useSelector(
    (state: RootState) =>
      state.imagingDiagnosticsListSlice.diagnosticTestsLoincCodes
  )

  function addCurrentDetails() {
    if (
      currentCondition.coding &&
      currentCondition.coding.length > 0 &&
      currentCondition.existingObs
    ) {
      setUpdateStatus({ requesting: true })
      UpdateImaging(
        patientReference,
        encounterReference,

        currentCondition.coding,
        currentCondition.existingObs,
        appointmentId,
        existingImaging.category
      ).then((e) => {
        if (e) {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('Imaging Diagnostics updated successfully'))

          onClose()
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(
            showErrorAlert(
              'Error adding Imaging Diagnostics. Please try again later'
            )
          )
        }
      })
    }
  }

  useEffect(() => {
    setCurrentCondition({
      id: existingImaging.id,
      existingObs: existingImaging.existingObs,
      coding: existingImaging.coding,
    })
  }, [existingImaging])

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      maxWidth='sm'
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Edit Imaging Studies</DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: '#ededed',
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0,
        }}
      >
        <Box marginX={2} marginY={1} display='flex' flexDirection='column'>
          <Box marginY={1} display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='row'>
              <ValueSetSelectSingleForOther
                key='wellopathy-imaging-techniques-vs-category'
                title='Imaging Category'
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-imaging-tech-categories-vs'
                id='imaging_cat'
                fuzzySearch={true}
                disabled={true}
                onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
                  if (conditionsData) {
                    setCurrentCondition({
                      ...currentCondition,
                      category: conditionsData,
                    })
                    // setCategory(conditionsData)
                  }
                }}
                helperText={undefined}
                placeHolder='Category'
                preSelectedOptions={existingImaging.category}
                alwaysShowAllOptions={true}
                isImaging={true}
              />
            </Box>
            <Box display='flex' flexDirection='row'>
              {existingImaging.category &&
                existingImaging.category.code === 'LP6207-7' && (
                  <ValueSetSelectMultipleWithDesignation
                    key='wellopathy-imaging-techniques-vs-medicines1'
                    title='Imaging Studies'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-imaging-tech-ct-vs'
                    id='imaging-specialty'
                    fuzzySearch={true}
                    pluralWord='Imagings'
                    disabled={existingImaging.category === undefined}
                    singularWorld='Imaging'
                    onOptionSelected={(selectedImagings) => {
                      setCurrentCondition({
                        ...currentCondition,
                        coding: selectedImagings,
                      })
                    }}
                    helperText={undefined}
                    error={undefined}
                    preSelectedOptions={currentCondition.coding}
                  />
                )}
              {existingImaging.category &&
                existingImaging.category.code === 'LP6406-5' && (
                  <ValueSetSelectMultipleWithDesignation
                    key='wellopathy-imaging-techniques-vs-medicines2'
                    title='Imaging Studies'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-imaging-tech-mri-vs'
                    id='imaging-specialty'
                    fuzzySearch={true}
                    pluralWord='Imagings'
                    disabled={existingImaging.category === undefined}
                    singularWorld='Imaging'
                    onOptionSelected={(selectedImagings) => {
                      setCurrentCondition({
                        ...currentCondition,
                        coding: selectedImagings,
                      })
                    }}
                    helperText={undefined}
                    error={undefined}
                    preSelectedOptions={currentCondition.coding}
                  />
                )}
              {existingImaging.category &&
                existingImaging.category.code === 'LA15415-5' && (
                  <ValueSetSelectMultipleWithDesignation
                    key='wellopathy-imaging-techniques-vs-medicines3'
                    title='Imaging Studies'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-imaging-tech-usg-vs'
                    id='imaging-specialty'
                    fuzzySearch={true}
                    pluralWord='Imagings'
                    disabled={existingImaging.category === undefined}
                    singularWorld='Imaging'
                    onOptionSelected={(selectedImagings) => {
                      setCurrentCondition({
                        ...currentCondition,
                        coding: selectedImagings,
                      })
                    }}
                    helperText={undefined}
                    error={undefined}
                    preSelectedOptions={currentCondition.coding}
                  />
                )}

              {existingImaging.category &&
                existingImaging.category.code === 'LA9481-8' && (
                  <ValueSetSelectMultipleWithDesignation
                    key='wellopathy-imaging-techniques-vs-medicines4'
                    title='Imaging Studies'
                    url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-imaging-tech-xrays-vs'
                    id='imaging-specialty'
                    fuzzySearch={true}
                    pluralWord='Imagings'
                    disabled={existingImaging.category === undefined}
                    singularWorld='Imaging'
                    onOptionSelected={(selectedImagings) => {
                      setCurrentCondition({
                        ...currentCondition,
                        coding: selectedImagings,
                      })
                    }}
                    helperText={undefined}
                    error={undefined}
                    preSelectedOptions={currentCondition.coding}
                  />
                )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {updateStatus.requesting && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
        <Box display='flex' flexDirection='row' p={1} alignItems='center'>
          <Box display='flex' flexDirection='row'>
            <Box paddingX={1}>
              <Button
                onClick={() => {
                  resetForm()
                  onClose()
                }}
                variant='outlined'
                id='cancel'
                disableElevation
              >
                {t('labelCommon:cancel')}
              </Button>
            </Box>

            <Box paddingRight={0.2}>
              <Button
                onClick={() => {
                  addCurrentDetails()
                }}
                variant='contained'
                color='primary'
                id='save'
                disableElevation
                disabled={updateStatus.requesting}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
