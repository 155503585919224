import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ICoding,
  IExtension,
  INutritionOrder,
  IPatient,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import { Diet } from 'models/diet'
import { LLMDietPlanOutPut } from 'models/dietPlan/LLMDietPLanOutput'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import moment from 'moment'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import {
  bedTime,
  breakFast,
  dinner,
  earlyMorning,
  lunch,
  midMorning,
  snack,
} from 'utils/constants'

import {
  getDefaultCodeOfSystemFromCodableConcept,
  getExtensionValueDataFromValueForDietOrder,
} from 'utils/fhirResourcesHelper'
import { getUniqueTempId } from './idHelpers'
import {
  getBedTime,
  getBedTimeCode,
  getBedTimeText,
  getBedTimeTimeForOrder,
  getBreakfastDisplayText,
  getBreakfastTimeForOrder,
  getDinnerText,
  getDinnerTime,
  getDinnerTimeCode,
  getDinnerTimeForOrder,
  getEarlyBreakFastTime,
  getEarlyBreakFastTimeCode,
  getEarlyMorningTime,
  getEarlyMorningTimeCode,
  getEarlyMorningTimeForOrder,
  getLunchDisplayText,
  getLunchTime,
  getLunchTimeCode,
  getLunchTImeForOrder,
  getMidMorningDisplayText,
  getMidMorningTime,
  getMidMorningTimeCode,
  getMidMorningTimeForOrder,
  getMorningDisplayText,
  getNutritionOrdersForSepcificTimeForOPD,
  getSnackDisplayText,
  getSnackTime,
  getSnackTimeCode,
  getSnackTImeForOrder,
} from './ipdDietHelper'

export interface DateWiseNutritionOrders {
  date: string
  nutritionOrders: R4.INutritionOrder[]
}

export function getDietPlanFromBundleForFixed(
  dietTime: DietTiming[],
  responseBundle: R4.IBundle,
  patientReference: R4.IReference,
  currentEncounterId?: string,
  isForSpecificDoctor?: boolean
): Diet[] {
  const planData: Diet[] = []

  let encounterId: string | undefined = currentEncounterId

  const dateWiseOrders: DateWiseNutritionOrders[] = []
  const allNutritionOrders: R4.INutritionOrder[] = []
  const latestNutritionOrder: R4.INutritionOrder | undefined =
    getLatestNutritionOrderFromBundleSorted(responseBundle)
  if (isForSpecificDoctor && latestNutritionOrder) {
    encounterId = getEncounterIdFromNutritionOrder(latestNutritionOrder)
  }
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (
                e.resource?.resourceType === 'NutritionOrder' &&
                e.resource?.intent === 'plan'
              ) {
                if (isForSpecificDoctor) {
                  const encounterIdFromNutritionOrder: string | undefined =
                    getEncounterIdFromNutritionOrder(
                      e.resource as R4.INutritionOrder
                    )

                  if (encounterIdFromNutritionOrder === encounterId) {
                    allNutritionOrders.push(e.resource)
                  }
                } else {
                  allNutritionOrders.push(e.resource)
                }
              }
            })
          }
        }
      }
    }
    const finalOrders: R4.INutritionOrder[] = []

    if (allNutritionOrders.length > 0) {
      for (let i = 0; i < allNutritionOrders.length; i++) {
        if (allNutritionOrders[i].oralDiet) {
          const diet = allNutritionOrders[i].oralDiet
          if (diet)
            if (
              getExtensionValueDataFromValueForDietOrder(
                diet.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext'
              ).length > 0
            ) {
              finalOrders.push(allNutritionOrders[i])
            }
        }
      }
    }

    const currentDate: Date = new Date()
    if (encounterId) {
      planData.push({
        date: moment(currentDate).format('YYYY-MM-DD'),
        eight: getNutritionOrdersForSpecificTime(
          finalOrders,
          getBreakfastTimeForOrder(dietTime),
          encounterId,
          patientReference,
          currentDate,
          getEarlyBreakFastTimeCode(dietTime)!,
          getBreakfastDisplayText(dietTime)
        ),
        five: getNutritionOrdersForSpecificTime(
          finalOrders,
          getSnackTImeForOrder(dietTime),
          encounterId,
          patientReference,
          currentDate,
          getSnackTimeCode(dietTime),
          getSnackDisplayText(dietTime)
        ),
        id: '',
        seven: getNutritionOrdersForSpecificTime(
          finalOrders,
          getDinnerTimeForOrder(dietTime),
          encounterId,
          patientReference,
          currentDate,
          getDinnerTimeCode(dietTime),
          getDinnerText(dietTime)
        ),
        six: getNutritionOrdersForSpecificTime(
          finalOrders,
          getEarlyMorningTimeForOrder(dietTime),
          encounterId,
          patientReference,
          currentDate,
          getEarlyMorningTimeCode(dietTime),
          getMorningDisplayText(dietTime)
        ),
        twelve: getNutritionOrdersForSpecificTime(
          finalOrders,
          getMidMorningTimeForOrder(dietTime),
          encounterId,
          patientReference,
          currentDate,
          getMidMorningTimeCode(dietTime),
          getMidMorningDisplayText(dietTime)
        ),
        two: getNutritionOrdersForSpecificTime(
          finalOrders,
          getLunchTImeForOrder(dietTime),
          encounterId,
          patientReference,
          currentDate,
          getLunchTimeCode(dietTime),
          getLunchDisplayText(dietTime)
        ),
        nine: getNutritionOrdersForSpecificTime(
          finalOrders,
          getBedTimeTimeForOrder(dietTime),
          encounterId,
          patientReference,
          currentDate,
          getBedTimeCode(dietTime),
          getBedTimeText(dietTime)
        ),
      })
    }
  } catch (error) {
    console.error(error)
  }

  return planData
}

export function getDietPlanFromBundle(
  dietTime: DietTiming[],
  responseBundle: R4.IBundle,
  start: string,
  end: string,
  patientReference: R4.IReference,
  currentEncounterId?: string,
  isForSpecificDoctor?: boolean
): Diet[] {
  const planData: Diet[] = []

  let encounterId: string | undefined = currentEncounterId
  const finalOrder: R4.INutritionOrder[] = []
  const dateWiseOrders: DateWiseNutritionOrders[] = []
  const allNutritionOrders: R4.INutritionOrder[] = []
  const latestNutritionOrder: R4.INutritionOrder | undefined =
    getLatestNutritionOrderFromBundleSorted(responseBundle)
  if (isForSpecificDoctor && latestNutritionOrder) {
    encounterId = getEncounterIdFromNutritionOrder(latestNutritionOrder)
  }
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (
                e.resource?.resourceType === 'NutritionOrder' &&
                e.resource?.intent === 'plan'
              ) {
                if (isForSpecificDoctor) {
                  const encounterIdFromNutritionOrder: string | undefined =
                    getEncounterIdFromNutritionOrder(
                      e.resource as R4.INutritionOrder
                    )

                  if (encounterIdFromNutritionOrder === encounterId) {
                    allNutritionOrders.push(e.resource)
                  } else {
                    allNutritionOrders.push(e.resource)
                  }
                } else {
                  allNutritionOrders.push(e.resource)
                }
              }
            })
          }

          allNutritionOrders.forEach((item) => {
            const dateDet = moment(
              item.oralDiet?.schedule![0].event![0]
            ).format('YYYY-MM-DD')
            const index = dateWiseOrders.findIndex((s) => s.date === dateDet)
            if (index < 0) {
              dateWiseOrders.push({
                date: dateDet,
                nutritionOrders: [item],
              })
            } else {
              dateWiseOrders[index].nutritionOrders.push(item)
            }
          })
        }
      }
    }

    const currentDate: Date = new Date()
    if (encounterId) {
      let currentDateData: Date = moment(start).toDate()
      let endDateData: Date = moment(end).toDate()

      do {
        const index = dateWiseOrders.findIndex(
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          (e) => e.date === moment(currentDateData).format('YYYY-MM-DD')
        )

        if (index < 0) {
        } else {
          planData.push({
            date: moment(currentDateData).format('YYYY-MM-DD'),
            week: moment(moment(currentDateData).toDate(), 'MM-DD-YYYY')
              .isoWeek()
              .toString(),
            eight: getNutritionOrdersForSepcificTimeForOPD(
              dateWiseOrders[index].nutritionOrders,
              getEarlyBreakFastTime(dietTime),
              encounterId,
              patientReference,
              currentDateData,
              getEarlyBreakFastTimeCode(dietTime),
              getBreakfastDisplayText(dietTime),
              getBreakfastTimeForOrder(dietTime)
            ),
            five: getNutritionOrdersForSepcificTimeForOPD(
              dateWiseOrders[index].nutritionOrders,
              getSnackTime(dietTime),
              encounterId,
              patientReference,
              currentDateData,
              getSnackTimeCode(dietTime),
              getSnackDisplayText(dietTime),
              getSnackTImeForOrder(dietTime)
            ),
            id: `${moment(currentDateData).format('YYYY-MM-DD')}id`,
            seven: getNutritionOrdersForSepcificTimeForOPD(
              dateWiseOrders[index].nutritionOrders,
              getDinnerTime(dietTime),
              encounterId,
              patientReference,
              currentDateData,
              getDinnerTimeCode(dietTime),
              getDinnerText(dietTime),
              getDinnerTimeForOrder(dietTime)
            ),
            six: getNutritionOrdersForSepcificTimeForOPD(
              dateWiseOrders[index].nutritionOrders,
              getEarlyMorningTime(dietTime),
              encounterId,
              patientReference,
              currentDateData,
              getEarlyMorningTimeCode(dietTime),
              getMorningDisplayText(dietTime),
              getEarlyMorningTimeForOrder(dietTime)
            ),
            twelve: getNutritionOrdersForSepcificTimeForOPD(
              dateWiseOrders[index].nutritionOrders,
              getMidMorningTime(dietTime),
              encounterId,
              patientReference,
              currentDateData,
              getMidMorningTimeCode(dietTime),
              getMidMorningDisplayText(dietTime),
              getMidMorningTimeForOrder(dietTime)
            ),
            two: getNutritionOrdersForSepcificTimeForOPD(
              dateWiseOrders[index].nutritionOrders,
              getLunchTime(dietTime),
              encounterId,
              patientReference,
              currentDateData,
              getLunchTimeCode(dietTime),
              getLunchDisplayText(dietTime),
              getLunchTImeForOrder(dietTime)
            ),
            nine: getNutritionOrdersForSepcificTimeForOPD(
              dateWiseOrders[index].nutritionOrders,
              getBedTime(dietTime),
              encounterId,
              patientReference,
              currentDateData,
              getBedTimeCode(dietTime),
              getBedTimeText(dietTime),
              getBedTimeTimeForOrder(dietTime)
            ),
          })
        }

        const newDate = moment(currentDateData).add(1, 'days').toDate()
        currentDateData = newDate
        if (moment().isAfter(moment())) {
          const newEndDate = moment().local().endOf('day').toDate()
          endDateData = newEndDate
        }
      } while (currentDateData <= endDateData)
    }
  } catch (error) {}

  return planData
}

export function getNOSFromBundle(
  dietTime: DietTiming[],
  responseBundle: R4.IBundle,
  start: string,
  end: string,
  patientReference: R4.IReference,
  currentEncounterId?: string,
  isForSpecificDoctor?: boolean
): INutritionOrder[] {
  const planData: INutritionOrder[] = []

  let encounterId: string | undefined = currentEncounterId
  const finalOrder: R4.INutritionOrder[] = []
  const dateWiseOrders: DateWiseNutritionOrders[] = []
  const allNutritionOrders: R4.INutritionOrder[] = []
  const latestNutritionOrder: R4.INutritionOrder | undefined =
    getLatestNutritionOrderFromBundleSorted(responseBundle)
  if (isForSpecificDoctor && latestNutritionOrder) {
    encounterId = getEncounterIdFromNutritionOrder(latestNutritionOrder)
  }
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (
                e.resource?.resourceType === 'NutritionOrder' &&
                e.resource?.intent === 'plan'
              ) {
                if (isForSpecificDoctor) {
                  const encounterIdFromNutritionOrder: string | undefined =
                    getEncounterIdFromNutritionOrder(
                      e.resource as R4.INutritionOrder
                    )

                  if (encounterIdFromNutritionOrder === encounterId) {
                    allNutritionOrders.push(e.resource)
                  } else {
                    allNutritionOrders.push(e.resource)
                  }
                } else {
                  allNutritionOrders.push(e.resource)
                }
              }
            })
          }
        }
      }
    }

    for (const nutritionOrder of allNutritionOrders) {
      const dateDet = moment(
        nutritionOrder.oralDiet?.schedule![0].event![0]
      ).format('YYYY-MM-DD')

      if (
        moment(dateDet).isSameOrAfter(moment(start)) &&
        moment(dateDet).isSameOrBefore(moment(end))
      ) {
        planData.push(nutritionOrder)
      }
    }
  } catch (error) {}

  return planData
}

export function getDietPlanFromBundleRawNutrition(
  responseBundle: R4.IBundle,
  patientReference: R4.IReference,
  currentEncounterId?: string,
  isForSpecificDoctor?: boolean
): INutritionOrder[] {
  let encounterId: string | undefined = currentEncounterId

  const allNutritionOrders: R4.INutritionOrder[] = []
  const latestNutritionOrder: R4.INutritionOrder | undefined =
    getLatestNutritionOrderFromBundleSorted(responseBundle)
  if (isForSpecificDoctor && latestNutritionOrder) {
    encounterId = getEncounterIdFromNutritionOrder(latestNutritionOrder)
  }
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (
                e.resource?.resourceType === 'NutritionOrder' &&
                e.resource?.intent === 'plan'
              ) {
                if (isForSpecificDoctor) {
                  const encounterIdFromNutritionOrder: string | undefined =
                    getEncounterIdFromNutritionOrder(
                      e.resource as R4.INutritionOrder
                    )

                  if (encounterIdFromNutritionOrder === encounterId) {
                    allNutritionOrders.push(e.resource)
                  } else {
                    allNutritionOrders.push(e.resource)
                  }
                } else {
                  allNutritionOrders.push(e.resource)
                }
              }
            })
          }
        }
      }
    }

    return allNutritionOrders
  } catch (error) {
    console.error(error)
  }

  return []
}

/// get encounter id from nutrition order
export function getEncounterIdFromNutritionOrder(
  nutritionOrder: R4.INutritionOrder
): string | undefined {
  if (nutritionOrder.encounter) {
    if (nutritionOrder.encounter.reference) {
      return nutritionOrder.encounter.reference.split('/')[1]
    }
  }
  return undefined
}

/// sort nutrition orders by date and get latest nutrition order from bundle
export function getLatestNutritionOrderFromBundleSorted(
  responseBundle: R4.IBundle
): R4.INutritionOrder | undefined {
  let nutritionOrder: R4.INutritionOrder | undefined
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            const nutritionOrders: R4.INutritionOrder[] =
              getNutritionOrdersFromBundle(responseBundle)

            return nutritionOrders[0]
          }
        }
      }
    }
  } catch (error) {
    console.error(error)
  }

  return nutritionOrder
}

/// get nitrition orders of same encounter
export function getNutritionOrdersOfSameEncounter(
  nutritionOrders: R4.INutritionOrder[],
  encounterId: string
): R4.INutritionOrder[] {
  const filteredNOS = [...nutritionOrders].filter(
    (e) => getEncounterIdFromNutritionOrder(e) === encounterId
  )

  if (filteredNOS && filteredNOS.length > 0) {
    return filteredNOS
  }
  return nutritionOrders
}

/// get nutrition orders from bundle
export function getNutritionOrdersFromBundle(
  responseBundle: R4.IBundle
): R4.INutritionOrder[] {
  const nutritionOrders: R4.INutritionOrder[] = []
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (
                e.resource?.resourceType === 'NutritionOrder' &&
                e.resource?.intent === 'plan'
              ) {
                nutritionOrders.push(e.resource as R4.INutritionOrder)
              }
            })
          }
        }
      }
    }
  } catch (error) {
    console.error(error)
  }

  return nutritionOrders
}

function getNutritionOrdersForSpecificTime(
  nutritionOrders: R4.INutritionOrder[],
  type: string,
  encounterId: string,
  patientReference: R4.IReference,
  currentDate?: Date,
  dietCode?: R4.ICoding,
  displayText?: string
): R4.INutritionOrder[] {
  let filteredNOS = [...nutritionOrders].filter(
    (e) => getDayCodeFromNO(e) === type
  )

  if (dietCode && filteredNOS.length === 0) {
    filteredNOS = [...nutritionOrders].filter(
      (e) => getDayCodeFromNOFOrDiet(e) === dietCode.code
    )
  }

  if (filteredNOS && filteredNOS.length > 0) {
    return filteredNOS
  }
  return [
    {
      resourceType: 'NutritionOrder',
      status: 'active',
      id: getUniqueTempId(),
      encounter: {
        reference: `Encounter/${encounterId}`,
      },
      patient: patientReference,
      oralDiet: {
        extension: [],
        schedule: [
          {
            event:
              currentDate !== undefined
                ? [currentDate.toISOString()]
                : undefined,
            repeat: {
              timeOfDay: [type.trim()],
            },
            code: {
              text: displayText ?? '',
              coding: dietCode
                ? [dietCode]
                : [
                    {
                      code: type.trim(),
                    },
                  ],
            },
          },
        ],
      },
    },
  ]
}

function getDayCodeFromNO(nutritionOrders: R4.INutritionOrder): string {
  if (nutritionOrders.oralDiet) {
    if (
      nutritionOrders.oralDiet.schedule &&
      nutritionOrders.oralDiet.schedule.length > 0
    ) {
      return (
        getDefaultCodeOfSystemFromCodableConcept(
          nutritionOrders.oralDiet.schedule[0].code
        ) ?? ''
      )
    }
  }
  return ''
}

function getDayCodeFromNOFOrDiet(nutritionOrders: R4.INutritionOrder): string {
  if (nutritionOrders.oralDiet) {
    if (
      nutritionOrders.oralDiet.schedule &&
      nutritionOrders.oralDiet.schedule.length > 0
    ) {
      const codeFinal = nutritionOrders.oralDiet.schedule[0].code
      if (codeFinal) {
        const codingData = codeFinal.coding ?? []
        if (codingData.length > 0) {
          return codingData[0].code ?? ''
        }
      }
    }
  }
  return ''
}

export function getPreferredFoods(nos: R4.INutritionOrder[]) {
  let preferredFoods: string[] = []

  nos.forEach((e) => {
    e.foodPreferenceModifier?.forEach((k) => {
      if (k.text && k.text.length > 0) {
        preferredFoods = [k.text]
      }
    })
  })

  if (preferredFoods.length > 0) {
    return preferredFoods.join(', ')
  }

  return undefined
}

export function getAvoidingFoods(nos: R4.INutritionOrder[]) {
  let preferredFoods: string[] = []

  nos.forEach((e) => {
    e.excludeFoodModifier?.forEach((k) => {
      if (k.text && k.text.length > 0) {
        preferredFoods = [k.text]
      }
    })
  })

  if (preferredFoods.length > 0) {
    return preferredFoods.join(', ')
  }

  return undefined
}

export function getStartDate(nos: R4.INutritionOrder[]) {
  let date: string | undefined

  const dateArray: string[] = []
  nos.forEach((e) => {
    if (e.oralDiet) {
      if (e.oralDiet.schedule) {
        if (e.oralDiet.schedule[0].repeat) {
          if (e.oralDiet.schedule[0].repeat.boundsPeriod) {
            date = e.oralDiet.schedule[0].repeat.boundsPeriod.start
            if (date) {
              dateArray.push(date)
            }
          } else if (e.oralDiet.schedule[0].event) {
            date = e.oralDiet.schedule[0].event[0]
            if (date) {
              dateArray.push(date)
            }
          }
        } else if (e.oralDiet.schedule[0].event) {
          date = e.oralDiet.schedule[0].event[0]
          if (date) {
            dateArray.push(date)
          }
        }
      }
    }
  })

  if (dateArray.length > 0) {
    dateArray.sort((a, b) => moment(a).diff(b))
    // const finalDate = moment.utc(dateArray[0]).local()
    return dateArray[0]
  }

  return undefined
}

export function getEndDate(nos: R4.INutritionOrder[]) {
  let date: string | undefined
  const dateArray: string[] = []
  nos.forEach((e) => {
    if (e.oralDiet) {
      if (e.oralDiet.schedule) {
        if (e.oralDiet.schedule[0].repeat) {
          if (e.oralDiet.schedule[0].repeat.boundsPeriod) {
            date = e.oralDiet.schedule[0].repeat.boundsPeriod.end
            if (date) {
              dateArray.push(date)
            }
          } else if (e.oralDiet.schedule[0].event) {
            date = e.oralDiet.schedule[0].event[0]
            if (date) {
              dateArray.push(date)
            }
          }
        } else if (e.oralDiet.schedule[0].event) {
          date = e.oralDiet.schedule[0].event[0]
          if (date) {
            dateArray.push(date)
          }
        }
      }
    }
  })

  if (dateArray.length > 0) {
    dateArray.sort((a, b) => moment(b).diff(a))
    // const finalDate = moment.utc(dateArray[0]).local()
    return dateArray[0]
  }

  return undefined
}

export function getCreatedDate(nos: R4.INutritionOrder[]) {
  const customNos = [...nos]
  customNos.sort((a, b) => {
    if (a.meta && b.meta) {
      if (a.meta?.lastUpdated && b.meta?.lastUpdated) {
        return (
          new Date(b.meta?.lastUpdated).getTime() -
          new Date(a.meta?.lastUpdated).getTime()
        )
      }
    }
    return 0
  })
  if (customNos && customNos.length > 0) {
    const date: string | undefined = customNos[0].meta?.lastUpdated

    return date
  }
  return undefined
}

export function getDietPlanFromBundleForCustom(
  dietTime: DietTiming[],
  startDate: string,
  responseBundle: R4.IBundle,
  patientReference: R4.IReference,
  currentEncounterId?: string,
  isForSpecificDoctor?: boolean,
  endDate?: string
): Diet[] {
  const planData: Diet[] = []

  let encounterId: string | undefined = currentEncounterId
  const finalOrders: R4.INutritionOrder[] = []

  const dateWiseOrders: DateWiseNutritionOrders[] = []
  const allNutritionOrders: R4.INutritionOrder[] = []
  const latestNutritionOrder: R4.INutritionOrder | undefined =
    getLatestNutritionOrderFromBundleSorted(responseBundle)
  if (isForSpecificDoctor && latestNutritionOrder) {
    encounterId = getEncounterIdFromNutritionOrder(latestNutritionOrder)
  }
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (
                e.resource?.resourceType === 'NutritionOrder' &&
                e.resource?.intent === 'plan'
              ) {
                if (isForSpecificDoctor) {
                  const encounterIdFromNutritionOrder: string | undefined =
                    getEncounterIdFromNutritionOrder(
                      e.resource as R4.INutritionOrder
                    )

                  if (encounterIdFromNutritionOrder === encounterId) {
                    allNutritionOrders.push(e.resource)
                  }
                  allNutritionOrders.push(e.resource)
                } else {
                  allNutritionOrders.push(e.resource)
                }
              }
            })
          }

          if (allNutritionOrders.length > 0) {
            for (let i = 0; i < allNutritionOrders.length; i++) {
              if (allNutritionOrders[i].oralDiet) {
                const diet = allNutritionOrders[i].oralDiet
                if (diet)
                  if (
                    getExtensionValueDataFromValueForDietOrder(
                      diet.extension ?? [],
                      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext'
                    ).length === 0
                  ) {
                    finalOrders.push(allNutritionOrders[i])
                  }
              }
            }
          }

          finalOrders.forEach((item) => {
            const dateDet = moment(
              item.oralDiet?.schedule![0].event![0]
            ).format('YYYY-MM-DD')
            const index = dateWiseOrders.findIndex((s) => s.date === dateDet)
            if (index < 0) {
              dateWiseOrders.push({
                date: dateDet,
                nutritionOrders: [item],
              })
            } else {
              dateWiseOrders[index].nutritionOrders.push(item)
            }
          })
        }
      }
    }

    const currentDate: Date = new Date()

    if (encounterId) {
      if (endDate === undefined && dateWiseOrders.length === 0) {
        planData.push({
          date: startDate,
          eight: getNutritionOrdersForSpecificTime(
            finalOrders,
            getBreakfastTimeForOrder(dietTime),
            encounterId,
            patientReference,
            moment(startDate).toDate(),
            getEarlyBreakFastTimeCode(dietTime)!,
            getBreakfastDisplayText(dietTime)
          ),
          five: getNutritionOrdersForSpecificTime(
            finalOrders,
            getSnackTImeForOrder(dietTime),
            encounterId,
            patientReference,
            moment(startDate).toDate(),
            getSnackTimeCode(dietTime),
            getSnackDisplayText(dietTime)
          ),
          id: '',
          seven: getNutritionOrdersForSpecificTime(
            finalOrders,
            getDinnerTimeForOrder(dietTime),
            encounterId,
            patientReference,
            moment(startDate).toDate(),
            getDinnerTimeCode(dietTime),
            getDinnerText(dietTime)
          ),
          six: getNutritionOrdersForSpecificTime(
            finalOrders,
            getEarlyMorningTimeForOrder(dietTime),
            encounterId,
            patientReference,
            moment(startDate).toDate(),
            getEarlyMorningTimeCode(dietTime),
            getMorningDisplayText(dietTime)
          ),
          twelve: getNutritionOrdersForSpecificTime(
            finalOrders,
            getMidMorningTimeForOrder(dietTime),
            encounterId,
            patientReference,
            moment(startDate).toDate(),
            getMidMorningTimeCode(dietTime),
            getMidMorningDisplayText(dietTime)
          ),
          two: getNutritionOrdersForSpecificTime(
            finalOrders,
            getLunchTImeForOrder(dietTime),
            encounterId,
            patientReference,
            moment(startDate).toDate(),
            getLunchTimeCode(dietTime),
            getLunchDisplayText(dietTime)
          ),
          nine: getNutritionOrdersForSpecificTime(
            finalOrders,
            getBedTimeTimeForOrder(dietTime),
            encounterId,
            patientReference,
            moment(startDate).toDate(),
            getBedTimeCode(dietTime),
            getBedTimeText(dietTime)
          ),
        })
      } else {
        let currentDateData: Date = moment(startDate).toDate()
        let endDateData: Date = endDate
          ? moment(endDate).toDate()
          : moment().toDate()

        do {
          if (
            moment(currentDateData).format('YYYY-MM-DD') <=
            moment(endDateData).format('YYYY-MM-DD')
          ) {
            const index = dateWiseOrders.findIndex(
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line @typescript-eslint/no-loop-func
              (e) => e.date === moment(currentDateData).format('YYYY-MM-DD')
            )

            if (index < 0) {
              planData.push({
                date: moment(currentDateData).format('YYYY-MM-DD'),
                eight: [
                  {
                    resourceType: 'NutritionOrder',
                    id: getUniqueTempId(),
                    status: 'active',

                    extension: [
                      {
                        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                        valueReference: {
                          reference: `Organization/${
                            getCurrentUserUnitDetails().id
                          }`,
                        },
                      },
                    ],
                    encounter: {
                      reference: `Encounter/${encounterId}`,
                    },
                    dateTime: currentDateData.toISOString(),
                    patient: patientReference,
                    oralDiet: {
                      extension: [],
                      schedule: [
                        {
                          event: [currentDateData.toISOString()],
                          repeat: {
                            timeOfDay: [getBreakfastTimeForOrder(dietTime)],
                          },
                          code: {
                            text: getBreakfastDisplayText(dietTime),
                            coding: [getEarlyBreakFastTimeCode(dietTime)!],
                          },
                        },
                      ],
                    },
                  },
                ],
                five: [
                  {
                    resourceType: 'NutritionOrder',
                    id: getUniqueTempId(),
                    status: 'active',

                    encounter: {
                      reference: `Encounter/${encounterId}`,
                    },
                    extension: [
                      {
                        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                        valueReference: {
                          reference: `Organization/${
                            getCurrentUserUnitDetails().id
                          }`,
                        },
                      },
                    ],
                    patient: patientReference,
                    oralDiet: {
                      extension: [],
                      schedule: [
                        {
                          event: [currentDateData.toISOString()],
                          repeat: {
                            timeOfDay: [getSnackTImeForOrder(dietTime)],
                          },
                          code: {
                            coding: [getSnackTimeCode(dietTime)!],
                            text: getSnackDisplayText(dietTime),
                          },
                        },
                      ],
                    },
                  },
                ],
                id: `${moment(currentDateData).format('YYYY-MM-DD')}id`,
                seven: [
                  {
                    resourceType: 'NutritionOrder',
                    id: getUniqueTempId(),
                    status: 'active',

                    encounter: {
                      reference: `Encounter/${encounterId}`,
                    },
                    extension: [
                      {
                        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                        valueReference: {
                          reference: `Organization/${
                            getCurrentUserUnitDetails().id
                          }`,
                        },
                      },
                    ],
                    patient: patientReference,

                    oralDiet: {
                      extension: [],
                      schedule: [
                        {
                          event: [currentDateData.toISOString()],
                          repeat: {
                            timeOfDay: [getDinnerTimeForOrder(dietTime)],
                          },
                          code: {
                            coding: [getDinnerTimeCode(dietTime)!],
                            text: getDinnerText(dietTime),
                          },
                        },
                      ],
                    },
                  },
                ],
                six: [
                  {
                    resourceType: 'NutritionOrder',
                    status: 'active',

                    id: getUniqueTempId(),
                    encounter: {
                      reference: `Encounter/${encounterId}`,
                    },
                    extension: [
                      {
                        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                        valueReference: {
                          reference: `Organization/${
                            getCurrentUserUnitDetails().id
                          }`,
                        },
                      },
                    ],
                    patient: patientReference,
                    oralDiet: {
                      extension: [],
                      schedule: [
                        {
                          event: [currentDateData.toISOString()],
                          repeat: {
                            timeOfDay: [getEarlyMorningTimeForOrder(dietTime)],
                          },
                          code: {
                            coding: [getEarlyMorningTimeCode(dietTime)!],
                            text: getMorningDisplayText(dietTime),
                          },
                        },
                      ],
                    },
                  },
                ],
                twelve: [
                  {
                    resourceType: 'NutritionOrder',
                    status: 'active',
                    intent: 'plan',
                    id: getUniqueTempId(),
                    encounter: {
                      reference: `Encounter/${encounterId}`,
                    },
                    extension: [
                      {
                        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                        valueReference: {
                          reference: `Organization/${
                            getCurrentUserUnitDetails().id
                          }`,
                        },
                      },
                    ],
                    patient: patientReference,
                    oralDiet: {
                      extension: [],
                      schedule: [
                        {
                          event: [currentDateData.toISOString()],
                          repeat: {
                            timeOfDay: [getMidMorningTimeForOrder(dietTime)],
                          },
                          code: {
                            coding: [getMidMorningTimeCode(dietTime)!],
                            text: getMidMorningDisplayText(dietTime),
                          },
                        },
                      ],
                    },
                  },
                ],
                two: [
                  {
                    resourceType: 'NutritionOrder',
                    status: 'active',
                    intent: 'plan',
                    id: getUniqueTempId(),
                    encounter: {
                      reference: `Encounter/${encounterId}`,
                    },
                    extension: [
                      {
                        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                        valueReference: {
                          reference: `Organization/${
                            getCurrentUserUnitDetails().id
                          }`,
                        },
                      },
                    ],
                    patient: patientReference,
                    oralDiet: {
                      extension: [],
                      schedule: [
                        {
                          event: [currentDateData.toISOString()],
                          repeat: {
                            timeOfDay: [getLunchTImeForOrder(dietTime)],
                          },
                          code: {
                            coding: [getLunchTimeCode(dietTime)!],
                            text: getLunchDisplayText(dietTime),
                          },
                        },
                      ],
                    },
                  },
                ],
                nine: [
                  {
                    resourceType: 'NutritionOrder',
                    status: 'active',
                    intent: 'plan',
                    id: getUniqueTempId(),
                    encounter: {
                      reference: `Encounter/${encounterId}`,
                    },
                    extension: [
                      {
                        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
                        valueReference: {
                          reference: `Organization/${
                            getCurrentUserUnitDetails().id
                          }`,
                        },
                      },
                    ],
                    patient: patientReference,
                    oralDiet: {
                      extension: [],
                      schedule: [
                        {
                          event: [currentDateData.toISOString()],
                          repeat: {
                            timeOfDay: [getBedTimeTimeForOrder(dietTime)],
                          },
                          code: {
                            coding: [getBedTimeCode(dietTime)!],
                            text: getBedTimeText(dietTime),
                          },
                        },
                      ],
                    },
                  },
                ],
              })
            } else {
              planData.push({
                date: moment(currentDateData).format('YYYY-MM-DD'),
                eight: getNutritionOrdersForSepcificTimeForOPD(
                  dateWiseOrders[index].nutritionOrders,
                  getEarlyBreakFastTime(dietTime),
                  encounterId,
                  patientReference,
                  currentDateData,
                  getEarlyBreakFastTimeCode(dietTime),
                  getBreakfastDisplayText(dietTime),
                  getBreakfastTimeForOrder(dietTime)
                ),
                five: getNutritionOrdersForSepcificTimeForOPD(
                  dateWiseOrders[index].nutritionOrders,
                  getSnackTime(dietTime),
                  encounterId,
                  patientReference,
                  currentDateData,
                  getSnackTimeCode(dietTime),
                  getSnackDisplayText(dietTime),
                  getSnackTImeForOrder(dietTime)
                ),
                id: `${moment(currentDateData).format('YYYY-MM-DD')}id`,
                seven: getNutritionOrdersForSepcificTimeForOPD(
                  dateWiseOrders[index].nutritionOrders,
                  getDinnerTime(dietTime),
                  encounterId,
                  patientReference,
                  currentDateData,
                  getDinnerTimeCode(dietTime),
                  getDinnerText(dietTime),
                  getDinnerTimeForOrder(dietTime)
                ),
                six: getNutritionOrdersForSepcificTimeForOPD(
                  dateWiseOrders[index].nutritionOrders,
                  getEarlyMorningTime(dietTime),
                  encounterId,
                  patientReference,
                  currentDateData,
                  getEarlyMorningTimeCode(dietTime),
                  getMorningDisplayText(dietTime),
                  getEarlyMorningTimeForOrder(dietTime)
                ),
                twelve: getNutritionOrdersForSepcificTimeForOPD(
                  dateWiseOrders[index].nutritionOrders,
                  getMidMorningTime(dietTime),
                  encounterId,
                  patientReference,
                  currentDateData,
                  getMidMorningTimeCode(dietTime),
                  getMidMorningDisplayText(dietTime),
                  getMidMorningTimeForOrder(dietTime)
                ),
                two: getNutritionOrdersForSepcificTimeForOPD(
                  dateWiseOrders[index].nutritionOrders,
                  getLunchTime(dietTime),
                  encounterId,
                  patientReference,
                  currentDateData,
                  getLunchTimeCode(dietTime),
                  getLunchDisplayText(dietTime),
                  getLunchTImeForOrder(dietTime)
                ),
                nine: getNutritionOrdersForSepcificTimeForOPD(
                  dateWiseOrders[index].nutritionOrders,
                  getBedTime(dietTime),
                  encounterId,
                  patientReference,
                  currentDateData,
                  getBedTimeCode(dietTime),
                  getBedTimeText(dietTime),
                  getBedTimeTimeForOrder(dietTime)
                ),
              })
            }
          }

          const newDate = moment(currentDateData).add(1, 'days').toDate()
          currentDateData = newDate
          if (moment().isAfter(moment())) {
            const newEndDate = moment().local().endOf('day').toDate()
            endDateData = newEndDate
          }
        } while (currentDateData <= endDateData)
      }
    }
  } catch (error) {
    console.error(error)
  }

  return planData
}

export function getFoodTimeCoding(label: string): R4.ICoding[] {
  let timeCoding: R4.ICoding[] = []

  if (label === 'Early Morning') {
    timeCoding = earlyMorning
  }
  if (label === 'Breakfast') {
    timeCoding = breakFast
  }
  if (label === 'Mid Morning') {
    timeCoding = midMorning
  }
  if (label === 'Lunch') {
    timeCoding = lunch
  }
  if (label === 'Evening Snack') {
    timeCoding = snack
  }
  if (label === 'Dinner') {
    timeCoding = dinner
  }
  if (label === 'Bed Time') {
    timeCoding = bedTime
  }
  return timeCoding
}

export function getDietPlanFromBundleRawNutritionForFixed(
  responseBundle: R4.IBundle,
  patientReference: R4.IReference,
  currentEncounterId?: string,
  isForSpecificDoctor?: boolean
): INutritionOrder[] {
  let encounterId: string | undefined = currentEncounterId

  const allNutritionOrders: R4.INutritionOrder[] = []
  const latestNutritionOrder: R4.INutritionOrder | undefined =
    getLatestNutritionOrderFromBundleSorted(responseBundle)
  if (isForSpecificDoctor && latestNutritionOrder) {
    encounterId = getEncounterIdFromNutritionOrder(latestNutritionOrder)
  }
  try {
    if (responseBundle.total) {
      if (responseBundle.total > 0) {
        if (responseBundle.entry) {
          if (responseBundle.entry && responseBundle.entry.length > 0) {
            responseBundle.entry.forEach((e) => {
              if (
                e.resource?.resourceType === 'NutritionOrder' &&
                e.resource?.intent === 'plan'
              ) {
                if (isForSpecificDoctor) {
                  const encounterIdFromNutritionOrder: string | undefined =
                    getEncounterIdFromNutritionOrder(
                      e.resource as R4.INutritionOrder
                    )

                  if (encounterIdFromNutritionOrder === encounterId) {
                    allNutritionOrders.push(e.resource)
                  }
                } else {
                  allNutritionOrders.push(e.resource)
                }
              }
            })
          }
        }
      }
    }
    const finalOrders: R4.INutritionOrder[] = []
    if (allNutritionOrders.length > 0) {
      for (let i = 0; i < allNutritionOrders.length; i++) {
        if (
          getExtensionValueDataFromValueForDietOrder(
            allNutritionOrders[i].extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext'
          ).length > 1
        ) {
          finalOrders.push(allNutritionOrders[i])
        }
      }
    }

    return finalOrders
  } catch (error) {
    console.error(error)
  }

  return []
}

export function getFixedDietTimingCheck(diet: Diet[]): boolean {
  const orders: string[] = []

  for (let i = 0; i < diet.length; i++) {
    const eightOrder = diet[i].eight ?? []
    if (eightOrder.length > 0) {
      for (let k = 0; k < eightOrder.length; k++) {
        if (eightOrder[k].oralDiet) {
          const dietData = eightOrder[k].oralDiet
          if (dietData)
            if (
              getExtensionValueDataFromValueForDietOrder(
                dietData.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext'
              ).length > 0
            ) {
              orders.push('1')
            }
        }
      }
    }
    const fiveOrder = diet[i].five ?? []
    if (fiveOrder.length > 0) {
      for (let k = 0; k < fiveOrder.length; k++) {
        if (fiveOrder[k].oralDiet) {
          const dietData = fiveOrder[k].oralDiet
          if (dietData)
            if (
              getExtensionValueDataFromValueForDietOrder(
                dietData.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext'
              ).length > 0
            ) {
              orders.push('1')
            }
        }
      }
    }
    const nineOrder = diet[i].nine ?? []
    if (nineOrder.length > 0) {
      for (let k = 0; k < nineOrder.length; k++) {
        if (nineOrder[k].oralDiet) {
          const dietData = nineOrder[k].oralDiet
          if (dietData)
            if (
              getExtensionValueDataFromValueForDietOrder(
                dietData.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext'
              ).length > 0
            ) {
              orders.push('1')
            }
        }
      }
    }
    const sevenOrder = diet[i].seven ?? []
    if (sevenOrder.length > 0) {
      for (let k = 0; k < sevenOrder.length; k++) {
        if (sevenOrder[k].oralDiet) {
          const dietData = sevenOrder[k].oralDiet
          if (dietData)
            if (
              getExtensionValueDataFromValueForDietOrder(
                dietData.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext'
              ).length > 0
            ) {
              orders.push('1')
            }
        }
      }
    }
    const sixOrder = diet[i].six ?? []
    if (sixOrder.length > 0) {
      for (let k = 0; k < sixOrder.length; k++) {
        if (sixOrder[k].oralDiet) {
          const dietData = sixOrder[k].oralDiet
          if (dietData)
            if (
              getExtensionValueDataFromValueForDietOrder(
                dietData.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext'
              ).length > 0
            ) {
              orders.push('1')
            }
        }
      }
    }
    const twelveOrder = diet[i].twelve ?? []
    if (twelveOrder.length > 0) {
      for (let k = 0; k < twelveOrder.length; k++) {
        if (twelveOrder[k].oralDiet) {
          const dietData = twelveOrder[k].oralDiet
          if (dietData)
            if (
              getExtensionValueDataFromValueForDietOrder(
                dietData.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext'
              ).length > 0
            ) {
              orders.push('1')
            }
        }
      }
    }
    const twoOrder = diet[i].two ?? []
    if (twoOrder.length > 0) {
      for (let k = 0; k < twoOrder.length; k++) {
        if (twoOrder[k].oralDiet) {
          const dietData = twoOrder[k].oralDiet
          if (dietData)
            if (
              getExtensionValueDataFromValueForDietOrder(
                dietData.extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-repetition-type-ext'
              ).length > 0
            ) {
              orders.push('1')
            }
        }
      }
    }
  }
  if (orders.length > 1) {
    return true
  }
  return false
}

export function getNutritionOrdersForDietBasedOnCodes(
  llmBasedDietPlan: LLMDietPlanOutPut[],
  encounterId: string,
  patient: IPatient,
  preSelectedDietTimings: DietTiming[]
): INutritionOrder[] {
  const returnNutritionOrders: INutritionOrder[] = []
  const organizationId = getCurrentUserUnitDetails().id
  const practRoleRef: R4.IReference = getCurrentUserPractitionerRoleRef()!
  if (llmBasedDietPlan) {
    for (const item of llmBasedDietPlan) {
      const date = moment(item.dietDate).utc().toISOString()
      for (const timeWiseDiet of item.timeWiseDiets) {
        const code = timeWiseDiet.timeCoding
        const timeOfDay = timeWiseDiet.timePeriod.split('-')[1]
        const timeOfDayAsDate = getTimeForDietTimeBasedOnCode(
          preSelectedDietTimings,
          code,
          moment(timeOfDay, 'hh:mmA').format('HH:mm:ss')
        )
        console.log(
          '------------------timeOfDayAsDate-----------------',
          timeOfDayAsDate
        )
        const nutritionOrder: INutritionOrder = {
          resourceType: 'NutritionOrder',
          status: 'active',
          id: getUniqueTempId(),
          encounter: {
            reference: `Encounter/${encounterId}`,
          },

          orderer: practRoleRef,
          patient: {
            reference: `Patient/${patient.id}`,
          },
          intent: 'plan',
          dateTime: new Date().toISOString(),
          extension: [
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-unit-organization-ext',
              valueReference: {
                reference: `Organization/${organizationId}`,
              },
            },
          ],
          oralDiet: {
            extension: [
              {
                url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-intake-ext',
                extension: [
                  ...timeWiseDiet.dietItems.map((e) => {
                    const extension: IExtension = {
                      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-nutrition-product-code-ext',
                      valueCodeableConcept: {
                        coding: [
                          {
                            code: e.code,
                            display: e.display,
                            system: e.system,
                          },
                        ],
                      },
                    }
                    return extension
                  }),
                ],
              },
            ],
            schedule: [
              {
                event: [date],
                repeat: {
                  timeOfDay: [timeOfDayAsDate],
                },
                code: { coding: [timeWiseDiet.timeCoding] },
              },
            ],
          },
        }
        returnNutritionOrders.push(nutritionOrder)
      }
    }
  }

  console.log(
    '----------------------returnNutritionOrders-------------------',
    returnNutritionOrders
  )

  return returnNutritionOrders
}

export function getTimeForDietTimeBasedOnCode(
  preSelectedDietTiming: DietTiming[],
  code: ICoding,
  defaultTime: string
): string {
  const time = preSelectedDietTiming.find((e) => e.time?.code === code.code)
  if (time) {
    return time.timeOfTheDay
  }
  return defaultTime
}

export function createBundleTransactionForNutritionOrders(
  nutritionOrders: INutritionOrder[]
) {
  const bundleEntries: R4.IBundle_Entry[] = []

  nutritionOrders.forEach((e) => {
    const transactionEntry: R4.IBundle_Entry = {
      fullUrl: `${e.resourceType}`,
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: `${e.resourceType}`,
      } as R4.IBundle_Request,
      resource: {
        ...e,
      } as INutritionOrder,
    }
    bundleEntries.push(transactionEntry)
  })

  const transactionBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: bundleEntries,
  }

  return transactionBundle
}

export async function postBundleTransactionForNutritionOrders(
  transactionBundle: R4.IBundle
) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction('', transactionBundle)

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: R4.IBundle = response as R4.IBundle

  if (resp.entry && resp.entry.length > 0) {
    return true
  }

  return false
}
