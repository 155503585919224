/* eslint-disable @typescript-eslint/no-unused-vars */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'

import { Errors } from 'io-ts'
import _ from 'lodash'
import { LabObsData } from 'models/labObsData'
import { ObsRecordForSearch } from 'models/obsRecords'
import {
  ObsDataWithVal,
  ObsDataWithValForCatalog,
  ObsDetails,
  ObsServiceData,
  ObsServiceDataWithVal,
} from 'models/obsServiceData'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCodeOfSystemCodings,
  getIdentifierValueBySystem,
} from 'utils/fhirResourcesHelper'
import {
  getObServationArrangedData,
  getObServationData,
  getObServationDisplayData,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { logger } from 'utils/logger'
import { ObservationFinderStatus } from './observationFinderStatus'

const initialState: ObservationFinderStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const diagnosticObsFinderSlice = createSlice({
  name: 'diagnosticObsFinderSlice',
  initialState,
  reducers: {
    searchingDoctorDetails(
      state,
      action: PayloadAction<ObservationFinderStatus>
    ) {
      state.error = false
      state.searching = true
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = false
      state.activityDef = undefined
      state.observations = undefined
      state.observationDef = undefined
      state.finalLabObsData = undefined
      state.testData = undefined
      state.lftData = undefined
      state.lipidData = undefined
      state.rftData = undefined
      state.tftData = undefined
      state.urineData = undefined
      state.homa = undefined
      state.dexa = undefined
      state.serumElectraLytes = undefined
      state.searchObs = undefined
      state.obsDefVal = undefined
    },

    searchResults(state, action: PayloadAction<ObservationFinderStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = ''
      state.resultsAvailable = true
      state.activityDef = action.payload.activityDef
      state.observations = action.payload.observations
      state.observationDef = action.payload.observationDef
      state.finalLabObsData = action.payload.finalLabObsData
      state.testData = action.payload.testData
      state.lftData = action.payload.lftData
      state.lipidData = action.payload.lipidData
      state.rftData = action.payload.rftData
      state.tftData = action.payload.tftData
      state.urineData = action.payload.urineData
      state.homa = action.payload.homa
      state.dexa = action.payload.dexa
      state.serumElectraLytes = action.payload.serumElectraLytes
      state.searchObs = action.payload.searchObs
      state.obsDefVal = action.payload.obsDefVal
    },

    noDataFoundForSearch(
      state,
      action: PayloadAction<ObservationFinderStatus>
    ) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = true
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.activityDef = undefined
      state.observations = undefined
      state.finalLabObsData = undefined
      state.testData = undefined
      state.lftData = undefined
      state.lipidData = undefined
      state.rftData = undefined
      state.tftData = undefined
      state.urineData = undefined
      state.homa = undefined
      state.dexa = undefined
      state.serumElectraLytes = undefined
      state.searchObs = undefined
      state.obsDefVal = undefined
    },

    errorWhileSearching(state, action: PayloadAction<ObservationFinderStatus>) {
      state.error = true
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = false
      state.activityDef = undefined
      state.observations = undefined
      state.finalLabObsData = undefined
      state.testData = undefined
      state.lftData = undefined
      state.lipidData = undefined
      state.rftData = undefined
      state.tftData = undefined
      state.urineData = undefined
      state.homa = undefined
      state.dexa = undefined
      state.serumElectraLytes = undefined
      state.searchObs = undefined
      state.obsDefVal = undefined
    },
    resetState(state, action: PayloadAction<ObservationFinderStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.observations = undefined
      state.activityDef = undefined
      state.finalLabObsData = undefined
      state.testData = undefined
      state.lftData = undefined
      state.lipidData = undefined
      state.rftData = undefined
      state.tftData = undefined
      state.urineData = undefined
      state.homa = undefined
      state.dexa = undefined
      state.serumElectraLytes = undefined
      state.searchObs = undefined
    },
  },
})

export const searchObservationsForAddLabTest =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ObservationFinderStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(diagnosticObsFinderSlice.actions.searchingDoctorDetails(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()

      const searchParameters: any = {
        status: 'active',
        type: 'test,panel',
      }

      const response: any = await fhirClient.doGetResourceForMasterData(
        `/PlanDefinition?_count=200`,
        searchParameters
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        const labOfferingsResponse: R4.IBundle = relatedFhirDecodeRes.right
        if (labOfferingsResponse.entry) {
          if (labOfferingsResponse.entry.length > 0) {
            const planArray: R4.IPlanDefinition[] = labOfferingsResponse.entry
              .filter((e) => e.resource?.resourceType === 'PlanDefinition')
              .map((e) => e.resource as R4.IPlanDefinition)
            const newPlanArray: R4.IPlanDefinition[] = []
            for (let i = 0; i < planArray.length; i++) {
              if (planArray[i].extension !== undefined) {
                newPlanArray.push(planArray[i])
              } else {
                newPlanArray.push(planArray[i])
              }
            }

            const cbsPanel: ObsServiceData[] = []
            const lftPanel: ObsServiceData[] = []
            const rftPanel: ObsServiceData[] = []
            const lipidPanel: ObsServiceData[] = []
            const thPanel: ObsServiceData[] = []
            const urinePanel: ObsServiceData[] = []
            const homaPanel: ObsServiceData[] = []
            const dexaPanel: ObsServiceData[] = []
            const serumPanel: ObsServiceData[] = []
            newPlanArray.sort((a, b) =>
              a.title! > b.title! ? 1 : b.title! > a.title! ? -1 : 0
            )
            const obsDefineData: ObsDataWithValForCatalog[] = []
            await Promise.all(
              newPlanArray.map(async (e) => {
                const dataObs = await getObsDefinitions(e)

                const type = e.type
                  ? getCodeOfSystemCodings(
                      e.type!.coding ?? [],
                      'http://hl7.org/fhir/uv/order-catalog/CodeSystem/laboratory-service-definition-type'
                    )!.code ?? ''
                  : ''
                if (dataObs.length > 0) {
                  dataObs.forEach((value) => {
                    obsDefineData.push({
                      obsDef: value,
                      checked: false,
                      planDef: e,
                      name:
                        type.length > 0 && type === 'test'
                          ? 'Lab Test'
                          : e.title ?? '',
                      id: value.id ?? '',
                      obsDisplayName: value.preferredReportName ?? '',
                      order: type.length > 0 && type === 'test' ? 1 : 2,
                    })
                  })
                }
                // obsValData.push({
                //   name: e.title ?? '',
                //   obsDef: obsDefineData,
                // })

                console.log('---------testData------------', dataObs)
              })
            )
            console.log('---------testData------------', cbsPanel)

            state.testData = cbsPanel
              .sort((a, b) =>
                a.name! > b.name! ? 1 : b.name! > a.name! ? -1 : 0
              )
              .filter((e) => _.isEmpty(e.obsDef) === false)

            console.log('---------testData------------', state.testData)

            state.obsDefVal = obsDefineData.sort((a, b) =>
              a.order! > b.order! ? 1 : b.order! > a.order! ? -1 : 0
            )
            state.lipidData = lipidPanel
            state.rftData = rftPanel
            state.lftData = lftPanel
            state.tftData = thPanel
            state.urineData = urinePanel
            state.serumElectraLytes = serumPanel
            state.homa = homaPanel
            state.dexa = dexaPanel
            state.resultsAvailable = true
            state.searching = false
            state.error = false
            dispatch(diagnosticObsFinderSlice.actions.searchResults(state))
            return
          }
          state.resultsAvailable = false
          state.searching = false
          state.error = false
          state.noResultsAvailable = true
          dispatch(diagnosticObsFinderSlice.actions.noDataFoundForSearch(state))
          return
        }
        state.resultsAvailable = false
        state.searching = false
        state.error = false
        state.noResultsAvailable = true
        dispatch(diagnosticObsFinderSlice.actions.noDataFoundForSearch(state))
        return
      }
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(diagnosticObsFinderSlice.actions.errorWhileSearching(state))
      return
    } catch (error) {
      logger.error(error)
      state.resultsAvailable = false
      state.searching = false
      state.error = true
      state.noResultsAvailable = false
      state.errorMessage = 'Error while fetching results'
      dispatch(diagnosticObsFinderSlice.actions.errorWhileSearching(state))
    }
  }

export const resetObservationState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ObservationFinderStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      observations: undefined,
    }
    dispatch(diagnosticObsFinderSlice.actions.resetState(state))
  }

export async function getObsDefinitions(
  PlanDefinition: R4.IPlanDefinition
): Promise<R4.IObservationDefinition[]> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const activityParmeter: string[] = []
  let obsDef: R4.IObservationDefinition[] = []
  if (PlanDefinition) {
    if (PlanDefinition.action) {
      const actionData = PlanDefinition.action[0]
      if (actionData.action) {
        for (let i = 0; i < actionData.action.length; i++) {
          if (actionData.action[i].definitionCanonical) {
            const reference = actionData.action[i].definitionCanonical
            if (reference) {
              const activeId = reference.split(
                'http://wellopathy.com/ActivityDefinition/'
              )[1]

              activityParmeter.push(activeId)
            }
          }
        }
      }
    }
  }

  const searchParameters: any = {
    status: 'active',
    _count: 50,
  }
  const response: any = await fhirClient.doGetResourceForMasterData(
    `/ActivityDefinition?activitydef-code=${activityParmeter.toString()}&_include=ActivityDefinition:observationResultRequirement`,
    searchParameters
  )
  const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(response)

  const labOfferingsResponse: R4.IBundle = response

  if (labOfferingsResponse.entry && labOfferingsResponse.entry.length > 0) {
    obsDef = getObServationData(labOfferingsResponse)
  }

  return obsDef.sort((a, b) =>
    a.preferredReportName!.toLowerCase() > b.preferredReportName!.toLowerCase()
      ? 1
      : b.preferredReportName!.toLowerCase() >
        a.preferredReportName!.toLowerCase()
      ? -1
      : 0
  )
}
export function getPractitionerRoleObject(item: R4.IPractitionerRole) {
  const val: PractitionerWithRole = {
    id: item.id ?? '',
    name: item.practitioner?.display ?? '',
    gender: '',
    roleObject: item,
    fullName: '',
    color: '',
    primaryContact: '',
    status: '',
    phone: '',
    enabled: false,
  }
  return val
}

export function getLabTestDetails(
  cbcPanel: ObsServiceData[],
  lipidPanel: ObsServiceData[],
  rftPanel: ObsServiceData[],
  lftPanel: ObsServiceData[],
  thPanel: ObsServiceData[],
  urinePanel: ObsServiceData[],
  serumPanel: ObsServiceData[],
  homaPanel: ObsServiceData[],
  dexaPanel: ObsServiceData[]
): ObsRecordForSearch[] {
  const obsDetails: ObsRecordForSearch[] = []

  obsDetails.push({
    testName: 'HbA1c',
    id: `hba1c`,
    type: '',
  })
  obsDetails.push({
    testName: 'Hemoglobin',
    id: `homo`,
    type: '',
  })
  obsDetails.push({
    testName: 'Post Prandial Blood Sugar',
    id: `pp`,
    type: '',
  })
  obsDetails.push({
    testName: 'Serum C peptide',
    id: `serumc`,
    type: '',
  })
  obsDetails.push({
    testName: 'Fasting Blood Sugar',
    id: `bloodSugar`,
    type: '',
  })

  obsDetails.push({
    testName: 'Serum Creatinine',
    id: `serum`,
    type: '',
  })
  obsDetails.push({
    testName: 'Serum Urea',
    id: `serum0`,
    type: '',
  })

  if (cbcPanel.length > 0) {
    for (let i = 0; i < cbcPanel.length; i++) {
      if (cbcPanel[i].obsDef.length > 0) {
        for (let j = 0; j < cbcPanel[i].obsDef.length; j++)
          obsDetails.push({
            testName: `${
              cbcPanel[i].obsDef[j].preferredReportName ?? ''
            } - Complete Blood Counts`,
            id: `cbc${j}`,
            type: 'panel',
          })
      }
    }
  }

  if (lipidPanel.length > 0) {
    for (let i = 0; i < lipidPanel.length; i++) {
      if (lipidPanel[i].obsDef.length > 0) {
        for (let j = 0; j < lipidPanel[i].obsDef.length; j++)
          obsDetails.push({
            testName: `${
              lipidPanel[i].obsDef[j].preferredReportName ?? ''
            } - Lipid Profile`,
            id: `lipid${j}`,
            type: 'panel',
          })
      }
    }
  }

  return obsDetails
}

export default diagnosticObsFinderSlice.reducer
