import { R4 } from '@ahryman40k/ts-fhir-types'

import { Box, Typography } from '@material-ui/core'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { PurposeOfUse } from 'models/purposeOfUse'
import React, { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReactVisibilitySensor from 'react-visibility-sensor'

import { setSelectedSection } from 'redux/navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import { isAyurvedaDoctor } from 'services/userDetailsService'

import { getGenderOfPatient } from 'utils/fhirResourcesHelper'
import { isGenderFemale } from 'utils/fhirResoureHelpers/ipdHelper'

import { Addictions } from '../history/additctions'
import { Allergies } from '../history/allergies'
import { BowelsOPD } from '../history/BowelsOPD'

import { FamilyMedicalConditions } from '../history/familyMedicalConditions'
import { Habits } from '../history/habits'
import { MedicalConditions } from '../history/medical_conditions'

import { AppetiteOPD } from '../history/OPD/Appetite/appetiteOPD'
import { AyurvedaHistoryAndSubjective } from '../history/OPD/ayurvedaHistoryAndSubjective'
import { DietOPD } from '../history/OPD/DietOPD'
import { MedicationOPD } from '../history/OPD/medicationOPD'
import { MensurationDetailsOPD } from '../history/OPD/mensurationOPD/mensurations'
import { MicturitionOPD } from '../history/OPD/Micturition/micturition'
import { ObstricsOPD } from '../history/OPD/obstrics/obstricsListOPD'
import { OccupationalsOPD } from '../history/OPD/occupationalOPD'
import { SymptomProfile } from '../history/OPD/symtomps/systemtomsProfile'

import { Procedures } from '../history/procedures'

interface MedicalAndHealthHistoryOfPatientProps {
  fhirAppointmentDetails: FhirAppointmentDetail
  split: boolean
  date?: Date
}

const ToxScore = React.lazy(() => import('../history/OPD/ToxScore/ToxScore'))

export const MedicalAndHealthHistoryOfPatient: React.FC<MedicalAndHealthHistoryOfPatientProps> =
  ({
    fhirAppointmentDetails,
    split,
    date,
  }: MedicalAndHealthHistoryOfPatientProps) => {
    const { t } = useTranslation(['common'])
    const dispatch = useDispatch()
    const [addButton, showAddButton] = useState<boolean>(false)

    return (
      <ReactVisibilitySensor
        offset={{
          top: 100,
          bottom: 30,
        }}
        onChange={(isVisible) => {
          if (isVisible) {
            dispatch(setSelectedSection({ selectedSection: 'history' }))
          }
        }}
      >
        <section id='history' style={{ width: '100%' }}>
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            onMouseEnter={() => showAddButton(true)}
            onMouseLeave={() => showAddButton(false)}
          >
            <Box display='flex' flexDirection='row' width='100%'>
              <Box py={1}>
                <Typography variant='h6' style={{ fontWeight: 'bolder' }}>
                  {' '}
                  {t('labelCommon:History')}{' '}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='row'
            width={split ? '100%' : '100%'}
          >
            <MedicalConditions
              patient={fhirAppointmentDetails.patient}
              purposeOfUse={PurposeOfUse.OPDAppointment}
              requestingAppointmentId={fhirAppointmentDetails.appointment.id}
              status=''
              splitview={split}
              encounterId={
                fhirAppointmentDetails.encounter
                  ? fhirAppointmentDetails.encounter.id ?? ''
                  : ''
              }
              referral={
                fhirAppointmentDetails.appointment.basedOn ? true : undefined
              }
              date={date}
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%'>
            <Procedures
              patient={fhirAppointmentDetails.patient}
              purposeOfUse={PurposeOfUse.OPDAppointment}
              requestingAppointmentId={fhirAppointmentDetails.appointment.id}
              status=''
              splitView={split}
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <Allergies
              patient={fhirAppointmentDetails.patient}
              purposeOfUse={PurposeOfUse.OPDAppointment}
              requestingAppointmentId={fhirAppointmentDetails.appointment.id}
              status=''
              splitView={split}
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <MedicationOPD
              fhirAppointmentDetails={fhirAppointmentDetails}
              patient={fhirAppointmentDetails.patient}
              purposeOfUse={PurposeOfUse.OPDAppointment}
              requestingAppointmentId={fhirAppointmentDetails.appointment.id}
              status=''
              splitView={split}
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <SymptomProfile
              patient={fhirAppointmentDetails.patient}
              purposeOfUse={PurposeOfUse.OPDAppointment}
              appointmentDetails={fhirAppointmentDetails}
              requestingAppointmentId={fhirAppointmentDetails.appointment.id}
              status=''
              splitview={split}
              encounterId={
                fhirAppointmentDetails.encounter
                  ? fhirAppointmentDetails.encounter.id ?? ''
                  : ''
              }
            />
          </Box>
          {isGenderFemale(
            getGenderOfPatient(fhirAppointmentDetails.patient)
          ) && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <MensurationDetailsOPD
                fhirAppointmentDetails={fhirAppointmentDetails}
                status=''
                splitView={split}
              />
            </Box>
          )}

          {isGenderFemale(
            getGenderOfPatient(fhirAppointmentDetails.patient)
          ) && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <ObstricsOPD
                fhirAppointmentDetails={fhirAppointmentDetails}
                status=''
                splitView={split}
              />
            </Box>
          )}
          {isAyurvedaDoctor() === false && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <Suspense fallback={<div>Loading...</div>}>
                <ToxScore
                  fhirAppointmentDetails={fhirAppointmentDetails}
                  status=''
                  split={split}
                />
              </Suspense>
            </Box>
          )}

          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <OccupationalsOPD
              fhirAppointmentDetails={fhirAppointmentDetails}
              status=''
              splitView={split}
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <Habits
              fhirAppointmentDetails={fhirAppointmentDetails}
              splitView={split}
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <Addictions
              fhirAppointmentDetails={fhirAppointmentDetails}
              splitView={split}
            />
          </Box>
          <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <FamilyMedicalConditions
              fhirAppointmentDetails={fhirAppointmentDetails}
              splitView={split}
            />
          </Box>
          {!isAyurvedaDoctor() && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <DietOPD
                fhirAppointmentDetails={fhirAppointmentDetails}
                status=''
                splitview={split}
              />
            </Box>
          )}
          {!isAyurvedaDoctor() && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <AppetiteOPD
                fhirAppointmentDetails={fhirAppointmentDetails}
                status=''
                splitView={split}
              />
            </Box>
          )}
          {!isAyurvedaDoctor() && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <MicturitionOPD
                fhirAppointmentDetails={fhirAppointmentDetails}
                status=''
                splitView={split}
              />
            </Box>
          )}

          {!isAyurvedaDoctor() && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <BowelsOPD
                fhirAppointmentDetails={fhirAppointmentDetails}
                status=''
                splitView={split}
              />
            </Box>
          )}

          {/* {isGenderFemale(
            getGenderOfPatient(fhirAppointmentDetails.patient)
          ) && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='history'
                formName='History Menstrual History_OCA'
                observationCode='C0025329'
                isReadOnly={split}
              />
            </Box>
          )}
          {isGenderFemale(
            getGenderOfPatient(fhirAppointmentDetails.patient)
          ) && (
            <Box
              display='flex'
              flexDirection='row'
              width='100%'
              flexGrow
              pl={-2}
            >
              <ObservationOCAForViewOnly
                fhirAppointmentDetails={fhirAppointmentDetails}
                category='history'
                formName='History Obstetric History_OCA'
                observationCode='C4045976'
                isReadOnly={split}
              />
            </Box>
          )} */}
          {isAyurvedaDoctor() && (
            <Box display='flex' flexDirection='row' width='100%' flexGrow>
              <AyurvedaHistoryAndSubjective
                fhirAppointmentDetails={fhirAppointmentDetails}
                isReadOnly={split}
              />
            </Box>
          )}

          {/* <Box display='flex' flexDirection='row' width='100%' flexGrow>
            <Bowels
              fhirAppointmentDetails={fhirAppointmentDetails}
              status={
                fhirAppointmentDetails.mainTask.businessStatus!.coding
                  ? fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'initiate-discharge' ||
                    fhirAppointmentDetails.mainTask.businessStatus!.coding[0]
                      .code === 'discharged' ||
                    fhirAppointmentDetails.mainServiceRequest.status! ===
                      'completed'
                    ? 'completed'
                    : ''
                  : ''
              }
            />
          </Box> */}
        </section>
      </ReactVisibilitySensor>
    )
  }
